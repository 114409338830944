import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const rows2 = [
  { name: "DocumentDb", calories: 159 },
  { name: "Web Socket", calories: 159 },
  { name: "Map Content ", calories: 159 },
];

export default function BasicTable() {
  return (
    <>
      <div style={{ margin: "50px 400px", width: "700px" }}>
        <Button
          variant="outlined"
          style={{ margin: "20px 2px 10px 630px", width: "50px" }}
        >
          reload
        </Button>
        <TableContainer style={{ border: "1px solid black" }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Service</TableCell>
                <TableCell align="left">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows2.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="left">
                    {/* {row.calories} */}
                    {/* <Button variant="outlined">{row.calories}</Button> */}
                    <Checkbox {...label} checked color="success" />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}
