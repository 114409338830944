import React, { memo, useCallback } from "react";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

import { useGetListQuery } from "../../../slices/lists/api";
import { QueryListName } from "../../../slices/lists/types";
import useWebsocket from "../../../utils/useWebsocket";
import {
  DistributionSystemFilterType,
  InputParamsType,
  Result,
} from "../../../container/DistributionSystem/types";
import { WebsocketActionType } from "../../../slices/Websocket/types";
import { EventForms } from "../../../container/DistributionSystem/EventForms";
import { PaperPageError, PaperPageSpinner } from "../../PaperPage";

dayjs.extend(timezone);
dayjs.extend(utc);

type InGameResponseProps = {
  playerID: string;
};

const InGameResponse = ({ playerID }: InGameResponseProps) => {
  const { sendMessage } = useWebsocket();
  const {
    data: virtualGoodShortCodes,
    error: virtualGoodsError,
    refetch: virtualGoodsRefetch,
    isLoading: isLoadingVirtualGoods,
    isFetching: isFetchingVirtualGoods,
  } = useGetListQuery(QueryListName.virtualGoodShortCodes);

  const onCreate = useCallback(
    (data: Result) => {
      const inputParams: InputParamsType = {
        sendPushNotification: false,
        goods: data.goods,
        currencies: data.currencies,
        pets: data.pets,
        furniture: data.furniture,
        sendMailNotification: true,
        isMassMail: false,
        mailContent: data.mailContent,
        mailHeader: data.mailHeader,
        filterType: DistributionSystemFilterType.playerIds,
        arrayStringValues: data.filter as string[],
      };

      if (data.executionTimeUTC) {
        inputParams.executionTimeUTC = dayjs(data.executionTimeUTC)
          .tz("Asia/Tokyo", true)
          .utc()
          .unix();
      }

      const processedMsg = {
        action: WebsocketActionType.distributionSystemUpdateOnePlayerTask,
        inputParams: JSON.stringify(inputParams),
      };

      sendMessage(processedMsg);
    },
    [sendMessage]
  );

  if (isLoadingVirtualGoods) {
    return <PaperPageSpinner />;
  }

  if (virtualGoodsError) {
    return (
      <PaperPageError
        refetch={virtualGoodsRefetch}
        disabled={isFetchingVirtualGoods}
        message="refetch"
      />
    );
  }

  return (
    <EventForms
      onCreate={onCreate}
      inGameResponseMode
      playerID={playerID}
      vGoodShortCodes={virtualGoodShortCodes}
    />
  );
};

export default memo(InGameResponse);
