import { DynamicSchema } from "../types";
import { InputType } from "../consts";

export const playerMoneySpentSchema: DynamicSchema = {
  _root: {
    type: InputType.root,
    children: {
      _id: {
        type: InputType.text,
        label: "ID",
        isDisabled: true,
        additionalFields: [],
        children: {},
      },
      products: {
        type: InputType.link,
        label: "products",
        additionalFields: [],
        children: {},
      },
    },
  },
};
