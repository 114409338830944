import React, { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { StoreContentEntry } from "../../slices/storeContent/types";
import { ROUTE_PATHS } from "../../consts";
import { useTableUrlTools } from "../../utils/useTableUrlTools";
import dayjs from "dayjs";
import { v4 as uuid } from "uuid";

import {
  Box,
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import { Link } from "react-router-dom";
import { SpinnerButton } from "../../components/SpinnerButton";
import {
  WebsocketActionType,
  WebsocketMessageStatus,
} from "../../slices/Websocket/types";
import { useFeedSuccess } from "../../utils/feedHooks";
import useWebsocket from "../../utils/useWebsocket";
import { useSelector } from "../../store";
import { getWasWebsocketOpen } from "../../slices/Websocket/slice";
import { SpinnerDotted } from "spinners-react";

type ViewStoreContentProps = {
  allStoreContent: StoreContentEntry[];
  refetchList?: () => void;
};

const ViewStoreContent = ({
  allStoreContent,
  refetchList,
}: ViewStoreContentProps) => {
  const { t } = useTranslation();

  const mainRoute = ROUTE_PATHS.STORE_CONTENT;

  const { id, closeDrawer } = useTableUrlTools(mainRoute);

  const [isLoading, setIsLoading] = useState(false);
  const [websocketId, setWebsocketId] = useState<string | null>(null);

  const feedSuccess = useFeedSuccess();
  const { sendMessage, subscribeOnMessage, unsubscribeOnMessage } =
    useWebsocket();

  const wasWebsocketOpen = useSelector(getWasWebsocketOpen);

  const deleteStoreContent = useCallback(async () => {
    const randomId = uuid();

    const data = {
      _id: id,
    };

    const processedMsg = {
      action: WebsocketActionType.deleteStoreTabConfiguration,
      inputParams: JSON.stringify(data),
      id: randomId,
    };

    setWebsocketId(randomId);
    sendMessage(processedMsg);
    setIsLoading(true);
  }, [id, sendMessage]);

  const onMessageReceived = useCallback(
    (event: MessageEvent) => {
      const msg = JSON.parse(event.data);

      if (
        msg.action === WebsocketActionType.deleteStoreTabConfiguration &&
        msg.id === websocketId
      ) {
        if (msg.status === WebsocketMessageStatus.success) {
          feedSuccess(t("storeContentCreate.successMsgAfterDelete"));
          closeDrawer();
          setIsLoading(false);
          refetchList && refetchList();
        }
        if (msg.status === WebsocketMessageStatus.error) {
          setIsLoading(false);
        }
      }
    },
    [closeDrawer, websocketId, feedSuccess, refetchList, t]
  );

  useEffect(() => {
    if (wasWebsocketOpen && isLoading) {
      subscribeOnMessage(onMessageReceived);
    }

    return () => {
      unsubscribeOnMessage(onMessageReceived);
    };
  });

  const currentStoreContent = allStoreContent.find((item) => item._id === id);

  if (!allStoreContent.length) {
    return (
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <SpinnerDotted />
      </Box>
    );
  }

  if ((!currentStoreContent || !currentStoreContent._id) && !isLoading) {
    return (
      <Typography
        variant="body1"
        color="primary"
        sx={{ fontWeight: "bold", ml: 2.5, color: "primary.light" }}
      >
        {t("errors.noData")}
      </Typography>
    );
  }

  return (
    <Box
      sx={{
        m: 2,
        p: 2,
        backgroundColor: "white",
      }}
    >
      <h2>{t("storeContentDetails.title")}</h2>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>{t("storeContentDetails.id")}</TableCell>
            <TableCell>{currentStoreContent?._id}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>{t("storeContentDetails.tab")}</TableCell>
            <TableCell>{currentStoreContent?.tab}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>{t("storeContentDetails.start")}</TableCell>
            <TableCell>
              {dayjs
                .unix(currentStoreContent?.start)
                .tz("Asia/Tokyo")
                .format("YYYY/MM/DD, HH:mm Z")}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>{t("storeContentDetails.end")}</TableCell>
            <TableCell>
              {dayjs
                .unix(currentStoreContent?.end)
                .tz("Asia/Tokyo")
                .format("YYYY/MM/DD, HH:mm Z")}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <h2>{t("storeContentDetails.storeItems")}</h2>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>{t("storeContentDetails.n")}</TableCell>
            <TableCell>{t("storeContentDetails.lootboxId")}</TableCell>
            <TableCell>{t("storeContentDetails.discountPercentage")}</TableCell>
            <TableCell>{t("storeContentDetails.price")}</TableCell>
            <TableCell>{t("storeContentDetails.buyAmountLimit")}</TableCell>
            <TableCell>{t("storeContentDetails.storeItemStart")}</TableCell>
            <TableCell>{t("storeContentDetails.storeItemEnd")}</TableCell>
          </TableRow>
          {currentStoreContent?.storeItems.map((item, index) => (
            <TableRow key={`content-item-detail-${item.lootboxID}-${index}`}>
              <TableCell>{index}</TableCell>
              <TableCell>{item.lootboxID}</TableCell>
              <TableCell>{item.discountPercentage}</TableCell>
              <TableCell>{item.price}</TableCell>
              <TableCell>{item.buyAmountLimit}</TableCell>
              <TableCell>
                {item.availabilityStart
                  ? dayjs
                      .unix(item.availabilityStart)
                      .tz("Asia/Tokyo")
                      .format("YYYY/MM/DD, HH:mm Z")
                  : "-"}
              </TableCell>
              <TableCell>
                {item.availabilityEnd
                  ? dayjs
                      .unix(item.availabilityEnd)
                      .tz("Asia/Tokyo")
                      .format("YYYY/MM/DD, HH:mm Z")
                  : "-"}
              </TableCell>
              <TableCell>
                <Checkbox
                  disabled
                  checked={item.showAsOffer}
                  sx={{ margin: 0, padding: 0 }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <EditButton
        component={Link}
        to={`${ROUTE_PATHS.STORE_CONTENT}/${id}/edit`}
        variant="outlined"
      >
        {t("storeContentDetails.toEdit")}
      </EditButton>
      <SpinnerButton
        variant="contained"
        buttonName={t("storeContentCreate.delete")}
        loading={isLoading}
        type="submit"
        color="error"
        sx={{ marginTop: "20px" }}
        onClick={deleteStoreContent}
      />
    </Box>
  );
};

export default memo(ViewStoreContent);

const EditButton = styled(Button, {
  shouldForwardProp: () => true,
})<Record<string, unknown>>(({ theme }) => ({
  marginRight: theme.spacing(2),
}));
