import React from "react";

import { Dialog, DialogActions, DialogTitle, Button } from "@mui/material";

export type TeamMemberAlertProps = {
  message: string;
  isOpen: boolean;
  handleClickSave: () => void;
  handleClickCancel: () => void;
};

const TeamMemberAlert: React.FC<TeamMemberAlertProps> = ({
  message,
  isOpen,
  handleClickSave,
  handleClickCancel,
}) => {
  return (
    <Dialog
      open={isOpen}
      aria-labelledby="alertUp-dialog-title"
      aria-describedby="alertUp-dialog-description"
    >
      <DialogTitle id="alertUp-dialog-title">{message}</DialogTitle>
      <DialogActions>
        <Button onClick={handleClickSave} autoFocus>
          Yes
        </Button>
        <Button onClick={handleClickCancel}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default TeamMemberAlert;
