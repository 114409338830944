import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";

import { Helmet } from "react-helmet";
import { Box, Container, Grid, Stack, Tab, Tabs } from "@mui/material";
import { DataTable } from "../../components/DataTable/DataTable";
import { WideDrawer } from "../../components/WideDrawerForm/WideDrawer";
import { Edit } from "../../components/Edit/Edit";
import { EditPlayerHeader } from "../../components/Edit/EditPlayerHeader";
import SupportChat from "../../components/SupportChat/SupportChat";
import AlertMessage from "../../components/AlertMessage";
import OpenEditBtn from "../../components/OpenEditBtn";
import DeletePlayerAlertBody from "./DeletePlayerAlertBody";

import useWebsocket from "../../utils/useWebsocket";
import { getWasWebsocketOpen } from "../../slices/Websocket/slice";
import { useDispatch, useSelector } from "../../store";
import { useTranslation } from "react-i18next";
import { useTableUrlTools } from "../../utils/useTableUrlTools";
import { useTitle } from "../../utils/useTitle";
import {
  useEditPlayerPersonalValleyMutation,
  useEditPlayerMutation,
  useEditPetPlayerMutation,
  useGetPlayerPersonalValleysQuery,
  useGetPetPlayersQuery,
  useGetPlayersQuery,
  useGetPlayerMoneySpentQuery,
  useEditPlayerMoneySpentMutation,
} from "../../slices/players/api";
import { useFeedSuccess } from "../../utils/feedHooks";
import { useGetListQuery } from "../../slices/lists/api";
import { COLLECTION_NAMES, DB_NAMES, ROUTE_PATHS } from "../../consts";
import { playerSearchFieldsBack } from "./searchScheme";
import {
  WebsocketActionType,
  WebsocketMessageStatus,
} from "../../slices/Websocket/types";
import { ColumnData } from "../../components/CustomDataGrid/types";
import {
  getCurrentFeedbackID,
  getCurrentPlayerID,
  getUrlAfterCloseDrawer,
  setCurrentFeedbackID,
  setCurrentPlayerID,
  setUrlOnCloseDrawer,
} from "../../slices/players/slice";
import { QueryListName } from "../../slices/lists/types";
import { PlayerEntry } from "../../slices/players/types";

const searchFieldsFront = ["userName", "id", "displayName"];
const requiredFields = ["email", "displayName"];

const collectionNamePlayerData = COLLECTION_NAMES.PLAYER;
const collectionNamePlayerPersonalValley =
  COLLECTION_NAMES.PLAYER_PERSONAL_VALLEY;
const collectionNamePetPlayer = COLLECTION_NAMES.PET_PLAYER;
const collectionNamePlayerMoneySpent = COLLECTION_NAMES.PLAYER_MONEY_SPENT;
const dbName = DB_NAMES.SYSTEM;

enum PlayerTabsValue {
  playerData = "playerData",
  playerPersonalValley = "playerPersonalValley",
  petPlayer = "petPlayer",
  playerMoneySpent = "playerMoneySpent",
}

const Players = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const title = useTitle(t("players.title"));
  const editPlayerTitle = useTitle(t("players.editPlayer"));
  const editPlayerPersonalValleyTitle = useTitle(
    t("players.editPlayerPersonalValleys")
  );
  const editPetPlayerTitle = useTitle(t("players.editPetPlayers"));
  const editPlayerMoneySpentTitle = useTitle(t("players.editPlayerMoneySpent"));
  const wasWebsocketOpen = useSelector(getWasWebsocketOpen);
  const { sendMessage, subscribeOnMessage, unsubscribeOnMessage } =
    useWebsocket();
  const feedSuccess = useFeedSuccess();
  const { data: virtualGoodShortCodes, isLoading: isLoadingVirtualGoods } =
    useGetListQuery(QueryListName.virtualGoodShortCodes);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isAlertShown, setIsAlertShown] = useState(false);
  const [alertBodyValue, setAlertBodyValue] = useState("");
  const [isDeletePlayerSending, setIsDeletePlayerSending] = useState(false);
  const [currentPlayerName, setCurrentPlayerName] = useState("");
  const [refetchFunc, setRefetchFunc] = useState<null | (() => void)>(null);
  const [tabIndex, setTabIndex] = useState(PlayerTabsValue.playerData);

  const handleOpenEditBtnClick = useCallback(
    ({ playerID }) => {
      dispatch(setCurrentPlayerID(playerID));
    },
    [dispatch]
  );

  const dataTableColumns: ColumnData<PlayerEntry>[] = useMemo(
    () => [
      {
        width: 200,
        label: "N",
        dataKey: "id",
        renderCell: (rowData, rowIndex: number) => rowIndex + 1,
      },
      {
        width: 650,
        label: "Email",
        dataKey: "email",
        renderCell: ({ email }) => email,
      },
      {
        width: 650,
        label: "Display name",
        dataKey: "displayName",
        renderCell: ({ displayName }) => displayName,
      },
      {
        width: 120,
        label: "Open",
        dataKey: "open",
        renderCell: ({ _id }) => (
          <OpenEditBtn
            id={_id}
            routePath={ROUTE_PATHS.PLAYERS}
            handleOpenEditBtnClick={handleOpenEditBtnClick}
          />
        ),
      },
    ],
    [handleOpenEditBtnClick]
  );

  const { id, detailsDrawerOpen, editDrawerOpen, closeDrawer } =
    useTableUrlTools(ROUTE_PATHS.PLAYERS);
  const getQueryData = useGetPlayersQuery;
  const currentPlayerID = useSelector(getCurrentPlayerID) || id;
  const currentFeedbackID = useSelector(getCurrentFeedbackID);
  const urlAfterCloseDrawer = useSelector(getUrlAfterCloseDrawer);

  const onCloseDrawer = useCallback(() => {
    setIsEditMode(false);
    dispatch(setCurrentFeedbackID(null));
    dispatch(setCurrentPlayerID(null));
    closeDrawer(urlAfterCloseDrawer);
    dispatch(setUrlOnCloseDrawer(""));
  }, [closeDrawer, dispatch, urlAfterCloseDrawer]);

  const handleOpenAlert = useCallback(() => {
    setIsAlertShown(true);
  }, []);

  const handleCloseAlert = useCallback(() => {
    setIsAlertShown(false);
  }, []);

  const handleClickDelete = useCallback(() => {
    setIsDeletePlayerSending(true);

    const processedMsg = {
      action: WebsocketActionType.deleteAccount,
      inputParams: JSON.stringify({
        playerID: currentPlayerID,
      }),
    };

    sendMessage(processedMsg);
    setIsAlertShown(false);
  }, [currentPlayerID, sendMessage]);

  const onMessageReceived = useCallback(
    (event: MessageEvent) => {
      setIsDeletePlayerSending(false);

      const msg = JSON.parse(event.data);

      if (msg.action === WebsocketActionType.deleteAccount) {
        if (msg.status === WebsocketMessageStatus.success) {
          feedSuccess(t("players.deletePlayerSuccessMsg"));

          setIsEditMode(false);
          navigate(urlAfterCloseDrawer);
          dispatch(setUrlOnCloseDrawer(""));

          refetchFunc && refetchFunc();
        }
      }
    },
    [dispatch, feedSuccess, navigate, refetchFunc, t, urlAfterCloseDrawer]
  );

  useEffect(() => {
    if (wasWebsocketOpen) {
      subscribeOnMessage(onMessageReceived);
    }

    return () => {
      unsubscribeOnMessage(onMessageReceived);
    };
  }, [
    onMessageReceived,
    subscribeOnMessage,
    unsubscribeOnMessage,
    wasWebsocketOpen,
  ]);

  const handleChangeTab = useCallback(
    (event: SyntheticEvent, newValue: PlayerTabsValue) => {
      setIsEditMode(false);
      setTabIndex(newValue);
    },
    []
  );

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Container maxWidth="xl" sx={{ height: "100%" }}>
        <DataTable
          searchFieldsFront={searchFieldsFront}
          searchFieldsBack={playerSearchFieldsBack}
          getQueryData={getQueryData}
          collectionName={collectionNamePlayerData}
          dbName={dbName}
          columns={dataTableColumns}
          errorMessageEntity="players"
          setRefetchFunc={setRefetchFunc}
          requiredFields={requiredFields}
        />
      </Container>
      <WideDrawer
        open={detailsDrawerOpen || editDrawerOpen}
        close={onCloseDrawer}
      >
        <Box mx={2.5}>
          <Grid container spacing={3}>
            {currentPlayerID && (
              <Grid item xs={6}>
                <Stack>
                  <Box
                    sx={{
                      mt: 2.5,
                      borderBottom: 1,
                      borderColor: "divider",
                      backgroundColor: "white",
                    }}
                  >
                    <Tabs
                      value={tabIndex}
                      onChange={handleChangeTab}
                      variant="scrollable"
                      scrollButtons="auto"
                    >
                      <Tab
                        label={t("players.labelPlayerData")}
                        value={PlayerTabsValue.playerData}
                      />
                      <Tab
                        label={t("players.labelPlayerPersonalValleys")}
                        value={PlayerTabsValue.playerPersonalValley}
                      />
                      <Tab
                        label={t("players.labelPlayerPets")}
                        value={PlayerTabsValue.petPlayer}
                      />
                      <Tab
                        label={t("players.labelPlayerMoneySpent")}
                        value={PlayerTabsValue.playerMoneySpent}
                      />
                    </Tabs>
                  </Box>
                  {tabIndex === PlayerTabsValue.playerData && (
                    <>
                      <EditPlayerHeader
                        playerID={currentPlayerID}
                        isDeleteBtnActive={isEditMode}
                        isDeletePlayerSending={isDeletePlayerSending}
                        deletePlayer={handleOpenAlert}
                      />
                      <Edit
                        id={currentPlayerID}
                        collectionName={collectionNamePlayerData}
                        dbName={dbName}
                        title={editPlayerTitle}
                        getQueryData={getQueryData}
                        getEditItemTools={useEditPlayerMutation}
                        errorsDescriptionPath="players.errors.editPlayer"
                        vGoodsNames={virtualGoodShortCodes}
                        isVGoodsNamesLoading={isLoadingVirtualGoods}
                        setIsEditMode={setIsEditMode}
                        setPlayerName={setCurrentPlayerName}
                      />
                    </>
                  )}
                  {tabIndex === PlayerTabsValue.playerPersonalValley && (
                    <Edit
                      id={currentPlayerID}
                      collectionName={collectionNamePlayerPersonalValley}
                      dbName={dbName}
                      title={editPlayerPersonalValleyTitle}
                      getQueryData={useGetPlayerPersonalValleysQuery}
                      getEditItemTools={useEditPlayerPersonalValleyMutation}
                      errorsDescriptionPath="players.errors.editPlayerPersonalValleys"
                      setIsEditMode={setIsEditMode}
                      isEditBtnHidden
                    />
                  )}
                  {tabIndex === PlayerTabsValue.petPlayer && (
                    <Edit
                      id={currentPlayerID}
                      collectionName={collectionNamePetPlayer}
                      dbName={dbName}
                      title={editPetPlayerTitle}
                      getQueryData={useGetPetPlayersQuery}
                      getEditItemTools={useEditPetPlayerMutation}
                      errorsDescriptionPath="players.errors.editPetPlayers"
                      setIsEditMode={setIsEditMode}
                      isEditBtnHidden
                    />
                  )}
                  {tabIndex === PlayerTabsValue.playerMoneySpent && (
                    <Edit
                      id={currentPlayerID}
                      collectionName={collectionNamePlayerMoneySpent}
                      dbName={dbName}
                      title={editPlayerMoneySpentTitle}
                      getQueryData={useGetPlayerMoneySpentQuery}
                      getEditItemTools={useEditPlayerMoneySpentMutation}
                      errorsDescriptionPath="players.errors.editPlayerMoneySpent"
                      setIsEditMode={setIsEditMode}
                      isEditBtnHidden
                    />
                  )}
                </Stack>
                <AlertMessage
                  isShown={isAlertShown}
                  onClickOk={handleClickDelete}
                  onCancel={handleCloseAlert}
                  title={t("players.confirmDeleteTitle")}
                  textOk={t("buttonNames.deletePlayer")}
                  isBtnOkDisabled={alertBodyValue !== currentPlayerName}
                >
                  <DeletePlayerAlertBody
                    setAlertBodyValue={setAlertBodyValue}
                    alertBodyValue={alertBodyValue}
                    playerName={currentPlayerName}
                  />
                </AlertMessage>
              </Grid>
            )}
            <Grid item xs={6}>
              <SupportChat
                playerID={currentPlayerID}
                feedbackDataID={currentFeedbackID}
              />
            </Grid>
          </Grid>
        </Box>
      </WideDrawer>
    </>
  );
};

export default Players;
