import React, {
  Dispatch,
  LegacyRef,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import dayjs from "dayjs";

import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  Index,
  InfiniteLoader,
  List,
  ListRowProps,
} from "react-virtualized";
import { Stack } from "@mui/material";
import { ChatController } from "../ChatController";
import ChatUIMessage from "./ChatUIMessage";
import ChatUIInput from "./ChatUIInput";
import AlertMessage from "../../AlertMessage";

import { useTranslation } from "react-i18next";
import {
  InternalNoteParamsType,
  Message,
  MessageContent,
  TextActionRequest,
  UpdatedMessage,
} from "../types";
import { MessageSourceType } from "../../../slices/Websocket/types";

type ChatUIProps = {
  chatController: ChatController;
  isMsgSending: boolean;
  cancelledMsgValue?: MessageContent;
  onMsgDelete: (uuid: string) => void;
  loadPrevMessages: (msgsLength: number) => Promise<number>;
  totalDocs: number;
  setInternalNoteParams?: Dispatch<SetStateAction<InternalNoteParamsType>>;
  internalNoteParams?: InternalNoteParamsType;
  handleChangeStatus: () => void;
  playerID: string;
};

const DEFAULT_ROW_HEIGHT = 135;

const ChatUI = ({
  chatController,
  isMsgSending,
  onMsgDelete,
  loadPrevMessages,
  totalDocs,
  setInternalNoteParams,
  internalNoteParams,
  handleChangeStatus,
  playerID,
}: ChatUIProps) => {
  const { t } = useTranslation();

  const chatCtl = chatController;
  const cache = new CellMeasurerCache({
    defaultHeight: DEFAULT_ROW_HEIGHT,
    fixedWidth: true,
  });

  const [messages, setMessages] = useState(chatCtl.getMessages());
  const [actReq, setActReq] = useState(chatCtl.getActionRequest());
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [uuidMsgWillDelete, setUuidMsgWillDelete] = useState<string | null>(
    null
  );
  const [defaultEmailSubject, setDefaultEmailSubject] = useState<string>(
    t("supportChats.subjectTemplateRE")
  );
  const [isNextPageLoading, setIsNextPageLoading] = useState(false);
  const currentTotalDocsRef = useRef(totalDocs);
  const scrollToIndexRef = useRef(totalDocs);
  const showTimeRef = useRef(!!chatCtl.getOption().showDateTime);

  useEffect(() => {
    const handleMessagesChanged = (): void => {
      setMessages([...chatCtl.getMessages()]);
    };

    const handleActionChanged = (): void => {
      setActReq(chatCtl.getActionRequest());
    };

    chatCtl.addOnMessagesChanged(handleMessagesChanged);
    chatCtl.addOnActionChanged(handleActionChanged);
  }, [chatCtl]);

  useEffect(() => {
    const currentLastPlayerMsg = messages.reduceRight<Message<string> | null>(
      (result, msg) => {
        if (result === null) {
          return msg.msgSourceType === MessageSourceType.playerMail ||
            msg.msgSourceType === MessageSourceType.playerWebFeedback ||
            msg.msgSourceType === MessageSourceType.player
            ? msg
            : result;
        }
        return result;
      },
      null
    );

    switch (currentLastPlayerMsg?.msgSourceType) {
      case MessageSourceType.playerMail:
        if (currentLastPlayerMsg?.subject) {
          setDefaultEmailSubject(
            `${t("supportChats.subjectTemplateRE")} ${
              currentLastPlayerMsg?.subject
            }`
          );
        } else {
          setDefaultEmailSubject(
            `${t("supportChats.subjectTemplateUserUnregistered")} ${
              currentLastPlayerMsg?.email
            }`
          );
        }
        break;

      case MessageSourceType.player:
        setDefaultEmailSubject(
          `${t("supportChats.subjectTemplateUserRegistered")} ${
            currentLastPlayerMsg?.email
          }`
        );
        break;

      case MessageSourceType.playerWebFeedback:
        setDefaultEmailSubject(
          `${t("supportChats.subjectTemplateUserUnregistered")} ${
            currentLastPlayerMsg?.email
          }`
        );
        break;
      default:
        break;
    }
  }, [messages, t]);

  const handleCloseAlert = useCallback(() => {
    setIsAlertOpen(false);
    setUuidMsgWillDelete(null);
  }, []);

  const handleClickDelete = useCallback(() => {
    uuidMsgWillDelete && onMsgDelete(uuidMsgWillDelete);
    handleCloseAlert();
  }, [handleCloseAlert, uuidMsgWillDelete, onMsgDelete]);

  const handleOpenAlert = useCallback((uuid: string) => {
    setIsAlertOpen(true);
    setUuidMsgWillDelete(uuid);
  }, []);

  const updatedMessages = useMemo<
    (UpdatedMessage<string> | undefined)[]
  >(() => {
    let prevDate = dayjs(0);
    const filledMessages = messages.map((msg) => {
      let showDate = false;

      if (showTimeRef.current && !msg.removed) {
        const current = dayjs(msg.updatedAt ? msg.updatedAt : msg.createdAt);

        if (current.format("YYYYMMDD") !== prevDate.format("YYYYMMDD")) {
          showDate = true;
        }

        prevDate = current;
      }

      return {
        ...msg,
        showDate,
        showTime: showTimeRef.current,
      };
    });

    if (currentTotalDocsRef.current > messages.length) {
      const emptyMessages = Array.from(
        { length: currentTotalDocsRef.current - messages.length },
        () => undefined
      );
      const msgs = [...emptyMessages, ...filledMessages];

      currentTotalDocsRef.current = msgs.length;

      return msgs;
    } else {
      currentTotalDocsRef.current = messages.length;
    }

    scrollToIndexRef.current = currentTotalDocsRef.current;

    return filledMessages;
  }, [messages]);

  const isRowLoaded = useCallback(
    ({ index }: Index) => !!updatedMessages[index],
    [updatedMessages]
  );

  const loadNextPage = useCallback(async () => {
    setIsNextPageLoading(true);

    await loadPrevMessages(messages.length);

    setIsNextPageLoading(false);

    if (scrollToIndexRef.current !== -1) {
      scrollToIndexRef.current = -1;
    }
  }, [loadPrevMessages, messages.length]);

  const loadMoreRows = isNextPageLoading
    ? () =>
        new Promise(() => {
          return;
        })
    : loadNextPage;

  const rowRenderer = ({ key, index, style, parent }: ListRowProps) => {
    const msg = updatedMessages[index];

    return (
      <CellMeasurer
        key={key}
        parent={parent}
        cache={cache}
        columnIndex={0}
        rowIndex={index}
      >
        {({ registerChild }) => (
          <div
            ref={registerChild as LegacyRef<HTMLDivElement> | undefined}
            style={style}
          >
            {msg ? (
              <ChatUIMessage
                onDelete={handleOpenAlert}
                message={msg}
                showDate={msg?.showDate}
                showTime={msg?.showTime}
              />
            ) : (
              <div style={{ height: `${DEFAULT_ROW_HEIGHT}px` }}></div>
            )}
          </div>
        )}
      </CellMeasurer>
    );
  };

  return (
    <Stack
      sx={{
        height: "100%",
        width: "100%",

        "& > *": {
          maxWidth: "100%",
        },
      }}
    >
      <Stack
        sx={{
          flex: "1 1 0%",
          overflowY: "auto",
          WebkitOverflowScrolling: "touch",
          maxHeight: 500,
          minHeight: 500,
          bgcolor: "background.default",
          p: 1,
          pb: 2,
          "& > *": {
            maxWidth: "100%",
          },
        }}
      >
        {updatedMessages.length > 0 && (
          <InfiniteLoader
            isRowLoaded={isRowLoaded}
            loadMoreRows={loadMoreRows}
            rowCount={currentTotalDocsRef.current}
          >
            {({ onRowsRendered, registerChild }) => (
              <AutoSizer>
                {({ height, width }) => (
                  <List
                    ref={registerChild}
                    width={width}
                    height={height}
                    onRowsRendered={onRowsRendered}
                    rowCount={currentTotalDocsRef.current}
                    rowHeight={cache.rowHeight}
                    deferredMeasurementCache={cache}
                    rowRenderer={rowRenderer}
                    scrollToIndex={scrollToIndexRef.current}
                    scrollToAlignment="end"
                  />
                )}
              </AutoSizer>
            )}
          </InfiniteLoader>
        )}
      </Stack>

      <ChatUIInput
        playerID={playerID}
        chatController={chatCtl}
        actionRequest={actReq as TextActionRequest}
        isMsgSending={isMsgSending}
        defaultEmailSubject={defaultEmailSubject}
        setInternalNoteParams={setInternalNoteParams}
        internalNoteParams={internalNoteParams}
        handleChangeStatus={handleChangeStatus}
      />
      <AlertMessage
        isShown={isAlertOpen}
        onClickOk={handleClickDelete}
        onCancel={handleCloseAlert}
        title={t("messages.confirmDeletingMessage")}
        textOk={t("messages.deleteMessage")}
      />
    </Stack>
  );
};

export default memo(ChatUI);
