import ArrowRightAltOutlinedIcon from "@mui/icons-material/ArrowRightAltOutlined";
import React, { useCallback } from "react";
import { styled, TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import { searchFieldsBackType } from "../../DataTable/DataTableTypes";
import FormControl from "@mui/material/FormControl";

export type timeIntervalType = {
  dateFrom: number | null;
  dateTo: number | null;
};

export type SearchFieldsProps = {
  error: boolean;
  searchFieldsBack: searchFieldsBackType[];
  searchValueType: string;
  searchData: string | number | Date | null | undefined | timeIntervalType;
  addSearchData: (
    value: string | number | Date | null | timeIntervalType
  ) => void;
};

export const SearchFields = ({
  searchValueType,
  searchFieldsBack,
  error,
  searchData,
  addSearchData,
}: SearchFieldsProps) => {
  const currentFieldsType = searchFieldsBack.filter(
    (field) => field.key === searchValueType
  )[0].type;

  const elWithData = searchFieldsBack.filter(
    (el) => el.key === searchValueType
  )[0];

  let dropDownData: string[] | undefined = [];

  if (elWithData.data) {
    dropDownData = elWithData.data;
  }

  const handleChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      if (typeof event.target.value === "string") {
        addSearchData(event.target.value);
      }
    },
    [addSearchData]
  );

  const addSearchDataTimeRangeFrom = (from: string) => {
    addSearchData({
      dateFrom: Date.parse(from),
      // dateTo: searchData?.dateTo,
      dateTo:
        searchData && typeof searchData === "object" && "dateTo" in searchData
          ? searchData.dateTo
          : null,
    });
  };

  const addSearchDataTimeRangeTo = (to: string) => {
    addSearchData({
      // dateFrom: searchData?.dateFrom,
      dateFrom:
        searchData && typeof searchData === "object" && "dateFrom" in searchData
          ? searchData.dateFrom
          : null,
      dateTo: Date.parse(to),
    });
  };

  if (currentFieldsType === "dropDown") {
    return (
      <FormControl>
        <Select sx={{ width: "150px", height: "40px" }} onChange={handleChange}>
          {dropDownData.map((el, i) => (
            <MenuItem key={i} value={el}>
              {el}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  if (currentFieldsType === "text") {
    return (
      <SearchTextField
        error={error}
        variant="outlined"
        type="text"
        size="small"
        label={`Input ${searchValueType}`}
        value={searchData}
        onChange={(event) => addSearchData(event.currentTarget.value)}
      />
    );
  }

  if (currentFieldsType === "data") {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          value={searchData}
          onChange={(newValue) => {
            addSearchData(newValue);
          }}
          renderInput={(params) => (
            <TextField
              size="small"
              style={{ width: "230px", marginRight: "20px" }}
              {...params}
            />
          )}
        />
      </LocalizationProvider>
    );
  }

  if (currentFieldsType === "dataInterval") {
    return (
      <div
        style={{
          background: "white",
          border: "1px solid lightGray",
          borderRadius: "5px",
          display: "flex",
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={
              searchData &&
              typeof searchData === "object" &&
              "dateFrom" in searchData
                ? searchData.dateFrom
                : null
            }
            onChange={(newValue) => {
              // @ts-ignore
              addSearchDataTimeRangeFrom(newValue);
            }}
            renderInput={(params) => (
              <TextField
                size="small"
                variant="standard"
                style={{
                  width: "127px",
                  marginTop: "8px",
                  marginRight: "8px",
                  marginLeft: "4px",
                }}
                {...params}
              />
            )}
          />
        </LocalizationProvider>
        <span style={{ paddingTop: "8px" }}>
          <ArrowRightAltOutlinedIcon />
        </span>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={
              searchData &&
              typeof searchData === "object" &&
              "dateTo" in searchData
                ? searchData.dateTo
                : null
            }
            onChange={(newValue) => {
              // @ts-ignore
              addSearchDataTimeRangeTo(newValue);
            }}
            renderInput={(params) => (
              <TextField
                size="small"
                variant="standard"
                style={{
                  width: "127px",
                  marginTop: "8px",
                  marginLeft: "8px",
                  marginRight: "10px",
                }}
                {...params}
              />
            )}
          />
        </LocalizationProvider>
      </div>
    );
  }

  return <div></div>;
};

const SearchTextField = styled(TextField)({
  marginLeft: "5px",
});
