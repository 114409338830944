import React, { memo, useCallback, useEffect, useMemo, useState } from "react";

import { Box } from "@mui/system";
import { Stack, Typography } from "@mui/material";
import ControlledInputText from "../../components/inputs/ControlledInputText";
import ControlledInputSelect from "../../components/inputs/ControlledInputSelect";
import { CustomErrorBox } from "../../components/inputs/StyledComponents";
import { SpinnerButton } from "../../components/SpinnerButton";

import { useTranslation } from "react-i18next";
import { useFeedError, useFeedSuccess } from "../../utils/feedHooks";
import { useForm, useWatch } from "react-hook-form";
import { useGetVirtualGoodsQuery } from "../../slices/virtualGoods/api";
import { SetProbabilityConfigFormValues } from "./types";
import {
  PresetCount,
  PRESET_DEFAULT,
  ProbabilityConfigOptionName,
  ProbabilityElementName,
  setProbabilityConfigInitialValues,
  VirtualGoodType,
} from "./helpers";
import { DropDownDataItem } from "../../components/inputs/consts";
import { COLLECTION_NAMES, DB_NAMES } from "../../consts";
import {
  useCreateUpdateProbabilityConfigMutation,
  useCreateUpdateProbabilityPresetMutation,
  useGetProbabilityConfigMutation,
  useGetProbabilityDefaultPresetsQuery,
  useGetProbabilityPresetsQuery,
} from "../../slices/probabilityConfig/api";
import {
  CreateUpdateProbabilityConfigBody,
  ProbabilityItemType,
} from "../../slices/probabilityConfig/types";
import { QueryListName } from "../../slices/lists/types";
// import { useGetProbabilityConfigQuery } from "../../slices/probabilityConfig/api";

const SetProbabilityConfig = () => {
  const { t } = useTranslation();
  const feedError = useFeedError();
  const feedSuccess = useFeedSuccess();
  const [hasErrors, setHasErrors] = useState(false);
  const [mainElement, setMainElement] = useState("");
  const [isFormDisabled, setIsFormDisabled] = useState(true);
  const [isPresetDisabled, setIsPresetDisabled] = useState(true);

  const {
    isLoading: isLoadingVirtualGoods,
    data: virtualGoods,
    // error,
    // refetch,
  } = useGetVirtualGoodsQuery(QueryListName.virtualGoodBaseFields);

  const { data: probabilityDefaultPresets } =
    useGetProbabilityDefaultPresetsQuery();

  const {
    isLoading: isLoadingProbabilityPresets,
    isFetching: isFetchingProbabilityPresets,
    data: probabilityPresets,
    refetch: refetchProbabilityPresets,
  } = useGetProbabilityPresetsQuery();

  const shortCodeList = (): DropDownDataItem[] => {
    const data = virtualGoods?.reduce(
      (result, item) =>
        item.type === VirtualGoodType.VGOOD
          ? [
              ...result,
              {
                value: item.shortCode,
                label: item.shortCode,
              },
            ]
          : result,

      []
    );

    data?.sort((a, b) => {
      if (a.value < b.value) {
        return -1;
      }
      if (b.value > a.value) {
        return 1;
      }
      return 0;
    });

    return data || [];
  };

  const presetList = useMemo(() => {
    const data = probabilityPresets?.map((item) => ({
      value: item.name,
      label: item.name,
    }));

    data?.sort((a, b) => {
      if (a.value < b.value) {
        return -1;
      }
      if (b.value > a.value) {
        return 1;
      }
      return 0;
    });

    return [
      {
        value: PRESET_DEFAULT,
        label: PRESET_DEFAULT,
      },
      ...(data || []),
    ];
  }, [probabilityPresets]);

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitSuccessful, errors },
    setValue,
  } = useForm<SetProbabilityConfigFormValues>({
    defaultValues: setProbabilityConfigInitialValues,
  });

  const [shortCode, preset, name] = useWatch({
    control,
    name: ["shortCode", "preset", "name"],
  });

  const currentRarity = useMemo(() => {
    const value = virtualGoods?.find((item) => item.shortCode === shortCode);

    return value?.propertySet?.rarity.rarity;
  }, [shortCode, virtualGoods]);

  const [
    createUpdateProbabilityPreset,
    { isLoading: isLoadingCreateUpdateProbabilityPreset },
  ] = useCreateUpdateProbabilityPresetMutation();

  const createUpdateProbabilityConfigPreset = useCallback(async () => {
    try {
      if (name && name !== "" && name.length >= 3 && name !== "default") {
        const items: ProbabilityItemType = {};
        for (const probabilityConfigOptionName in ProbabilityConfigOptionName) {
          items[probabilityConfigOptionName] =
            control._formValues[probabilityConfigOptionName];
        }
        await createUpdateProbabilityPreset({ name, items }).unwrap();
        feedSuccess(
          t(
            "setProbabilityConfig.successMsgAfterCreateUpdateProbabilityConfigPresetSubmit"
          )
        );
        refetchProbabilityPresets();
      } else {
        feedError(
          t(
            "setProbabilityConfig.errorMsgAfterCreateUpdateProbabilityConfigPresetValidation"
          )
        );
      }
    } catch (error) {
      feedError(
        t(
          "setProbabilityConfig.errorMsgAfterCreateUpdateProbabilityConfigPresetSubmit"
        )
      );
    }
  }, [
    control._formValues,
    createUpdateProbabilityPreset,
    feedError,
    feedSuccess,
    name,
    refetchProbabilityPresets,
    t,
  ]);

  const [getProbabilityConfig] = useGetProbabilityConfigMutation();

  const getProbabilityConfigAction = useCallback(
    async (shortCode: string) => {
      setIsFormDisabled(true);
      const msgData = await getProbabilityConfig({
        shortCode,
        groupName: "items",
      }).unwrap();
      const probabilityElementNames = Object.keys(ProbabilityElementName);
      if (msgData) {
        for (const elementName of probabilityElementNames) {
          for (let i = 1; i <= 6; i += 1) {
            setValue(
              `${elementName}_${i}` as keyof SetProbabilityConfigFormValues,
              msgData[`${elementName}_${i}`]
            );
          }
        }
      }
      const virtualGoodItem = virtualGoods.find(
        (el) => el.shortCode === shortCode
      );

      const mainElement = virtualGoodItem.tags.find((el) =>
        probabilityElementNames.some((pr) => pr === el)
      );
      setMainElement(mainElement);
      setIsFormDisabled(false);
    },
    [getProbabilityConfig, setValue, virtualGoods?.docs]
  );

  useEffect(() => {
    if (shortCode) {
      getProbabilityConfigAction(shortCode);
    }
  }, [getProbabilityConfigAction, shortCode]);

  useEffect(() => {
    if (shortCode?.length > 0 && isPresetDisabled) {
      setIsPresetDisabled(false);
    }

    if (shortCode?.length < 1 && !isPresetDisabled) {
      setIsPresetDisabled(true);
    }
  }, [isPresetDisabled, shortCode?.length]);

  const [
    createUpdateProbabilityConfig,
    { isLoading: isLoadingCreateUpdateProbabilityConfig },
  ] = useCreateUpdateProbabilityConfigMutation();

  const createUpdateProbabilityConfigAction = useCallback(
    async (data: SetProbabilityConfigFormValues) => {
      try {
        const items: ProbabilityItemType = {};
        for (const probabilityConfigOptionName in ProbabilityConfigOptionName) {
          items[probabilityConfigOptionName] =
            data[probabilityConfigOptionName];
        }
        const obj: CreateUpdateProbabilityConfigBody = {
          shortCode: data.shortCode,
          groupName: "items",
          items,
        };
        await createUpdateProbabilityConfig(obj).unwrap();
        feedSuccess(t("setProbabilityConfig.successMsgAfterSubmit"));
        setIsFormDisabled(false);
      } catch (error) {
        feedError(t("setProbabilityConfig.errorMsgAfterSubmit"));
      }
    },
    [createUpdateProbabilityConfig, feedError, feedSuccess, t]
  );

  const onSubmitError = useCallback((errors) => {
    setHasErrors(true);
  }, []);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
      setIsFormDisabled(true);
      setMainElement("");
    }
  }, [isSubmitSuccessful, reset, setValue]);

  const handleSetPreset = useCallback(() => {
    const probabilityElementNames = Object.keys(ProbabilityElementName);
    switch (preset) {
      case "default": {
        const virtualGoodItem = virtualGoods?.find(
          (el) => el.shortCode === shortCode
        );

        const probabilityDefaultPresetItem = probabilityDefaultPresets.find(
          (el) =>
            el.element == mainElement &&
            el.rarity === virtualGoodItem.propertySet.rarity.rarity
        );

        for (const elementName of probabilityElementNames) {
          if (elementName === mainElement) {
            for (let i = 1; i <= 6; i += 1) {
              setValue(
                `${elementName}_${i}` as keyof SetProbabilityConfigFormValues,
                probabilityDefaultPresetItem[`level${i}Priority`]
              );
            }
          } else {
            for (let i = 1; i <= 6; i += 1) {
              setValue(
                `${elementName}_${i}` as keyof SetProbabilityConfigFormValues,
                probabilityDefaultPresetItem.defaultPriority
              );
            }
          }
        }
        break;
      }
      default: {
        const probabilityPresetItem = probabilityPresets.find(
          (el) => el.name == preset
        );
        for (const elementName of probabilityElementNames) {
          for (let i = 1; i <= 6; i += 1) {
            setValue(
              `${elementName}_${i}` as keyof SetProbabilityConfigFormValues,
              probabilityPresetItem.items[`${elementName}_${i}`]
            );
          }
        }
        setValue("name", preset);
        break;
      }
    }
  }, [
    preset,
    probabilityDefaultPresets,
    shortCode,
    virtualGoods,
    mainElement,
    setValue,
    probabilityPresets,
  ]);

  return (
    <Box
      sx={{
        m: 2,
        p: 2,
        bgcolor: "white",
      }}
    >
      <form
        onSubmit={handleSubmit(
          createUpdateProbabilityConfigAction,
          onSubmitError
        )}
      >
        <h2>{t("setProbabilityConfig.title")}</h2>
        <Stack direction="row" alignItems="center" spacing={1}>
          <ControlledInputSelect
            control={control}
            title={t("setProbabilityConfig.selectVirtualGood")}
            fieldName="shortCode"
            dropDownData={shortCodeList()}
            isLoadingDropdownData={isLoadingVirtualGoods}
            isRequired
          />
          {currentRarity && (
            <Title isDisabled>
              {t("setProbabilityConfig.rarity")}: {currentRarity}
            </Title>
          )}
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          <ControlledInputSelect
            control={control}
            title={t("setProbabilityConfig.selectPreset")}
            fieldName="preset"
            dropDownData={presetList}
            isLoadingDropdownData={
              isLoadingProbabilityPresets || isFetchingProbabilityPresets
            }
            isDisabled={isPresetDisabled}
          />
          <SpinnerButton
            type="button"
            onClick={handleSetPreset}
            buttonName={t("buttonNames.set")}
            loading={false}
            disabled={isPresetDisabled}
          />
        </Stack>

        <Stack direction="row" ml={2} my={4} spacing={7}>
          <Stack>
            <Title isDisabled={isFormDisabled}>
              {`${t("setProbabilityConfig.airTitle")}${
                mainElement === "air" ? t("setProbabilityConfig.main") : ""
              }`}
            </Title>
            {PresetCount.map((numb: 1 | 2 | 3 | 4 | 5 | 6) => (
              <ControlledInputText
                key={numb}
                control={control}
                title={numb}
                fieldName={`air_${numb}`}
                titleWidth={20}
                inputWidth={130}
                isInputDisabled={isFormDisabled}
                isNumeric
              />
            ))}
          </Stack>
          <Stack>
            <Title isDisabled={isFormDisabled}>
              {`${t("setProbabilityConfig.forestTitle")}${
                mainElement === "forest" ? t("setProbabilityConfig.main") : ""
              }`}
            </Title>
            {PresetCount.map((numb: 1 | 2 | 3 | 4 | 5 | 6) => (
              <ControlledInputText
                key={numb}
                control={control}
                title={numb}
                fieldName={`forest_${numb}`}
                titleWidth={20}
                inputWidth={130}
                isInputDisabled={isFormDisabled}
                isNumeric
              />
            ))}
          </Stack>
          <Stack>
            <Title isDisabled={isFormDisabled}>
              {`${t("setProbabilityConfig.groundTitle")}${
                mainElement === "ground" ? t("setProbabilityConfig.main") : ""
              }`}
            </Title>
            {PresetCount.map((numb: 1 | 2 | 3 | 4 | 5 | 6) => (
              <ControlledInputText
                key={numb}
                control={control}
                title={numb}
                fieldName={`ground_${numb}`}
                titleWidth={20}
                inputWidth={130}
                isInputDisabled={isFormDisabled}
                isNumeric
              />
            ))}
          </Stack>
          <Stack>
            <Title isDisabled={isFormDisabled}>
              {`${t("setProbabilityConfig.waterTitle")}${
                mainElement === "water" ? t("setProbabilityConfig.main") : ""
              }`}
            </Title>
            {PresetCount.map((numb: 1 | 2 | 3 | 4 | 5 | 6) => (
              <ControlledInputText
                key={numb}
                control={control}
                title={numb}
                fieldName={`water_${numb}`}
                titleWidth={20}
                inputWidth={130}
                isInputDisabled={isFormDisabled}
                isNumeric
              />
            ))}
          </Stack>
        </Stack>

        <Stack direction="row" alignItems="center" spacing={2}>
          <ControlledInputText
            control={control}
            title={t("setProbabilityConfig.configName")}
            fieldName="name"
            inputWidth={300}
            isInputDisabled={isFormDisabled}
          />
          <SpinnerButton
            type="button"
            onClick={createUpdateProbabilityConfigPreset}
            buttonName={t("buttonNames.save")}
            loading={isLoadingCreateUpdateProbabilityPreset}
            disabled={isFormDisabled}
          />
        </Stack>

        <Stack direction="row" alignItems="center" spacing={2} sx={{ my: 4 }}>
          <SpinnerButton
            variant="contained"
            buttonName={t("setProbabilityConfig.saveProbabilityConfig")}
            loading={isLoadingCreateUpdateProbabilityConfig}
            disabled={isFormDisabled}
          />
          {hasErrors && (
            <CustomErrorBox>
              {t("errors.hasErrorsOnSubmitMessage")}
            </CustomErrorBox>
          )}
        </Stack>
      </form>
    </Box>
  );
};

export default memo(SetProbabilityConfig);

const Title = ({ children, isDisabled }) => (
  <Typography
    variant="h6"
    sx={{
      ml: 2,
      color: isDisabled ? "rgba(0, 0, 0, 0.38)" : "initial",
    }}
  >
    {children}
  </Typography>
);
