import React from "react";
import { Controller } from "react-hook-form";
import { MenuItem } from "@mui/material";
import { rarityDropDown, typeAmountDropDown } from "./selectionData";

import {
  CustomBox,
  CustomTypography,
  CustomSelect,
  CustomSelectActive,
  CustomErrorBox,
} from "./EventFormsStyles";

import { TagsTypes } from "./types";

export const Tags = ({
  control,
  vGoodTags,
  watchActiveDirectCollectibles,
}: TagsTypes) => {
  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <CustomTypography style={{ width: "30px" }}>Tags</CustomTypography>
        <Controller
          control={control}
          name="col1"
          rules={{
            required: {
              value: !watchActiveDirectCollectibles,
              message: "required",
            },
          }}
          defaultValue={""}
          render={({ field, fieldState: { error } }) => {
            return (
              <div
                style={{
                  display: "flex",
                  width: "240px",
                  alignItems: "center",
                }}
              >
                <CustomSelectActive {...field}>
                  {vGoodTags.map((tag: string) => (
                    <MenuItem key={tag} value={tag}>
                      {tag}
                    </MenuItem>
                  ))}
                </CustomSelectActive>
                {error && <CustomErrorBox>{error.message}</CustomErrorBox>}
              </div>
            );
          }}
        />

        <CustomTypography style={{ width: "50px" }}>Rarity</CustomTypography>

        <Controller
          control={control}
          name="rarity1"
          defaultValue={"1"}
          render={({ field }) => {
            return (
              <CustomSelect {...field}>
                {rarityDropDown.map((line) => (
                  <MenuItem key={line.value} value={line.value}>
                    {line.label}
                  </MenuItem>
                ))}
              </CustomSelect>
            );
          }}
        />

        <Controller
          control={control}
          name="rarity2"
          defaultValue={"-"}
          render={({ field }) => {
            return (
              <CustomSelect {...field}>
                <MenuItem key={"-"} value={"-"}>
                  -
                </MenuItem>
                {rarityDropDown.map((line) => (
                  <MenuItem key={line.value} value={line.value}>
                    {line.label}
                  </MenuItem>
                ))}
              </CustomSelect>
            );
          }}
        />

        <Controller
          control={control}
          name="rarity3"
          defaultValue={"-"}
          render={({ field }) => {
            return (
              <CustomSelect {...field}>
                <MenuItem key={"-"} value={"-"}>
                  -
                </MenuItem>
                {rarityDropDown.map((line) => (
                  <MenuItem key={line.value} value={line.value}>
                    {line.label}
                  </MenuItem>
                ))}
              </CustomSelect>
            );
          }}
        />
      </div>

      <br />
      <br />
      <h3>How many items players need to collect</h3>

      <CustomBox style={{ marginLeft: "50px" }}>
        <CustomTypography>Type amount</CustomTypography>
        <Controller
          control={control}
          name="typeAmount1"
          defaultValue={"1"}
          render={({ field }) => {
            return (
              <CustomSelect {...field}>
                {typeAmountDropDown.map((line) => (
                  <MenuItem key={line.value} value={line.value}>
                    {line.label}
                  </MenuItem>
                ))}
              </CustomSelect>
            );
          }}
        />
      </CustomBox>
      <br />
    </>
  );
};
