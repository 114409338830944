import React, { useCallback, useState } from "react";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import i18n from "../../i18n";

import { QRCodeCanvas } from "qrcode.react";
import { Alert, Collapse, Stack, TextField, Typography } from "@mui/material";
import { SubmitSpinnerButton } from "../../components/SubmitSpinnerButton";

import { useDispatch, useSelector } from "../../store";
import { fieldError, useSubmitFormErrorHandler } from "../../utils/formHelpers";
import { getQRCodeLink } from "../../slices/auth/slice";
import { signInSecondStep } from "../../slices/auth/actions";
import { SecondStepFormInputs } from "./types";
import { ROUTE_PATHS } from "../../consts";

const signInFormSecondStepSchema = yup.object({
  authCode: yup
    .string()
    .length(6)
    .matches(/^\d+$/, "Input must contain only digits")
    .required(i18n.t("validationErrors.required")),
});

export const SignInSecondStep = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { control, handleSubmit } = useForm<SecondStepFormInputs>({
    resolver: yupResolver(signInFormSecondStepSchema),
  });
  const { catchFormError, formError, fieldsErrors } = useSubmitFormErrorHandler(
    t("error.signIn")
  );

  const QRCodeLink = useSelector(getQRCodeLink);
  const [isLoading, setIsLoading] = useState(false);

  const onSecondSubmit = useCallback(
    (fields: SecondStepFormInputs) => {
      setIsLoading(true);

      dispatch(signInSecondStep(fields))
        .unwrap()
        .then(() => {
          setIsLoading(false);
          navigate(ROUTE_PATHS.PLAYERS);
        })
        .catch((error) => {
          console.log("SecondStep error", error);

          setIsLoading(false);
          catchFormError(error);
        });
    },
    [catchFormError, dispatch, navigate]
  );

  return (
    <Stack
      spacing={3}
      sx={{ marginTop: "50px" }}
      component="form"
      autoComplete="off"
      onSubmit={handleSubmit(onSecondSubmit)}
    >
      <Typography component="h2" variant="h3">
        {t("sign in")}
      </Typography>
      {formError && (
        <Collapse in={!!formError}>
          <Alert severity="error">{formError}</Alert>
        </Collapse>
      )}
      {QRCodeLink?.length > 0 && <QRCodeCanvas value={QRCodeLink} size={200} />}
      <Controller
        name="authCode"
        control={control}
        render={({ field, fieldState: { invalid, error } }) => {
          const [serverInvalid, serverError] = fieldError(
            "authCode",
            fieldsErrors
          );

          return (
            <TextField
              variant="outlined"
              size="small"
              autoFocus
              label={t("authCode")}
              {...field}
              error={invalid || serverInvalid}
              helperText={error?.message || serverError}
            />
          );
        }}
      />
      <SubmitSpinnerButton loading={isLoading} />
    </Stack>
  );
};
