import React, { memo } from "react";

import { useTranslation } from "react-i18next";
import { useTitle } from "../../utils/useTitle";
import { useGetDailyActivePlayersKPIMutation } from "../../slices/analytics/api";
import ChartKPI from "../../components/Chart/ChartKPI";
import { KPINames } from "../../components/Chart/types";

const DailyActivePlayersKPIChart = () => {
  const { t } = useTranslation();
  const title = useTitle(t("navigation.dailyActivePlayersKpi"));

  return (
    <ChartKPI
      title={title}
      useGetKpiData={useGetDailyActivePlayersKPIMutation}
      kpiName={KPINames.dailyActivePlayersKPI}
    />
  );
};

export default memo(DailyActivePlayersKPIChart);
