export enum DeletePlayersFilterType {
  mongoQuery = "mongoQuery",
  playerIds = "playerIds",
}

export enum DeletePlayersStatus {
  active = "active",
  inProgress = "inProgress",
  applied = "applied",
  error = "error",
}

export type EventFormsTypes = {
  onCreate: (data: Result) => void;
};

type BaseResultType = {
  filterType: `${DeletePlayersFilterType}`;
};

export type Result = BaseResultType & {
  filter: string | string[];
};

export type InputParamsType = BaseResultType & {
  query?: string;
  arrayStringValues?: string[];
};

export type FormValues = {
  filterData: string;
  filterType: `${DeletePlayersFilterType}`;
};
