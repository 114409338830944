import { COGNITO_LOCAL_STORAGE_IDENTIFIER } from "../consts";
import { Roles } from "../types";

export const getUrl = (path: string) =>
  `${process.env.REACT_APP_PUBLIC_URL}${path}`;

export const getDefaultHeaders = (auth?: boolean) => {
  const headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  if (auth) {
    return {
      ...headers,
      Authorization: `Bearer ${getAccessToken()}`,
    };
  }

  return headers;
};

/**
 * Same as getDefaultHeaders but meant to be used in RTK-Query
 **/
export const prepareHeaders = (headers: Headers) => {
  const defaultHeaders = getDefaultHeaders(true);
  for (const [key, value] of Object.entries(defaultHeaders)) {
    headers.set(key, value);
  }
  return headers;
};

export const getAccessToken = (): string => {
  const LastAuthUser = localStorage.getItem(
    `${COGNITO_LOCAL_STORAGE_IDENTIFIER}.LastAuthUser`
  );
  const accessToken = localStorage.getItem(
    `${COGNITO_LOCAL_STORAGE_IDENTIFIER}.${LastAuthUser}.accessToken`
  );

  return accessToken || "";
};

export const getIdTokenToken = (): string => {
  const LastAuthUser = localStorage.getItem(
    `${COGNITO_LOCAL_STORAGE_IDENTIFIER}.LastAuthUser`
  );
  const idToken = localStorage.getItem(
    `${COGNITO_LOCAL_STORAGE_IDENTIFIER}.${LastAuthUser}.idToken`
  );

  return idToken || "";
};

export const sendTokensToIframe = (groups: Roles) => {
  const iframe = document.querySelector("iframe");

  const LastAuthUser = localStorage.getItem(
    `${COGNITO_LOCAL_STORAGE_IDENTIFIER}.LastAuthUser`
  );
  const accessToken = localStorage.getItem(
    `${COGNITO_LOCAL_STORAGE_IDENTIFIER}.${LastAuthUser}.accessToken`
  );
  const idToken = localStorage.getItem(
    `${COGNITO_LOCAL_STORAGE_IDENTIFIER}.${LastAuthUser}.idToken`
  );

  iframe?.contentWindow?.postMessage(
    JSON.stringify({
      type: "SEND_TOKENS",
      accessToken,
      idToken,
      groups,
    }),
    "*"
  );
};
