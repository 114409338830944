import React, { memo, ReactNode, useMemo } from "react";

import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Circular } from "../spinners/Circular";

import { useTranslation } from "react-i18next";
import { useGetLists } from "../../utils/useGetLists";
import { ListNameType } from "../../slices/lists/types";

export type InputChipsType = {
  isDirty?: boolean;
  isDisabled?: boolean;
  value?: string[];
  handleChange?: (value: any) => void;
  label?: ReactNode;
  listName?: ListNameType;
  error?: boolean;
  width?: string | number;
  isFullWidth?: boolean;
};

const InputChips = ({
  isDirty,
  isDisabled,
  label,
  listName,
  value = [],
  handleChange,
  error = false,
  width,
  isFullWidth = true,
}: InputChipsType) => {
  const { t } = useTranslation();
  const { isLoadingListData, listData, listDataError } = useGetLists(listName);

  const currTagList = useMemo(() => {
    let tagsMerged: (string | number)[] = [];
    const tagList: (string | number)[] = listData || [];

    if (tagList?.length > 0) {
      tagsMerged = tagList;

      if (value !== undefined) {
        tagsMerged = [
          ...value,
          ...tagList.filter((tag: string) => !value.includes(tag)),
        ];
      }
    }
    return tagsMerged;
  }, [listData, value]);

  if (listDataError) {
    return (
      <div style={{ color: "pink" }}>...error on fetching data for chips</div>
    );
  }

  return isLoadingListData ? (
    <Circular />
  ) : (
    <Autocomplete
      onChange={(e, value) => {
        handleChange(value);
      }}
      disabled={isDisabled}
      multiple
      fullWidth={isFullWidth}
      freeSolo
      defaultValue={value}
      options={currTagList}
      value={value}
      getOptionLabel={(option) => `${option}`}
      renderInput={(params) => (
        <TextField
          {...params}
          color={isDirty ? "error" : "primary"}
          focused={isDirty}
          variant="outlined"
          label={label}
          error={error}
          placeholder={isDisabled ? "" : t("typeHere")}
          sx={{ width: width || "auto" }}
        />
      )}
      {...(listName && {
        renderTags: (value: readonly (string | number)[], getTagProps) =>
          value.map((option: string | number, index: number) => (
            <Chip
              variant="outlined"
              label={option}
              key={option}
              {...getTagProps({ index })}
            />
          )),
      })}
    />
  );
};

export default memo(InputChips);
