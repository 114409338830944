import React, { useCallback } from "react";
import {
  AppBar,
  Box,
  Button,
  Divider,
  IconButton,
  styled,
  Toolbar,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/logo.png";
import NavigationDrawer from "./NavigationDrawer/NavigationDrawer";
import { useTranslation } from "react-i18next";
import { Menu } from "@mui/icons-material";
import { useLocalStorage } from "usehooks-ts";
import { LS_KEY_DRAWER_OPEN, ROUTE_PATHS } from "../../consts";
import { useDispatch, useSelector } from "../../store";
import { logOut } from "../../slices/auth/actions";
import { getIsAuthenticated } from "../../slices/auth/slice";

const Header = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(getIsAuthenticated);

  const [drawerOpenState, setDrawerOpenState] = useLocalStorage(
    LS_KEY_DRAWER_OPEN,
    true
  );
  const openDrawer = useCallback(
    () => setDrawerOpenState(true),
    [setDrawerOpenState]
  );
  const closeDrawer = useCallback(
    () => setDrawerOpenState(false),
    [setDrawerOpenState]
  );

  const onLogOutClick = useCallback(() => {
    dispatch(logOut());
    navigate(ROUTE_PATHS.SIGN_IN);
  }, [dispatch, navigate]);

  return (
    <>
      <HeaderContainer
        position="fixed"
        drawerOpen={isAuthenticated && drawerOpenState}
      >
        <StyledToolbar>
          <ToolbarLeftBox>
            {isAuthenticated && !drawerOpenState && (
              <StyledIconButton onClick={openDrawer}>
                <Menu />
              </StyledIconButton>
            )}
            <Link to="/">
              <Img src={logo} style={{ backgroundColor: "LightBlue" }} />
            </Link>
          </ToolbarLeftBox>
          {isAuthenticated && (
            <Button variant="outlined" onClick={onLogOutClick}>
              {t("logOut")}
            </Button>
          )}
        </StyledToolbar>
        <Divider />
      </HeaderContainer>
      <NavigationDrawer
        open={isAuthenticated && drawerOpenState}
        closeDrawer={closeDrawer}
      />
    </>
  );
};

export default Header;

const HeaderContainer = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== "drawerOpen",
})<{
  drawerOpen?: boolean;
}>(({ theme, drawerOpen }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: "none",
  width: drawerOpen ? "calc(100% - 240px)" : "100%",
  marginLeft: drawerOpen ? "240px" : 0,
}));

const StyledToolbar = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between",
});

const ToolbarLeftBox = styled(Box)({
  display: "flex",
});

const StyledIconButton = styled(IconButton)({
  marginRight: "20px",
});

const Img = styled("img")({
  display: "block",
  maxHeight: "40px",
});
