import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { v4 as uuid } from "uuid";
import { useTranslation } from "react-i18next";

import { Box, Stack, Typography } from "@mui/material";
import { ChatController } from "./ChatController";
import ChatUI from "./ChatUI/ChatUI";
import { Dotted } from "../spinners/Dotted";

import {
  useGetMorePrevMessagesMutation,
  useGetPrevMessagesQuery,
} from "../../slices/feedback/api";
import useWebsocket from "../../utils/useWebsocket";
import { useFeedError } from "../../utils/feedHooks";
import {
  ChatMessageTypes,
  Message,
  MessageContent,
  PrevMessage,
  ActionResponse,
  InputParamsChatMsg,
  InternalNoteParamsType,
} from "./types";
import {
  WebsocketActionType,
  WebsocketMessageStatus,
  MessageSourceType,
} from "../../slices/Websocket/types";
import { SearchParams } from "../../types";
import {
  CHAT_STATUSES,
  COGNITO_LOCAL_STORAGE_IDENTIFIER,
  MSG_PAGE_STEP,
} from "../../consts";

const { TEXT, PENDING, SENDING, SYSTEM } = ChatMessageTypes;

const defaultInternalNoteParams: InternalNoteParamsType = {
  sendNotificationToSlack: true,
  chatStatusValue: "",
  initialChatStatusValue: "",
  isChatStatusChanging: false,
};

type SupportChatProps = {
  playerID: string | null;
  feedbackDataID: string | null;
};

const SupportChat = ({ playerID, feedbackDataID }: SupportChatProps) => {
  const feedError = useFeedError();
  const { t } = useTranslation();
  const [chatCtl] = useState(
    new ChatController({
      showDateTime: true,
      delay: 0,
    })
  );
  const { sendMessage, subscribeOnMessage, unsubscribeOnMessage } =
    useWebsocket();

  const [isMsgSending, setIsMsgSending] = useState(false);
  const [cancelledMsgValue, setCancelledMsgValue] =
    useState<MessageContent>("");
  const [internalNoteParams, setInternalNoteParams] = useState(
    defaultInternalNoteParams
  );
  const currentFeedbackDataID = useRef(feedbackDataID);

  const adminEmail = useMemo(() => {
    const LastAuthUser = localStorage.getItem(
      `${COGNITO_LOCAL_STORAGE_IDENTIFIER}.LastAuthUser`
    );
    const userDataJSON = localStorage.getItem(
      `${COGNITO_LOCAL_STORAGE_IDENTIFIER}.${LastAuthUser}.userData`
    );
    const userAttributes =
      userDataJSON && JSON.parse(userDataJSON)?.UserAttributes;
    const email = userAttributes?.find(
      (item: any) => item?.Name === "email"
    )?.Value;

    return email;
  }, []);

  const prevMessagesQueryBody = useMemo(() => {
    const search: SearchParams = playerID
      ? { playerID }
      : { _id: feedbackDataID };
    return {
      page: 1,
      limit: MSG_PAGE_STEP,
      search,
    };
  }, [feedbackDataID, playerID]);

  const { isLoading: isPrevMessagesLoading, data: prevMessagesData } =
    useGetPrevMessagesQuery(prevMessagesQueryBody);

  if (!currentFeedbackDataID.current) {
    currentFeedbackDataID.current = prevMessagesData?.docs?.[0]?._id || null;
  }

  const [getMorePrevMessages, { isLoading: isGetMorePrevMessagesLoading }] =
    useGetMorePrevMessagesMutation({
      selectFromResult: ({ isLoading }) => ({
        isLoading,
      }),
    });

  const loadPrevMessages = useCallback(
    async (msgsLength: number) => {
      const search: SearchParams = playerID
        ? { playerID }
        : { _id: feedbackDataID };
      const msgData = await getMorePrevMessages({
        page: 1,
        limit: MSG_PAGE_STEP,
        search,
        offset: msgsLength,
      }).unwrap();

      const messages = msgData?.docs;
      const prevMessages =
        messages?.length > 0
          ? messages.map<Message<MessageContent>>((msg: PrevMessage) => ({
              type: msg.type === SYSTEM ? SYSTEM : TEXT,
              content: msg.value,
              self:
                msg.type === MessageSourceType.admin ||
                msg.type === MessageSourceType.adminMail ||
                msg.type === MessageSourceType.internalNotes ||
                msg.type === MessageSourceType.system,
              createdAt: new Date(msg.timeStamp * 1000),
              username: msg.displayName,
              removed: msg.removed,
              msgSourceType: msg.type,
              uuid: msg.uuid,
              email: msg.email,
              subject: msg.subject,
              webFeedbackModel: msg.webFeedbackModel,
              attachments: msg.attachments,
              adminOriginalEmail: msg.adminOriginalEmail,
            }))
          : [];

      chatCtl.addGroupMessages(prevMessages);

      return msgData.totalDocs;
    },
    [chatCtl, feedbackDataID, getMorePrevMessages, playerID]
  );

  useEffect(() => {
    const messages = prevMessagesData?.docs;
    if (messages && messages.length > 0) {
      const lastMsg = messages[messages.length - 1];

      if (lastMsg?.status) {
        setInternalNoteParams((prev) => ({
          ...prev,
          chatStatusValue: lastMsg.status,
          initialChatStatusValue: lastMsg.status,
        }));
      }

      const prevMessages = messages.map<Message<MessageContent>>(
        (msg: PrevMessage) => ({
          type: msg.type === SYSTEM ? SYSTEM : TEXT,
          content: msg.value,
          self:
            msg.type === MessageSourceType.admin ||
            msg.type === MessageSourceType.adminMail ||
            msg.type === MessageSourceType.internalNotes ||
            msg.type === MessageSourceType.system,
          createdAt: new Date(msg.timeStamp * 1000),
          username: msg.displayName,
          removed: msg.removed,
          msgSourceType: msg.type,
          uuid: msg.uuid,
          email: msg.email,
          subject: msg.subject,
          webFeedbackModel: msg.webFeedbackModel,
          attachments: msg.attachments,
          adminOriginalEmail: msg.adminOriginalEmail,
        })
      );

      chatCtl.setMessages(prevMessages);
    }
  }, [chatCtl, prevMessagesData]);

  const handleMsgDelete = useCallback(
    (uuid: string) => {
      setIsMsgSending(true);

      const processedMsg = {
        action: WebsocketActionType.removeFeedback,
        inputParams: JSON.stringify({
          uuid,
          feedbackDataID: currentFeedbackDataID.current,
        }),
      };
      sendMessage(processedMsg);
    },
    [sendMessage]
  );

  const onMessageSent = useCallback(
    (message: ActionResponse) => {
      const inputParams: InputParamsChatMsg = {
        adminOriginalEmail: adminEmail,
        feedbackDataID: currentFeedbackDataID.current,
        sendNotificationToSlack: internalNoteParams.sendNotificationToSlack,
        playerID,
      };

      if (
        message.msgActionType === WebsocketActionType.internalNoteFeedbackAdmin
      ) {
        inputParams.value = message.value;

        const processedMsg = {
          action: message.msgActionType,
          inputParams: JSON.stringify(inputParams),
          uuid: message.uuid,
        };
        sendMessage(processedMsg);

        chatCtl.addMessage({
          type: SENDING,
          content: message.value,
          msgActionType: message.msgActionType,
          self: true,
          createdAt: new Date(),
          uuid: message.uuid,
        });
      } else {
        inputParams.body = message.value;
        inputParams.subject = message.subject;

        const processedMsg = {
          action: message.msgActionType,
          inputParams: JSON.stringify(inputParams),
          uuid: message.uuid,
        };

        const onTimeOut = () => {
          sendMessage(processedMsg);

          chatCtl.updateMessage(message.uuid, {
            type: SENDING,
            content: message.value,
            subject: message.subject,
            self: true,
            uuid: message.uuid,
          });
        };

        const onCancel = (sentMsg: Message<MessageContent>) => {
          chatCtl.removeMessage(sentMsg.uuid);
          setCancelledMsgValue(sentMsg.content);
          setIsMsgSending(false);
        };

        chatCtl.addMessage({
          type: PENDING,
          content: message.value,
          subject: message.subject,
          msgActionType: message.msgActionType,
          self: true,
          onTimeOut,
          onCancel,
          createdAt: new Date(),
          uuid: message.uuid,
        });
      }

      setIsMsgSending(true);
    },
    [
      adminEmail,
      internalNoteParams.sendNotificationToSlack,
      playerID,
      sendMessage,
      chatCtl,
    ]
  );

  const onMessageReceived = useCallback(
    (event: MessageEvent) => {
      const msgReceived = JSON.parse(event.data);
      const receivedPlayerId = msgReceived.data?.playerID;
      const receivedFeedbackDataID = msgReceived.data?.feedbackDataID;

      if (
        receivedPlayerId === playerID ||
        receivedFeedbackDataID === currentFeedbackDataID.current
      ) {
        const msgReceivedDataMessage = msgReceived.data?.message;

        switch (msgReceived?.action) {
          case WebsocketActionType.replyFeedbackAdmin:
          case WebsocketActionType.replyFeedbackEmailAdmin:
          case WebsocketActionType.internalNoteFeedbackAdmin:
            {
              const messages = chatCtl.getMessages();
              const sentMsg = messages?.find((msg) => msg.type === SENDING);

              if (sentMsg) {
                const msgStatus = msgReceived?.status;

                if (msgStatus === WebsocketMessageStatus.error) {
                  chatCtl.removeMessage(sentMsg.uuid);
                  setCancelledMsgValue(sentMsg.content);

                  feedError(
                    t(
                      "messages.somethingWentWrongAndActionWithMessageWasntSuccessful"
                    )
                  );
                } else {
                  chatCtl.updateMessage(sentMsg.uuid, {
                    type: TEXT,
                    content: msgReceivedDataMessage.value,
                    self: true,
                    createdAt: new Date(
                      msgReceivedDataMessage.timeStamp * 1000
                    ),
                    username: msgReceivedDataMessage.admin?.displayName,
                    msgSourceType: msgReceivedDataMessage.type,
                    uuid: msgReceivedDataMessage.uuid,
                    webFeedbackModel: msgReceivedDataMessage.webFeedbackModel,
                    attachments: msgReceivedDataMessage.attachments,
                    adminOriginalEmail:
                      msgReceivedDataMessage.adminOriginalEmail,
                  });
                }
                setInternalNoteParams((prev) => ({
                  ...prev,
                  sendNotificationToSlack:
                    defaultInternalNoteParams.sendNotificationToSlack,
                }));
                setIsMsgSending(false);
              }
            }
            break;

          case WebsocketActionType.sendFeedback:
            if (
              msgReceivedDataMessage?.type === MessageSourceType.player ||
              msgReceivedDataMessage?.type ===
                MessageSourceType.playerWebFeedback ||
              msgReceivedDataMessage?.type === MessageSourceType.playerMail
            ) {
              chatCtl.addMessage({
                type: TEXT,
                content: msgReceivedDataMessage.value,
                self: false,
                createdAt: new Date(msgReceivedDataMessage.timeStamp * 1000),
                username: msgReceivedDataMessage.admin?.displayName,
                msgSourceType: msgReceivedDataMessage.type,
                email: msgReceivedDataMessage.email,
                uuid: msgReceivedDataMessage.uuid || uuid(),
                webFeedbackModel: msgReceivedDataMessage.webFeedbackModel,
                attachments: msgReceivedDataMessage.attachments,
              });
            }
            break;

          case WebsocketActionType.changeChatStatus:
            setInternalNoteParams((prev) => ({
              ...prev,
              isChatStatusChanging: false,
              chatStatusValue: msgReceived.data.status,
              initialChatStatusValue: msgReceived.data.status,
            }));

            chatCtl.addMessage({
              type: SYSTEM,
              content: msgReceivedDataMessage.value,
              self: true,
              systemMsgStatus: msgReceived.data.status,
              createdAt: new Date(msgReceivedDataMessage.timeStamp * 1000),
              username: msgReceivedDataMessage.admin?.displayName,
              uuid: msgReceivedDataMessage.uuid || uuid(),
              adminOriginalEmail: msgReceivedDataMessage.adminOriginalEmail,
            });
            break;

          case WebsocketActionType.inGameResponseMessage:
            chatCtl.addMessage({
              type: SYSTEM,
              content: msgReceivedDataMessage.value,
              self: true,
              systemMsgStatus: msgReceived.data.status,
              createdAt: new Date(msgReceivedDataMessage.timeStamp * 1000),
              username: msgReceivedDataMessage.admin?.displayName,
              uuid: msgReceivedDataMessage.uuid || uuid(),
              adminOriginalEmail: msgReceivedDataMessage.adminOriginalEmail,
            });
            break;

          case WebsocketActionType.removeFeedback:
            {
              const messages = chatCtl.getMessages();
              const removedMsg = messages?.find(
                (msg) => msg.uuid === msgReceivedDataMessage.uuid
              );

              if (removedMsg) {
                const msgStatus = msgReceived?.status;

                if (msgStatus === WebsocketMessageStatus.error) {
                  feedError(
                    t(
                      "messages.somethingWentWrongAndActionWithMessageWasntSuccessful"
                    )
                  );
                } else {
                  chatCtl.updateMessage(removedMsg.uuid, {
                    type: TEXT,
                    content: msgReceivedDataMessage.value,
                    self:
                      msgReceivedDataMessage.type === MessageSourceType.admin ||
                      msgReceivedDataMessage.type ===
                        MessageSourceType.adminMail ||
                      msgReceivedDataMessage.type === MessageSourceType.system,
                    createdAt: new Date(
                      msgReceivedDataMessage.timeStamp * 1000
                    ),
                    username: msgReceivedDataMessage.admin?.displayName,
                    uuid: msgReceivedDataMessage.uuid,
                    removed: msgReceivedDataMessage.removed,
                  });
                }

                setIsMsgSending(false);
              }
            }
            break;
        }
      }
    },
    [chatCtl, feedError, playerID, t]
  );

  useEffect(() => {
    subscribeOnMessage(onMessageReceived);
    return () => {
      unsubscribeOnMessage(onMessageReceived);
    };
  }, [onMessageReceived, subscribeOnMessage, unsubscribeOnMessage]);

  useEffect(() => {
    return () => {
      const messages = chatCtl.getMessages();
      const pendingMessages = messages?.filter((msg) => msg.type === PENDING);

      if (pendingMessages?.length > 0) {
        pendingMessages.forEach((message) => {
          const inputParams: InputParamsChatMsg = {
            adminOriginalEmail: adminEmail,
            feedbackDataID: currentFeedbackDataID.current,
            playerID,
          };

          if (
            message.msgActionType ===
            WebsocketActionType.internalNoteFeedbackAdmin
          ) {
            inputParams.value = message.content;
          }

          if (
            message.msgActionType ===
            WebsocketActionType.replyFeedbackEmailAdmin
          ) {
            inputParams.body = message.content;
            inputParams.subject = message.subject;
          }

          const processedMsg = {
            action: message.msgActionType,
            inputParams: JSON.stringify(inputParams),
            uuid: message.uuid,
          };

          sendMessage(processedMsg);
        });
      }
    };
  }, [adminEmail, chatCtl, playerID, sendMessage]);

  useMemo(async () => {
    chatCtl.setActionRequest(
      {
        type: TEXT,
        always: true,
        addMessage: false,
        placeholder: "Type message here",
        sendButtonText: "Send",
      },
      onMessageSent
    );
  }, [chatCtl, onMessageSent]);

  const handleChangeStatus = useCallback(() => {
    setInternalNoteParams((prev) => ({
      ...prev,
      isChatStatusChanging: true,
    }));
    sendMessage({
      action: WebsocketActionType.changeChatStatus,
      inputParams: JSON.stringify({
        status: internalNoteParams.chatStatusValue,
        playerID,
        feedbackDataID,
        adminOriginalEmail: adminEmail,
      }),
    });
  }, [
    adminEmail,
    feedbackDataID,
    internalNoteParams.chatStatusValue,
    playerID,
    sendMessage,
  ]);

  const chatStatusColor = useMemo(() => {
    let msgColor = "info.light";

    switch (internalNoteParams.chatStatusValue) {
      case CHAT_STATUSES.closed:
        msgColor = "secondary.main";
        break;
      case CHAT_STATUSES.waitingResponse:
        msgColor = "warning.main";
        break;
      case CHAT_STATUSES.handledInAsana:
        msgColor = "#F06A6A";
        break;
      case CHAT_STATUSES.waitingTech:
        msgColor = "error.main";
        break;
      case CHAT_STATUSES.open:
        msgColor = "info.main";
        break;
      default:
        msgColor = "info.main";
        break;
    }

    return msgColor;
  }, [internalNoteParams.chatStatusValue]);

  return (
    <Box sx={{ minHeight: 400 }}>
      <Stack
        direction="row"
        alignItems="center"
        spacing={3}
        sx={{ height: 60, marginTop: 1.25 }}
      >
        <h3>{t("supportChats.supportChat")}</h3>
        {internalNoteParams.chatStatusValue && (
          <Typography
            variant="button"
            sx={{
              px: 1,
              py: "2px",
              fontSize: "0.75rem",
              color: chatStatusColor,
              border: "1px solid",
              borderRadius: "4px",
              borderColor: chatStatusColor,
            }}
          >
            {internalNoteParams.chatStatusValue}
          </Typography>
        )}
      </Stack>
      <Box sx={{ position: "relative", minHeight: 400 }}>
        {(isPrevMessagesLoading || isGetMorePrevMessagesLoading) && (
          <Box
            sx={{
              position: "absolute",
              zIndex: 1000,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
              p: 1,
              backgroundColor: "rgba(255, 255, 255, 0.5)",
            }}
          >
            <Dotted size={70} />
          </Box>
        )}
        {!isPrevMessagesLoading && (
          <ChatUI
            playerID={playerID}
            chatController={chatCtl}
            isMsgSending={isMsgSending}
            cancelledMsgValue={cancelledMsgValue}
            onMsgDelete={handleMsgDelete}
            totalDocs={prevMessagesData?.totalDocs || 0}
            loadPrevMessages={loadPrevMessages}
            setInternalNoteParams={setInternalNoteParams}
            internalNoteParams={internalNoteParams}
            handleChangeStatus={handleChangeStatus}
          />
        )}
      </Box>
    </Box>
  );
};

export default SupportChat;
