import React, { useCallback, useEffect, useState } from "react";

import { Helmet } from "react-helmet";
import { Box, Button, Container, Tab, Tabs } from "@mui/material";
import {
  StoreContentEntry,
  StoreContentTabsValue,
  StoreContentTimestampRecords,
} from "../../slices/storeContent/types";
import {
  PaperPage,
  PaperPageError,
  PaperPageSpinner,
} from "../../components/PaperPage";
import { ERROR_STATUS_UNAUTHORIZED, ROUTE_PATHS } from "../../consts";
import { useTitle } from "../../utils/useTitle";
import { useTranslation } from "react-i18next";
import { WideDrawer } from "../../components/WideDrawerForm/WideDrawer";
import { useTableUrlTools } from "../../utils/useTableUrlTools";
import CreateStoreContent from "./CreateStoreContent";
import { Link, useNavigate } from "react-router-dom";
import EditStoreContent from "./EditStoreContent";
import ViewStoreContentDetails from "./ViewStoreContentDetails";
import { useDispatch, useSelector } from "../../store";
import { logOut } from "../../slices/auth/actions";
import { capitalize } from "../../utils/capitalize";
import VerticalTimelineComponent from "./VerticalTimelineComponent";
import { useGetStoreContentTableDataQuery } from "../../slices/storeContent/api";
import { WebsocketActionType } from "../../slices/Websocket/types";
import useWebsocket from "../../utils/useWebsocket";
import { getWasWebsocketOpen } from "../../slices/Websocket/slice";

const StoreContent = () => {
  const { t } = useTranslation();
  const title = useTitle(t("storeContent.title"));
  const mainRoute = ROUTE_PATHS.STORE_CONTENT;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { sendMessage, subscribeOnMessage, unsubscribeOnMessage } =
    useWebsocket();
  const wasWebsocketOpen = useSelector(getWasWebsocketOpen);

  const [currentTab, setCurrnetTab] = useState<StoreContentTabsValue>("offers");
  const [storeContentData, setStoreContentData] = useState<StoreContentEntry[]>(
    []
  );
  const [lootboxData, setLootboxData] = useState<any[]>([]);
  const [isLootboxDataLoading, setIsLootboxDataLoading] = useState(true);

  const { isLoading, data, isFetching, error, refetch } =
    useGetStoreContentTableDataQuery();

  const onMessageReceived = useCallback((event: MessageEvent) => {
    const msg = JSON.parse(event.data);

    switch (msg?.action) {
      case WebsocketActionType.getLootboxIds: {
        setLootboxData(msg?.data?.lootboxIds);
        setIsLootboxDataLoading(false);
        break;
      }
    }
  }, []);

  useEffect(() => {
    if (lootboxData.length && data?.length) {
      setStoreContentData(
        data.map((el, index) => {
          return {
            ...el,
            storeItems: el.storeItems.map((storeItem) => {
              const lootbox = lootboxData.find(
                (lootboxEl) => lootboxEl.lootboxID === storeItem.lootboxID
              );

              return {
                ...storeItem,
                discountPercentage: storeItem.discountPercentage
                  ? storeItem.discountPercentage
                  : lootbox.discountPercentage,
                price: storeItem.price ? storeItem.price : lootbox.price,
                buyAmountLimit: storeItem.buyAmountLimit
                  ? storeItem.buyAmountLimit
                  : lootbox.buyAmountLimit,
                orderIndex: index,
              };
            }),
          };
        })
      );
    }
  }, [JSON.stringify(lootboxData), data]);

  useEffect(() => {
    if (wasWebsocketOpen && isLootboxDataLoading) {
      subscribeOnMessage(onMessageReceived);
    }

    return () => {
      unsubscribeOnMessage(onMessageReceived);
    };
  }, [
    isLootboxDataLoading,
    onMessageReceived,
    subscribeOnMessage,
    unsubscribeOnMessage,
    wasWebsocketOpen,
  ]);

  useEffect(() => {
    const getLootboxIds = {
      action: WebsocketActionType.getLootboxIds,
      inputParams: "{}",
    };

    if (wasWebsocketOpen) {
      sendMessage(getLootboxIds);
    }
  }, [sendMessage, wasWebsocketOpen]);

  const timestampsToDisable: StoreContentTimestampRecords = {
    offers: data
      ? data
          .filter((item) => item.tab === "offers")
          .map((item) => ({
            _id: item._id,
            start: item.start,
            end: item.end,
          }))
      : [],
    chests: data
      ? data
          .filter((item) => item.tab === "chests")
          .map((item) => ({
            _id: item._id,
            start: item.start,
            end: item.end,
          }))
      : [],
  };

  const { detailsDrawerOpen, editDrawerOpen, closeDrawer, addDrawerOpen } =
    useTableUrlTools(mainRoute);

  const handleCloseDrawer = useCallback(() => {
    closeDrawer();
  }, [closeDrawer]);

  if (error) {
    if ("status" in error && error.status === ERROR_STATUS_UNAUTHORIZED) {
      dispatch(logOut());
      navigate(ROUTE_PATHS.SIGN_IN);
    } else {
      return (
        <>
          <Helmet>
            <title>{title}</title>
          </Helmet>
          <Container maxWidth="xl" sx={{ height: "100%" }}>
            <PaperPageError
              refetch={refetch}
              disabled={isFetching}
              message={t("refetch", {
                entity: "storeContent",
                ns: "errors",
              })}
            />
          </Container>
        </>
      );
    }
  }

  if (isLoading) {
    return (
      <>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <Container maxWidth="xl" sx={{ height: "100%" }}>
          <Box
            sx={{
              width: "100%",
              height: 400,
              mt: 2.5,
              backgroundColor: "white",
            }}
          >
            <PaperPageSpinner />
          </Box>
        </Container>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          backgroundColor: "white",
        }}
      >
        <Tabs
          value={currentTab}
          onChange={(e, newValue: StoreContentTabsValue) => {
            setCurrnetTab(newValue);
          }}
          variant="scrollable"
          scrollButtons="auto"
        >
          {Object.keys(timestampsToDisable).map(
            (tab: StoreContentTabsValue) => (
              <Tab
                key={`store-content-tab-${tab}`}
                label={t("storeContent.timelineTabLabel", {
                  tab: capitalize(tab),
                })}
                value={tab}
              />
            )
          )}
        </Tabs>
      </Box>
      <Container maxWidth="xl">
        <PaperPage elevation={0}>
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
              }}
            >
              <Button
                variant="outlined"
                sx={{ ml: 2, mb: 2 }}
                onClick={refetch}
              >
                {t("buttonNames.refreshList")}
              </Button>
            </Box>
          </Box>
          <VerticalTimelineComponent
            timeRanges={timestampsToDisable[currentTab]}
            currentTab={currentTab}
            allStoreContent={storeContentData.length ? storeContentData : []}
          />
        </PaperPage>
      </Container>

      <WideDrawer open={addDrawerOpen} close={handleCloseDrawer}>
        <CreateStoreContent
          timestampsToDisable={timestampsToDisable}
          refetchList={refetch}
          allStoreContent={storeContentData.length ? storeContentData : []}
          lootboxData={lootboxData}
        />
      </WideDrawer>
      <WideDrawer open={detailsDrawerOpen} close={handleCloseDrawer}>
        <ViewStoreContentDetails
          allStoreContent={storeContentData.length ? storeContentData : []}
          refetchList={refetch}
        />
      </WideDrawer>
      <WideDrawer open={editDrawerOpen} close={handleCloseDrawer}>
        <EditStoreContent
          allStoreContent={storeContentData.length ? storeContentData : []}
          timestampsToDisable={timestampsToDisable}
          refetchList={refetch}
          lootboxData={lootboxData}
        />
      </WideDrawer>
    </>
  );
};

export default StoreContent;
