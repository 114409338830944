import React, { memo, useCallback } from "react";
import { Link } from "react-router-dom";

import { Launch } from "@mui/icons-material";
import { IconButton } from "@mui/material";

type OpenEditBtnProps = {
  routePath: string;
  id: string | null;
  feedbackDataID?: string | null;
  handleOpenEditBtnClick?: ({
    playerID,
    feedbackDataID,
  }: {
    playerID: string | null;
    feedbackDataID: string | null;
  }) => void;
};

const OpenEditBtn = ({
  id,
  feedbackDataID,
  routePath,
  handleOpenEditBtnClick,
}: OpenEditBtnProps) => {
  const onOpenEditBtnClick = useCallback(
    () =>
      handleOpenEditBtnClick &&
      handleOpenEditBtnClick({
        playerID: id,
        feedbackDataID: feedbackDataID || null,
      }),
    [feedbackDataID, handleOpenEditBtnClick, id]
  );
  const currentID = id || feedbackDataID;

  return (
    <IconButton
      component={Link}
      to={`${routePath}/${currentID}`}
      title="Open"
      sx={{ p: 0 }}
      onClick={onOpenEditBtnClick}
    >
      <Launch color="primary" />
    </IconButton>
  );
};

export default memo(OpenEditBtn);
