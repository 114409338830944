import React, { memo, useCallback, useEffect, useState } from "react";
import { Box, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import {
  CreateStoreContentFormValues,
  StoreContentTimestampRecords,
  StoreItem,
  tabDropDownOptions,
} from "../../slices/storeContent/types";
import ControlledInputSelect from "../../components/inputs/ControlledInputSelect";
import ControlledInputDateTimeRange from "../../components/inputs/ControlledInputDateTimeRange";
import { StoreItems } from "./StoreItems";
import { SpinnerButton } from "../../components/SpinnerButton";
import { CustomErrorBox } from "../../components/inputs/StyledComponents";
import dayjs, { Dayjs } from "dayjs";
import { TimeRange } from "../../types";
import { useFeedError } from "../../utils/feedHooks";

type FormProps = {
  onSubmit?: (data: CreateStoreContentFormValues) => void;
  defaultValues?: CreateStoreContentFormValues;
  timestampsToDisable: StoreContentTimestampRecords;
  isLoading?: boolean;
  isEdit?: boolean;
  excludeTimestamp?: TimeRange;
  lootboxData: any;
};

const Form = ({
  onSubmit,
  timestampsToDisable,
  defaultValues = {
    tab: "offers",
    start: null,
    end: null,
    storeItems: [],
  },
  isLoading,
  isEdit,
  excludeTimestamp,
  lootboxData,
}: FormProps) => {
  const { t } = useTranslation();

  const methods = useForm<
    Omit<CreateStoreContentFormValues, "start" | "end"> & {
      start: Dayjs | number | null;
      end: Dayjs | number | null;
    }
  >({
    defaultValues: {
      ...defaultValues,
      start: defaultValues.start
        ? dayjs.unix(defaultValues.start).tz("Asia/Tokyo")
        : null,
      end: defaultValues.end
        ? dayjs.unix(defaultValues.end).tz("Asia/Tokyo")
        : null,
    },
    mode: "onChange",
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitSuccessful },
    setValue,
    watch,
    setError,
    register,
  } = methods;

  const currentTab = useWatch({
    control,
    name: "tab",
  });

  const [hasErrors, setHasErrors] = useState(false);

  const resetDatetimePickers = () => {
    setValue("start", null);
    setValue("end", null);
  };

  const setDatetimePickers = (
    name: "start" | "end",
    value: number | dayjs.Dayjs
  ) => {
    setValue(name, value);
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  const onSubmitError = () => {
    setHasErrors(true);
  };

  return (
    <Box
      sx={{
        m: 2,
        p: 2,
        backgroundColor: "white",
      }}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit, onSubmitError)}>
          <h2>
            {t(
              isEdit
                ? "storeContentCreate.editTitle"
                : "storeContentCreate.title"
            )}
          </h2>
          <ControlledInputSelect
            control={control}
            title={t("storeContentCreate.tab")}
            fieldName="tab"
            dropDownData={tabDropDownOptions}
            isRequired
          />
          <ControlledInputDateTimeRange
            defaultValues={defaultValues}
            control={control}
            startFieldName="start"
            endFieldName="end"
            title={t("storeContentCreate.timeRange")}
            fieldDateTimeView={["year", "month", "day", "hours"]}
            isRequired
            timestampsToDisable={timestampsToDisable[
              currentTab || "offers"
            ].filter((item) =>
              excludeTimestamp
                ? !(
                    item.start === excludeTimestamp.start &&
                    item.end === excludeTimestamp.end
                  )
                : true
            )}
            timestamps={timestampsToDisable[currentTab || "offers"]}
            resetValues={resetDatetimePickers}
            setDatetimePickers={setDatetimePickers}
            resetText={t("storeContentCreate.resetDateTimeRange")}
          />
          <StoreItems
            setValue={setValue}
            lootboxData={lootboxData}
            watch={watch}
          />
          <Stack direction="row" alignItems="center" spacing={2} sx={{ my: 4 }}>
            <SpinnerButton
              variant="contained"
              buttonName={t(
                isEdit
                  ? "storeContentCreate.submitEdit"
                  : "storeContentCreate.submitCreate"
              )}
              loading={isLoading}
              type="submit"
            />
            {hasErrors && (
              <CustomErrorBox>
                {t("errors.hasErrorsOnSubmitMessage")}
              </CustomErrorBox>
            )}
          </Stack>
        </form>
      </FormProvider>
    </Box>
  );
};

export default memo(Form);
