import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";

import { Helmet } from "react-helmet";
import CustomLineChart from "./CustomLineChart";
import { Container, Stack } from "@mui/material";
import ChartFilter from "./ChartFilter";
import VirtualTable from "../CustomDataGrid/VirtualTable";

import {
  useGetDailyActivePlayersKPIMutation,
  useGetStepsKPIMutation,
  useGetWalkDistanceKPIMutation,
} from "../../slices/analytics/api";
import { FiltersType, KPINames } from "./types";
import { DataKPIType } from "../../slices/analytics/types";
import { ColumnData } from "../CustomDataGrid/types";

const tableColumns: ColumnData<DataKPIType>[] = [
  {
    width: 150,
    label: "N",
    dataKey: "id",
    renderCell: (rowData, rowIndex: number) => rowIndex + 1,
  },
  {
    width: 800,
    label: "Date",
    dataKey: "date",
    renderCell: ({ date }) =>
      date ? dayjs.unix(date).format("YYYY/MM/DD") : "",
  },
  {
    width: 800,
    label: "Count",
    dataKey: "count",
    renderCell: ({ count }) => count,
  },
];

const defaultFilters: FiltersType = {
  dateFrom: null,
  dateTo: null,
  gender: [],
  age: [],
  deepLink: [],
};

type ChartKPIProps = {
  title: string;
  useGetKpiData:
    | typeof useGetDailyActivePlayersKPIMutation
    | typeof useGetStepsKPIMutation
    | typeof useGetWalkDistanceKPIMutation;
  kpiName?: `${KPINames}`;
};

const ChartKPI = ({ title, useGetKpiData, kpiName }: ChartKPIProps) => {
  const initialFilters = useMemo(
    () =>
      kpiName
        ? {
            ...defaultFilters,
            kpiName,
          }
        : defaultFilters,
    [kpiName]
  );
  const [kpiData, setKpiData] = useState<DataKPIType[]>([]);
  const [getKpiData, { isLoading }] = useGetKpiData({
    selectFromResult: ({ isLoading }) => ({ isLoading }),
  });

  const [filters, setFilters] = useState<FiltersType>(initialFilters);

  useEffect(() => {
    const getFirstData = async () => {
      const data = await getKpiData(initialFilters).unwrap();
      const finishedData = data.filter((item) => item.date);

      setKpiData(finishedData);
    };
    getFirstData();
  }, [getKpiData, initialFilters]);

  const handleUseFilter = useCallback(async () => {
    const utcDateFrom = filters.dateFrom
      ? Date.parse(
          new Date(dayjs(filters.dateFrom).format("YYYY-MM-DD")).toISOString()
        ) / 1000
      : null;
    const utcDateTo = filters.dateTo
      ? Date.parse(
          new Date(dayjs(filters.dateTo).format("YYYY-MM-DD")).toISOString()
        ) / 1000
      : null;
    const currentFilters = {
      ...filters,
      dateFrom: utcDateFrom,
      dateTo: utcDateTo,
    };

    const data = await getKpiData(currentFilters).unwrap();
    const finishedData = data.filter((item) => item.date);

    setKpiData(finishedData);
  }, [filters, getKpiData]);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Container
        maxWidth="xl"
        sx={{
          height: "100%",
          pt: 3,
          backgroundColor: "white",
        }}
      >
        <>
          <ChartFilter
            setFilters={setFilters}
            filters={filters}
            handleUseFilter={handleUseFilter}
            isDataLoading={isLoading}
          />
          <>
            <CustomLineChart data={kpiData} />
            <Stack px={3} pb={4} height={600}>
              <VirtualTable data={kpiData} columns={tableColumns} />
            </Stack>
          </>
        </>
      </Container>
    </>
  );
};

export default memo(ChartKPI);
