import React, { useCallback, useMemo, useState } from "react";
import dayjs from "dayjs";

import { useTranslation } from "react-i18next";
import { useLocalStorage } from "usehooks-ts";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "../../store";
import { logOut } from "../../slices/auth/actions";

import { CustomDataGrid } from "../../components/CustomDataGrid/CustomDataGrid";
import {
  PaperPage,
  PaperPageError,
  PaperPageSpinner,
} from "../../components/PaperPage";

import { SearchPaginateRequestBody } from "../../types";
import { DataTableProps, GroupDataType } from "./DataTableTypes";
import {
  DATE_MASK_DEFAULT,
  DEFAULT_PAGE_SIZE,
  ERROR_STATUS_UNAUTHORIZED,
  ROUTE_PATHS,
} from "../../consts";

export const DataTable = ({
  getQueryData,
  columns,
  collectionName,
  dbName,
  errorMessageEntity = null,
  searchFieldsFront,
  searchFieldsBack,
  requiredFields,
  hasPagination = true,
}: DataTableProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(["common", "errors"]);

  const [pageSize] = useLocalStorage(
    `table-${collectionName}.pageSize`,
    DEFAULT_PAGE_SIZE
  );

  const [query, setQuery] = useState<SearchPaginateRequestBody>({
    search: null,
    limit: pageSize,
    page: 1,
    dbName,
    collectionName,
    requiredFields,
    pagination: hasPagination,
  });

  const { isLoading, isFetching, data, error, refetch } = getQueryData(query);

  const refetchHandler = useCallback(() => {
    refetch();
  }, [refetch]);

  const getAllItems = useCallback(() => {
    setQuery((prevState) => ({ ...prevState, search: null }));
  }, [setQuery]);

  const responsePageSize = data?.limit;
  const responsePage = data?.page;
  const responseHasNextPage = data?.hasNextPage;
  const totalPages = data?.totalPages;

  const groupData: GroupDataType[] = useMemo(() => {
    if (!data) {
      return [];
    }

    const newData = data.docs.map((item) => {
      const newItem = {
        ...item,
        id: item._id,
        lastActiveDate: dayjs
          .unix(item.lastActive / 1000)
          .format(DATE_MASK_DEFAULT),
        membersCount: item.members.length,
        playerAverageLevel: Number(item.playerAverageLevel.toFixed(1)),
      };

      if (item.members.length > 1) {
        const teamOwnerId = item.owner;
        const owner = item.members.filter(
          (player) => player.playerID === teamOwnerId
        )[0];
        const shortTeam = item.members.filter(
          (player) => player.playerID !== teamOwnerId
        );

        newItem.members = [owner, ...shortTeam];
      }

      return newItem;
    });

    newData.sort((a, b) => b.teamLevel - a.teamLevel);

    return newData;
  }, [data]);

  if (error) {
    if ("status" in error && error.status === ERROR_STATUS_UNAUTHORIZED) {
      dispatch(logOut());
      navigate(ROUTE_PATHS.SIGN_IN);
    } else {
      return (
        <PaperPageError
          refetch={refetchHandler}
          disabled={isFetching}
          message={t("refetch", { entity: errorMessageEntity, ns: "errors" })}
        />
      );
    }
  }

  if (!data) {
    return <PaperPageSpinner />;
  }

  return (
    <PaperPage elevation={0}>
      <CustomDataGrid
        isLoading={isLoading}
        getAllItems={getAllItems}
        onSearchClick={setQuery}
        tableName={collectionName}
        rows={groupData}
        totalPages={totalPages}
        searchFieldsFront={searchFieldsFront}
        searchFieldsBack={searchFieldsBack}
        dbName={dbName}
        collectionName={collectionName}
        responsePageSize={responsePageSize}
        responsePage={responsePage}
        responseHasNextPage={responseHasNextPage}
        columns={columns}
        hasPagination={hasPagination}
        isSupportTable={false}
      />
    </PaperPage>
  );
};
