export type DropDownDataItem = {
  value: string | number | null;
  label: string;
};

export const INPUT_COLUMN_WIDTH = 400 as const;
export const INPUT_TITLE_WIDTH = 250 as const;

export const rewardTopDropDown: DropDownDataItem[] = [
  { value: "tickets", label: "tickets" },
  { value: "rubies", label: "rubies" },
  { value: "blueberry", label: "blueberry" },
  { value: "coins", label: "coins" },
];

export const rarityDropDownData: DropDownDataItem[] = [
  { value: null, label: "None" },
  { value: 0, label: "0" },
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
];

export const rarityValues = [1, 2, 3, 4, 5];
export const characterTagsValues = ["generic", "creature"];
export const currencyList = [
  "blueberry",
  "coins",
  "experience",
  "hearts",
  "pet food",
  "rubies",
  "rubiesBought",
  "tickets",
];
export const petList = [
  "bat",
  "bat_1",
  "bat_2",
  "bat_3",

  "cat",
  "cat_1",
  "cat_2",
  "cat_3",

  "owl",
  "owl_1",
  "owl_2",
  "owl_3",

  "squirrel",
  "squirrel_1",
  "squirrel_2",
  "squirrel_3",

  "parrot",
  "parrot_1",
  "parrot_2",
  "parrot_3",

  "crawly",
  "crawly_1",
  "crawly_2",
  "crawly_3",

  "dragon",
  "dragon_1",
  "dragon_2",
  "dragon_3",

  "mouse",
  "mouse_1",
  "mouse_2",
  "mouse_3",

  "creep",
  "creep_1",
  "creep_2",
  "creep_3",

  "frog",
  "frog_1",
  "frog_2",
  "frog_3",

  "hedgehog",
  "hedgehog_1",
  "hedgehog_2",
  "hedgehog_3",
];
export const furnitureList = petList;

export const currencyListData: DropDownDataItem[] = currencyList.map(
  (item) => ({
    value: item,
    label: item,
  })
);

export const typeAmountDropDown: DropDownDataItem[] = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
];

export const eventSeasonDropDown: DropDownDataItem[] = [
  { value: "all time", label: "all time" },
  { value: "winter", label: "winter" },
  { value: "summer", label: "summer" },
  { value: "spring", label: "spring" },
  { value: "fall", label: "fall" },
];
