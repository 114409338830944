import React, { memo, useCallback } from "react";
import dayjs from "dayjs";

import { Controller } from "react-hook-form";
import {
  Button,
  Select,
  Checkbox,
  styled,
  TextField,
  MenuItem,
  Box,
  Typography,
  Stack,
  IconButton,
} from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import InputChips from "../inputs/InputChips";

import { useTranslation } from "react-i18next";
import { fieldError } from "../../utils/formHelpers";
import { InputProps, DynamicFormInputProps } from "./types";
import { DATE_MASK_WITH_SECONDS } from "../../consts";
import { InputType } from "./consts";

export const Input = memo(
  ({
    isDisabled,
    formIsDisabled,
    isDirty,
    type,
    hide,
    label,
    placeholder,
    defaultValue,
    error,
    helperText,
    field,
    isDateInMilliseconds,
    listName,
    isItemOfEditableArray,
    handleClickLink,
    handleClickDeleteItem,
  }: InputProps) => {
    const { t } = useTranslation();

    const handleClickDeleteArrayItem = useCallback(() => {
      handleClickDeleteItem(defaultValue);
    }, [defaultValue, handleClickDeleteItem]);

    if (hide) return <></>;

    switch (type) {
      case InputType.text:
        return (
          <StyledTextField
            disabled={formIsDisabled || isDisabled}
            color={isDirty ? "error" : "primary"}
            focused={isDirty}
            label={label}
            variant="outlined"
            size="small"
            placeholder={placeholder}
            defaultValue={defaultValue}
            error={error}
            helperText={helperText}
            fullWidth
            {...field}
            InputProps={{
              readOnly: isDisabled,
            }}
          />
        );
      case InputType.numeric:
        return (
          <StyledTextField
            disabled={formIsDisabled || isDisabled}
            color={isDirty ? "error" : "primary"}
            focused={isDirty}
            label={label}
            variant="outlined"
            size="small"
            placeholder={placeholder}
            defaultValue={defaultValue}
            error={error}
            helperText={helperText}
            fullWidth
            {...field}
            onBlur={(e) => {
              const value = +e.target.value.trim();
              field?.onChange(isNaN(value) ? 0 : value);
            }}
            InputProps={{
              readOnly: isDisabled,
            }}
          />
        );
      case InputType.date: {
        const value = field?.value as unknown as number;

        const timeJP = dayjs
          .unix(isDateInMilliseconds ? value / 1000 : value)
          .utc(true)
          .tz("Asia/Tokyo")
          .format(DATE_MASK_WITH_SECONDS);

        return (
          <StyledTextField
            disabled={formIsDisabled || isDisabled}
            color={isDirty ? "error" : "primary"}
            focused={isDirty}
            label={label}
            variant="outlined"
            size="small"
            placeholder={placeholder}
            defaultValue={defaultValue}
            error={error}
            helperText={helperText}
            fullWidth
            value={timeJP}
            InputProps={{
              readOnly: isDisabled,
            }}
          />
        );
      }

      case InputType.link:
        return (
          <Stack direction="row" spacing={2}>
            <Button
              onClick={handleClickLink}
              endIcon={<ArrowRightIcon fontSize="small" color="primary" />}
            >
              {label}
            </Button>
            {isItemOfEditableArray && (
              <IconButton
                color="warning"
                title={t("buttonNames.deleteItem")}
                onClick={handleClickDeleteArrayItem}
              >
                <DeleteOutlineIcon />
              </IconButton>
            )}
          </Stack>
        );

      case InputType.radio:
        return <h1>radio</h1>;

      case InputType.dropdown:
        return (
          <>
            <Select
              style={{ width: "100%", height: "40px" }}
              {...field}
              value={field?.value[0]}
            >
              {field?.value.map((el, i) => (
                <MenuItem key={i} value={el}>
                  {el}
                </MenuItem>
              ))}
            </Select>
          </>
        );

      case InputType.checkbox:
        return (
          <>
            <Typography
              variant="body1"
              component="span"
              sx={{
                color:
                  isDisabled || formIsDisabled
                    ? "primary.main"
                    : "text.primary",
              }}
            >
              {label}
            </Typography>
            <Checkbox
              {...field}
              checked={!!field?.value}
              disabled={isDisabled || formIsDisabled}
            />
          </>
        );

      case InputType.arrayOfSimpleValues:
        return (
          <InputChips
            isDirty={isDirty}
            isDisabled={isDisabled || formIsDisabled}
            label={label}
            listName={listName}
            value={field?.value}
            handleChange={field?.onChange}
          />
        );

      case InputType.default:
        return (
          <StyledTextField
            disabled={formIsDisabled || isDisabled}
            color={isDirty ? "error" : "primary"}
            label={label}
            focused={isDirty}
            variant="outlined"
            size="small"
            placeholder={placeholder}
            defaultValue={String(defaultValue)}
            error={error}
            helperText={helperText}
            fullWidth
            {...field}
            InputProps={{
              readOnly: isDisabled,
            }}
          />
        );
      default:
        return null;
    }
  }
);

export const DynamicFormInput = memo(
  ({
    control,
    fieldsErrors,
    formIsDisabled,
    inputName,
    schemaItem,
    dataItem,
  }: DynamicFormInputProps) => {
    return dataItem ||
      dataItem === false ||
      dataItem === 0 ||
      schemaItem?.isShownWithoutData ? (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          p: "5px 10px",
        }}
      >
        <Controller
          name={inputName}
          control={control}
          defaultValue={dataItem}
          render={({ field, fieldState: { invalid, error, isDirty } }) => {
            const [serverInvalid, serverError] = fieldError(
              inputName,
              fieldsErrors
            );

            return (
              <Input
                field={field}
                error={invalid || serverInvalid}
                helperText={error?.message || serverError}
                isDirty={isDirty}
                formIsDisabled={formIsDisabled}
                {...schemaItem}
              />
            );
          }}
        />
      </Box>
    ) : null;
  }
);

const StyledTextField = styled(TextField)({
  "& .MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: "#5085cc",
  },
});
