import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { State } from "../../store";
import { Roles } from "../../types";
import { logOut } from "../auth/actions";

interface PayloadActionType {
  currentRoles: Roles;
}

export const roleReducer = createSlice({
  name: "Role",
  initialState: {
    currentRoles: [],
  } as PayloadActionType,
  reducers: {
    addData(state, action: PayloadAction<PayloadActionType>) {
      state.currentRoles = action.payload.currentRoles;
    },
    removeData(state, action: PayloadAction<PayloadActionType>) {
      console.log("action.payload");
      console.log(action.payload);
      state = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logOut.fulfilled, (state) => {
      state.currentRoles = [];
    });
  },
});

export const { addData, removeData } = roleReducer.actions;
export const getCurrentRoles = ({ roles }: State) => roles.currentRoles;

export default roleReducer.reducer;
