import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import {
  PrevMessage,
  PrevMessagesModel,
} from "../../components/SupportChat/types";
import { ROUTE_PATHS } from "../../consts";
import { PaginateResponse } from "../../types";
import { getUrl, prepareHeaders } from "../../utils/api";
import { FeedbackDataRequestBody } from "./types";

const { FEEDBACK } = ROUTE_PATHS;

export const feedbackApi = createApi({
  reducerPath: "feedbackApi",
  baseQuery: fetchBaseQuery({
    baseUrl: getUrl("/"),
    prepareHeaders,
  }),
  tagTypes: ["FeedbackTableData"],
  endpoints: (builder) => ({
    getFeedbackTableData: builder.query<
      PaginateResponse<PrevMessagesModel>,
      FeedbackDataRequestBody
    >({
      query: (body) => {
        return {
          url: `${FEEDBACK}/tableData`,
          method: "POST",
          body: {
            page: body.page,
            limit: body.limit,
            search: body.search,
            excludeFromSearch: body.excludeFromSearch,
          },
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.docs.map(({ _id: id }) => ({
                type: "FeedbackTableData" as const,
                id,
              })),
              { type: "FeedbackTableData", id: "LIST" },
            ]
          : [{ type: "FeedbackTableData", id: "LIST" }],
    }),
    getPrevMessages: builder.query<
      PaginateResponse<PrevMessage>,
      FeedbackDataRequestBody
    >({
      query: (body) => ({
        url: `${FEEDBACK}/messages`,
        method: "POST",
        body,
      }),
      keepUnusedDataFor: 1,
    }),
    getMorePrevMessages: builder.mutation<
      PaginateResponse<PrevMessage>,
      FeedbackDataRequestBody
    >({
      query: (body) => ({
        url: `${FEEDBACK}/messages`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGetFeedbackTableDataQuery,
  useGetPrevMessagesQuery,
  useGetMorePrevMessagesMutation,
} = feedbackApi;
