import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { MarkedTimeRange } from "../../types";
import dayjs from "dayjs";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import { Link } from "react-router-dom";
import { ROUTE_PATHS } from "../../consts";
import { useTranslation } from "react-i18next";
import {
  StoreContentEntry,
  StoreContentTabsValue,
} from "../../slices/storeContent/types";
import { mergeTimeRanges } from "../../utils/timeRange";

// --------- Move to utils or consts ---------------

const getSepratorSize = (start: number, end: number): string => {
  // Small if less than 4 hours (14400 seconds)
  if (end - start < 14400) {
    return "30px";
  }

  // Large if less than 24 hours (86400 seconds)
  if (end - start > 86400) {
    return "150px";
  }

  // Midle if between
  return "30px";
};

const findIsGaps = (ranges: MarkedTimeRange[]): boolean => {
  if (ranges.length < 1) {
    return true;
  }

  const mergedRanges = mergeTimeRanges(
    ranges.map(({ start, end }) => ({ start, end }))
  );

  return mergedRanges.length > 1;
};

const findIsThisWeekUnset = (
  ranges: MarkedTimeRange[],
  startWeek: number,
  endWeek: number
) => {
  if (ranges.length < 1) {
    return true;
  }
  const mergedRangesThisWeek = mergeTimeRanges(
    ranges
      .map(({ start, end }) => ({ start, end }))
      .filter((range) => range.start <= endWeek)
  );

  return (
    mergedRangesThisWeek.length > 1 ||
    mergedRangesThisWeek[0].start > startWeek ||
    mergedRangesThisWeek[0].end < endWeek
  );
};

// --------- ------------------------- ---------------

type VerticalTimelineComponentProps = {
  timeRanges: MarkedTimeRange[];
  currentTab: StoreContentTabsValue;
  allStoreContent: StoreContentEntry[];
};

const VerticalTimelineComponent: React.FC<VerticalTimelineComponentProps> = ({
  timeRanges,
  currentTab,
  allStoreContent,
}) => {
  // TODO fix *1000 and /1000 after moving to dayjs from Date
  const { t } = useTranslation();

  const now =
    dayjs().tz("Asia/Tokyo").set("minutes", 0).set("seconds", 0).unix() * 1000;

  const weekFromNow = now + 7 * 24 * 60 * 60 * 1000;

  const croppedRanges = timeRanges
    .filter((range) => !(range.end * 1000 < now))
    .slice()
    .sort((a, b) => a.start - b.start);

  const iscroppedRangesEmpty = croppedRanges.length < 1;

  const firstCrossNow = iscroppedRangesEmpty
    ? false
    : croppedRanges[0].start * 1000 <= now;

  const isGaps = !firstCrossNow || findIsGaps(croppedRanges);

  const isThisWeekUnset =
    !firstCrossNow ||
    findIsThisWeekUnset(croppedRanges, now / 1000, weekFromNow / 1000);

  const unsortedDots: number[] = [];

  const findBookedRangeId = (
    start: number,
    end: number
  ): string | undefined => {
    if (iscroppedRangesEmpty) {
      return undefined;
    }

    return croppedRanges.find(
      (range) => range.start === start && range.end === end
    )?._id;
  };

  if (!iscroppedRangesEmpty) {
    croppedRanges[0] = {
      _id: croppedRanges[0]._id,
      start: croppedRanges[0].start,
      end: croppedRanges[0].end,
    };

    croppedRanges.forEach((range) => {
      if (!unsortedDots.includes(range.start)) {
        unsortedDots.push(range.start);
      }
      if (!unsortedDots.includes(range.end)) {
        unsortedDots.push(range.end);
      }
    });
  }

  const dots = unsortedDots.sort((a, b) => a - b);

  return (
    <Box>
      <Box>
        {isGaps && (
          <Typography color="error">{t("storeContent.gapsInRange")}</Typography>
        )}
        {isThisWeekUnset && (
          <Typography color="error">
            {t("storeContent.thisWeekUnset")}
          </Typography>
        )}
      </Box>
      <Timeline position="alternate">
        {/* TODO Remove position="alternate" to show buttons on same side */}
        {!firstCrossNow && (
          <>
            <CompleteTimelineDot time={now / 1000} outlined />
            <CompleteTimelineSeparator
              start={now / 1000}
              end={iscroppedRangesEmpty ? weekFromNow / 1000 : dots[0]}
              currentTab={currentTab}
              isNoTimelines={!dots.length}
              storeContentIndex={0}
            />
          </>
        )}
        {dots.map((dot, index) => {
          const rangeId =
            index < dots.length - 1
              ? findBookedRangeId(dot, dots[index + 1])
              : undefined;

          return (
            <React.Fragment key={`timeline-dot-${dot}`}>
              <CompleteTimelineDot time={dot} />
              {index < dots.length - 1 && (
                <CompleteTimelineSeparator
                  _id={rangeId}
                  start={dot}
                  end={dots[index + 1]}
                  currentTab={currentTab}
                  storeContentIndex={!firstCrossNow ? index + 1 : index}
                />
              )}
            </React.Fragment>
          );
        })}
        {!iscroppedRangesEmpty && (
          <CompleteTimelineSeparator
            start={dots[dots.length - 1]}
            end={weekFromNow}
            currentTab={currentTab}
            isAddStoreContentAfterWeek={true}
            storeContentIndex={
              allStoreContent.length === 0 ? 0 : allStoreContent.length - 1
            }
          />
        )}
      </Timeline>
    </Box>
  );
};

export default VerticalTimelineComponent;

const CompleteTimelineDot = ({
  time,
  outlined = false,
}: {
  time: number;
  outlined?: boolean;
}) => (
  <TimelineItem>
    <TimelineSeparator>
      <TimelineDot variant={outlined ? "outlined" : "filled"} />
    </TimelineSeparator>
    <TimelineContent>
      {dayjs.unix(time).tz("Asia/Tokyo").format("YYYY/MM/DD, HH:mm [(GMT]Z)")}
    </TimelineContent>
  </TimelineItem>
);

const CompleteTimelineSeparator = ({
  _id,
  start,
  end,
  currentTab,
  isAddStoreContentAfterWeek,
  isNoTimelines,
  storeContentIndex,
}: {
  _id?: string;
  start: number;
  end: number;
  currentTab: StoreContentTabsValue;
  isAddStoreContentAfterWeek?: boolean;
  isNoTimelines?: boolean;
  storeContentIndex?: number;
}) => {
  const { t } = useTranslation();
  const mainRoute = ROUTE_PATHS.STORE_CONTENT;

  const connectorHeight = isAddStoreContentAfterWeek
    ? "30px"
    : getSepratorSize(start, end);

  return (
    <>
      <TimelineItem sx={{ minHeight: connectorHeight }}>
        <TimelineSeparator>
          <TimelineConnector
            sx={{
              marginTop: "-40px",
              backgroundColor: "rgba(0, 0, 0, 0)",
              border: "none",
              borderLeft: _id ? "2px gray solid" : "2px red dashed",
            }}
          />
        </TimelineSeparator>
        <TimelineContent
          sx={{ marginTop: `calc(${connectorHeight} / 2 - 40px)` }}
        >
          {!isAddStoreContentAfterWeek && !isNoTimelines && (
            <Button
              variant="outlined"
              sx={{ ml: 2, mb: 2 }}
              component={Link}
              to={`${mainRoute}/${
                _id || "add"
              }?start=${start}&end=${end}&tab=${currentTab}&index=${storeContentIndex}`}
              color={_id ? "primary" : "error"}
            >
              {t(_id ? "storeContent.show" : "storeContent.fillGap")}
            </Button>
          )}
        </TimelineContent>
      </TimelineItem>
      {(isAddStoreContentAfterWeek || isNoTimelines) && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="outlined"
            sx={{ ml: 2, mb: 2, mt: 2, maxWidth: "181.35px" }}
            component={Link}
            to={`${mainRoute}/add?start=${start}&tab=${currentTab}&index=${storeContentIndex}`}
            color={"error"}
          >
            {t("storeContent.fillGap")}
          </Button>
        </div>
      )}
    </>
  );
};
