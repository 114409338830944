import React from "react";
import "normalize.css";
import "./i18n";

import { SnackbarProvider } from "notistack";
import { CssBaseline } from "@mui/material";
import Navigator from "./Navigator";

function App() {
  return (
    <>
      <SnackbarProvider maxSnack={3}>
        <CssBaseline />
        <Navigator />
      </SnackbarProvider>
    </>
  );
}

export default App;
