import { Dayjs } from "dayjs";

export type StoreContentTabsValue = "offers" | "chests";

export const storeContentTabsValues: StoreContentTabsValue[] = [
  "chests",
  "offers",
];

export const tabDropDownOptions: {
  label: StoreContentTabsValue;
  value: StoreContentTabsValue;
}[] = [
  {
    value: "offers",
    label: "offers",
  },
  {
    value: "chests",
    label: "chests",
  },
];

export type StoreContentTimestampRecords = Record<
  StoreContentTabsValue,
  { _id: string; start: number; end: number }[]
>;

export type StoreItem = {
  lootboxID: string;
  showAsOffer: boolean;
  discountPercentage: string;
  price: string;
  buyAmountLimit: string;
  discountPercentageOverridden: boolean;
  priceOverridden: boolean;
  buyAmountLimitOverridden: boolean;
  availabilityStart: number;
  availabilityEnd: number;
  timerangeOverridden: boolean;
  orderIndex?: number;
};

export type StoreContentEntry = {
  _id: string;
  tab: StoreContentTabsValue;
  start: number;
  end: number;
  storeItems: StoreItem[];
};

export type CreateStoreContentFormValues = Omit<StoreContentEntry, "_id">;
