import dayjs from "dayjs";
import { DATE_MASK_WITH_SECONDS } from "../consts";

export const getJPTime = (timestamp: number) =>
  dayjs
    .unix(timestamp)
    .utc(true)
    .tz("Asia/Tokyo")
    .format(DATE_MASK_WITH_SECONDS);

export const truncateMinutes = (value) => {
  const dayjsTimeLocal = dayjs(value);

  const year = dayjsTimeLocal.get("year");
  const month = dayjsTimeLocal.get("month");
  const day = dayjsTimeLocal.get("date");
  const hours = dayjsTimeLocal.get("hours");

  return dayjs(new Date(year, month, day, hours)).unix() * 1000;
};
