import * as yup from "yup";
import { DynamicSchema } from "../types";
import { InputType } from "../consts";

const privateDataFieldsWithTimestamps = [
  { label: "adventurePassExpire", isDateInMilliseconds: false },
  { label: "donationRequestTimestamp", isDateInMilliseconds: true },
  { label: "hourlyWalkLastCreated", isDateInMilliseconds: false },
  { label: "lastAgeModifyTime", isDateInMilliseconds: true },
  { label: "lastBerryRegeneration", isDateInMilliseconds: false },
  { label: "lastBerryRegenerationOnline", isDateInMilliseconds: false },
  { label: "lastGameDayTimestamp", isDateInMilliseconds: false },
  { label: "lastMapObjectSpawn", isDateInMilliseconds: false },
  { label: "lastTimeTeamLeft", isDateInMilliseconds: false },
  { label: "lastUpdate", isDateInMilliseconds: false },
  { label: "latestADBanner", isDateInMilliseconds: false },
  { label: "latestNewsCalled", isDateInMilliseconds: false },
  { label: "loginBonusTime", isDateInMilliseconds: true },
  { label: "rewardQueueDelay", isDateInMilliseconds: false },
  { label: "teamLastRequested", isDateInMilliseconds: false },
];

export const playerSchema: DynamicSchema = {
  _root: {
    type: InputType.root,
    additionalFields: ["privateData/badge/typeID"],
    children: {
      _id: {
        type: InputType.text,
        label: "ID",
        isDisabled: true,
        additionalFields: [],
        children: {},
      },
      segments: {
        type: InputType.link,
        label: "segments",
        hide: true,
        additionalFields: [],
        children: {},
      },
      deviceRegIds: {
        type: InputType.link,
        label: "deviceRegIds",
        hide: true,
        additionalFields: [],
        children: {},
      },
      scriptData: {
        type: InputType.link,
        label: "scriptData",
        hide: true,
        additionalFields: [],
        children: {},
      },
      pushTokens: {
        type: InputType.link,
        label: "pushTokens",
        hide: true,
        additionalFields: [],
        children: {},
      },
      location: {
        type: InputType.link,
        label: "location",
        hide: true,
        additionalFields: [],
        children: {},
      },
      displayName: {
        type: InputType.text,
        label: "DisplayName",
        placeholder: "Edit displayName",
        yupRules: yup.string().required("required"),
        additionalFields: [],
        children: {},
      },
      created: {
        type: InputType.date,
        label: "created",
        isDisabled: true,
        additionalFields: [],
        children: {},
        isDateInMilliseconds: true,
      },
      lastSeen: {
        type: InputType.link,
        label: "lastSeen",
        isDisabled: true,
        additionalFields: [],
        children: {},
        isDateInMilliseconds: true,
      },
      lastActiveHour: {
        type: InputType.date,
        label: "lastActiveHour",
        isDisabled: true,
        additionalFields: [],
        children: {},
        isDateInMilliseconds: true,
      },
      deletionRequested: {
        type: InputType.date,
        label: "deletionRequested",
        isDisabled: true,
        additionalFields: [],
        children: {},
      },
      goods: {
        type: InputType.link,
        label: "Goods",
        consistOfNumericFields: true,
        additionalFields: [],
        children: {},
      },
      currencies: {
        type: InputType.link,
        label: "Currencies",
        additionalFields: [],
        consistOfNumericFields: true,
        children: {
          blueberry: {
            type: InputType.numeric,
            label: "blueberry",
            additionalFields: [],
            children: {},
          },
          coins: {
            type: InputType.numeric,
            label: "coins",
            additionalFields: [],
            children: {},
          },
          experience: {
            type: InputType.numeric,
            label: "experience",
            additionalFields: [],
            children: {},
          },
          hearts: {
            type: InputType.numeric,
            label: "hearts",
            additionalFields: [],
            children: {},
          },
          petShard: {
            type: InputType.numeric,
            label: "petShard",
            additionalFields: [],
            children: {},
          },
          rubies: {
            type: InputType.numeric,
            label: "rubies",
            additionalFields: [],
            children: {},
          },
          rubiesBought: {
            type: InputType.numeric,
            label: "rubiesBought",
            additionalFields: [],
            children: {},
          },
          tickets: {
            type: InputType.numeric,
            label: "tickets",
            additionalFields: [],
            children: {},
          },
        },
      },
      privateData: {
        type: InputType.link,
        label: "Private Data",
        additionalFields: [],
        children: {
          lootboxes: {
            type: InputType.link,
            label: "lootboxes",
            additionalFields: [],
            consistOfNumericFields: true,
            children: {
              bundle_adventure_pass: {
                type: InputType.numeric,
                label: "bundle_adventure_pass",
                placeholder: "Edit bundle_adventure_pass",
                additionalFields: [],
                isShownWithoutData: true,
                children: {},
              },
              lootbox_bundle_pumpkin_party: {
                type: InputType.numeric,
                label: "lootbox_bundle_pumpkin_party",
                placeholder: "Edit lootbox_bundle_pumpkin_party",
                additionalFields: [],
                isShownWithoutData: true,
                children: {},
              },
              lootbox_token_chest_grand: {
                type: InputType.numeric,
                label: "lootbox_token_chest_grand",
                placeholder: "Edit lootbox_token_chest_grand",
                additionalFields: [],
                isShownWithoutData: true,
                children: {},
              },
              lootbox_token_chest: {
                type: InputType.numeric,
                label: "lootbox_token_chest",
                placeholder: "Edit lootbox_token_chest",
                additionalFields: [],
                isShownWithoutData: true,
                children: {},
              },
              lootbox_token_chest_large: {
                type: InputType.numeric,
                label: "lootbox_token_chest_large",
                placeholder: "Edit lootbox_token_chest_large",
                additionalFields: [],
                isShownWithoutData: true,
                children: {},
              },
              lootbox_bundle_daily_item_chest: {
                type: InputType.numeric,
                label: "lootbox_bundle_daily_item_chest",
                placeholder: "Edit lootbox_bundle_daily_item_chest",
                additionalFields: [],
                isShownWithoutData: true,
                children: {},
              },
              bundle_garden_set: {
                type: InputType.numeric,
                label: "bundle_garden_set",
                placeholder: "Edit bundle_garden_set",
                additionalFields: [],
                isShownWithoutData: true,
                children: {},
              },
              lootbox_bundle_daily_free_chest: {
                type: InputType.numeric,
                label: "lootbox_bundle_daily_free_chest",
                placeholder: "Edit lootbox_bundle_daily_free_chest",
                additionalFields: [],
                isShownWithoutData: true,
                children: {},
              },
              lootbox_bundle_valley_chest: {
                type: InputType.numeric,
                label: "lootbox_bundle_valley_chest",
                placeholder: "Edit lootbox_bundle_valley_chest",
                additionalFields: [],
                isShownWithoutData: true,
                children: {},
              },
              petbox_rare: {
                type: InputType.numeric,
                label: "petbox_rare",
                placeholder: "Edit petbox_rare",
                additionalFields: [],
                isShownWithoutData: true,
                children: {},
              },
              petbox_unique: {
                type: InputType.numeric,
                label: "petbox_unique",
                placeholder: "Edit petbox_unique",
                additionalFields: [],
                isShownWithoutData: true,
                children: {},
              },
              petbox_mysterious: {
                type: InputType.numeric,
                label: "petbox_mysterious",
                placeholder: "Edit petbox_mysterious",
                additionalFields: [],
                isShownWithoutData: true,
                children: {},
              },
              bundle_starter: {
                type: InputType.numeric,
                label: "bundle_starter",
                placeholder: "Edit bundle_starter",
                additionalFields: [],
                isShownWithoutData: true,
                children: {},
              },
              bundle_welcome_pack: {
                type: InputType.numeric,
                label: "bundle_welcome_pack",
                placeholder: "Edit bundle_welcome_pack",
                additionalFields: [],
                isShownWithoutData: true,
                children: {},
              },
              bundle_booster_pack: {
                type: InputType.numeric,
                label: "bundle_booster_pack",
                placeholder: "Edit bundle_booster_pack",
                additionalFields: [],
                isShownWithoutData: true,
                children: {},
              },
              bundle_pet_food: {
                type: InputType.numeric,
                label: "bundle_pet_food",
                placeholder: "Edit bundle_pet_food",
                additionalFields: [],
                isShownWithoutData: true,
                children: {},
              },
              bundle_camping_set: {
                type: InputType.numeric,
                label: "bundle_camping_set",
                placeholder: "Edit bundle_camping_set",
                additionalFields: [],
                isShownWithoutData: true,
                children: {},
              },
              bundle_gold_stash: {
                type: InputType.numeric,
                label: "bundle_gold_stash",
                placeholder: "Edit bundle_gold_stash",
                additionalFields: [],
                isShownWithoutData: true,
                children: {},
              },
            },
          },
          badge: {
            type: InputType.link,
            label: "Badge",
            additionalFields: [],
            children: {
              type: {
                type: InputType.text,
                label: "Type",
                placeholder: "Edit Type",
                additionalFields: [],
                children: {},
              },
              typeID: {
                type: InputType.text,
                label: "TypeID",
                placeholder: "Edit TypeID",
                additionalFields: [],
                children: {},
                yupRules: yup
                  .string()
                  .required("required")
                  .test("value should starts with 'badge_'", (val) =>
                    val!.toString().includes("badge_")
                  ),
              },
            },
          },
          InventoryData: {
            type: InputType.link,
            label: "InventoryData",
            additionalFields: [],
            children: {
              lastChange: {
                type: InputType.date,
                label: "lastChange",
                isDisabled: true,
                additionalFields: [],
                children: {},
              },
            },
          },
          boosterItems: {
            type: InputType.link,
            label: "boosterItems",
            additionalFields: [],
            children: {
              compass: {
                type: InputType.link,
                label: "compass",
                additionalFields: [],
                children: {
                  dueTime: {
                    type: InputType.date,
                    label: "dueTime",
                    isDisabled: true,
                    additionalFields: [],
                    children: {},
                  },
                },
              },
              telescope: {
                type: InputType.link,
                label: "telescope",
                additionalFields: [],
                children: {
                  dueTime: {
                    type: InputType.date,
                    label: "dueTime",
                    isDisabled: true,
                    additionalFields: [],
                    children: {},
                  },
                },
              },
            },
          },
          tutorial_done: {
            type: InputType.checkbox,
            label: "tutorial_done",
            placeholder: "Edit tutorial_done",
            additionalFields: [],
            children: {},
          },
          ...privateDataFieldsWithTimestamps.reduce(
            (result, { label, isDateInMilliseconds }) => ({
              ...result,
              [label]: {
                type: InputType.date,
                label: label,
                isDisabled: true,
                additionalFields: [],
                children: {},
                isDateInMilliseconds,
              },
            }),
            {}
          ),
        },
      },
    },
  },
};
