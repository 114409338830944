import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { getUrl, prepareHeaders } from "../../utils/api";
import {
  PlayerPersonalValleyEntry,
  GetPlayersCountBody,
  Player,
  PlayerEntry,
  PetPlayerEntry,
  PlayerMoneySpentEntry,
} from "./types";
import {
  SearchPaginateRequestBody,
  PaginateResponse,
  EditRequestBody,
  SearchAllRequestBody,
} from "../../types";
import { ROUTE_PATHS, DB_NAMES, COLLECTION_NAMES } from "../../consts";

const { SEARCH, ALL, UPDATE, COUNT } = ROUTE_PATHS;

export const playersApi = createApi({
  reducerPath: "playersApi",
  baseQuery: fetchBaseQuery({
    baseUrl: getUrl("/"),
    prepareHeaders,
  }),
  tagTypes: ["Player", "PlayerPersonalValley", "PetPlayer", "PlayerMoneySpent"],
  endpoints: (builder) => ({
    getPlayersCount: builder.mutation<number, GetPlayersCountBody>({
      query: (body) => {
        return {
          url: `${SEARCH}${COUNT}`,
          method: "POST",
          body: {
            ...body,
            dbName: DB_NAMES.SYSTEM,
            collectionName: COLLECTION_NAMES.PLAYER,
          },
        };
      },
    }),
    getAllPlayers: builder.mutation<
      PlayerEntry[],
      Omit<SearchAllRequestBody, "dbName" | "collectionName">
    >({
      query: (body) => {
        return {
          url: `${SEARCH}${ALL}`,
          method: "POST",
          body: {
            ...body,
            dbName: DB_NAMES.SYSTEM,
            collectionName: COLLECTION_NAMES.PLAYER,
          },
        };
      },
    }),
    getPlayers: builder.query<
      PaginateResponse<PlayerEntry>,
      SearchPaginateRequestBody
    >({
      query: (body) => {
        return { url: `${SEARCH}`, method: "POST", body };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.docs.map(({ _id: id }) => ({
                type: "Player" as const,
                id,
              })),
              { type: "Player", id: "LIST" },
            ]
          : [{ type: "Player", id: "LIST" }],
    }),
    editPlayer: builder.mutation<Player, EditRequestBody>({
      query: (body) => ({ url: `${SEARCH}${UPDATE}`, method: "POST", body }),
      invalidatesTags: [{ type: "Player", id: "LIST" }],
    }),
    getPlayerPersonalValleys: builder.query<
      PaginateResponse<PlayerPersonalValleyEntry>,
      SearchPaginateRequestBody
    >({
      query: (body) => {
        return { url: `${SEARCH}`, method: "POST", body };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.docs.map(({ _id: id }) => ({
                type: "PlayerPersonalValley" as const,
                id,
              })),
              { type: "PlayerPersonalValley", id: "LIST" },
            ]
          : [{ type: "PlayerPersonalValley", id: "LIST" }],
    }),
    editPlayerPersonalValley: builder.mutation<
      PlayerPersonalValleyEntry,
      EditRequestBody
    >({
      query: (body) => ({ url: `${SEARCH}${UPDATE}`, method: "POST", body }),
      invalidatesTags: [{ type: "PetPlayer", id: "LIST" }],
    }),
    getPetPlayers: builder.query<
      PaginateResponse<PetPlayerEntry>,
      SearchPaginateRequestBody
    >({
      query: (body) => {
        return { url: `${SEARCH}`, method: "POST", body };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.docs.map(({ _id: id }) => ({
                type: "PetPlayer" as const,
                id,
              })),
              { type: "PetPlayer", id: "LIST" },
            ]
          : [{ type: "PetPlayer", id: "LIST" }],
    }),
    editPetPlayer: builder.mutation<PetPlayerEntry, EditRequestBody>({
      query: (body) => ({ url: `${SEARCH}${UPDATE}`, method: "POST", body }),
      invalidatesTags: [{ type: "PetPlayer", id: "LIST" }],
    }),
    getPlayerMoneySpent: builder.query<
      PaginateResponse<PlayerMoneySpentEntry>,
      SearchPaginateRequestBody
    >({
      query: (body) => {
        return { url: `${SEARCH}`, method: "POST", body };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.docs.map(({ _id: id }) => ({
                type: "PlayerMoneySpent" as const,
                id,
              })),
              { type: "PlayerMoneySpent", id: "LIST" },
            ]
          : [{ type: "PlayerMoneySpent", id: "LIST" }],
    }),
    editPlayerMoneySpent: builder.mutation<
      PlayerMoneySpentEntry,
      EditRequestBody
    >({
      query: (body) => ({ url: `${SEARCH}${UPDATE}`, method: "POST", body }),
      invalidatesTags: [{ type: "PlayerMoneySpent", id: "LIST" }],
    }),
  }),
});

export const {
  useGetAllPlayersMutation,
  useGetPlayersCountMutation,
  useGetPlayersQuery,
  useEditPlayerMutation,
  useGetPlayerPersonalValleysQuery,
  useEditPlayerPersonalValleyMutation,
  useGetPetPlayersQuery,
  useEditPetPlayerMutation,
  useGetPlayerMoneySpentQuery,
  useEditPlayerMoneySpentMutation,
} = playersApi;
