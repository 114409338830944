import React, { useCallback, useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocalStorage } from "usehooks-ts";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "../../store";
import { logOut } from "../../slices/auth/actions";
import { escapeRegEx } from "../../utils/escapeRegEx";

import { CustomDataGrid } from "../CustomDataGrid/CustomDataGrid";
import { PaperPage, PaperPageError } from "../PaperPage";

import { SearchPaginateRequestBody } from "../../types";
import { DataTableProps } from "./DataTableTypes";
import {
  CHAT_STATUSES,
  DEFAULT_PAGE_SIZE,
  ERROR_STATUS_UNAUTHORIZED,
  ROUTE_PATHS,
} from "../../consts";

export const DataTable = ({
  filter,
  filterType = "",
  filterFieldsFront = "",
  getQueryData,
  columns,
  collectionName,
  dbName,
  errorMessageEntity = null,
  searchFieldsFront,
  searchFieldsBack,
  hasRefreshBtn = false,
  setRefetchFunc,
  requiredFields,
  hasPagination = true,
  isSupportTable = false,
}: DataTableProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(["common", "errors"]);

  const [pageSize] = useLocalStorage(
    `table-${collectionName}.pageSize`,
    DEFAULT_PAGE_SIZE
  );
  const [isClosedIssuesShown] = useLocalStorage<boolean>(
    `table-${collectionName}.isClosedIssuesShown`,
    false
  );

  const initialQuery: SearchPaginateRequestBody = useMemo(() => {
    const usualQuery = {
      search: null,
      limit: pageSize,
      page: 1,
      dbName,
      collectionName,
      requiredFields,
      pagination: hasPagination,
    };

    return isSupportTable
      ? {
          ...usualQuery,
          excludeFromSearch: isClosedIssuesShown
            ? null
            : { status: CHAT_STATUSES.closed },
        }
      : usualQuery;
  }, [
    collectionName,
    dbName,
    hasPagination,
    isClosedIssuesShown,
    isSupportTable,
    pageSize,
    requiredFields,
  ]);

  const [query, setQuery] = useState(initialQuery);

  const { isLoading, isFetching, data, error, refetch } = getQueryData(query);

  useEffect(() => {
    setRefetchFunc && setRefetchFunc(() => refetch);
  }, [refetch, setRefetchFunc]);

  const getAllItems = useCallback(() => {
    setQuery((prevState) => ({ ...prevState, search: null }));
  }, [setQuery]);

  const responsePageSize = data?.limit;
  const responsePage = data?.page;
  const responseHasNextPage = data?.hasNextPage;
  const totalPages = data?.totalPages;

  const rowsData = useMemo(() => {
    if (!data) {
      return [];
    }

    const adaptedData = data.docs.map((el) => ({
      ...el,
      id: el._id,
    }));

    if (filterType !== "" && filterFieldsFront.length > 0) {
      const searchRegEx = new RegExp(escapeRegEx(filterType), "i");

      return adaptedData.filter((row: any) => {
        return Object.keys(row).some((field) => {
          if (!filterFieldsFront.includes(field) || !row[field]) {
            return false;
          }
          if (searchRegEx.test(JSON.stringify(row[field]))) {
            return true;
          }
          return false;
        });
      });
    }
    return adaptedData;
  }, [data, filterFieldsFront, filterType]);

  if (error) {
    if ("status" in error && error.status === ERROR_STATUS_UNAUTHORIZED) {
      dispatch(logOut());
      navigate(ROUTE_PATHS.SIGN_IN);
    } else {
      return (
        <PaperPageError
          refetch={refetch}
          disabled={isFetching}
          message={t("refetch", { entity: errorMessageEntity, ns: "errors" })}
        />
      );
    }
  }

  return (
    <PaperPage elevation={0}>
      <CustomDataGrid
        isLoading={isLoading || isFetching}
        filter={filter}
        getAllItems={getAllItems}
        onSearchClick={setQuery}
        tableName={collectionName}
        rows={rowsData}
        totalPages={totalPages}
        searchFieldsFront={searchFieldsFront}
        searchFieldsBack={searchFieldsBack}
        dbName={dbName}
        collectionName={collectionName}
        responsePageSize={responsePageSize}
        responsePage={responsePage}
        responseHasNextPage={responseHasNextPage}
        columns={columns}
        hasRefreshBtn={hasRefreshBtn}
        refetch={refetch}
        hasPagination={hasPagination}
        isSupportTable={isSupportTable}
      />
    </PaperPage>
  );
};
