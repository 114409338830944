import * as yup from "yup";
import { DynamicSchema } from "../types";
import { InputType } from "../consts";

export const groupManagementSchema: DynamicSchema = {
  _root: {
    type: InputType.root,
    additionalFields: [],
    children: {
      owner: {
        type: InputType.text,
        label: "owner",
        placeholder: "Edit owner",
        additionalFields: [],
        children: {},
        isDisabled: true,
      },
      teamName: {
        type: InputType.text,
        yupRules: yup.string().required("required"),
        label: "team name",
        placeholder: "Edit Team Name",
        additionalFields: [],
        children: {},
        isDisabled: false,
      },
      teamDesc: {
        type: InputType.text,
        label: "team description",
        yupRules: yup.string().min(1),
        placeholder: "Edit team description",
        additionalFields: [],
        children: {},
        isDisabled: false,
      },
      tags: {
        type: InputType.text,
        label: "tags",
        placeholder: "Edit tags",
        additionalFields: [],
        children: {},
        isDisabled: true,
      },
      badge: {
        type: InputType.text,
        yupRules: yup
          .string()
          .required("required")
          .test("value should starts with 'badge_'", (val) =>
            val!.toString().includes("badge_")
          ),
        label: "badge",
        placeholder: "Edit badge",
        additionalFields: [],
        children: {},
        isDisabled: false,
      },
      teamLevel: {
        type: InputType.text,
        label: "team level",
        placeholder: "Edit Team Level",
        additionalFields: [],
        children: {},
        isDisabled: true,
      },
      lastActive: {
        type: InputType.date,
        label: "Last Active",
        additionalFields: [],
        children: {},
        isDisabled: true,
        isDateInMilliseconds: true,
      },
      playerAverageLevel: {
        type: InputType.text,
        label: "playerAverageLevel",
        placeholder: "Edit player average level",
        additionalFields: [],
        children: {},
        isDisabled: true,
      },
      questsCompleted: {
        type: InputType.text,
        label: "quests completed",
        placeholder: "Edit quests completed ",
        additionalFields: [],
        children: {},
        isDisabled: true,
      },
      teamID: {
        type: InputType.text,
        label: "teamID",
        placeholder: "Edit teamID",
        additionalFields: [],
        children: {},
        isDisabled: true,
      },
    },
  },
};
