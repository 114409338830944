import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { State } from "../../store";
import { logOut } from "../auth/actions";

interface WebsocketDataState {
  unreadChatIds: string[];
  wasWebsocketOpen: boolean;
}

const initialState: WebsocketDataState = {
  unreadChatIds: [],
  wasWebsocketOpen: false,
};

const websocketSlice = createSlice({
  name: "websocketData",
  initialState,
  reducers: {
    addChatId(state, action: PayloadAction<string>) {
      state.unreadChatIds.push(action.payload);
    },
    removeChatId(state, action: PayloadAction<string>) {
      state.unreadChatIds = state.unreadChatIds.filter(
        (id) => id !== action.payload
      );
    },
    cleanUnreadChatIds(state) {
      state.unreadChatIds = initialState.unreadChatIds;
    },
    setWasWebsocketOpen(state, action: PayloadAction<boolean>) {
      state.wasWebsocketOpen = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logOut.fulfilled, (state) => {
      state.unreadChatIds = [];
      state.wasWebsocketOpen = false;
    });
  },
});

export const getUnreadChatIds = ({ websocketData }: State) =>
  websocketData.unreadChatIds;
export const getWasWebsocketOpen = ({ websocketData }: State) =>
  websocketData.wasWebsocketOpen;

export const {
  addChatId,
  removeChatId,
  cleanUnreadChatIds,
  setWasWebsocketOpen,
} = websocketSlice.actions;

export default websocketSlice.reducer;
