import React, { useCallback, useState } from "react";
import * as yup from "yup";
import { Alert, Collapse, Stack, TextField, Typography } from "@mui/material";
import { SubmitSpinnerButton } from "../../components/SubmitSpinnerButton";

import { useDispatch, useSelector } from "../../store";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import i18n from "../../i18n";

import { SignInFirstStepRequestDto as FirstStepFormInputs } from "../../slices/auth/types";
import {
  signInAddNewPassword,
  signInFirstStep,
} from "../../slices/auth/actions";
import { getIsNewPasswordRequired } from "../../slices/auth/slice";
import { fieldError, useSubmitFormErrorHandler } from "../../utils/formHelpers";

const signInFormFirstStepSchema = yup.object({
  email: yup
    .string()
    .email(i18n.t("validationErrors.incorrectEmail"))
    .required(i18n.t("validationErrors.required")),
  password: yup
    .string()
    .min(3, i18n.t("validationErrors.tooShort", { minLength: 3 }))
    .required(i18n.t("validationErrors.required")),
});

export const SignInFirstStep = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { control, handleSubmit } = useForm<FirstStepFormInputs>({
    resolver: yupResolver(signInFormFirstStepSchema),
  });
  const { catchFormError, formError, fieldsErrors } = useSubmitFormErrorHandler(
    t("error.signIn")
  );
  const [isLoading, setIsLoading] = useState(false);

  const isNewPasswordRequired = useSelector(getIsNewPasswordRequired);

  const onFirstSubmit = useCallback(
    (fields: FirstStepFormInputs) => {
      const currentAction = isNewPasswordRequired
        ? signInAddNewPassword
        : signInFirstStep;

      setIsLoading(true);

      dispatch(currentAction(fields))
        .unwrap()
        .then(() => {
          setIsLoading(false);
        })
        .catch((error) => {
          console.log("FirstStep error", error);
          setIsLoading(false);
          catchFormError(error);
        });
    },
    [catchFormError, dispatch, isNewPasswordRequired]
  );

  return (
    <Stack
      spacing={3}
      sx={{ marginTop: "50px" }}
      component="form"
      autoComplete="on"
      onSubmit={handleSubmit(onFirstSubmit)}
    >
      <Typography component="h2" variant="h3">
        {t("sign in")}
      </Typography>
      {formError && (
        <Collapse in={!!formError}>
          <Alert severity="error">{formError}</Alert>
        </Collapse>
      )}
      {!isNewPasswordRequired && (
        <Controller
          name="email"
          control={control}
          render={({ field, fieldState: { invalid, error } }) => {
            const [serverInvalid, serverError] = fieldError(
              "email",
              fieldsErrors
            );
            return (
              <TextField
                autoComplete="on"
                variant="outlined"
                size="small"
                label={t("email")}
                {...field}
                error={invalid || serverInvalid}
                helperText={error?.message || serverError}
              />
            );
          }}
        />
      )}
      <Controller
        name="password"
        control={control}
        render={({ field, fieldState: { invalid, error } }) => {
          const [serverInvalid, serverError] = fieldError(
            "password",
            fieldsErrors
          );
          return (
            <TextField
              autoComplete="off"
              variant="outlined"
              size="small"
              label={t(isNewPasswordRequired ? "newPassword" : "password")}
              type="password"
              {...field}
              error={invalid || serverInvalid}
              helperText={error?.message || serverError}
            />
          );
        }}
      />
      <SubmitSpinnerButton loading={isLoading} />
    </Stack>
  );
};
