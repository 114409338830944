import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { getUrl, prepareHeaders } from "../../utils/api";
import { StoreContentEntry } from "./types";
import {} from "../../types";
import { ROUTE_PATHS } from "../../consts";

const { STORE_CONTENT_API } = ROUTE_PATHS;

export const storeContentApi = createApi({
  reducerPath: "storeContentApi",
  baseQuery: fetchBaseQuery({
    baseUrl: getUrl("/"),
    prepareHeaders,
  }),
  tagTypes: ["StoreContent"],
  endpoints: (builder) => ({
    getStoreContentTableData: builder.query<StoreContentEntry[], void>({
      query: () => {
        return {
          url: `${STORE_CONTENT_API}/tableData`,
          method: "POST",
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ _id: id }) => ({
                type: "StoreContent" as const,
                id,
              })),
              { type: "StoreContent", id: "LIST" },
            ]
          : [{ type: "StoreContent", id: "LIST" }],
    }),
  }),
});

export const { useGetStoreContentTableDataQuery } = storeContentApi;
