import React, { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { Box, Button, MenuItem, Stack, TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { CustomSelectActive, ErrorBox } from "./EventFormsStyles";
import { useTranslation } from "react-i18next";
import { FormValues, Result } from "./types";

type FormValuesKey =
  | "virtualGoodCount"
  | "virtualGoodKey"
  | "currencyCount"
  | "currencyKey"
  | "petCount"
  | "petKey"
  | "furnitureCount"
  | "furnitureKey";

type EventGoodItemProps = {
  name: "goods" | "currencies" | "pets" | "furniture";
  setResult: Dispatch<SetStateAction<Result>>;
  result: Result;
  setHasError: Dispatch<SetStateAction<string>>;
  valueList: string[];
};

export const EventGoodItem = ({
  name,
  setResult,
  result,
  setHasError,
  valueList,
}: EventGoodItemProps) => {
  const { t } = useTranslation();
  const { control, handleSubmit } = useFormContext<FormValues>();

  const { currentKey, currentCount, currentLabel } = useMemo(() => {
    let currentKey: FormValuesKey;
    let currentCount: FormValuesKey;
    let currentLabel = "";
    switch (name) {
      case "goods":
        currentKey = "virtualGoodKey";
        currentCount = "virtualGoodCount";
        currentLabel = "virtualGoods.title";
        break;

      case "currencies":
        currentKey = "currencyKey";
        currentCount = "currencyCount";
        currentLabel = "distributionSystem.fieldCurrency";
        break;

      case "pets":
        currentKey = "petKey";
        currentCount = "petCount";
        currentLabel = "distributionSystem.fieldPet";
        break;

      case "furniture":
        currentKey = "furnitureKey";
        currentCount = "furnitureCount";
        currentLabel = "distributionSystem.fieldFurniture";
        break;

      default:
        break;
    }
    return { currentKey, currentCount, currentLabel };
  }, [name]);

  const onSubmit = useCallback(
    (data: FormValues) => {
      const exist = result[name].find((item) => {
        const objKey = Object.keys(item)[0];
        return objKey === data[currentKey];
      });
      if (exist === undefined) {
        setResult((prevResult) => ({
          ...prevResult,
          [name]: [
            ...prevResult[name],
            { [data[currentKey]]: parseInt(data[currentCount], 10) },
          ],
        }));
      }
      setHasError("");
    },
    [currentCount, currentKey, name, result, setHasError, setResult]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="row" spacing={2}>
        <Box>
          <h3 style={{ margin: 0 }}>{t(currentLabel)}</h3>
          <Controller
            control={control}
            rules={{ required: "required" }}
            name={currentKey}
            render={({ field, fieldState: { error } }) => (
              <>
                <CustomSelectActive {...field}>
                  <MenuItem key="-" value=""></MenuItem>
                  {valueList.map((val: string) => (
                    <MenuItem key={val} value={val}>
                      {val}
                    </MenuItem>
                  ))}
                </CustomSelectActive>
                <ErrorBox>{error?.message}</ErrorBox>
              </>
            )}
          />
        </Box>

        <Box>
          <h3 style={{ margin: 0 }}>{t("distributionSystem.fieldCount")}</h3>
          <Controller
            control={control}
            name={currentCount}
            rules={{
              required: "required",
              min: { value: 1, message: "positive only" },
            }}
            defaultValue=""
            render={({ field, fieldState: { invalid, error } }) => (
              <>
                <TextField
                  size="small"
                  type="number"
                  style={{ width: 120 }}
                  variant="outlined"
                  error={invalid}
                  {...field}
                />
                <ErrorBox>{error?.message}</ErrorBox>
              </>
            )}
          />
        </Box>

        <Stack justifyContent="end">
          <Button type="submit" variant="contained">
            {t("buttonNames.add")}
          </Button>
          <ErrorBox></ErrorBox>
        </Stack>
      </Stack>
    </form>
  );
};
