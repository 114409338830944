import React, { Dispatch, memo, SetStateAction, useCallback } from "react";

import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { SelectChangeEvent, Stack, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDayjs from "@mui/lab/AdapterDayjs";
import ChartFilterInput from "./ChartFilterInput";
import { SpinnerButton } from "../SpinnerButton";

import {
  AgePeriods,
  FiltersType,
  FilterValuesType,
  GenderTypes,
  COLUMN_WIDTH,
  INPUT_WIDTH,
  DeepLinkTypes,
} from "./types";

type ChartFilterProps = {
  setFilters: Dispatch<SetStateAction<FiltersType>>;
  filters: FiltersType;
  handleUseFilter: () => void;
  isDataLoading: boolean;
};

const ChartFilter = ({
  setFilters,
  filters,
  handleUseFilter,
  isDataLoading,
}: ChartFilterProps) => {
  const { t } = useTranslation();
  const { dateFrom, dateTo, gender, age, deepLink } = filters;

  const handleChangeDateFrom = useCallback(
    (value) => {
      const currValue = dayjs(value).valueOf();
      const newDateFrom = !isNaN(currValue) ? currValue : null;

      setFilters((prevState) => ({
        ...prevState,
        dateFrom: newDateFrom,
      }));
    },
    [setFilters]
  );

  const handleChangeDateTo = useCallback(
    (value) => {
      const currValue = dayjs(value).valueOf();
      const newDateTo = !isNaN(currValue) ? currValue : null;

      setFilters((prevState) => ({
        ...prevState,
        dateTo: newDateTo,
      }));
    },
    [setFilters]
  );

  const handleChangeFilterValue = useCallback(
    (event: SelectChangeEvent<FilterValuesType>, valueName: string) => {
      const value = event.target.value;

      setFilters((prevState) => ({
        ...prevState,
        [valueName]: value,
      }));
    },
    [setFilters]
  );

  const handleChangeGender = useCallback(
    (event: SelectChangeEvent<FilterValuesType>) => {
      handleChangeFilterValue(event, "gender");
    },
    [handleChangeFilterValue]
  );
  const handleChangeDeepLink = useCallback(
    (event: SelectChangeEvent<FilterValuesType>) => {
      handleChangeFilterValue(event, "deepLink");
    },
    [handleChangeFilterValue]
  );
  const handleChangeAgePeriod = useCallback(
    (event: SelectChangeEvent<FilterValuesType>) => {
      handleChangeFilterValue(event, "age");
    },
    [handleChangeFilterValue]
  );

  return (
    <>
      <h2 style={{ margin: 0, marginBottom: 12 }}>{t("filter.Filter")}</h2>
      <Stack direction="row" mb={3}>
        <Stack width={COLUMN_WIDTH}>
          <h3 style={{ margin: 0, marginBottom: 8, color: "#1976d2" }}>
            {t("filter.Period")}:
          </h3>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label={t("filter.labelFromDate")}
              inputFormat="YYYY/MM/DD"
              mask="____/__/__"
              maxDate={dayjs(dateTo)}
              onChange={handleChangeDateFrom}
              value={dateFrom}
              renderInput={(params) => (
                <TextField
                  size="small"
                  sx={{ width: INPUT_WIDTH, mb: 1 }}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label={t("filter.labelToDate")}
              inputFormat="YYYY/MM/DD"
              mask="____/__/__"
              minDate={dayjs(dateFrom)}
              onChange={handleChangeDateTo}
              value={dateTo}
              renderInput={(params) => (
                <TextField
                  size="small"
                  sx={{ width: INPUT_WIDTH }}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
        </Stack>

        <ChartFilterInput
          filterName="Gender"
          currentValue={gender}
          filterValues={Object.values(GenderTypes)}
          handleChangeValue={handleChangeGender}
          shouldBeTranslated
        />
        <ChartFilterInput
          filterName="Age"
          currentValue={age}
          filterValues={Object.values(AgePeriods)}
          handleChangeValue={handleChangeAgePeriod}
        />
        <ChartFilterInput
          filterName="DeepLink"
          currentValue={deepLink}
          filterValues={Object.values(DeepLinkTypes)}
          handleChangeValue={handleChangeDeepLink}
          shouldBeTranslated
        />
      </Stack>

      <SpinnerButton
        type="button"
        variant="contained"
        buttonName={t("buttonNames.setFilters")}
        loading={isDataLoading}
        onClick={handleUseFilter}
      />
    </>
  );
};

export default memo(ChartFilter);
