import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

import { Helmet } from "react-helmet";
import { Container, Stack } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import DvrOutlinedIcon from "@mui/icons-material/DvrOutlined";
import { DataTable } from "../../components/DataTable/DataTable";
import { WideDrawer } from "../../components/WideDrawerForm/WideDrawer";
import { Edit } from "../../components/Edit/Edit";
import { EditPlayerHeader } from "../../components/Edit/EditPlayerHeader";
import SupportChat from "../../components/SupportChat/SupportChat";
import AlertMessage from "../../components/AlertMessage";
import OpenEditBtn from "../../components/OpenEditBtn";
import DeletePlayerAlertBody from "../Players/DeletePlayerAlertBody";

import useWebsocket from "../../utils/useWebsocket";
import { getWasWebsocketOpen } from "../../slices/Websocket/slice";
import { useDispatch, useSelector } from "../../store";
import { useTranslation } from "react-i18next";
import { useTableUrlTools } from "../../utils/useTableUrlTools";
import { useTitle } from "../../utils/useTitle";
import {
  useEditPlayerMutation,
  useGetPlayersQuery,
} from "../../slices/players/api";
import { useFeedSuccess } from "../../utils/feedHooks";
import { useGetFeedbackTableDataQuery } from "../../slices/feedback/api";
import { useGetListQuery } from "../../slices/lists/api";
import { searchFieldsFront, searchFieldsBack } from "./searchScheme";
import {
  getCurrentFeedbackID,
  getCurrentPlayerID,
  getUrlAfterCloseDrawer,
  setCurrentFeedbackID,
  setCurrentPlayerID,
  setUrlOnCloseDrawer,
} from "../../slices/players/slice";
import { ColumnData } from "../../components/CustomDataGrid/types";
import { MessageSourceType } from "../../slices/Websocket/types";
import {
  WebsocketActionType,
  WebsocketMessageStatus,
} from "../../slices/Websocket/types";
import { QueryListName } from "../../slices/lists/types";
import { FirstMessageType } from "../../components/SupportChat/types";
import { COLLECTION_NAMES, DB_NAMES, ROUTE_PATHS } from "../../consts";

const collectionName = COLLECTION_NAMES.PLAYER;
const dbName = DB_NAMES.SYSTEM;

const Support = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const title = useTitle(t("support.title"));
  const editPlayerTitle = useTitle(t("players.editPlayer"));
  const wasWebsocketOpen = useSelector(getWasWebsocketOpen);
  const { sendMessage, subscribeOnMessage, unsubscribeOnMessage } =
    useWebsocket();
  const feedSuccess = useFeedSuccess();
  const { data: virtualGoodShortCodes, isLoading: isLoadingVirtualGoods } =
    useGetListQuery(QueryListName.virtualGoodShortCodes);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isAlertShown, setIsAlertShown] = useState(false);
  const [alertBodyValue, setAlertBodyValue] = useState("");
  const [isDeletePlayerSending, setIsDeletePlayerSending] = useState(false);
  const [currentPlayerName, setCurrentPlayerName] = useState("");
  const [refetchFunc, setRefetchFunc] = useState<null | (() => void)>(null);

  const handleOpenEditBtnClick = useCallback(
    ({ playerID, feedbackDataID }) => {
      dispatch(setUrlOnCloseDrawer(ROUTE_PATHS.SUPPORT));
      dispatch(setCurrentFeedbackID(feedbackDataID));
      dispatch(setCurrentPlayerID(playerID));
    },
    [dispatch]
  );

  const dataTableColumns: ColumnData<FirstMessageType>[] = useMemo(
    () => [
      {
        width: 100,
        label: "N",
        dataKey: "id",
        renderCell: (rowData, rowIndex: number) => rowIndex + 1,
      },
      {
        width: 200,
        label: "User name",
        dataKey: "displayName",
        renderCell: ({ displayName }) => displayName,
      },
      {
        width: 120,
        label: "Status",
        dataKey: "status",
        renderCell: ({ status }) => status,
      },
      {
        width: 120,
        label: "Source",
        dataKey: "type",
        renderCell: ({ firstMessage }) => {
          switch (firstMessage?.type) {
            case MessageSourceType.player:
              return <PhoneIphoneIcon color="action" fontSize="small" />;

            case MessageSourceType.playerMail:
              return <MailOutlineIcon color="action" fontSize="small" />;

            case MessageSourceType.playerWebFeedback:
              return <DvrOutlinedIcon color="action" fontSize="small" />;

            default:
              return null;
          }
        },
      },
      {
        width: 600,
        label: "Last message",
        dataKey: "firstMessage",
        renderCell: ({ firstMessage }) =>
          firstMessage?.value.replace(/<[^>]+>/g, ""),
      },
      {
        width: 250,
        label: "Time",
        dataKey: "firstMessage",
        renderCell: ({ firstMessage }) =>
          firstMessage
            ? dayjs.unix(firstMessage.timeStamp).format("YYYY/MM/DD, HH:mm:ss")
            : "",
      },
      {
        width: 120,
        label: "Open",
        dataKey: "open",
        renderCell: ({ _id, playerID }) => {
          return (
            <OpenEditBtn
              id={playerID}
              feedbackDataID={_id}
              handleOpenEditBtnClick={handleOpenEditBtnClick}
              routePath={playerID ? ROUTE_PATHS.PLAYERS : ROUTE_PATHS.SUPPORT}
            />
          );
        },
      },
    ],
    [handleOpenEditBtnClick]
  );

  const { id, detailsDrawerOpen, editDrawerOpen, closeDrawer } =
    useTableUrlTools(ROUTE_PATHS.SUPPORT);
  const currentPlayerID = useSelector(getCurrentPlayerID);
  const currentFeedbackID = useSelector(getCurrentFeedbackID) || id;
  const urlAfterCloseDrawer = useSelector(getUrlAfterCloseDrawer);

  const onCloseDrawer = useCallback(() => {
    setIsEditMode(false);
    dispatch(setCurrentFeedbackID(null));
    dispatch(setCurrentPlayerID(null));
    closeDrawer(urlAfterCloseDrawer);
    dispatch(setUrlOnCloseDrawer(""));
  }, [closeDrawer, dispatch, urlAfterCloseDrawer]);

  const handleOpenAlert = useCallback(() => {
    setIsAlertShown(true);
  }, []);

  const handleCloseAlert = useCallback(() => {
    setIsAlertShown(false);
  }, []);

  const handleClickDelete = useCallback(() => {
    setIsDeletePlayerSending(true);

    const processedMsg = {
      action: WebsocketActionType.deleteAccount,
      inputParams: JSON.stringify({
        playerID: currentPlayerID,
      }),
    };

    sendMessage(processedMsg);
    setIsAlertShown(false);
  }, [currentPlayerID, sendMessage]);

  const onMessageReceived = useCallback(
    (event: MessageEvent) => {
      setIsDeletePlayerSending(false);

      const msg = JSON.parse(event.data);

      if (msg.action === WebsocketActionType.deleteAccount) {
        if (msg.status === WebsocketMessageStatus.success) {
          feedSuccess(t("players.deletePlayerSuccessMsg"));

          setIsEditMode(false);
          navigate(urlAfterCloseDrawer);
          dispatch(setUrlOnCloseDrawer(""));

          refetchFunc && refetchFunc();
        }
      }
    },
    [dispatch, feedSuccess, navigate, refetchFunc, t, urlAfterCloseDrawer]
  );

  useEffect(() => {
    if (wasWebsocketOpen) {
      subscribeOnMessage(onMessageReceived);
    }

    return () => {
      unsubscribeOnMessage(onMessageReceived);
    };
  }, [
    onMessageReceived,
    subscribeOnMessage,
    unsubscribeOnMessage,
    wasWebsocketOpen,
  ]);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Container maxWidth="xl" sx={{ height: "100%" }}>
        <DataTable
          searchFieldsFront={searchFieldsFront}
          searchFieldsBack={searchFieldsBack}
          getQueryData={useGetFeedbackTableDataQuery}
          collectionName={collectionName}
          dbName={dbName}
          columns={dataTableColumns}
          errorMessageEntity="players"
          setRefetchFunc={setRefetchFunc}
          hasRefreshBtn
          isSupportTable
        />
      </Container>
      <WideDrawer
        open={detailsDrawerOpen || editDrawerOpen}
        close={onCloseDrawer}
      >
        <Stack direction="row">
          {currentPlayerID && (
            <>
              <Stack>
                <EditPlayerHeader
                  playerID={currentPlayerID}
                  isDeleteBtnActive={isEditMode}
                  isDeletePlayerSending={isDeletePlayerSending}
                  deletePlayer={handleOpenAlert}
                />
                <Edit
                  id={currentPlayerID}
                  collectionName={collectionName}
                  dbName={dbName}
                  title={editPlayerTitle}
                  getQueryData={useGetPlayersQuery}
                  getEditItemTools={useEditPlayerMutation}
                  errorsDescriptionPath="players.errors.edit"
                  vGoodsNames={virtualGoodShortCodes}
                  isVGoodsNamesLoading={isLoadingVirtualGoods}
                  setIsEditMode={setIsEditMode}
                  setPlayerName={setCurrentPlayerName}
                />
              </Stack>
              <AlertMessage
                isShown={isAlertShown}
                onClickOk={handleClickDelete}
                onCancel={handleCloseAlert}
                title={t("players.confirmDeleteTitle")}
                textOk={t("buttonNames.deletePlayer")}
                isBtnOkDisabled={alertBodyValue !== currentPlayerName}
              >
                <DeletePlayerAlertBody
                  setAlertBodyValue={setAlertBodyValue}
                  alertBodyValue={alertBodyValue}
                  playerName={currentPlayerName}
                />
              </AlertMessage>
            </>
          )}
          <SupportChat
            playerID={currentPlayerID}
            feedbackDataID={currentFeedbackID}
          />
        </Stack>
      </WideDrawer>
    </>
  );
};

export default Support;
