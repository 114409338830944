import { createAsyncThunk } from "@reduxjs/toolkit";
import { Auth } from "aws-amplify";
import { Amplify } from "aws-amplify";
import { addData } from "../../slices/Roles/slice";

import { ThunkApi } from "../../store";
import { CognitoRefreshToken } from "amazon-cognito-identity-js";

import {
  signInAddNewPasswordResponseDto,
  SignInFirstStepRequestDto,
  SignInFirstStepResponseDto,
} from "./types";
import { SecondStepFormInputs } from "../../container/SignIn/types";
import { AUTH_CHALLENGE_NAMES } from "../../consts";

const getQRCodeLink = (username: string, code: string) =>
  `otpauth://totp/AWSCognito:${username}?secret=${code}&issuer=Admin`;

const { MFA_SETUP, SOFTWARE_TOKEN_MFA, NEW_PASSWORD_REQUIRED } =
  AUTH_CHALLENGE_NAMES;
const { REACT_APP_REGION, REACT_APP_USER_POOL_ID, REACT_APP_APP_CLIENT_ID } =
  process.env;

Amplify.configure({
  aws_cognito_region: REACT_APP_REGION,
  aws_user_pools_id: REACT_APP_USER_POOL_ID,
  aws_user_pools_web_client_id: REACT_APP_APP_CLIENT_ID,
});

const refreshSessionPromise = async (refreshToken: CognitoRefreshToken) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const user = await Auth.currentAuthenticatedUser();
    return user.refreshSession(
      refreshToken,
      async (err: Error, data: unknown) => {
        if (err) {
          reject(err);
        } else {
          resolve(data); // THIS IS REFRESHED DATA
        }
      }
    );
  });
};

import { useEffect } from "react";
import { useDispatch } from "./../../store";
import { sendTokensToIframe } from "../../utils/api";
import { setWasWebsocketOpen } from "../Websocket/slice";

export const useRefreshCurrentSession = async () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const func = async () => {
      const session = await Auth.currentSession();
      const currentRoles = session.getAccessToken().decodePayload()[
        "cognito:groups"
      ];

      dispatch(addData({ currentRoles }));

      await refreshSessionPromise(session.getRefreshToken());

      sendTokensToIframe(currentRoles);
    };

    func();
  }, [dispatch]);
};

export const signInFirstStep = createAsyncThunk<
  SignInFirstStepResponseDto,
  SignInFirstStepRequestDto,
  ThunkApi
>("auth/signInFirstStep", async ({ email, password }, { getState }) => {
  let {
    auth: { authUser, isNewPasswordRequired },
  } = getState();
  let QRCodeLink = "";
  let isFirstStepFinished = true;

  if (!authUser) {
    authUser = await Auth.signIn(email, password);
  }

  if (authUser.challengeName === MFA_SETUP) {
    const code = await Auth.setupTOTP(authUser);

    QRCodeLink = getQRCodeLink(authUser.username, code);
  }

  if (authUser.challengeName === NEW_PASSWORD_REQUIRED) {
    isNewPasswordRequired = true;
    isFirstStepFinished = false;
  }

  return { authUser, QRCodeLink, isNewPasswordRequired, isFirstStepFinished };
});

export const signInAddNewPassword = createAsyncThunk<
  signInAddNewPasswordResponseDto,
  SignInFirstStepRequestDto,
  ThunkApi
>("auth/signInAddNewPassword", async ({ password }, { getState }) => {
  let {
    auth: { authUser },
  } = getState();
  let QRCodeLink = "";

  await Auth.completeNewPassword(authUser, password).then(async (user) => {
    authUser = user;

    if (authUser.challengeName === MFA_SETUP) {
      const code = await Auth.setupTOTP(authUser);

      QRCodeLink = getQRCodeLink(authUser.username, code);
    }
  });

  return { authUser, QRCodeLink };
});

export const signInSecondStep = createAsyncThunk<
  void,
  SecondStepFormInputs,
  ThunkApi
>("auth/signInSecondStep", async ({ authCode }, { getState, dispatch }) => {
  const {
    auth: { authUser },
  } = getState();

  if (authUser?.challengeName === SOFTWARE_TOKEN_MFA) {
    const user = await Auth.confirmSignIn(
      authUser,
      authCode,
      authUser.challengeName
    );

    dispatch(
      addData({
        currentRoles:
          user?.signInUserSession.accessToken.payload["cognito:groups"],
      })
    );
  }

  if (authUser.challengeName === MFA_SETUP) {
    const session = await Auth.verifyTotpToken(authUser, authCode);

    dispatch(
      addData({
        currentRoles: session.getAccessToken().decodePayload()[
          "cognito:groups"
        ],
      })
    );

    Auth.setPreferredMFA(authUser, "TOTP");
  }
});

export const logOut = createAsyncThunk(
  "auth/logOut",
  async (x, { dispatch }) => {
    try {
      const signOut = await Auth.signOut();
      dispatch(setWasWebsocketOpen(false));
      console.log("signOut", signOut);
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }
);
