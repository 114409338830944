import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { State } from "../../store";

interface QuestsDataState {
  currentQuestID: string | null;
}

const initialState = {
  currentQuestID: null,
} as QuestsDataState;

const questsSlice = createSlice({
  name: "questsData",
  initialState,
  reducers: {
    setCurrentQuestID(
      state,
      action: PayloadAction<QuestsDataState["currentQuestID"]>
    ) {
      state.currentQuestID = action.payload;
    },
  },
});

export const getCurrentQuestID = ({ questsData }: State) =>
  questsData.currentQuestID;
export const { setCurrentQuestID } = questsSlice.actions;

export default questsSlice.reducer;
