import React, { memo, useCallback, useEffect, useState } from "react";

import { Button, Stack } from "@mui/material";
import { SpinnerButton } from "../SpinnerButton";

import { useTranslation } from "react-i18next";
import useWebsocket from "../../utils/useWebsocket";
import { useFeedError, useFeedSuccess } from "../../utils/feedHooks";
import {
  WebsocketActionType,
  WebsocketMessageStatus,
} from "../../slices/Websocket/types";
import { useSelector } from "../../store";
import { getWasWebsocketOpen } from "../../slices/Websocket/slice";

type EditPlayerHeaderProps = {
  playerID: string | null;
  isDeleteBtnActive?: boolean;
  isDeletePlayerSending?: boolean;
  deletePlayer?: () => void;
};

export const EditPlayerHeader = memo(
  ({
    playerID,
    isDeleteBtnActive,
    isDeletePlayerSending,
    deletePlayer,
  }: EditPlayerHeaderProps) => {
    const { t } = useTranslation();
    const wasWebsocketOpen = useSelector(getWasWebsocketOpen);
    const { sendMessage, subscribeOnMessage, unsubscribeOnMessage } =
      useWebsocket();
    const feedError = useFeedError();
    const feedSuccess = useFeedSuccess();

    const [isConfirmSending, setIsConfirmSending] = useState(false);

    const handleClickConfirmPlayer = useCallback(() => {
      setIsConfirmSending(true);

      const processedMsg = {
        action: WebsocketActionType.confirmEmail,
        inputParams: JSON.stringify({
          playerID,
        }),
      };

      sendMessage(processedMsg);
    }, [playerID, sendMessage]);

    const onMessageReceived = useCallback(
      (event: MessageEvent) => {
        const msg = JSON.parse(event.data);

        if (msg.action === WebsocketActionType.confirmEmail) {
          if (msg.status === WebsocketMessageStatus.success) {
            feedSuccess(t("players.confirmPlayerSuccessMsg"));
          }
          if (msg.status === WebsocketMessageStatus.error) {
            feedError(t("players.confirmPlayerErrorMsg"));
          }
        }

        setIsConfirmSending(false);
      },
      [feedError, feedSuccess, t]
    );

    useEffect(() => {
      if (wasWebsocketOpen) {
        subscribeOnMessage(onMessageReceived);
      }

      return () => {
        unsubscribeOnMessage(onMessageReceived);
      };
    }, [
      onMessageReceived,
      subscribeOnMessage,
      unsubscribeOnMessage,
      wasWebsocketOpen,
    ]);

    const handleCancel = useCallback(() => {
      alert(t("buttonNames.recoveryPassword"));
    }, [t]);

    return (
      <Stack
        direction="row"
        sx={{
          justifyContent: "space-between",
          alignItems: "flex-end",
          pb: 1,
          pt: 2,
          px: 2.5,
          width: "100%",
          backgroundColor: "white",
        }}
      >
        <Stack direction="row">
          <Button variant="outlined" onClick={handleCancel} sx={{ mr: 2 }}>
            {t("buttonNames.recoveryPassword")}
          </Button>
          <SpinnerButton
            type="button"
            buttonName={t("buttonNames.confirmPlayer")}
            loading={isConfirmSending}
            variant="outlined"
            onClick={handleClickConfirmPlayer}
          />
        </Stack>
        {isDeleteBtnActive && (
          <SpinnerButton
            type="button"
            buttonName={t("buttonNames.deletePlayer")}
            loading={isDeletePlayerSending}
            variant="outlined"
            color="error"
            onClick={deletePlayer}
          />
        )}
      </Stack>
    );
  }
);
