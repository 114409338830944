import React, { memo, ReactNode } from "react";

import { Control, Controller } from "react-hook-form";
import { MenuItem, Typography } from "@mui/material";
import { CustomBox, CustomErrorBox, CustomSelect } from "./StyledComponents";
import { Circular } from "../spinners/Circular";

import { CreateQuestFormValues } from "../../container/Quests/types";
import {
  CreateVirtualGoodFormValues,
  SetProbabilityConfigFormValues,
} from "../../container/VirtualGoods/types";
import {
  DropDownDataItem,
  INPUT_COLUMN_WIDTH,
  INPUT_TITLE_WIDTH,
} from "./consts";
import { CreateStoreContentFormValues } from "../../slices/storeContent/types";

type ControlledInputSelectProps = {
  control: Control<any>;
  title?: ReactNode;
  fieldName:
    | keyof CreateStoreContentFormValues
    | keyof CreateQuestFormValues
    | keyof CreateVirtualGoodFormValues
    | keyof SetProbabilityConfigFormValues
    | string;
  isRequired?: boolean;
  isDisabled?: boolean;
  isLoadingDropdownData?: boolean;
  defaultValue?: string;
  dropDownData: DropDownDataItem[];
  inputWidth?: string | number;
  titleWidth?: string | number;
};

const ControlledInputSelect = ({
  control,
  title,
  fieldName,
  isRequired = false,
  isDisabled = false,
  isLoadingDropdownData = false,
  defaultValue = "",
  dropDownData,
  inputWidth = INPUT_COLUMN_WIDTH * 0.75,
  titleWidth = INPUT_TITLE_WIDTH,
}: ControlledInputSelectProps) => {
  return (
    <CustomBox>
      {title && (
        <Typography
          component="div"
          width={titleWidth}
          sx={{ color: isDisabled ? "rgba(0, 0, 0, 0.38)" : "initial" }}
        >
          {title}
        </Typography>
      )}
      <Controller
        control={control}
        name={fieldName}
        defaultValue={defaultValue}
        {...(isRequired && {
          rules: {
            required: "required",
          },
        })}
        render={({ field, fieldState: { invalid, error } }) => {
          return isLoadingDropdownData ? (
            <Circular />
          ) : (
            <>
              <CustomSelect
                {...field}
                sx={{ width: inputWidth }}
                disabled={isDisabled}
                error={invalid}
              >
                <MenuItem key="-" value=""></MenuItem>
                {dropDownData?.map(({ value, label }, id) => (
                  <MenuItem key={id} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </CustomSelect>
              {error && <CustomErrorBox>{error.message}</CustomErrorBox>}
            </>
          );
        }}
      />
    </CustomBox>
  );
};

export default memo(ControlledInputSelect);
