import React, { useCallback, useEffect } from "react";

import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { Button, Paper, styled, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";

import { useTranslation } from "react-i18next";
import { ROWS_PER_PAGE_LIST } from "../../consts";

export type BasicPaginationType = {
  onPageChange: (n: number) => void;
  page: number;
  onPageSizeChange: (n: number) => void;
  pageSize: number;
  rowsPerPageOptions?: number[];
  totalPages?: number;
  responsePageSize?: number;
  responsePage?: number;
  responseHasNextPage?: boolean;
  hasRefreshBtn: boolean;
  refetch?: () => void;
};

export default function BasicPagination({
  onPageSizeChange,
  onPageChange,
  page,
  pageSize,
  rowsPerPageOptions = [...ROWS_PER_PAGE_LIST],
  totalPages,
  responsePageSize,
  responsePage,
  responseHasNextPage,
  hasRefreshBtn,
  refetch,
}: BasicPaginationType) {
  const { t } = useTranslation();

  const handleCurrentPageChange = useCallback(
    (e, page) => {
      onPageChange(page);
    },
    [onPageChange]
  );
  const handleCurrentPageSizeChange = useCallback(
    (e) => {
      onPageSizeChange(+e.target.value);
    },
    [onPageSizeChange]
  );

  useEffect(() => {
    responsePageSize && onPageSizeChange(responsePageSize);
    responsePage && onPageChange(responsePage);
  }, [onPageChange, onPageSizeChange, responsePage, responsePageSize]);

  return (
    <PaperPage elevation={0}>
      {hasRefreshBtn ? (
        <Button variant="outlined" onClick={refetch}>
          {t("buttonNames.refreshList")}
        </Button>
      ) : (
        <div></div>
      )}
      <Box display="flex" alignItems="center">
        <Typography variant="subtitle2" sx={{ pr: 1 }}>
          Rows per page:
        </Typography>

        <FormControl>
          <Select
            style={{ height: "30px", fontSize: "14px", background: "none" }}
            autoWidth={true}
            displayEmpty={true}
            labelId="select-label"
            id="simple-select"
            value={pageSize}
            onChange={handleCurrentPageSizeChange}
          >
            {rowsPerPageOptions.map((el: number, index) => {
              return (
                <MenuItem key={index} value={el}>
                  {el}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <Box display="flex">
          <Stack spacing={4}>
            <Pagination
              hideNextButton={!responseHasNextPage}
              count={totalPages}
              page={page}
              onChange={handleCurrentPageChange}
            />
          </Stack>
        </Box>
      </Box>
    </PaperPage>
  );
}

export const PaperPage = styled(Paper)({
  width: "100%",
  marginTop: 16,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingRight: 16,
});
