import { createSlice } from "@reduxjs/toolkit";
import { AuthSliceState } from "./types";
import { State } from "../../store";
import {
  logOut,
  signInAddNewPassword,
  signInFirstStep,
  signInSecondStep,
} from "./actions";
import { getAccessToken } from "../../utils/api";

export const { reducer } = createSlice({
  name: "auth",
  initialState: {
    QRCodeLink: "",
    authUser: null,
    isNewPasswordRequired: false,
    isFirstStepFinished: false,
    isAuthenticated: getAccessToken().length > 0,
  } as AuthSliceState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(signInFirstStep.fulfilled, (state, { payload }) => {
      if (payload) {
        state.QRCodeLink = payload.QRCodeLink;
        state.authUser = payload.authUser;
        state.isNewPasswordRequired = payload.isNewPasswordRequired;
        state.isFirstStepFinished = payload.isFirstStepFinished;
      }
    });
    builder.addCase(signInAddNewPassword.fulfilled, (state, { payload }) => {
      if (payload) {
        state.authUser = payload.authUser;
        state.QRCodeLink = payload.QRCodeLink;
        state.isNewPasswordRequired = false;
        state.isFirstStepFinished = true;
      }
    });
    builder.addCase(signInSecondStep.fulfilled, (state) => {
      state.isNewPasswordRequired = false;
      state.isFirstStepFinished = false;
      state.isAuthenticated = true;
      state.QRCodeLink = "";
    });
    builder.addCase(logOut.fulfilled, (state) => {
      state.isAuthenticated = false;
    });
  },
});

export const getIsAuthenticated = ({ auth }: State) => auth.isAuthenticated;
export const getQRCodeLink = ({ auth }: State) => auth.QRCodeLink;
export const getIsNewPasswordRequired = ({ auth }: State) =>
  auth.isNewPasswordRequired;
export const getIsFirstStepFinished = ({ auth }: State) =>
  auth.isFirstStepFinished;
