import React, { memo, ReactNode } from "react";

import { Control, Controller, Validate } from "react-hook-form";
import { Typography } from "@mui/material";
import InputChips from "./InputChips";
import { CustomBox, CustomErrorBox } from "./StyledComponents";

import { CreateQuestFormValues } from "../../container/Quests/types";
import { CreateVirtualGoodFormValues } from "../../container/VirtualGoods/types";
import { ListNameType } from "../../slices/lists/types";
import { INPUT_COLUMN_WIDTH, INPUT_TITLE_WIDTH } from "./consts";

type ControlledInputChipsProps = {
  control: Control<any>;
  title: ReactNode;
  fieldName: keyof CreateQuestFormValues | keyof CreateVirtualGoodFormValues;
  isRequired?: boolean;
  isDisabled?: boolean;
  defaultValue?: string[];
  listName?: ListNameType;
  inputWidth?: string | number;
  titleWidth?: string | number;
  validateRule?: Validate<keyof CreateQuestFormValues, any>;
};

const ControlledInputChips = ({
  control,
  title,
  fieldName,
  isRequired = false,
  isDisabled = false,
  defaultValue = [],
  listName,
  inputWidth = INPUT_COLUMN_WIDTH,
  titleWidth = INPUT_TITLE_WIDTH,
  validateRule,
}: ControlledInputChipsProps) => {
  return (
    <CustomBox>
      <Typography
        component="div"
        width={titleWidth}
        sx={{ color: isDisabled ? "rgba(0, 0, 0, 0.38)" : "initial" }}
      >
        {title}
      </Typography>
      <Controller
        control={control}
        name={fieldName}
        defaultValue={defaultValue}
        {...(isRequired && {
          rules: {
            required: "required",
          },
        })}
        {...(validateRule && {
          rules: {
            validate: validateRule,
          },
        })}
        render={({ field, fieldState: { invalid, error } }) => {
          return (
            <>
              <InputChips
                listName={listName}
                value={field?.value as string[]}
                handleChange={field?.onChange}
                error={invalid}
                width={inputWidth}
                isFullWidth={false}
                isDisabled={isDisabled}
              />
              {error && <CustomErrorBox>{error.message}</CustomErrorBox>}
            </>
          );
        }}
      />
    </CustomBox>
  );
};

export default memo(ControlledInputChips);
