import React, { memo } from "react";
import { Box, Button, styled, SxProps, Theme } from "@mui/material";
import { Circular } from "./spinners/Circular";

export type SpinnerButtonProps = {
  loading?: boolean;
  buttonName?: string;
  variant?: "outlined" | "contained" | "text";
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
  size?: "small" | "medium" | "large";
  sx?: SxProps<Theme>;
  type?: "submit" | "button";
  onClick?: () => void;
  disabled?: boolean;
};

export const SpinnerButton = memo<SpinnerButtonProps>(
  ({
    loading,
    buttonName,
    variant = "outlined",
    color = "primary",
    size = "medium",
    sx = {},
    type = "submit",
    onClick,
    disabled = false,
  }) => {
    return (
      <ButtonBox sx={sx}>
        <Button
          variant={variant}
          color={color}
          size={size}
          type={type}
          disabled={loading || disabled}
          onClick={onClick}
        >
          {buttonName}
        </Button>
        {loading && (
          <SpinnerBox>
            <Circular />
          </SpinnerBox>
        )}
      </ButtonBox>
    );
  }
);

const ButtonBox = styled(Box)({
  position: "relative",
  display: "inline-flex",
});

const SpinnerBox = styled(Box)({
  position: "absolute",
  right: -33,
  display: "flex",
  alignItems: "center",
  height: "100%",
});
