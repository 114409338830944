import { TimeRange } from "../types";

export const mergeTimeRanges = (ranges: TimeRange[]): TimeRange[] => {
  if (ranges.length <= 1) {
    return ranges;
  }

  const sortedRanges = ranges.slice().sort((a, b) => a.start - b.start);

  const mergedRanges: TimeRange[] = [sortedRanges[0]];

  for (let i = 1; i < sortedRanges.length; i++) {
    const currentRange = sortedRanges[i];
    const lastMergedRange = mergedRanges[mergedRanges.length - 1];

    if (currentRange.start <= lastMergedRange.end) {
      lastMergedRange.end = Math.max(lastMergedRange.end, currentRange.end);
    } else {
      mergedRanges.push(currentRange);
    }
  }

  return mergedRanges;
};

export const isDayFullyBooked = (
  rawDate: Date | number | string,
  timeRanges: TimeRange[]
): boolean => {
  const date = new Date(rawDate);

  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();

  const startOfDay = new Date(year, month, day).getTime() / 1000;
  const endOfDay = startOfDay + 24 * 60 * 60;

  return timeRanges.some(
    (timeRange) => timeRange.start <= startOfDay && timeRange.end >= endOfDay
  );
};

export const isTimeSlotBooked = (
  hour: number,
  rawDate: Date | number | string,
  timeRanges: TimeRange[]
): boolean => {
  const date = new Date(rawDate);

  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();

  const startOfHour = new Date(year, month, day, hour, 0, 0).getTime() / 1000;
  const endOfHour = startOfHour + 60 * 60;

  return timeRanges.some(
    (timeRange) => timeRange.start <= startOfHour && timeRange.end >= endOfHour
  );
};

export const shiftTimeByHours = (timestamp: number, hours: number): number => {
  return timestamp + hours * 3600;
};

export const truncateDate = (value: number | Date): number =>
  new Date(value).setHours(0, 0, 0, 0);
