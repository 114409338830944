import {
  RequirementItemDataType,
  RewardItemDataType,
} from "../../container/Quests/types";

export const enum ALERT {
  GO_UP = "GO_UP",
  GO_DOWN = "GO_DOWN",
}

export const enum InputType {
  root = "root",
  text = "text",
  date = "date",
  numeric = "numeric",
  checkbox = "checkbox",
  radio = "radio",
  dropdown = "dropdown",
  link = "link",
  membersFields = "membersFields",
  lastActive = "lastActive",
  arrayOfSimpleValues = "arrayOfSimpleValues",
  default = "default",
}

export enum ArrayItemType {
  questRewards = "questRewards",
  questRequirement = "questRequirement",
}

export const addRewardInitialValues: RewardItemDataType = {
  questRewardsTags_1: false,
  questRewardsTagsValue_1: [],
  questRewardsRarity_1: false,
  questRewardsRarityValue_1: [],
  questRewardsCustom_1: false,
  questRewardsCustomValue_1: "",
  questRewardsMin_1: 1,
  questRewardsMax_1: 1,
};

export const addRequirementInitialValues: RequirementItemDataType = {
  questRequirementTags_1: false,
  questRequirementTagsValue_1: [],
  questRequirementRarity_1: false,
  questRequirementRarityValue_1: [],
  questRequirementCustom_1: false,
  questRequirementCustomValue_1: "",
  questRequirementMin_1: 1,
  questRequirementMax_1: 1,
};
