import { useCallback, useState } from "react";
import { capitalize } from "./capitalize";
import { useFeedError } from "./feedHooks";
import { FETCH_ERROR } from "../consts";
import { useTranslation } from "react-i18next";

export type FormErrorState = null | string;

export const useFormError = (initialState: FormErrorState = null) =>
  useState<FormErrorState>(initialState);

export type FieldsErrorState = null | Record<string, string[]>;

export const useFieldsError = (initialState: FieldsErrorState = null) =>
  useState<FieldsErrorState>(initialState);

export const fieldError = (
  field: string,
  formErrors?: FieldsErrorState
): [boolean, string | undefined] => {
  const errors = formErrors && formErrors[field];

  if (!errors) {
    return [false, undefined];
  }

  const errorMessage = errors.join(", ");

  return [
    !!errorMessage,
    errorMessage ? capitalize(errorMessage) : errorMessage,
  ];
};

export const useSubmitFormErrorHandler = (defaultErrorMessage: string) => {
  const { t } = useTranslation();
  const [fieldsErrors, setFieldsErrors] = useFieldsError();
  const [formError, setFormError] = useFormError();
  const feedError = useFeedError();

  const catchFormError = useCallback(
    (error) => {
      if (typeof error === "string") {
        setFormError(error);
      }

      const status = error?.data?.statusCode ?? error?.status;
      const message = error?.data?.message ?? error?.message;

      if (status == FETCH_ERROR) {
        return feedError(t("errors.connection"));
      }

      if (message) {
        return typeof message === "string"
          ? setFormError(message)
          : setFieldsErrors(message);
      }

      setFormError(defaultErrorMessage);
    },
    [defaultErrorMessage, feedError, setFieldsErrors, setFormError, t]
  );

  return { catchFormError, formError, fieldsErrors };
};
