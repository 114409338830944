export const INPUT_WIDTH = 180 as const;
export const COLUMN_WIDTH = INPUT_WIDTH + 20;
export const MENU_ITEM_HEIGHT = 36 as const;

export enum KPINames {
  newPlayersKPI = "newPlayersKPI",
  dailyActivePlayersKPI = "dailyActivePlayersKPI",
  monthlyActivePlayersKPI = "monthlyActivePlayersKPI",
  stepsKPI = "stepsKPI",
  walkDistanceKPI = "walkDistanceKPI",
}

export enum GenderTypes {
  male = "male",
  female = "female",
  noAnswer = "no_answer",
}

export enum DeepLinkTypes {
  aomori = "aomori",
  hokkaido = "hokkaido",
  menolabo = "menolabo",
  landingPage = "landingPage",
}

export enum AgePeriods {
  second = "17-19",
  third = "20-24",
  fourth = "25-29",
  fifth = "30-34",
  sixth = "35-39",
  seventh = "40-44",
  eighth = "45-49",
  ninth = "50-54",
  tenth = "55-59",
  eleventh = "60-64",
  twelfth = "65-69",
  thirteenth = "70-74",
  fourteenth = "75-79",
  fifteenth = "80-84",
  sixteenth = "85-89",
  seventeenth = "90-150",
}

export type FiltersType = {
  kpiName?: `${KPINames}`;
  dateFrom: number | null;
  dateTo: number | null;
  gender: `${GenderTypes}`[];
  age: `${AgePeriods}`[];
  deepLink: `${DeepLinkTypes}`[];
};

export type FilterValuesType =
  | `${GenderTypes}`[]
  | `${AgePeriods}`[]
  | `${DeepLinkTypes}`[];
