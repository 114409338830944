import React from "react";

import { Tooltip } from "@mui/material";
import OpenEditBtn from "../../components/OpenEditBtn";
import { ColumnData } from "../../components/CustomDataGrid/types";
import { QuestsType } from "../../slices/quests/types";
import { CreateQuestFormValues } from "./types";
import { searchFieldsBackType } from "../../components/DataTable/DataTableTypes";
import { ROUTE_PATHS } from "../../consts";

export const questsSearchFieldsBack: searchFieldsBackType[] = [
  {
    key: "questID",
    type: "text",
    label: "Quest ID",
  },
  {
    key: "quests_characters",
    type: "text",
    label: "Characters",
    isCustomSearch: true,
  },
];

export const initialCustomDisabled = {
  tags: false,
  character: false,
  questRarity: false,
};

export const createQuestInitialValues: CreateQuestFormValues = {
  questID: "generic_1",
  questRarity: 1,
  tags: [],
  character: [],
  probability: 100,
  sceneType: "",
  questSequel: false,
  type: "items",
  description: "questRequest",
  start: "greeting_generic",
  quest: "questRequest",
  completed: "questCompleted",
  questAfter: "",
  questRequirementTags_1: false,
  questRequirementTags_2: false,
  questRequirementTags_3: false,
  questRequirementTagsValue_1: [],
  questRequirementTagsValue_2: [],
  questRequirementTagsValue_3: [],
  questRequirementRarity_1: false,
  questRequirementRarity_2: false,
  questRequirementRarity_3: false,
  questRequirementRarityValue_1: [],
  questRequirementRarityValue_2: [],
  questRequirementRarityValue_3: [],
  questRequirementCustom_1: false,
  questRequirementCustom_2: false,
  questRequirementCustom_3: false,
  questRequirementCustomValue_1: "",
  questRequirementCustomValue_2: "",
  questRequirementCustomValue_3: "",
  questRequirementMin_1: 1,
  questRequirementMin_2: 1,
  questRequirementMin_3: 1,
  questRequirementMax_1: 1,
  questRequirementMax_2: 1,
  questRequirementMax_3: 1,
  questRewardsTags_1: false,
  questRewardsTags_2: false,
  questRewardsTags_3: false,
  questRewardsTagsValue_1: [],
  questRewardsTagsValue_2: [],
  questRewardsTagsValue_3: [],
  questRewardsRarity_1: false,
  questRewardsRarity_2: false,
  questRewardsRarity_3: false,
  questRewardsRarityValue_1: [],
  questRewardsRarityValue_2: [],
  questRewardsRarityValue_3: [],
  questRewardsCustom_1: false,
  questRewardsCustom_2: false,
  questRewardsCustom_3: false,
  questRewardsCustomValue_1: "",
  questRewardsCustomValue_2: "",
  questRewardsCustomValue_3: "",
  questRewardsMin_1: 1,
  questRewardsMin_2: 1,
  questRewardsMin_3: 1,
  questRewardsMax_1: 1,
  questRewardsMax_2: 1,
  questRewardsMax_3: 1,
};

export const questTableColumns = (
  handleOpenEditBtnClick: any
): ColumnData<QuestsType>[] => {
  return [
    {
      width: 70,
      label: "N",
      dataKey: "id",
      isDisabled: ({ disabled }) => disabled,
      renderCell: (rowData, rowIndex: number) => rowIndex + 1,
    },
    {
      width: 150,
      label: "Quest ID",
      dataKey: "questID",
      isDisabled: ({ disabled }) => disabled,
      renderCell: ({ questID }) => <span title={questID}>{questID}</span>,
    },
    {
      width: 150,
      label: "Characters",
      dataKey: "characters",
      isDisabled: ({ disabled }) => disabled,
      renderCell: ({ characters, sequelCharacters }) => {
        const allCharacters = [
          ...(sequelCharacters || []),
          ...(characters || []),
        ];
        const allCharactersValue =
          allCharacters.length > 0
            ? allCharacters.reduce(
                (result: string, character: string, idx: number) =>
                  idx === 0 ? character : `${result}, ${character}`,
                ""
              )
            : null;

        return allCharactersValue ? (
          <span title={allCharactersValue}>{allCharactersValue}</span>
        ) : null;
      },
    },
    {
      width: 120,
      label: "Probability",
      dataKey: "probability",
      isDisabled: ({ disabled }) => disabled,
      renderCell: ({ baseProbability }) => baseProbability,
    },
    {
      width: 120,
      label: "Rarity",
      dataKey: "rarity",
      isDisabled: ({ disabled }) => disabled,
      renderCell: ({ questRarity }) => questRarity,
    },
    {
      width: 150,
      label: "Tags",
      dataKey: "tags",
      isDisabled: ({ disabled }) => disabled,
      renderCell: ({ tags }) => {
        const tagsValue =
          tags?.length > 0
            ? tags.reduce(
                (result: string, tag: string, idx: number) =>
                  idx === 0 ? tag : `${result}, ${tag}`,
                ""
              )
            : null;

        return tagsValue ? <span title={tagsValue}>{tagsValue}</span> : null;
      },
    },
    {
      width: 400,
      label: "Requirements",
      dataKey: "requirements",
      isDisabled: ({ disabled }) => disabled,
      renderCell: ({ data }) => {
        const requirements = data._spawnTable_requirements?.items?.reduce(
          (result, item, idx, arr) => {
            let currResult = "";
            if (item.typeID) {
              currResult = `${result}${idx + 1}: ${item.typeID}`;
            }

            if (item._randomItemObject_typeID?.tags) {
              currResult = `${result}${
                idx + 1
              }: [${item._randomItemObject_typeID.tags.join(", ")}]`;
            }

            if (item._randomObject_typeID) {
              currResult = `${result}${
                idx + 1
              }: ${item._randomObject_typeID.join(", ")}`;
            }

            if (item._randomItemTagObject_typeID) {
              currResult = `${result}${
                idx + 1
              }: [${item._randomItemTagObject_typeID.join(", ")}]`;
            }

            return idx === arr.length - 1 ? currResult : `${currResult}, `;
          },
          ""
        );

        return requirements.length > 0 ? (
          <span title={requirements}>{requirements}</span>
        ) : null;
      },
    },
    {
      width: 250,
      label: "Rewards",
      dataKey: "rewards",
      isDisabled: ({ disabled }) => disabled,
      renderCell: ({ data }) => {
        const isContainHearts = data.rewards.some(
          (item) => item.typeID === "_hearts"
        );

        const rewards = data.rewards?.reduce((result, item, idx, arr) => {
          if (item.typeID === "_hearts") {
            return result;
          }

          let currResult = "";
          if (item.typeID) {
            currResult = `${result}${idx}: ${item.typeID}`;
          }

          if (item._randomItemObject_typeID?.tags) {
            currResult = `${result}${idx}: [${item._randomItemObject_typeID.tags.join(
              ", "
            )}]`;
          }

          if (item._randomObject_typeID) {
            currResult = `${result}${idx}: ${item._randomObject_typeID.join(
              ", "
            )}`;
          }

          if (item._randomItemTagObject_typeID) {
            currResult = `${result}${idx}: [${item._randomItemTagObject_typeID.join(
              ", "
            )}]`;
          }

          return idx === arr.length - 1 ? currResult : `${currResult}, `;
        }, "");

        if (rewards.length > 0) {
          return isContainHearts ? (
            <span title={rewards}>{rewards}</span>
          ) : (
            <Tooltip
              title="The reward 'hearts' is missing on this quest"
              placement="top"
              arrow
            >
              <span style={{ color: "#d32f2f" }}>{rewards}</span>
            </Tooltip>
          );
        } else {
          return null;
        }
      },
    },
    {
      width: 120,
      label: "Action",
      dataKey: "action",
      isDisabled: ({ disabled }) => disabled,
      renderCell: ({ _id }) => (
        <OpenEditBtn
          id={_id}
          routePath={ROUTE_PATHS.QUESTS}
          handleOpenEditBtnClick={handleOpenEditBtnClick}
        />
      ),
    },
  ];
};
