import React, { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFeedError, useFeedSuccess } from "../../utils/feedHooks";
import {
  CreateStoreContentFormValues,
  StoreContentEntry,
  StoreContentTabsValue,
  storeContentTabsValues,
  StoreContentTimestampRecords,
  StoreItem,
} from "../../slices/storeContent/types";
import { ROUTE_PATHS } from "../../consts";
import { useTableUrlTools } from "../../utils/useTableUrlTools";
import Form from "./Form";
import {
  WebsocketActionType,
  WebsocketMessageStatus,
} from "../../slices/Websocket/types";
import useWebsocket from "../../utils/useWebsocket";
import { useSelector } from "../../store";
import { getWasWebsocketOpen } from "../../slices/Websocket/slice";
import { v4 as uuid } from "uuid";
import { useSearchParams } from "react-router-dom";
import _ from "lodash";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import weekday from "dayjs/plugin/weekday";
import dayOfYear from "dayjs/plugin/dayOfYear";
import updateLocale from "dayjs/plugin/updateLocale";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(weekday);
dayjs.extend(dayOfYear);

dayjs.extend(updateLocale);
dayjs.updateLocale(dayjs.locale(), {
  weekStart: 1,
});

type CreateStoreContentProps = {
  timestampsToDisable: StoreContentTimestampRecords;
  refetchList?: () => void;
  allStoreContent: Partial<StoreContentEntry>[];
  lootboxData: any;
};

const CreateStoreContent = ({
  timestampsToDisable,
  refetchList,
  allStoreContent,
  lootboxData,
}: CreateStoreContentProps) => {
  const [searchParams] = useSearchParams();
  const currentSectionIndex = Number(searchParams.get("index"));

  const defaultValues = {
    tab: storeContentTabsValues.includes(
      searchParams.get("tab") as StoreContentTabsValue
    )
      ? (searchParams.get("tab") as StoreContentTabsValue)
      : "offers",
    start: Number(searchParams.get("start")) || null,
    end: Number(searchParams.get("end")) || null,
    storeItems: [],
  };

  const { sendMessage, subscribeOnMessage, unsubscribeOnMessage } =
    useWebsocket();

  const wasWebsocketOpen = useSelector(getWasWebsocketOpen);

  const [isLoading, setIsLoading] = useState(false);
  const [websocketId, setWebsocketId] = useState<string | null>(null);

  const { t } = useTranslation();
  const feedSuccess = useFeedSuccess();
  const feedError = useFeedError();

  const mainRoute = ROUTE_PATHS.STORE_CONTENT;

  const { closeDrawer } = useTableUrlTools(mainRoute);

  const createStoreContent = async (rawData: CreateStoreContentFormValues) => {
    const randomId = uuid();
    const data = [
      ...allStoreContent,
      {
        ...rawData,
        start: dayjs(rawData.start).startOf("hour").utc().unix(),
        end: dayjs(rawData.end).startOf("hour").utc().unix(),
      },
    ]
      .filter((el) => el.tab === searchParams.get("tab"))
      .sort((a, b) => a.start - b.start)
      .reduce((prev, cur, index, arr) => {
        if (index === currentSectionIndex) {
          const isNextContentExistsAndNoGapWithCurrent =
            arr[index + 1]?.start === cur.end;
          const isNextContentExistsAndGapBetweenCurrentAndCurrentEndAfterNextStart =
            arr[index + 1]?.start !== cur.end &&
            arr[index + 1]?.start < cur.end;
          const isPrevContentExistsAndNoGapWithCurrent =
            arr[index - 1]?.end === cur.start;
          const isPrevContentExistsAndGapBetweenCurrentAndCurrentStartBeforePrevEnd =
            arr[index - 1]?.end !== cur.start &&
            arr[index - 1]?.end > cur.start;
          if (isNextContentExistsAndNoGapWithCurrent) {
            prev.push({
              ...arr[index + 1],
              start: cur.end,
            });
          }
          if (
            isNextContentExistsAndGapBetweenCurrentAndCurrentEndAfterNextStart
          ) {
            prev.push({
              ...arr[index + 1],
              start: cur.end,
            });
          }
          if (isPrevContentExistsAndNoGapWithCurrent) {
            prev.push({
              ...arr[index - 1],
              end: cur.start,
            });
          }
          if (
            isPrevContentExistsAndGapBetweenCurrentAndCurrentStartBeforePrevEnd
          ) {
            prev.push({
              ...arr[index - 1],
              end: cur.start,
            });
          }
          prev.push({
            ...rawData,
            start: cur.start,
            end: cur.end,
            storeItems: rawData.storeItems.map((el, index) => {
              return {
                ...el,
                discountPercentage: el.discountPercentageOverridden
                  ? el.discountPercentage
                  : null,
                price: el.priceOverridden ? el.price : null,
                buyAmountLimit: el.buyAmountLimitOverridden
                  ? el.buyAmountLimit
                  : null,
              };
            }),
          });
        }
        return prev;
      }, []);

    const processedMsg = {
      action: WebsocketActionType.createUpdateStoreTabConfiguration,
      inputParams: JSON.stringify(data),
      id: randomId,
    };
    setWebsocketId(randomId);
    sendMessage(processedMsg);
    setIsLoading(true);
  };

  const onMessageReceived = useCallback(
    (event: MessageEvent) => {
      const msg = JSON.parse(event.data);
      if (
        msg.action === WebsocketActionType.createUpdateStoreTabConfiguration &&
        msg.id === websocketId
      ) {
        if (msg.status === WebsocketMessageStatus.success) {
          feedSuccess(t("storeContentCreate.successMsgAfterSubmit"));
          closeDrawer();
          setIsLoading(false);
          refetchList && refetchList();
        }
        if (msg.status === WebsocketMessageStatus.error) {
          setIsLoading(false);
        }
      }
    },
    [closeDrawer, websocketId, feedSuccess, refetchList, t]
  );

  useEffect(() => {
    if (wasWebsocketOpen && isLoading) {
      subscribeOnMessage(onMessageReceived);
    }

    return () => {
      unsubscribeOnMessage(onMessageReceived);
    };
  }, [
    isLoading,
    onMessageReceived,
    subscribeOnMessage,
    unsubscribeOnMessage,
    wasWebsocketOpen,
  ]);

  return (
    <Form
      onSubmit={createStoreContent}
      timestampsToDisable={timestampsToDisable}
      defaultValues={defaultValues}
      lootboxData={lootboxData}
    />
  );
};

export default memo(CreateStoreContent);
