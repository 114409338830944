import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Typography,
} from "@mui/material";
import { Circular } from "../spinners/Circular";
import { ExpandMore } from "@mui/icons-material";

type FieldsArrayAccordionProps = {
  id: string;
  label: string;
  addLabel: string;
  addClickHandler: () => void;
  children: React.ReactNode;
  loading?: boolean;
  error?: boolean;
};

export const FieldsArrayAccordion = ({
  id,
  label,
  addLabel,
  addClickHandler,
  children,
  loading,
  error,
}: FieldsArrayAccordionProps) => {
  return (
    <Accordion defaultExpanded={true}>
      <AccordionSummary
        expandIcon={
          loading ? <Circular size={25} thickness={175} /> : <ExpandMore />
        }
        aria-controls={id}
        id={id}
      >
        <Typography variant="button" color={error ? "error" : "text.secondary"}>
          {label}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {children}
        <Box>
          <Button onClick={addClickHandler}>{addLabel}</Button>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
