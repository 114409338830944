import React from "react";
import { Container } from "@mui/material";
import { SignInFirstStep } from "./SignInFirstStep";
import { SignInSecondStep } from "./SignInSecondStep";
import { useSelector } from "../../store";
import { getIsFirstStepFinished } from "../../slices/auth/slice";

const SignIn = () => {
  const isFirstStepFinished = useSelector(getIsFirstStepFinished);

  return (
    <Container maxWidth="xs">
      {!isFirstStepFinished ? <SignInFirstStep /> : <SignInSecondStep />}
    </Container>
  );
};

export default SignIn;
