import { eventObjectType, FormValues } from "./types";

export const CreateObject = (data: FormValues) => {
  const col1 = data.col1;
  const rarity1 = parseInt(data.rarity1);
  let rarity2 = null;
  if (data.rarity2 !== "-") {
    rarity2 = parseInt(data.rarity2);
  }
  let rarity3 = null;
  if (data.rarity3 !== "-") {
    rarity3 = parseInt(data.rarity3);
  }
  const typeAmount = parseInt(data.typeAmount1);

  const rew1 = data.reward1;
  const rew1_amount = parseInt(data.reward1_amount);
  const rew1_required = parseInt(data.reward1_required);

  const rew_1 = data.reward_1;
  const rew_1_amount = parseInt(data.reward_1_amount);

  const rew_2 = data.reward_2;
  const rew_2_amount = parseInt(data.reward_2_amount);

  const rew_3 = data.reward_3;
  const rew_3_amount = parseInt(data.reward_3_amount);

  const rew_gold = data.reward_gold;
  const rew_gold_amount = parseInt(data.reward_gold_amount);
  const rew_gold_required = parseFloat(data.reward_gold_percent);

  const rew_silver = data.reward_silver;
  const rew_silver_amount = parseInt(data.reward_silver_amount);
  const rew_silver_required = parseInt(data.reward_silver_percent);

  const rew_bronze = data.reward_bronze;
  const rew_bronze_amount = parseInt(data.reward_bronze_amount);
  const rew_bronze_required = parseInt(data.reward_bronze_percent);

  const eventObject: eventObjectType = {
    eventID: data.eventID,
    eventName: data.eventName,
    eventDesc: data.eventDesc,
    character: data.character,
    eventPic: data.eventPic,
    eventSeason: data.eventSeason,
    eventType: data.eventType,
    startTime: null,
    dueTime: null,
    rewards: [
      {
        required: rew1_required,
        amount: rew1_amount,
        type: rew1,
      },
    ],
    leaderboardRewards: [
      {
        requiredPosition: 1,
        type: rew_1,
        amount: rew_1_amount,
      },
      {
        requiredPosition: 2,
        type: rew_2,
        amount: rew_2_amount,
      },
      {
        requiredPosition: 3,
        type: rew_3,
        amount: rew_3_amount,
      },
      {
        required: rew_gold_required,
        type: rew_gold,
        amount: rew_gold_amount,
      },
      {
        required: rew_silver_required,
        type: rew_silver,
        amount: rew_silver_amount,
      },
      {
        required: rew_bronze_required,
        type: rew_bronze,
        amount: rew_bronze_amount,
      },
    ],
  };

  if (data.eventType == "items") {
    eventObject.collectibles = [];
    eventObject.tag = col1;
    eventObject.typeAmount = typeAmount;
    eventObject.rarity = [];
    const directCollectibles = data.directCollectibles;

    if (directCollectibles == true) {
      const genCols = [];
      if (data.item1 != "-") {
        genCols.push(data.item1);
      }
      if (data.item2 != "-") {
        genCols.push(data.item2);
      }
      if (data.item3 != "-") {
        genCols.push(data.item3);
      }
      if (data.item4 != "-") {
        genCols.push(data.item4);
      }
      if (data.item5 != "-") {
        genCols.push(data.item5);
      }
      eventObject.collectibleGen = genCols;
    } else {
      eventObject.rarity.push(rarity1);
      if (rarity2 !== null) {
        eventObject.rarity.push(rarity2);
      }
      if (rarity3 !== null) {
        eventObject.rarity.push(rarity3);
      }
    }
  }

  return eventObject;
};
