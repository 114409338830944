import React, { memo } from "react";

import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ArrayItemType } from "../consts";

type ItemToDeleteProps = {
  count: number | string;
  data: any;
  type: `${ArrayItemType}`;
};

const ItemToDelete = ({ count, data, type }: ItemToDeleteProps) => {
  const { t } = useTranslation();

  const isReward = type === ArrayItemType.questRewards;
  const title = isReward
    ? t("createQuest.reward")
    : t("createQuest.requirement");
  const customData = data?.typeID;

  const rarities = customData ? null : data?._randomItemObject_typeID?.rarities;
  const tags = customData ? null : data?._randomItemObject_typeID?.tags;
  const min = data?._randomIntAmount_amount?.min;
  const max = data?._randomIntAmount_amount?.max;
  const amount = data?.amount;
  return (
    <>
      <Typography variant="subtitle1">{t("quests.deleteMsgTitle")}:</Typography>
      <Box color="warning.main" ml={2} mt={1}>
        <Typography variant="button">{`${title} ${count}`}</Typography>
        {customData ? (
          <Typography variant="subtitle1">{`${t(
            "createQuest.custom"
          )}: ${customData}`}</Typography>
        ) : (
          <>
            {tags?.length > 0 && (
              <Typography variant="subtitle1">{`${t(
                "createQuest.tags"
              )}: ${tags.join(", ")}`}</Typography>
            )}
            {rarities?.length > 0 && (
              <Typography variant="subtitle1">{`${t(
                "createQuest.rarity"
              )}: ${rarities.join(", ")}`}</Typography>
            )}
          </>
        )}
        {min && (
          <Typography variant="subtitle1">{`${t(
            "createQuest.min"
          )}: ${min}`}</Typography>
        )}
        {max && (
          <Typography variant="subtitle1">{`${t(
            "createQuest.max"
          )}: ${max}`}</Typography>
        )}
        {amount && (
          <Typography variant="subtitle1">{`${t(
            "createQuest.amount"
          )}: ${amount}`}</Typography>
        )}
      </Box>
    </>
  );
};

export default memo(ItemToDelete);
