import { useNavigate, useMatch } from "react-router-dom";
import { useCallback } from "react";

export const useTableUrlTools = (baseUrl: string) => {
  const navigate = useNavigate();
  const addDrawerMatcher = useMatch(`${baseUrl}/add`);
  const setDrawerMatcher = useMatch(`${baseUrl}/set`);
  const editDrawerMatcher = useMatch(`${baseUrl}/:id/edit`);
  const detailsDrawerMatcher = useMatch(`${baseUrl}/:id`);
  const changePasswordDrawerMatcher = useMatch(`${baseUrl}/:id/changepassword`);
  const idMatcher = useMatch(`${baseUrl}/:id/*`); //*****

  const closeDrawer = useCallback(
    (afterCloseToUrl = baseUrl) => {
      navigate(afterCloseToUrl);
    },
    [baseUrl, navigate]
  );

  return {
    addDrawerOpen: !!addDrawerMatcher,
    setDrawerOpen: !!setDrawerMatcher,
    editDrawerOpen: !!editDrawerMatcher,
    detailsDrawerOpen:
      !!detailsDrawerMatcher && !addDrawerMatcher && !setDrawerMatcher,
    id: idMatcher?.params?.id || null,
    changePasswordDrawerOpen: !!changePasswordDrawerMatcher,
    closeDrawer,
  };
};
