import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { getUrl, prepareHeaders } from "../../utils/api";
import { DistributionSystemResponse } from "./types";
import { COLLECTION_NAMES, DB_NAMES, ROUTE_PATHS } from "../../consts";
import { PaginateResponse, SearchPaginateRequestBody } from "../../types";

export const distributionSystemApi = createApi({
  reducerPath: "distributionSystemApi",
  baseQuery: fetchBaseQuery({
    baseUrl: getUrl("/"),
    prepareHeaders,
  }),
  tagTypes: ["distributionSystem"],
  endpoints: (builder) => ({
    getDistributions: builder.query<
      PaginateResponse<DistributionSystemResponse>,
      Omit<SearchPaginateRequestBody, "dbName" | "collectionName">
    >({
      query: (body) => {
        return {
          url: `${ROUTE_PATHS.SEARCH}`,
          method: "POST",
          body: {
            ...body,
            dbName: DB_NAMES.SYSTEM,
            collectionName: COLLECTION_NAMES.DISTRIBUTION_SYSTEM,
          },
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.docs.map(({ _id: id }) => ({
                type: "distributionSystem" as const,
                id,
              })),
              { type: "distributionSystem", id: "LIST" },
            ]
          : [{ type: "distributionSystem", id: "LIST" }],
    }),
  }),
});

export const { useGetDistributionsQuery } = distributionSystemApi;
