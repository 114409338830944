import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { State } from "../../store";

interface PlayersDataState {
  currentFeedbackID: string | null;
  currentPlayerID: string | null;
  urlAfterCloseDrawer: string;
}

const initialState = {
  currentFeedbackID: null,
  currentPlayerID: null,
  urlAfterCloseDrawer: "",
} as PlayersDataState;

const playersSlice = createSlice({
  name: "playersData",
  initialState,
  reducers: {
    setCurrentFeedbackID(
      state,
      action: PayloadAction<PlayersDataState["currentFeedbackID"]>
    ) {
      state.currentFeedbackID = action.payload;
    },
    setCurrentPlayerID(
      state,
      action: PayloadAction<PlayersDataState["currentPlayerID"]>
    ) {
      state.currentPlayerID = action.payload;
    },
    setUrlOnCloseDrawer(
      state,
      action: PayloadAction<PlayersDataState["urlAfterCloseDrawer"]>
    ) {
      state.urlAfterCloseDrawer = action.payload;
    },
  },
});

export const getCurrentFeedbackID = ({ playersData }: State) =>
  playersData.currentFeedbackID;
export const getCurrentPlayerID = ({ playersData }: State) =>
  playersData.currentPlayerID;
export const getUrlAfterCloseDrawer = ({ playersData }: State) =>
  playersData.urlAfterCloseDrawer;

export const { setCurrentFeedbackID, setCurrentPlayerID, setUrlOnCloseDrawer } =
  playersSlice.actions;

export default playersSlice.reducer;
