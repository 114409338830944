import React, { memo, useMemo } from "react";
import { Link, useMatch } from "react-router-dom";
import { ROUTE_PATHS } from "../../../consts";

type ItemProps = {
  to: string;
  icon: typeof SvgIcon;
  label: string;
  unreadChatsCount?: number;
  nodeId: string;
  isHidden?: boolean;
};

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import TreeItem, { TreeItemProps, treeItemClasses } from "@mui/lab/TreeItem";
import Typography from "@mui/material/Typography";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

declare module "react" {
  interface CSSProperties {
    "--tree-view-color"?: string;
    "--tree-view-bg-color"?: string;
  }
}

type StyledTreeItemProps = TreeItemProps & {
  bgColor?: string;
  color?: string;
  labelIcon: React.ElementType<SvgIconProps>;
  labelInfo?: string;
  labelText: string;
};

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  [`& .${treeItemClasses.content}`]: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    "&.Mui-expanded": {
      fontWeight: theme.typography.fontWeightBold,
    },
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
    "&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
      color: "var(--tree-view-color)",
    },
    [`& .${treeItemClasses.label}`]: {
      fontWeight: "inherit",
      color: "inherit",
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 0,
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: theme.spacing(2),
    },
  },
}));

const StyledTreeItem = ({
  bgColor,
  color,
  labelIcon: LabelIcon,
  labelInfo,
  labelText,
  ...other
}: StyledTreeItemProps) => (
  <StyledTreeItemRoot
    label={
      <Box sx={{ display: "flex", alignItems: "center", p: 0.8, pr: 3 }}>
        <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
        <Typography variant="body2" sx={{ fontWeight: "inherit", flexGrow: 1 }}>
          {labelText}
        </Typography>
        <Typography variant="caption" color="blue">
          {labelInfo}
        </Typography>
      </Box>
    }
    style={{
      "--tree-view-color": color,
      "--tree-view-bg-color": bgColor,
    }}
    {...other}
  />
);

const CustomTreeItem = ({
  label,
  to,
  icon,
  unreadChatsCount = 0,
  nodeId,
  isHidden: hideRout,
}: ItemProps) => {
  const match = useMatch("/:path");

  const UsualItem = useMemo(
    () => (
      <Link to={to} style={{ textDecoration: "none" }}>
        <StyledTreeItem nodeId={nodeId} labelText={label} labelIcon={icon} />
      </Link>
    ),
    [icon, label, nodeId, to]
  );

  const ItemWithChip = useMemo(
    () => (
      <Link to={to} style={{ textDecoration: "none" }}>
        <StyledTreeItem
          nodeId={nodeId}
          labelText={label}
          labelIcon={icon}
          labelInfo={`${unreadChatsCount}`}
        />
      </Link>
    ),
    [icon, label, nodeId, to, unreadChatsCount]
  );

  if (!hideRout) {
    return null;
  }
  return to === ROUTE_PATHS.SUPPORT &&
    match?.pathname !== ROUTE_PATHS.SUPPORT &&
    unreadChatsCount
    ? ItemWithChip
    : UsualItem;
};

export default memo(CustomTreeItem);
