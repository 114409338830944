import * as React from "react";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";

type ChipData = {
  key: number;
  label: string;
};

type EventChipProps = {
  result: ChipData[];
  deleteItem: (key: number) => void;
};

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

export const EventChip = ({ result, deleteItem }: EventChipProps) => {
  const handleDelete = (chipToDelete: ChipData) => () => {
    deleteItem(chipToDelete.key);
  };

  return (
    <Paper
      elevation={0}
      sx={{
        display: "flex",
        justifyContent: "left",
        flexWrap: "wrap",
        listStyle: "none",
        p: 0.5,
        m: 0,
      }}
      component="ul"
    >
      {result.map((data: ChipData) => {
        let icon;
        return (
          <ListItem key={data.key}>
            <Chip
              icon={icon}
              label={data.label}
              onDelete={handleDelete(data)}
            />
          </ListItem>
        );
      })}
    </Paper>
  );
};
