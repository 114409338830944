import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { getUrl, prepareHeaders } from "../../utils/api";
import { ROUTE_PATHS } from "../../consts";

const { SEARCH, UPDATE, FEEDBACK } = ROUTE_PATHS;

import { GroupManagementType, SystemMessagesType } from "./types";

import {
  SearchPaginateRequestBody,
  PaginateResponse,
  EditRequestBody,
} from "../../types";

export const groupManagementApi = createApi({
  reducerPath: "groupManager",
  baseQuery: fetchBaseQuery({
    baseUrl: getUrl("/"),
    prepareHeaders,
  }),
  tagTypes: ["Group"],
  endpoints: (builder) => ({
    getGroupManagement: builder.query<
      PaginateResponse<GroupManagementType>,
      SearchPaginateRequestBody
    >({
      query: (body) => {
        return {
          url: `${SEARCH}`,
          method: "POST",
          body,
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.docs.map(({ _id: id }) => ({
                type: "Group" as const,
                id,
              })),
              { type: "Group", id: "LIST" },
            ]
          : [{ type: "Group", id: "LIST" }],
    }),
    editGroup: builder.mutation<GroupManagementType, EditRequestBody>({
      query: (body) => ({ url: `${SEARCH}${UPDATE}`, method: "POST", body }),
      invalidatesTags: [{ type: "Group", id: "LIST" }],
    }),
    getChatHistoryData: builder.query<
      PaginateResponse<SystemMessagesType>,
      SearchPaginateRequestBody
    >({
      query: (body) => {
        return {
          url: `${FEEDBACK}/chatHistory`,
          method: "POST",
          body: {
            page: body.page,
            limit: body.limit,
            search: body.search,
          },
        };
      },
    }),
  }),
});

export const {
  useGetGroupManagementQuery,
  useEditGroupMutation,
  useGetChatHistoryDataQuery,
} = groupManagementApi;
