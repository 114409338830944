import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { getUrl, prepareHeaders } from "../../utils/api";
import { QuestsType } from "./types";
import {
  SearchPaginateRequestBody,
  PaginateResponse,
  EditRequestBody,
  DeleteRequestBody,
} from "../../types";
import { CreateQuestFormValues } from "../../container/Quests/types";
import {
  COLLECTION_NAMES,
  CREATE_ITEM_TYPE,
  DB_NAMES,
  ROUTE_PATHS,
} from "../../consts";

const { SEARCH, UPDATE, CREATE, DELETE } = ROUTE_PATHS;

export const questsApi = createApi({
  reducerPath: "questsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: getUrl("/"),
    prepareHeaders,
  }),
  tagTypes: ["Quests"],
  endpoints: (builder) => ({
    getQuests: builder.query<
      PaginateResponse<QuestsType>,
      SearchPaginateRequestBody
    >({
      query: (body) => {
        return { url: `${SEARCH}`, method: "POST", body };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.docs.map(({ _id: id }) => ({
                type: "Quests" as const,
                id,
              })),
              { type: "Quests", id: "LIST" },
            ]
          : [{ type: "Quests", id: "LIST" }],
    }),

    editQuest: builder.mutation<QuestsType, EditRequestBody>({
      query: (body) => ({ url: `${SEARCH}${UPDATE}`, method: "POST", body }),
      invalidatesTags: [{ type: "Quests", id: "LIST" }],
    }),
    deleteQuest: builder.mutation<boolean, DeleteRequestBody>({
      query: (body) => ({ url: `${SEARCH}${DELETE}`, method: "DELETE", body }),
      invalidatesTags: [{ type: "Quests", id: "LIST" }],
    }),

    createQuest: builder.mutation<CreateQuestFormValues, CreateQuestFormValues>(
      {
        query: (body) => ({
          url: `${SEARCH}${CREATE}`,
          method: "POST",
          body: {
            data: { ...body, __type: CREATE_ITEM_TYPE.quest },
            dbName: DB_NAMES.META,
            collectionName: COLLECTION_NAMES.QUESTS_DATA,
          },
        }),
      }
    ),
  }),
});

export const {
  useGetQuestsQuery,
  useEditQuestMutation,
  useDeleteQuestMutation,
  useCreateQuestMutation,
} = questsApi;
