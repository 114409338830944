import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { getUrl, prepareHeaders } from "../../utils/api";

export const websocketApi = createApi({
  reducerPath: "websocket",
  baseQuery: fetchBaseQuery({
    baseUrl: getUrl("/"),
    prepareHeaders,
  }),
  tagTypes: ["websocket"],
  endpoints: (builder) => ({
    getWebsocketToken: builder.mutation<string, void>({
      query: () => {
        return {
          url: "socket",
          method: "POST",
        };
      },
    }),
  }),
});

export const { useGetWebsocketTokenMutation } = websocketApi;
