import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Hub } from "aws-amplify";

import CommonLayout from "./components/CommonLayout/CommonLayout";
import SignIn from "./container/SignIn/SignIn";
import Players from "./container/Players/Players";
import Events from "./container/Events/Events";
import VirtualGoods from "./container/VirtualGoods/VirtualGoods";
import GroupManagement from "./container/GroupManagement/GroupManagement";
import DistributionSystem from "./container/DistributionSystem/DistributionSystem";
import Deployment from "./container/Deployment/Deployment";
import Maintenance from "./container/Maintenance/Maintenance";
import Support from "./container/Support/Support";
import ServiceStatus from "./container/ServiceStatus/ServiceStatus";
import MapContent from "./container/MapContent/MapContent";
import WebsocketConnector from "./components/WebsocketConnector";
import DailyActivePlayersKPIChart from "./container/Analytics/DailyActivePlayersKPIChart";
import MonthlyActivePlayersKPIChart from "./container/Analytics/MonthlyActivePlayersKPIChart";
import StepsKPIChart from "./container/Analytics/StepsKPIChart";
import WalkDistanceKPIChart from "./container/Analytics/WalkDistanceKPIChart";
import Quests from "./container/Quests/Quests";
import StoreContent from "./container/StoreContent/StoreContent";

import { useSelector } from "./store";
import { getIsAuthenticated } from "./slices/auth/slice";
import { getCurrentRoles } from "./slices/Roles/slice";
import { sendTokensToIframe } from "./utils/api";
import { useRefreshCurrentSession } from "./slices/auth/actions";

import { ROLE, ROUTE_PATHS } from "./consts";
import { Roles } from "./types";
import DeletePlayers from "./container/DeletePlayers/DeletePlayers";

const {
  PLAYERS,
  EVENT_CREATOR,
  V_GOODS,
  QUESTS,
  STORE_CONTENT,
  DEPLOYMENT,
  MAINTENANCE,
  SIGN_IN,
  SUPPORT,
  GROUP_MANAGEMENT,
  DISTRIBUTION_SYSTEM,
  DELETE_PLAYERS,
  MAP_CONTENT,
  SERVICE_STATUS,
  NEW_PLAYERS_KPI,
  DAILY_ACTIVE_PLAYERS_KPI,
  MONTHLY_ACTIVE_PLAYERS_KPI,
  STEPS_KPI,
  WALK_DISTANCE_KPI,
} = ROUTE_PATHS;
const { Admin, PlayerSupport,PlayerSupportAdvanced, GameContentDeveloper, ViewAnalyticsData } = ROLE;

const Navigator = () => {
  const isAuthenticated = useSelector(getIsAuthenticated);
  const currentRoles = useSelector(getCurrentRoles);

  useRefreshCurrentSession();

  const checkPermittedRoles = (permittedRoles: Roles) => {
    return permittedRoles.some((role) => currentRoles.includes(role));
  };

  window.onmessage = (event) => {
    if (event.origin === process.env.REACT_APP_IFRAME_URL) {
      try {
        const result = JSON.parse(event.data);

        if (result.type === "GET_TOKEN") {
          sendTokensToIframe(currentRoles);
        }
      } catch (err) {
        console.log("error", err);
      }
    }
  };

  Hub.listen("auth", (data) => {
    if (data.payload.event === "tokenRefresh") {
      sendTokensToIframe(currentRoles);
    }
  });

  const routes =
    isAuthenticated && currentRoles.length > 0 ? (
      <>
        <Routes>
          <Route path="/" element={<CommonLayout />}>
            {checkPermittedRoles([Admin, PlayerSupport,PlayerSupportAdvanced]) && (
              <>
                <Route path={`${PLAYERS}/*`} element={<Players />} />
                <Route path={`${SUPPORT}/*`} element={<Support />} />
                <Route
                  path={`${GROUP_MANAGEMENT}/*`}
                  element={<GroupManagement />}
                />
              </>
            )}
            {checkPermittedRoles([Admin, PlayerSupportAdvanced]) && (
              <>
                <Route
                  path={`${DISTRIBUTION_SYSTEM}/*`}
                  element={<DistributionSystem />}
                />
              </>
            )}

            {checkPermittedRoles([Admin, GameContentDeveloper]) && (
              <>
                <Route path={`${EVENT_CREATOR}/*`} element={<Events />} />
                <Route path={`${V_GOODS}/*`} element={<VirtualGoods />} />
                <Route path={`${MAP_CONTENT}/*`} element={<MapContent />} />
                <Route path={`${QUESTS}/*`} element={<Quests />} />
                <Route path={`${STORE_CONTENT}/*`} element={<StoreContent />} />
              </>
            )}

            {checkPermittedRoles([Admin, ViewAnalyticsData]) && (
              <>
                {/* <Route
                  path={`${NEW_PLAYERS_KPI}/*`}
                  element={<NewPlayersKPIChart />}
                /> */}
                <Route
                  path={`${DAILY_ACTIVE_PLAYERS_KPI}/*`}
                  element={<DailyActivePlayersKPIChart />}
                />
                <Route
                  path={`${MONTHLY_ACTIVE_PLAYERS_KPI}/*`}
                  element={<MonthlyActivePlayersKPIChart />}
                />
                <Route path={`${STEPS_KPI}/*`} element={<StepsKPIChart />} />
                <Route
                  path={`${WALK_DISTANCE_KPI}/*`}
                  element={<WalkDistanceKPIChart />}
                />
              </>
            )}

            {checkPermittedRoles([Admin]) && (
              <>
                <Route path={`${DEPLOYMENT}/*`} element={<Deployment />} />
                <Route path={`${MAINTENANCE}/*`} element={<Maintenance />} />
                
                <Route
                  path={`${DELETE_PLAYERS}/*`}
                  element={<DeletePlayers />}
                />
                <Route
                  path={`${SERVICE_STATUS}/*`}
                  element={<ServiceStatus />}
                />
              </>
            )}

            <Route path="*" element={<h1>Page not found</h1>} />
          </Route>
        </Routes>
        <WebsocketConnector />
      </>
    ) : (
      <Routes>
        <Route path="/" element={<CommonLayout />}>
          <Route index element={<SignIn />} />
          <Route path={SIGN_IN} element={<SignIn />} />
          <Route path="*" element={<SignIn />} />
        </Route>
      </Routes>
    );

  return <BrowserRouter>{routes}</BrowserRouter>;
};

export default Navigator;
