import React, { Dispatch, memo, SetStateAction, useCallback } from "react";

import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import AddReward from "./AddReward";

import { useTranslation } from "react-i18next";
import { ArrayItemType } from "../consts";

type AddArrayItemAlertProps = {
  isShown: boolean;
  setIsShown: Dispatch<SetStateAction<boolean>>;
  arrayItemType?: `${ArrayItemType}`;
  pathToArray: string[];
  arrayCount: 1 | 2 | 3;
  id?: string;
};

export const AddArrayItemAlert = memo(
  ({
    isShown,
    setIsShown,
    arrayItemType,
    pathToArray,
    arrayCount,
    id,
  }: AddArrayItemAlertProps) => {
    const { t } = useTranslation();

    let body = null;
    let title = null;

    switch (arrayItemType) {
      case ArrayItemType.questRewards:
        body = (
          <AddReward
            itemType={ArrayItemType.questRewards}
            arrayCount={arrayCount}
            setIsShown={setIsShown}
            pathToArray={pathToArray}
            id={id}
          />
        );
        title = t("players.addArrayItem.addRewardTitle");
        break;

      case ArrayItemType.questRequirement:
        body = (
          <AddReward
            itemType={ArrayItemType.questRequirement}
            arrayCount={arrayCount}
            setIsShown={setIsShown}
            pathToArray={pathToArray}
            id={id}
          />
        );
        title = t("players.addArrayItem.addRequirementTitle");
        break;

      default:
        break;
    }

    const closeDialog = useCallback(() => {
      setIsShown(false);
    }, [setIsShown]);

    return (
      <Dialog
        open={isShown}
        onClose={closeDialog}
        scroll="paper"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "45rem",
            },
          },
        }}
      >
        <DialogTitle sx={{ color: "primary.main", fontSize: "1.5rem" }}>
          {title}
        </DialogTitle>
        <DialogContent>{body}</DialogContent>
      </Dialog>
    );
  }
);
