import React, { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFeedError, useFeedSuccess } from "../../utils/feedHooks";
import {
  CreateStoreContentFormValues,
  StoreContentEntry,
  StoreContentTimestampRecords,
  StoreItem,
} from "../../slices/storeContent/types";
import { ROUTE_PATHS } from "../../consts";
import { useTableUrlTools } from "../../utils/useTableUrlTools";
import Form from "./Form";
import { Typography } from "@mui/material";
import useWebsocket from "../../utils/useWebsocket";
import { useSelector } from "../../store";
import { getWasWebsocketOpen } from "../../slices/Websocket/slice";
import { v4 as uuid } from "uuid";
import {
  WebsocketActionType,
  WebsocketMessageStatus,
} from "../../slices/Websocket/types";
import _ from "lodash";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import weekday from "dayjs/plugin/weekday";
import dayOfYear from "dayjs/plugin/dayOfYear";
import updateLocale from "dayjs/plugin/updateLocale";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(weekday);
dayjs.extend(dayOfYear);

dayjs.extend(updateLocale);
dayjs.updateLocale(dayjs.locale(), {
  weekStart: 1,
});

type EditStoreContentProps = {
  allStoreContent: StoreContentEntry[];
  timestampsToDisable: StoreContentTimestampRecords;
  refetchList?: () => void;
  lootboxData: any;
};

const EditStoreContent = ({
  allStoreContent,
  timestampsToDisable,
  refetchList,
  lootboxData,
}: EditStoreContentProps) => {
  const { t } = useTranslation();
  const feedSuccess = useFeedSuccess();
  const feedError = useFeedError();

  const { sendMessage, subscribeOnMessage, unsubscribeOnMessage } =
    useWebsocket();

  const wasWebsocketOpen = useSelector(getWasWebsocketOpen);

  const [isLoading, setIsLoading] = useState(false);
  const [websocketId, setWebsocketId] = useState<string | null>(null);

  const mainRoute = ROUTE_PATHS.STORE_CONTENT;

  const { id, closeDrawer } = useTableUrlTools(mainRoute);

  const currentStoreContent = allStoreContent.find((item) => item._id === id);

  const editStoreContent = async (rawData: CreateStoreContentFormValues) => {
    const randomId = uuid();

    const data = allStoreContent
      .filter((el) => el.tab === currentStoreContent.tab)
      .sort((a, b) => a.start - b.start)
      .reduce((prev, cur, index, arr) => {
        if (id === cur._id) {
          const newStoreContentStartDateTimestamp = dayjs(rawData.start)
            .startOf("hour")
            .utc()
            .unix();
          const newStoreContentEndDateTimestamp = dayjs(rawData.end)
            .startOf("hour")
            .utc()
            .unix();

          const isNextContentExistsAndNoGapWithCurrent =
            arr[index + 1]?.start === cur.end;
          const isNextContentExistsAndGapBetweenCurrentAndCurrentEndAfterNextStart =
            arr[index + 1]?.start !== cur.end &&
            arr[index + 1]?.start < newStoreContentEndDateTimestamp;

          const isPrevContentExistsAndNoGapWithCurrent =
            arr[index - 1]?.end === cur.start;
          const isPrevContentExistsAndGapBetweenCurrentAndCurrentStartBeforePrevEnd =
            arr[index - 1]?.end !== cur.start &&
            arr[index - 1]?.end > newStoreContentStartDateTimestamp;

          if (isNextContentExistsAndNoGapWithCurrent) {
            prev.push({
              ...arr[index + 1],
              start: newStoreContentEndDateTimestamp,
            });
          }
          if (
            isNextContentExistsAndGapBetweenCurrentAndCurrentEndAfterNextStart
          ) {
            prev.push({
              ...arr[index + 1],
              start: newStoreContentEndDateTimestamp,
            });
          }
          if (isPrevContentExistsAndNoGapWithCurrent) {
            prev.push({
              ...arr[index - 1],
              end: newStoreContentStartDateTimestamp,
            });
          }
          if (
            isPrevContentExistsAndGapBetweenCurrentAndCurrentStartBeforePrevEnd
          ) {
            prev.push({
              ...arr[index - 1],
              end: newStoreContentStartDateTimestamp,
            });
          }

          prev.push({
            ...rawData,
            start: newStoreContentStartDateTimestamp,
            end: newStoreContentEndDateTimestamp,
            _id: id,
            storeItems: rawData.storeItems.map((el) => {
              return {
                ...el,
                discountPercentage: el.discountPercentageOverridden
                  ? el.discountPercentage
                  : null,
                price: el.priceOverridden ? el.price : null,
                buyAmountLimit: el.buyAmountLimitOverridden
                  ? el.buyAmountLimit
                  : null,
              };
            }),
          });
        }

        return prev;
      }, []);

    const processedMsg = {
      action: WebsocketActionType.createUpdateStoreTabConfiguration,
      inputParams: JSON.stringify(data),
      id: randomId,
    };

    setWebsocketId(randomId);
    sendMessage(processedMsg);
    setIsLoading(true);
  };

  const onMessageReceived = useCallback(
    (event: MessageEvent) => {
      const msg = JSON.parse(event.data);

      if (
        msg.action === WebsocketActionType.createUpdateStoreTabConfiguration &&
        msg.id === websocketId
      ) {
        if (msg.status === WebsocketMessageStatus.success) {
          feedSuccess(t("storeContentCreate.successMsgAfterSubmitEdit"));
          closeDrawer();
          setIsLoading(false);
          refetchList && refetchList();
        }
        if (msg.status === WebsocketMessageStatus.error) {
          setIsLoading(false);
        }
      }
    },
    [closeDrawer, websocketId, feedSuccess, refetchList, t]
  );

  useEffect(() => {
    if (wasWebsocketOpen && isLoading) {
      subscribeOnMessage(onMessageReceived);
    }

    return () => {
      unsubscribeOnMessage(onMessageReceived);
    };
  }, [
    isLoading,
    onMessageReceived,
    subscribeOnMessage,
    unsubscribeOnMessage,
    wasWebsocketOpen,
  ]);

  if (!currentStoreContent || !currentStoreContent._id) {
    return (
      <Typography
        variant="body1"
        color="primary"
        sx={{ fontWeight: "bold", ml: 2.5, color: "primary.light" }}
      >
        {t("errors.noData")}
      </Typography>
    );
  }

  return (
    <Form
      onSubmit={editStoreContent}
      defaultValues={currentStoreContent}
      isLoading={isLoading}
      excludeTimestamp={
        currentStoreContent
          ? {
              start: currentStoreContent.start,
              end: currentStoreContent.end,
            }
          : undefined
      }
      timestampsToDisable={timestampsToDisable}
      isEdit
      lootboxData={lootboxData}
    />
  );
};

export default memo(EditStoreContent);
