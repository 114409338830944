import React, { ReactNode } from "react";

import { Box } from "@mui/system";
import { TeamMembersFields } from "./TeamMembersFields";
import { useGetGroupManagementQuery } from "../../slices/groupManagement/api";
import { COLLECTION_NAMES, DB_NAMES } from "../../consts";

type ChatProps = {
  id: string | null;
  dbName: typeof DB_NAMES[keyof typeof DB_NAMES];
  collectionName: typeof COLLECTION_NAMES[keyof typeof COLLECTION_NAMES];
  title?: string | ReactNode;
  getQueryData: typeof useGetGroupManagementQuery;
};

const TeamMembers = ({
  id,
  collectionName,
  dbName,
  title = "",
  getQueryData,
}: ChatProps) => {
  const { data, refetch } = getQueryData({
    search: { _id: id },
    limit: 1000,
    page: 1,
    dbName,
    collectionName,
  });

  if (!data?.docs[0]) {
    return null;
  }

  const messagesData = data?.docs[0].members;
  const teamId = data?.docs[0].teamID;
  const ownerId = data?.docs[0].owner;
  const owner = messagesData.filter(({ playerID }) => playerID === ownerId)[0];
  const shortTeam = messagesData.filter(({ playerID }) => playerID !== ownerId);
  const sortedTeam = owner ? [owner, ...shortTeam] : shortTeam;

  return (
    <Box sx={{ mt: 2.5, backgroundColor: "white" }}>
      <h3 style={{ padding: "18px 0px 1px 18px" }}>{title}</h3>
      {messagesData ? (
        <TeamMembersFields
          data={sortedTeam}
          teamId={teamId}
          refetch={refetch}
        />
      ) : (
        <div>no team </div>
      )}
    </Box>
  );
};

export default TeamMembers;
