import React, {
  useCallback,
  useState,
  useMemo,
  Dispatch,
  SetStateAction,
} from "react";

import SearchToolbar from "./SearchToolbar";
import Pagination from "./Pagination";
import VirtualTable from "./VirtualTable";
import { PaperPageSpinner } from "../PaperPage";

import { escapeRegEx } from "../../utils/escapeRegEx";
import { useLocalStorage } from "usehooks-ts";
import { ColumnData } from "./types";
import { SearchPaginateRequestBody } from "../../types";
import {
  searchFieldsBackType,
  searchFieldsFrontType,
} from "../DataTable/DataTableTypes";
import { timeIntervalType } from "./SearchToolbar/SearchFields";
import {
  CHAT_STATUSES,
  COLLECTION_NAMES,
  DB_NAMES,
  DEFAULT_PAGE_SIZE,
} from "../../consts";

type GridRowModel = {
  [key: string]: any;
};

export type CustomDataGridProps = {
  tableName: string;
  filter?: React.ReactNode;
  dbName: typeof DB_NAMES[keyof typeof DB_NAMES];
  collectionName: typeof COLLECTION_NAMES[keyof typeof COLLECTION_NAMES];
  searchFieldsFront: searchFieldsFrontType;
  searchFieldsBack: searchFieldsBackType[];
  rows: GridRowModel[];
  totalPages?: number;
  responsePageSize?: number;
  responsePage?: number;
  responseHasNextPage?: boolean;
  columns: ColumnData<GridRowModel>[];
  isLoading: boolean;
  onSearchClick: Dispatch<SetStateAction<SearchPaginateRequestBody>>;
  getAllItems: () => void;
  hasRefreshBtn?: boolean;
  refetch?: () => void;
  hasPagination: boolean;
  isSupportTable: boolean;
};

export type InitialSearchDataType = Omit<
  SearchPaginateRequestBody,
  "limit" | "page"
>;

export const CustomDataGrid = ({
  filter,
  onSearchClick,
  isLoading,
  getAllItems,
  dbName,
  collectionName,
  tableName,
  rows,
  searchFieldsFront,
  searchFieldsBack,
  totalPages,
  responsePageSize,
  responsePage,
  responseHasNextPage,
  columns,
  hasRefreshBtn = false,
  refetch,
  hasPagination,
  isSupportTable = false,
}: CustomDataGridProps) => {
  const [isClosedIssuesShown, setIsClosedIssuesShown] =
    useLocalStorage<boolean>(`table-${tableName}.isClosedIssuesShown`, false);

  const initialSearchData = useMemo<InitialSearchDataType>(() => {
    const usualSearchData: InitialSearchDataType = {
      search: null,
      isCustomSearch: false,
      dbName,
      collectionName,
    };

    if (isSupportTable) {
      usualSearchData.excludeFromSearch = isClosedIssuesShown
        ? null
        : { status: CHAT_STATUSES.closed };
    }
    return usualSearchData;
  }, [dbName, collectionName, isSupportTable, isClosedIssuesShown]);

  const [searchData, setSearchData] = useState(initialSearchData);

  const [pageSize, setPageSize] = useLocalStorage<number>(
    `table-${tableName}.pageSize`,
    DEFAULT_PAGE_SIZE
  );
  const [page, setPage] = useLocalStorage(`table-${tableName}.page`, 0);

  const onPageChange = useCallback(
    (newPage: number) => {
      setPage(newPage);
      onSearchClick((prevSearchData) => ({
        ...prevSearchData,
        limit: pageSize,
        page: newPage,
      }));
    },
    [setPage, onSearchClick, pageSize]
  );

  const onPageSizeChange = useCallback(
    (newPageSize: number) => {
      setPageSize(newPageSize);
      onSearchClick((prevSearchData) => ({
        ...prevSearchData,
        limit: newPageSize,
        page: 1,
      }));
    },
    [setPageSize, onSearchClick]
  );

  const [searchValueType, setSearchValueType] = useState<string>(
    searchFieldsBack[0].key
  );

  const addTypeOfSearchData = useCallback(
    (typeOfSearchData: string) => {
      setSearchValueType(typeOfSearchData);
      setSearchData(initialSearchData);
    },
    [initialSearchData, setSearchData]
  );

  const addSearchData = (
    value: string | number | Date | null | timeIntervalType
  ) => {
    const dataType = searchFieldsBack.find((el) => el.key === searchValueType);
    const currentTypeofData = dataType?.typeofData;
    const isCustomSearch = dataType?.isCustomSearch;

    const search = {
      [searchValueType]: currentTypeofData === "number" ? Number(value) : value,
    };

    setSearchData((prevState) => ({
      ...prevState,
      ...(isCustomSearch ? { isCustomSearch } : {}),
      search,
    }));
  };

  const [searchInFront, setSearchInFront] = useLocalStorage(
    `table-${tableName}.search`,
    ""
  );
  const filteredRows = useMemo(() => {
    const searchRegEx = new RegExp(escapeRegEx(searchInFront), "i");

    return rows.filter((row) => {
      return Object.keys(row).some((field) => {
        if (!searchFieldsFront.includes(field) || !row[field]) {
          return false;
        }

        if (searchRegEx.test(JSON.stringify(row[field]))) {
          return true;
        }

        return false;
      });
    });
  }, [rows, searchInFront, searchFieldsFront]);

  const reset = useCallback(() => {
    setSearchInFront("");
  }, [setSearchInFront]);

  const handleChangeShowClosedIssues = useCallback(
    (event, checked) => {
      if (isSupportTable) {
        const excludeFromSearch = checked
          ? null
          : { status: CHAT_STATUSES.closed };

        setSearchData((prevState) => ({
          ...prevState,
          excludeFromSearch,
        }));

        onSearchClick((prevState) => ({
          ...prevState,
          ...searchData,
          excludeFromSearch,
          limit: pageSize,
          page: page,
        }));

        setIsClosedIssuesShown(checked);
      }
    },
    [
      isSupportTable,
      onSearchClick,
      page,
      pageSize,
      searchData,
      setIsClosedIssuesShown,
    ]
  );

  const transferSearchData = useCallback(() => {
    onSearchClick((prevState) => ({
      ...prevState,
      ...searchData,
      limit: pageSize,
      page: page,
    }));
  }, [onSearchClick, page, pageSize, searchData]);

  const transferGetAllItems = useCallback(() => {
    setSearchData(initialSearchData);
    getAllItems();
  }, [setSearchData, initialSearchData, getAllItems]);

  return (
    <>
      <SearchToolbar
        searchFieldsBack={searchFieldsBack}
        getAllItems={transferGetAllItems}
        addSearchData={addSearchData}
        transferSearchData={transferSearchData}
        addTypeOfSearchData={addTypeOfSearchData}
        searchData={searchData.search}
        searchValueType={searchValueType}
        searchInFront={searchInFront}
        setSearchInFront={setSearchInFront}
        onReset={reset}
        filter={filter}
        isClosedIssuesShown={isClosedIssuesShown}
        handleChangeShowClosedIssues={handleChangeShowClosedIssues}
        isSupportTable={isSupportTable}
      />
      {isLoading ? (
        <PaperPageSpinner />
      ) : (
        <>
          <VirtualTable data={filteredRows} columns={columns} />
          {hasPagination && (
            <Pagination
              onPageSizeChange={onPageSizeChange}
              pageSize={pageSize}
              onPageChange={onPageChange}
              page={page}
              totalPages={totalPages}
              responsePageSize={responsePageSize}
              responsePage={responsePage}
              responseHasNextPage={responseHasNextPage}
              hasRefreshBtn={hasRefreshBtn}
              refetch={refetch}
            />
          )}
        </>
      )}
    </>
  );
};
