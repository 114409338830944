import React, { memo, PureComponent } from "react";
import clsx from "clsx";

import { withStyles, WithStyles } from "@mui/styles";
import { Theme, createTheme } from "@mui/material/styles";
import { TableCell } from "@mui/material";
import Paper from "@mui/material/Paper";
import {
  AutoSizer,
  Column,
  Table,
  TableCellRenderer,
  TableHeaderProps,
} from "react-virtualized";

import { ColumnData } from "./types";

const styles = (theme: Theme) =>
  ({
    flexContainer: {
      display: "flex",
      alignItems: "center",
      boxSizing: "border-box",
    },
    table: {
      "& .ReactVirtualized__Table__headerRow": {
        ...(theme.direction === "rtl" && {
          paddingLeft: "0 !important",
        }),
        ...(theme.direction !== "rtl" && {
          paddingRight: undefined,
        }),
      },
    },
    tableRowHover: {
      "&:hover": {
        backgroundColor: theme.palette.grey[200],
      },
    },
    tableCell: {
      flex: 1,
      display: "inline-block",
      overflow: "hidden !important",
      width: "100%",
      padding: "10px 16px",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    noClick: {
      cursor: "initial",
    },
    disabled: {
      cursor: "initial",
      color: theme.palette.grey[500],
    },
    headerCell: {
      padding: "14px 16px",
    },
  } as const);

interface Row {
  index: number;
}

interface MuiVirtualizedTableProps extends WithStyles<typeof styles> {
  columns: readonly ColumnData<Data>[];
  headerHeight?: number;
  onRowClick?: () => void;
  rowCount: number;
  rowGetter: (row: Row) => Data;
  rowHeight?: number;
}

class MuiVirtualizedTable extends PureComponent<MuiVirtualizedTableProps> {
  static defaultProps = {
    headerHeight: 48,
    rowHeight: 40,
  };

  getRowClassName = ({ index }: Row) => {
    const { classes, onRowClick } = this.props;

    return clsx(classes.flexContainer, {
      [classes.tableRowHover]: index !== -1 && onRowClick !== null,
    });
  };

  cellRenderer: TableCellRenderer = ({ columnIndex, rowIndex, rowData }) => {
    const { columns, classes, rowHeight, onRowClick } = this.props;

    return (
      <TableCell
        component="div"
        className={clsx(classes.tableCell, classes.flexContainer, {
          [classes.noClick]: onRowClick === null,
          [classes.disabled]:
            columns[columnIndex].isDisabled &&
            columns[columnIndex].isDisabled(rowData),
        })}
        variant="body"
        sx={{ height: rowHeight }}
        align="left"
      >
        {columns[columnIndex].renderCell(rowData, rowIndex)}
      </TableCell>
    );
  };

  headerRenderer = ({ label }: TableHeaderProps & { columnIndex: number }) => {
    const { headerHeight, classes } = this.props;

    return (
      <TableCell
        component="div"
        className={clsx(
          classes.tableCell,
          classes.flexContainer,
          classes.noClick,
          classes.headerCell
        )}
        variant="head"
        style={{ height: headerHeight }}
        align="left"
      >
        <span>{label}</span>
      </TableCell>
    );
  };

  render() {
    const { classes, columns, rowHeight, headerHeight, ...tableProps } =
      this.props;

    return (
      <AutoSizer>
        {({ height, width }) => (
          <Table
            height={height}
            width={width}
            rowHeight={rowHeight!}
            gridStyle={{
              direction: "inherit",
            }}
            headerHeight={headerHeight!}
            className={classes.table}
            {...tableProps}
            rowClassName={this.getRowClassName}
          >
            {columns.map(({ dataKey, ...other }, index) => {
              return (
                <Column
                  key={dataKey}
                  headerRenderer={(headerProps) =>
                    this.headerRenderer({
                      ...headerProps,
                      columnIndex: index,
                    })
                  }
                  className={classes.flexContainer}
                  cellRenderer={this.cellRenderer}
                  dataKey={dataKey}
                  {...other}
                />
              );
            })}
          </Table>
        )}
      </AutoSizer>
    );
  }
}

const defaultTheme = createTheme();
const VirtualizedTable = withStyles(styles, { defaultTheme })(
  MuiVirtualizedTable
);

type Data = {
  [key: string]: any;
};

type ReactVirtualizedTableProps = {
  data: Data;
  columns: ColumnData<Data>[];
};

const ReactVirtualizedTable = ({
  data,
  columns,
}: ReactVirtualizedTableProps) => {
  return (
    <Paper
      variant="outlined"
      sx={{ flexGrow: 1, width: "100%", minHeight: "100px" }}
    >
      <VirtualizedTable
        rowCount={data?.length}
        rowGetter={({ index }) => data[index]}
        columns={columns}
      />
    </Paper>
  );
};

export default memo(ReactVirtualizedTable);
