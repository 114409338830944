import { CHAT_STATUSES } from "../../consts";

export enum WebsocketStatus {
  CONNECTING,
  OPEN,
  CLOSING,
  CLOSED,
}

export enum MessageSourceType {
  system = "system",
  player = "player",
  playerMail = "playerMail",
  playerWebFeedback = "playerWebFeedback",
  admin = "admin",
  adminMail = "adminMail",
  internalNotes = "internalNotes",
}

export enum WebsocketMessageStatus {
  success = "success",
  error = "error",
}

export enum WebsocketActionType {
  sendFeedback = "sendFeedback",
  removeFeedback = "admin/removeFeedback",
  replyFeedbackAdmin = "admin/replyFeedbackAdmin",
  replyFeedbackEmailAdmin = "admin/replyFeedbackEmailAdmin",
  changeChatStatus = "admin/changeChatStatus",
  inGameResponseMessage = "inGameResponseMessage",
  changeMaintenanceStatus = "admin/changeMaintenanceStatus",
  maintenanceStatusChanged = "admin/maintenanceStatusChanged",
  changeTeamOwner = "admin/changeTeamOwner",
  distributionSystemUpdateTask = "admin/distributionSystemUpdateTask",
  distributionSystemUpdateOnePlayerTask = "admin/distributionSystemUpdateOnePlayerTask",
  deletePlayers = "admin/deleteAccounts",
  getMaintenanceStatus = "admin/getMaintenanceStatus",
  kickTeamMember = "admin/kickTeamMember",
  transferCollections = "admin/transferCollections",
  createEventType = "admin/createEventType",
  confirmEmail = "admin/confirmEmail",
  deleteAccount = "admin/deleteAccount",
  internalNoteFeedbackAdmin = "admin/internalNoteFeedbackAdmin",
  cancelSchedullerMaintenanceStatus = "admin/cancelSchedullerMaintenanceStatus",
  createUpdateStoreTabConfiguration = "admin/createUpdateStoreTabConfiguration",
  getLootboxIds = "admin/getLootboxIds",
  deleteStoreTabConfiguration = "admin/deleteStoreTabConfiguration",
}

export type WebsocketMsgFromPlayer = {
  action: WebsocketActionType.sendFeedback;
  data: {
    message: {
      title: string;
      timeStamp: number;
      type: MessageSourceType.player;
      value: string;
      version: string;
    };
    status: `${CHAT_STATUSES}`;
    playerID: string;
    displayUserName: string;
  };
  status: `${WebsocketMessageStatus}`;
};

export type WebsocketMsgFromAdmin = {
  action: WebsocketActionType.replyFeedbackAdmin;
  data: {
    message: {
      title: string;
      timeStamp: number;
      type: MessageSourceType.admin;
      value: string;
      admin: {
        _id: string;
        displayName: string;
      };
    };
    status: `${CHAT_STATUSES}`;
    playerID: string;
  };
  status: `${WebsocketMessageStatus}`;
};

export type WebsocketMsgError = {
  scriptData: {
    ERROR: string;
  };
  id: number;
};
