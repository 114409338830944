import React, { useState, useCallback, ReactNode, useMemo } from "react";
import { Box } from "@mui/material";
import ChatFormVirtual from "./ChatFormVirtual";
import Pagination from "../../components/CustomDataGrid/Pagination";
import { useGetChatHistoryDataQuery } from "../../slices/groupManagement/api";
import { PaperPageSpinner } from "../../components/PaperPage";
import { SystemMessagesType } from "../../slices/groupManagement/types";
import { COLLECTION_NAMES, DB_NAMES } from "../../consts";

type ChatProps = {
  id: string | null;
  dbName: typeof DB_NAMES[keyof typeof DB_NAMES];
  collectionName: typeof COLLECTION_NAMES[keyof typeof COLLECTION_NAMES];
  title?: string | ReactNode;
};

export const Chats = ({
  id,
  collectionName,
  dbName,
  title = "",
}: ChatProps) => {
  const [currentPageSize] = useState(1000);
  const [currentPage] = useState(1);

  const [initialQuery, setInitialQuery] = useState({
    search: { _id: id },
    limit: currentPageSize,
    page: currentPage,
    dbName,
    collectionName,
  });

  const {
    data: chatsData,
    refetch: chatsRefetch,
    isLoading,
  } = useGetChatHistoryDataQuery(initialQuery);

  const onPageSizeChange = useCallback(
    (n: number) => {
      setInitialQuery({ ...initialQuery, limit: n, page: 1 });
    },
    [initialQuery]
  );

  const onPageChange = useCallback(
    (n: number) => {
      setInitialQuery({ ...initialQuery, page: n });
    },
    [initialQuery]
  );

  const formattedChatsData = useMemo(
    () =>
      chatsData?.docs?.map((el: SystemMessagesType) => {
        const temp = new Date(el.when).toDateString();
        return { ...el, when: temp };
      }) || [],
    [chatsData?.docs]
  );

  if (isLoading) {
    return (
      <Box
        sx={{
          width: "100%",
          height: 400,
          mt: 2.5,
          backgroundColor: "white",
        }}
      >
        <PaperPageSpinner />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        minHeight: 400,
        px: 2.5,
        backgroundColor: "white",
      }}
    >
      <h3 style={{ padding: "18px 0px 2px 18px", marginTop: 0 }}>{title}</h3>
      {formattedChatsData.length > 0 ? (
        <>
          <ChatFormVirtual rows={formattedChatsData} />
          <div style={{ padding: "10px", backgroundColor: "white" }}>
            <Pagination
              rowsPerPageOptions={[10, 100, 500, 1000, 10000]}
              onPageSizeChange={onPageSizeChange}
              onPageChange={onPageChange}
              pageSize={chatsData?.limit || 0}
              page={chatsData?.page || 1}
              totalPages={chatsData?.totalPages}
              responseHasNextPage={chatsData?.hasNextPage}
              hasRefreshBtn={true}
              refetch={chatsRefetch}
            />
          </div>
        </>
      ) : (
        <h3 style={{ margin: "18px 0px 25px 18px", color: "lightBlue" }}>
          No chat for this team
        </h3>
      )}
    </Box>
  );
};
