import React, { ReactNode } from "react";
import { Control, Controller, useWatch } from "react-hook-form";
import { DesktopDateTimePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { TextField, Grid, Typography, Button } from "@mui/material";
import {
  CreateStoreContentFormValues,
  StoreItem,
} from "../../slices/storeContent/types";
import { INPUT_COLUMN_WIDTH, INPUT_TITLE_WIDTH } from "./consts";
import { CustomBox } from "./StyledComponents";
import { DateTimePickerView } from "@mui/lab/DateTimePicker/shared";
import enGB from "date-fns/locale/de";
import { TimeRange } from "../../types";
import { mergeTimeRanges, shiftTimeByHours } from "../../utils/timeRange";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import weekday from "dayjs/plugin/weekday";
import dayOfYear from "dayjs/plugin/dayOfYear";

import updateLocale from "dayjs/plugin/updateLocale";
import _ from "lodash";
import { truncateMinutes } from "../../utils/getJPTime";
import { useFeedError } from "../../utils/feedHooks";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(weekday);
dayjs.extend(dayOfYear);

dayjs.extend(updateLocale);
dayjs.updateLocale(dayjs.locale(), {
  weekStart: 1,
});

type ControlledInputDateTimeRangeProps = {
  control: Control<any>;
  title: ReactNode;
  startFieldName: keyof CreateStoreContentFormValues;
  endFieldName: keyof CreateStoreContentFormValues;
  fieldDateTimeView?: DateTimePickerView[];
  isRequired?: boolean;
  isDisabled?: boolean;
  inputWidth?: string | number;
  titleWidth?: string | number;
  timestampsToDisable?: TimeRange[];
  resetValues?: () => void;
  resetText?: string;
  setDatetimePickers: (name: string, value: number | dayjs.Dayjs) => void;
  defaultValues: any;
  timestamps: TimeRange[];
};

const ControlledInputDateTimeRange = ({
  control,
  title,
  startFieldName,
  endFieldName,
  isRequired = false,
  isDisabled = false,
  fieldDateTimeView = ["year", "month", "day", "hours"],
  inputWidth = INPUT_COLUMN_WIDTH * 1.5 + 160,
  titleWidth = INPUT_TITLE_WIDTH,
  timestampsToDisable: utcTimestampsToDisable,
  resetValues,
  resetText,
  setDatetimePickers,
  defaultValues,
  timestamps,
}: ControlledInputDateTimeRangeProps) => {
  const feedError = useFeedError();

  const getLocalTimestamps = (timerange) => {
    const dayjsTimeLocalStart = dayjs(timerange.start * 1000).tz("Asia/Tokyo");

    const startYear = dayjsTimeLocalStart.get("year");
    const startMonth = dayjsTimeLocalStart.get("month");
    const startDay = dayjsTimeLocalStart.get("date");
    const startHours = dayjsTimeLocalStart.get("hours");

    const dayjsTimeLocalEnd = dayjs(timerange.end * 1000).tz("Asia/Tokyo");

    const endYear = dayjsTimeLocalEnd.get("year");
    const endMonth = dayjsTimeLocalEnd.get("month");
    const endDay = dayjsTimeLocalEnd.get("date");
    const endHours = dayjsTimeLocalEnd.get("hours");

    return {
      start: dayjs(
        new Date(startYear, startMonth, startDay, startHours)
      ).unix(),
      end: dayjs(new Date(endYear, endMonth, endDay, endHours)).unix(),
    };
  };

  const timestampsToDisable = utcTimestampsToDisable.map((timestamps) => {
    return getLocalTimestamps(timestamps);
  });

  const mergedTimesRanges = mergeTimeRanges(timestampsToDisable);
  // Shift start time to prevent choosing some time if next hour is already booked
  const bookedTimesRangesForStart = mergedTimesRanges.map((range) => ({
    start: shiftTimeByHours(range.start, 0),
    end: range.end,
  }));

  // Shift end time to prevent choosing time if previous hour is already booked
  const bookedTimesRangesForEnd = mergedTimesRanges.map((range) => ({
    start: shiftTimeByHours(range.start, 1),
    end: shiftTimeByHours(range.end, 1),
  }));

  const startDate = useWatch({
    control,
    name: startFieldName,
  });

  const endDate = useWatch({
    control,
    name: endFieldName,
  });

  const currentTimestampAndNeighborsTimestamps = timestamps
    .sort((a, b) => a.start - b.start)
    .reduce(
      (prev, cur, id, arr) => {
        if (
          cur.end === defaultValues.end &&
          cur.start === defaultValues.start
        ) {
          prev.prevTimestamp = arr[id - 1]
            ? getLocalTimestamps(arr[id - 1])
            : null;
          prev.nextTimestamp = arr[id + 1]
            ? getLocalTimestamps(arr[id + 1])
            : null;
          prev.curTimestamp = getLocalTimestamps(cur);
        } else {
          if (cur.end === defaultValues.start) {
            prev.prevTimestamp = getLocalTimestamps(cur);
          }

          if (cur.start === defaultValues.end) {
            prev.nextTimestamp = getLocalTimestamps(cur);
          }
        }

        return prev;
      },
      {
        prevTimestamp: null,
        curTimestamp: null,
        nextTimestamp: null,
      }
    );

  const shouldDisableEndTime = (time: number): boolean => {
    const truncatedCurrentSectionTokyoStartDate = dayjs(startDate)
      .tz("Asia/Tokyo", true)
      .startOf("day")
      .unix();
    const truncatedCurrentSectionTokyoEndDate = dayjs(endDate)
      .tz("Asia/Tokyo", true)
      .startOf("day")
      .unix();
    const currentSectionTokyoStartTime = dayjs(startDate)
      .tz("Asia/Tokyo", true)
      .get("h");
    const truncatedCurrentSectionTokyoDate = dayjs()
      .tz("Asia/Tokyo")
      .startOf("day")
      .unix();
    const nextSectionEndDateHours = dayjs
      .unix(currentTimestampAndNeighborsTimestamps.nextTimestamp?.end)
      .get("h");
    const truncatedNextSectionEndDate = dayjs
      .unix(currentTimestampAndNeighborsTimestamps.nextTimestamp?.end)
      .tz("Asia/Tokyo", true)
      .startOf("day")
      .unix();

    const IS_DISABLE_HOURS_BEFORE_START_DATE_TIME_PLUS_1_HOUR =
      truncatedCurrentSectionTokyoStartDate ===
        truncatedCurrentSectionTokyoEndDate &&
      time <= currentSectionTokyoStartTime;
    const IS_DISABLE_HOURS_AFTER_NEXT_SECTION_END_TIME =
      currentTimestampAndNeighborsTimestamps.nextTimestamp &&
      truncatedCurrentSectionTokyoEndDate === truncatedNextSectionEndDate &&
      time >= nextSectionEndDateHours;
    const IS_DISABLE_HOURS_IF_HOURS_BEFORE_CURRENT_HOURS =
      (!currentTimestampAndNeighborsTimestamps.prevTimestamp || !startDate) &&
      truncatedCurrentSectionTokyoEndDate ===
        truncatedCurrentSectionTokyoDate &&
      time <= dayjs().tz("Asia/Tokyo").get("h");

    return (
      IS_DISABLE_HOURS_BEFORE_START_DATE_TIME_PLUS_1_HOUR ||
      IS_DISABLE_HOURS_AFTER_NEXT_SECTION_END_TIME ||
      IS_DISABLE_HOURS_IF_HOURS_BEFORE_CURRENT_HOURS
    );
  };

  const shouldDisableStartTime = (time: number): boolean => {
    const truncatedCurrentSectionTokyoDate = dayjs()
      .tz("Asia/Tokyo", true)
      .startOf("day")
      .unix();
    const truncatedCurrentSectionTokyoStartDate = dayjs(startDate)
      .tz("Asia/Tokyo", true)
      .startOf("day")
      .unix();
    const currentTokyoHour = dayjs().tz("Asia/Tokyo").get("h");
    const truncatedCurrentSectionTokyoEndDate = dayjs(endDate)
      .tz("Asia/Tokyo", true)
      .startOf("day")
      .unix();
    const currentSectionTokyoEndTime = dayjs(endDate)
      .tz("Asia/Tokyo", true)
      .get("h");
    const truncatedPrevSectionStartDate = dayjs
      .unix(currentTimestampAndNeighborsTimestamps.prevTimestamp?.start)
      .tz("Asia/Tokyo", true)
      .startOf("day")
      .unix();
    const prevSectionStartDayHours = dayjs
      .unix(currentTimestampAndNeighborsTimestamps.prevTimestamp?.start)
      .get("h");

    const DISABLE_HOURS_IF_START_DATE_EQUALS_TO_CURRENT_DAY_AND_HOURS_BEFORE_CURRENT_HOUR =
      truncatedCurrentSectionTokyoStartDate ===
        truncatedCurrentSectionTokyoDate && time < currentTokyoHour;
    const DISABLE_HOURS_IF_START_DAY_EQuALS_TO_END_DAY_AND_HOURS_AFTER_END_HOURS =
      endDate &&
      truncatedCurrentSectionTokyoStartDate ===
        truncatedCurrentSectionTokyoEndDate &&
      time >= currentSectionTokyoEndTime;
    const DISABLE_HOURS_BEFORE_PREV_SECTION_START_HOUR =
      currentTimestampAndNeighborsTimestamps.prevTimestamp &&
      truncatedCurrentSectionTokyoStartDate === truncatedPrevSectionStartDate &&
      time <= prevSectionStartDayHours;

    return (
      DISABLE_HOURS_IF_START_DATE_EQUALS_TO_CURRENT_DAY_AND_HOURS_BEFORE_CURRENT_HOUR ||
      DISABLE_HOURS_IF_START_DAY_EQuALS_TO_END_DAY_AND_HOURS_AFTER_END_HOURS ||
      DISABLE_HOURS_BEFORE_PREV_SECTION_START_HOUR
    );
  };

  const shouldDisableEndDate = (date: Date): boolean => {
    const truncatedPickerTokyoDate = dayjs(date)
      .tz("Asia/Tokyo", true)
      .startOf("day")
      .unix();
    const truncatedCurrentSectionTokyoDate = dayjs()
      .tz("Asia/Tokyo", true)
      .startOf("day")
      .unix();
    const truncatedCurrentSectionTokyoStartDate = dayjs(startDate)
      .tz("Asia/Tokyo", true)
      .add(1, "hour")
      .startOf("day")
      .unix();
    const truncatedPrevSectionStartDate = dayjs
      .unix(currentTimestampAndNeighborsTimestamps.prevTimestamp?.start)
      .tz("Asia/Tokyo", true)
      .add(1, "hour")
      .startOf("day")
      .unix();
    const truncatedNextSectionEndDate = dayjs
      .unix(currentTimestampAndNeighborsTimestamps.nextTimestamp?.end)
      .tz("Asia/Tokyo", true)
      .add(-1, "hour")
      .startOf("day")
      .unix();

    const IS_DISABLE_ALL_DAYS_BEFORE_CURRENT_DAY =
      !currentTimestampAndNeighborsTimestamps.prevTimestamp &&
      !currentTimestampAndNeighborsTimestamps.nextTimestamp &&
      truncatedPickerTokyoDate < truncatedCurrentSectionTokyoDate;
    const IS_DISABLE_DAYS_BEFORE_START_DATE =
      startDate &&
      truncatedPickerTokyoDate < truncatedCurrentSectionTokyoStartDate;
    const IS_DISABLE_ALL_DAYS_BEFORE_PREV_SECTION_START_DAY_IF_IT_NOT_STARTS_AT_23 =
      currentTimestampAndNeighborsTimestamps.prevTimestamp &&
      truncatedPickerTokyoDate < truncatedPrevSectionStartDate;
    const IS_DISABLE_DAYS_AFTER_NEXT_SECTION_END_DATE =
      currentTimestampAndNeighborsTimestamps.nextTimestamp &&
      truncatedPickerTokyoDate > truncatedNextSectionEndDate;

    return (
      IS_DISABLE_ALL_DAYS_BEFORE_CURRENT_DAY ||
      IS_DISABLE_DAYS_BEFORE_START_DATE ||
      IS_DISABLE_ALL_DAYS_BEFORE_PREV_SECTION_START_DAY_IF_IT_NOT_STARTS_AT_23 ||
      IS_DISABLE_DAYS_AFTER_NEXT_SECTION_END_DATE
    );
  };

  const shouldDisableStartDate = (date: Date): boolean => {
    const truncatedPickerTokyoDate = dayjs(date)
      .tz("Asia/Tokyo", true)
      .startOf("day")
      .unix();
    const truncatedCurrentSectionTokyoDate = dayjs()
      .tz("Asia/Tokyo", true)
      .startOf("day")
      .unix();
    const truncatedCurrentSectionTokyoEndDate = dayjs(endDate)
      .tz("Asia/Tokyo", true)
      .add(1, "hour")
      .startOf("day")
      .unix();
    const truncatedPrevSectionStartDate = dayjs
      .unix(currentTimestampAndNeighborsTimestamps.prevTimestamp?.start)
      .tz("Asia/Tokyo", true)
      .add(1, "hour")
      .startOf("day")
      .unix();
    const truncatedNextSectionEndDate = dayjs
      .unix(currentTimestampAndNeighborsTimestamps.nextTimestamp?.start)
      .tz("Asia/Tokyo", true)
      .add(-1, "hour")
      .startOf("day")
      .unix();

    return !(
      // truncatedPickerTokyoDate >= truncatedCurrentSectionTokyoDate &&
      (
        (_.isNil(endDate) ||
          truncatedPickerTokyoDate <= truncatedCurrentSectionTokyoEndDate) &&
        (_.isNil(currentTimestampAndNeighborsTimestamps.prevTimestamp) ||
          truncatedPickerTokyoDate >= truncatedPrevSectionStartDate) &&
        (_.isNil(currentTimestampAndNeighborsTimestamps.nextTimestamp) ||
          truncatedPickerTokyoDate <= truncatedNextSectionEndDate)
      )
    );
  };

  const handleDesktopDateTimePickerChange = (
    inputDatetimeValue: Date,
    inputName: "start" | "end"
  ) => {
    if (!inputDatetimeValue) return;

    const currentTime = new Date().setMinutes(0, 0, 0);
    const japanTime = new Date(
      new Date().toLocaleString("en-US", {
        timeZone: "Asia/Tokyo",
      })
    ).setMinutes(0, 0, 0);

    const difference = Math.floor((japanTime - currentTime) / 1000);

    const timestamp =
      new Date(inputDatetimeValue).setMinutes(0, 0, 0) / 1000 - difference;

    const transformedDatetime = dayjs.unix(timestamp).tz("Asia/Tokyo");

    setDatetimePickers(inputName, transformedDatetime);
  };

  return (
    <CustomBox>
      <Typography
        component="div"
        width={titleWidth}
        sx={{ color: isDisabled ? "rgba(0, 0, 0, 0.38)" : "initial" }}
      >
        {title}
      </Typography>
      <Grid container spacing={2} width={inputWidth}>
        <Grid item xs={5}>
          <Controller
            name={startFieldName}
            control={control}
            rules={{
              required: true,
              validate: {
                range: (value, formValue) => {
                  const isStoreItemTimerangeOverContentsTimerange =
                    formValue.storeItems.some((el: StoreItem) => {
                      const contentStart = dayjs(value)
                        .startOf("hour")
                        .utc()
                        .unix();

                      if (
                        el.availabilityStart &&
                        el.availabilityStart < contentStart
                      )
                        return true;

                      return false;
                    });

                  if (isStoreItemTimerangeOverContentsTimerange) {
                    feedError(
                      "Date ranges are not valid or conflict with others, please fix and try again"
                    );
                  }

                  return !isStoreItemTimerangeOverContentsTimerange;
                },
              },
            }}
            render={({ field }) => (
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={enGB}
              >
                <DesktopDateTimePicker
                  {...field}
                  onChange={(val) =>
                    handleDesktopDateTimePickerChange(val, "start")
                  }
                  value={field.value && truncateMinutes(field.value)}
                  views={fieldDateTimeView}
                  shouldDisableDate={shouldDisableStartDate}
                  shouldDisableTime={shouldDisableStartTime}
                  ampm={false}
                  inputFormat="yyyy/MM/dd HH:mm"
                  renderInput={(params) => (
                    <div style={{ position: "relative" }}>
                      <TextField
                        {...params}
                        error={params.inputProps.value ? false : true}
                        margin="normal"
                        fullWidth
                        size="small"
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                      />
                      <Grid
                        item
                        sx={{
                          ml: 1,
                          position: "absolute",
                          top: "24px",
                          right: "36px",
                        }}
                      >
                        <Typography variant="body1">(GMT+9)</Typography>
                      </Grid>
                    </div>
                  )}
                />
              </LocalizationProvider>
            )}
          />
        </Grid>
        <Grid item xs={5}>
          <Controller
            name={endFieldName}
            control={control}
            rules={{
              required: true,
              validate: {
                range: (value, formValue) => {
                  const isStoreItemTimerangeOverContentsTimerange =
                    formValue.storeItems.some((el: StoreItem) => {
                      const contentEnd = dayjs(value)
                        .startOf("hour")
                        .utc()
                        .unix();

                      if (el.availabilityEnd && el.availabilityEnd > contentEnd)
                        return true;

                      return false;
                    });

                  if (isStoreItemTimerangeOverContentsTimerange) {
                    feedError(
                      "Date ranges are not valid or conflict with others, please fix and try again"
                    );
                  }

                  return !isStoreItemTimerangeOverContentsTimerange;
                },
              },
            }}
            render={({ field }) => (
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={enGB}
              >
                <DesktopDateTimePicker
                  {...field}
                  onChange={(val) =>
                    handleDesktopDateTimePickerChange(val, "end")
                  }
                  value={field.value && truncateMinutes(field.value)}
                  views={fieldDateTimeView}
                  shouldDisableDate={shouldDisableEndDate}
                  shouldDisableTime={shouldDisableEndTime}
                  ampm={false}
                  inputFormat="yyyy/MM/dd HH:mm"
                  renderInput={(params) => (
                    <div style={{ position: "relative" }}>
                      <TextField
                        {...params}
                        error={params.inputProps.value ? false : true}
                        margin="normal"
                        fullWidth
                        size="small"
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                      />
                      <Grid
                        item
                        sx={{
                          ml: 1,
                          position: "absolute",
                          top: "24px",
                          right: "36px",
                        }}
                      >
                        <Typography variant="body1">(GMT+9)</Typography>
                      </Grid>
                    </div>
                  )}
                />
              </LocalizationProvider>
            )}
          />
        </Grid>
        <Grid item xs={2}>
          {resetValues && resetText && (
            <Button
              variant="outlined"
              onClick={resetValues}
              sx={{
                marginTop: "16px",
                height: "40px",
              }}
            >
              {resetText}
            </Button>
          )}
        </Grid>
      </Grid>
    </CustomBox>
  );
};

export default ControlledInputDateTimeRange;
