import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import dayjs from "dayjs";

import { useForm, Controller, FormProvider } from "react-hook-form";
import {
  Button,
  TextField,
  Box,
  Checkbox,
  Stack,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Grid,
  Switch,
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/lab";
import AdapterDayjs from "@mui/lab/AdapterDayjs";
import { EventChip } from "./EventChip";
import { Circular } from "../../components/spinners/Circular";
import { ChatCancelBtn } from "../../components/SupportChat/ChatUI/ChatCancelBtn";
import { EventGoodItem } from "./EventGoodItem";

import { useTranslation } from "react-i18next";
import { useGetPlayersCountMutation } from "../../slices/players/api";
import { ErrorBox } from "./EventFormsStyles";
import {
  FormValues,
  EventFormsTypes,
  Result,
  DistributionSystemFilterType,
} from "./types";
import { DATE_MASK_DEFAULT, IN_GAME_MSG_TIMEOUT } from "../../consts";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import {
  currencyList,
  furnitureList,
  petList,
} from "../../components/inputs/consts";

dayjs.extend(timezone);
dayjs.extend(utc);

const initialResult: Result = {
  inGameResponseMode: false,
  goods: [],
  currencies: [],
  pets: [],
  furniture: [],
  filter: "",
  mailContent: "",
  sendMailNotification: false,
  isMassMail: true,
  mailHeader: "",
  webUrl: "",
  sendPushNotification: false,
  pushMessage: "",
  pushTitle: "",
  executionTimeUTC: dayjs()
    .utc()
    .add(9, "hours")
    .add(new Date().getTimezoneOffset(), "minutes")
    .valueOf(),
  filterType: DistributionSystemFilterType.mongoQuery,
};

export type Item = Record<string, number>;

export const EventForms = ({
  vGoodShortCodes = [],
  onCreate,
  inGameResponseMode,
  playerID,
}: EventFormsTypes) => {
  const { t } = useTranslation();
  if (inGameResponseMode) {
    initialResult.inGameResponseMode = true;
    initialResult.sendMailNotification = true;
    initialResult.isMassMail = false;
    initialResult.filterType = DistributionSystemFilterType.playerIds;
    initialResult.filter = [playerID];
  }
  const [result, setResult] = useState(initialResult);
  const [hasError, setHasError] = useState("");
  const [TimerBtn, setTimerBtn] = useState<ReactElement>(null);

  const formMethodsVirtualGoods = useForm<FormValues>();
  const formMethodsCurrency = useForm<FormValues>();
  const formMethodsPets = useForm<FormValues>();
  const formMethodsFurniture = useForm<FormValues>();
  const { reset: resetVirtualGoods } = formMethodsVirtualGoods;
  const { reset: resetCurrency } = formMethodsCurrency;
  const { reset: resetPets } = formMethodsPets;
  const { reset: resetFurniture } = formMethodsFurniture;

  const {
    control: controlFilter,
    handleSubmit: handleSubmitFilter,
    reset: resetFilter,
    watch: watchFilter,
  } = useForm<FormValues>({
    defaultValues: { filterType: initialResult.filterType },
  });
  const {
    control: controlMail,
    reset: resetMail,
    watch: watchMail,
  } = useForm<FormValues>({
    defaultValues: { sendMailNotification: initialResult.sendMailNotification },
  });
  const {
    control: controlIsMassMail,
    reset: resetIsMassMail,
    watch: watchIsMassMail,
  } = useForm<FormValues>({
    defaultValues: { isMassMail: initialResult.isMassMail },
  });
  const {
    control: controlPush,
    reset: resetPush,
    watch: watchPush,
  } = useForm<FormValues>({
    defaultValues: { sendPushNotification: initialResult.sendPushNotification },
  });

  const resetAllFields = useCallback(() => {
    resetVirtualGoods();
    resetCurrency();
    resetPets();
    resetFurniture();
    resetFilter({ filterType: initialResult.filterType });
    resetMail({ sendMailNotification: initialResult.sendMailNotification });
    resetPush({ sendPushNotification: initialResult.sendPushNotification });
    resetIsMassMail({ isMassMail: initialResult.isMassMail });
    setResult(initialResult);
    setHasError("");
  }, [
    resetVirtualGoods,
    resetCurrency,
    resetFilter,
    resetPets,
    resetFurniture,
    resetMail,
    resetPush,
    resetIsMassMail,
  ]);

  const onSend = useCallback(() => {
    if (TimerBtn) {
      setTimerBtn(null);
      onCreate(result);
      resetAllFields();

      return;
    }
    if (
      result.filter.length > 0 &&
      result.executionTimeUTC &&
      (result.sendPushNotification === true ||
        result.sendMailNotification === true ||
        result.goods.length > 0 ||
        result.currencies.length > 0 ||
        result.pets.length > 0 ||
        result.furniture.length > 0) &&
      (result.sendMailNotification === false ||
        (result.sendMailNotification &&
          result.mailContent !== "" &&
          result.mailHeader !== ""))
    ) {
      setHasError("");

      if (inGameResponseMode) {
        const handleTimeOut = () => {
          setTimerBtn(null);
          onCreate(result);
          resetAllFields();
        };
        const handleCancel = () => {
          setTimerBtn(null);
        };

        setTimerBtn(
          <ChatCancelBtn
            timeToCount={IN_GAME_MSG_TIMEOUT}
            onTimeOut={handleTimeOut}
            onCancel={handleCancel}
            sx={{ pl: 3, "& div": { top: "-1px" } }}
          />
        );
      } else {
        onCreate(result);
        resetAllFields();
      }
    } else {
      setHasError(
        t(
          `distributionSystem.${
            inGameResponseMode
              ? "errorMsgInGameResponse"
              : "errorMsgDistribution"
          }`
        )
      );
    }
  }, [TimerBtn, result, onCreate, resetAllFields, inGameResponseMode, t]);

  useEffect(() => {
    return () => {
      if (TimerBtn && !result) {
        setTimerBtn(null);
        onCreate(result);
      }
    };
  }, [TimerBtn, onCreate, result]);

  const onSubmitFilter = useCallback(
    ({ filterData, filterType }: FormValues) => {
      let filter: string | string[] = filterData;

      if (
        filterType === DistributionSystemFilterType.playerEmails ||
        filterType === DistributionSystemFilterType.playerIds
      ) {
        filter = filterData.split("\n").map((item) => item.trim());
      }

      setResult((prevResult) => ({
        ...prevResult,
        filterType,
        filter,
      }));
      setHasError("");
    },
    []
  );

  const handleChangeExecutionTimeUTC = useCallback((value: any) => {
    const currValue = dayjs(value).valueOf();
    const newTime = !isNaN(currValue) ? currValue : null;

    setResult((prevResult) => ({
      ...prevResult,
      executionTimeUTC: newTime,
    }));
  }, []);

  useEffect(() => {
    const subscriptionMail = watchMail((value, { name }) => {
      name &&
        setResult((prevResult) => ({
          ...prevResult,
          [name]: value[name],
        }));
    });

    const subscriptionPush = watchPush((value, { name }) => {
      name &&
        setResult((prevResult) => ({
          ...prevResult,
          [name]: value[name],
        }));
    });

    const subscriptionIsMassMail = watchIsMassMail((value, { name }) => {
      name &&
        setResult((prevResult) => ({
          ...prevResult,
          [name]: value[name],
        }));
    });

    return () => {
      subscriptionMail.unsubscribe();
      subscriptionPush.unsubscribe();
      subscriptionIsMassMail.unsubscribe();
    };
  }, [result, watchIsMassMail, watchMail, watchPush]);

  const goodsChip = useMemo(
    () =>
      result.goods.map((good: Item, index: number) => {
        const objKey = Object.keys(good)[0];
        const objValue = good[objKey];
        const forLabel = objKey + ": " + objValue;
        return { key: index, label: forLabel };
      }),
    [result.goods]
  );

  const currenciesChip = useMemo(
    () =>
      result.currencies.map((currencies: Item, index: number) => {
        const objKey = Object.keys(currencies)[0];
        const objValue = currencies[objKey];
        const forLabel = objKey + ": " + objValue;
        return { key: index, label: forLabel };
      }),
    [result.currencies]
  );

  const petsChip = useMemo(
    () =>
      result.pets.map((pet: Item, index: number) => {
        const objKey = Object.keys(pet)[0];
        const objValue = pet[objKey];
        const forLabel = objKey + ": " + objValue;
        return { key: index, label: forLabel };
      }),
    [result.pets]
  );

  const furnitureChip = useMemo(
    () =>
      result.furniture.map((item: Item, index: number) => {
        const objKey = Object.keys(item)[0];
        const objValue = item[objKey];
        const forLabel = objKey + ": " + objValue;
        return { key: index, label: forLabel };
      }),
    [result.furniture]
  );

  const deleteVGood = useCallback(
    (index: number) => {
      const tempGoods = [...result.goods];
      tempGoods.splice(index, 1);
      const tempResult = { ...result, goods: tempGoods };
      setResult(tempResult);
    },
    [result]
  );

  const deleteCurrencies = useCallback(
    (index: number) => {
      const tempCurrencies = [...result.currencies];
      tempCurrencies.splice(index, 1);
      const tempResult = { ...result, currencies: tempCurrencies };
      setResult(tempResult);
    },
    [result]
  );

  const deletePets = useCallback(
    (index: number) => {
      const tempPets = [...result.pets];
      tempPets.splice(index, 1);
      const tempResult = { ...result, pets: tempPets };
      setResult(tempResult);
    },
    [result]
  );

  const deleteFurniture = useCallback(
    (index: number) => {
      const tempFurniture = [...result.furniture];
      tempFurniture.splice(index, 1);
      const tempResult = { ...result, furniture: tempFurniture };
      setResult(tempResult);
    },
    [result]
  );

  const filterType = watchFilter("filterType", initialResult.filterType);

  const [getPlayersCount, { isLoading: isLoadingGetPlayersCount }] =
    useGetPlayersCountMutation();

  const [filteredPlayersCount, setFilteredPlayersCount] = useState(0);

  useEffect(() => {
    if (result.filter.length > 0) {
      switch (result.filterType) {
        case DistributionSystemFilterType.playerEmails:
        case DistributionSystemFilterType.playerIds:
          setFilteredPlayersCount(result.filter.length);

          break;

        case DistributionSystemFilterType.mongoQuery:
          {
            const filter = result.filter as string;

            getPlayersCount({
              __mongoQuery: JSON.parse(filter),
            })
              .unwrap()
              .then((data) => {
                setFilteredPlayersCount(data);
              });
          }
          break;

        default:
          break;
      }
    }
  }, [getPlayersCount, result.filter, result.filterType]);

  const placeholderFilter = useMemo(() => {
    switch (filterType) {
      case DistributionSystemFilterType.mongoQuery:
        return t("distributionSystem.placeholderFilterMongo");
      case DistributionSystemFilterType.playerEmails:
        return t("distributionSystem.placeholderFilterEmail");
      case DistributionSystemFilterType.playerIds:
        return t("distributionSystem.placeholderFilterId");
      default:
        break;
    }
  }, [filterType, t]);

  const validateFilter = useCallback(
    (value) => {
      if (filterType === DistributionSystemFilterType.mongoQuery) {
        try {
          JSON.parse(value);
        } catch (e) {
          return t("distributionSystem.errorInvalidJSON");
        }
      } else {
        return true;
      }
    },
    [filterType, t]
  );

  const renderResultFilter = useMemo(() => {
    switch (result.filterType) {
      case DistributionSystemFilterType.playerEmails:
      case DistributionSystemFilterType.playerIds: {
        const filter = result.filter as string[];

        return filter.length > 0 ? (
          <Stack
            sx={{
              maxHeight: 350,
              overflowY: "auto",
              minWidth: "21rem",
              p: 1,
              border: "1px solid",
              borderColor: "action.disabled",
              borderRadius: "4px",
            }}
          >
            {filter.map((item, idx) => (
              <h3 key={idx} style={{ margin: 0 }}>
                {item}
              </h3>
            ))}
          </Stack>
        ) : null;
      }
      case DistributionSystemFilterType.mongoQuery: {
        const filter = result.filter as string;

        return filter.length > 0 ? (
          <Stack
            sx={{
              direction: "row",
              minWidth: "21rem",
              maxWidth: "30rem",
              p: 1,
              border: "1px solid",
              borderColor: "action.disabled",
              borderRadius: "4px",
            }}
            spacing={2}
          >
            <h3 style={{ margin: 0 }}>{filter}</h3>
          </Stack>
        ) : null;
      }
      default:
        return null;
    }
  }, [result.filter, result.filterType]);

  return (
    <Box sx={{ width: "100%", backgroundColor: "white" }}>
      <FormProvider {...formMethodsVirtualGoods}>
        {vGoodShortCodes?.length > 0 && (
          <EventGoodItem
            name="goods"
            setResult={setResult}
            result={result}
            setHasError={setHasError}
            valueList={vGoodShortCodes}
          />
        )}
      </FormProvider>

      <FormProvider {...formMethodsCurrency}>
        <EventGoodItem
          name="currencies"
          setResult={setResult}
          result={result}
          setHasError={setHasError}
          valueList={currencyList}
        />
      </FormProvider>

      {/* <FormProvider {...formMethodsPets}>
        <EventGoodItem
          name="pets"
          setResult={setResult}
          result={result}
          setHasError={setHasError}
          valueList={petList}
        />
      </FormProvider>

      <FormProvider {...formMethodsFurniture}>
        <EventGoodItem
          name="furniture"
          setResult={setResult}
          result={result}
          setHasError={setHasError}
          valueList={furnitureList}
        />
      </FormProvider> */}

      {!inGameResponseMode && (
        <form onSubmit={handleSubmitFilter(onSubmitFilter)}>
          <Stack my={3}>
            <h3 style={{ margin: 0 }}>
              {t("distributionSystem.fieldFilter")}:
            </h3>
            <Controller
              rules={{ required: true }}
              control={controlFilter}
              name="filterType"
              render={({ field }) => (
                <RadioGroup row {...field}>
                  <FormControlLabel
                    sx={{ ml: 0 }}
                    value={DistributionSystemFilterType.mongoQuery}
                    control={<Radio size="small" sx={{ pl: 0 }} />}
                    label={t("distributionSystem.radioMongoLabel")}
                  />
                  <FormControlLabel
                    sx={{ ml: 0 }}
                    value={DistributionSystemFilterType.playerIds}
                    control={<Radio size="small" />}
                    label={t("distributionSystem.radioPlayerIdsLabel")}
                  />
                  <FormControlLabel
                    sx={{ ml: 0 }}
                    value={DistributionSystemFilterType.playerEmails}
                    control={<Radio size="small" />}
                    label={t("distributionSystem.radioPlayerEmailsLabel")}
                  />
                </RadioGroup>
              )}
            />
            <Controller
              control={controlFilter}
              name="filterData"
              rules={{
                required: "field cannot be empty",
                validate: validateFilter,
              }}
              defaultValue=""
              render={({ field, fieldState: { invalid, error } }) => (
                <Stack>
                  <div>
                    <TextField
                      size="small"
                      multiline
                      minRows={6}
                      maxRows={15}
                      variant="outlined"
                      error={invalid}
                      placeholder={placeholderFilter}
                      sx={{
                        "& textarea": {
                          resize: "both",
                          width: 435,
                        },
                      }}
                      {...field}
                    />
                  </div>
                  <ErrorBox>{error?.message}</ErrorBox>
                </Stack>
              )}
            />
            <Box>
              <Button type="submit" variant="contained">
                {t("buttonNames.setFilter")}
              </Button>
              <ErrorBox></ErrorBox>
            </Box>
          </Stack>
        </form>
      )}

      <h3 style={{ margin: 0, marginTop: "16px" }}>
        {t("distributionSystem.resultVirtualGoods")}
      </h3>
      <EventChip result={goodsChip} deleteItem={deleteVGood} />

      <h3 style={{ margin: 0 }}>{t("distributionSystem.resultCurrencies")}</h3>
      <EventChip result={currenciesChip} deleteItem={deleteCurrencies} />

      <h3 style={{ margin: 0 }}>{t("distributionSystem.resultPets")}</h3>
      <EventChip result={petsChip} deleteItem={deletePets} />

      <h3 style={{ margin: 0 }}>{t("distributionSystem.resultFurniture")}</h3>
      <EventChip result={furnitureChip} deleteItem={deleteFurniture} />

      {!inGameResponseMode && (
        <Stack direction="row" spacing={3}>
          <h3 style={{ margin: 0 }}>{t("distributionSystem.resultFilter")}</h3>
          <Stack
            direction="row"
            alignItems="flex-start"
            color="gray"
            spacing={2}
          >
            {renderResultFilter}
            {renderResultFilter &&
              (isLoadingGetPlayersCount ? (
                <Circular />
              ) : (
                <Typography variant="subtitle1" color="info.main">
                  {`${t(
                    "distributionSystem.selected"
                  )} ${filteredPlayersCount} ${t(
                    "distributionSystem.players"
                  )}`}
                </Typography>
              ))}
          </Stack>
        </Stack>
      )}

      {!inGameResponseMode && (
        <Stack direction="row" alignItems="center" my={4} spacing={2}>
          <h3 style={{ margin: 0 }}>
            {t("distributionSystem.fieldExecutionTime")}:
          </h3>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              renderInput={(params) => <TextField size="small" {...params} />}
              label={t("distributionSystem.fieldExecutionTimeLabel")}
              value={result.executionTimeUTC}
              ampm={false}
              minDateTime={dayjs()
                .utc()
                .add(9, "hours")
                .add(new Date().getTimezoneOffset() - 1, "minutes")}
              inputFormat={DATE_MASK_DEFAULT}
              mask="____/__/__ __:__"
              disablePast
              onChange={handleChangeExecutionTimeUTC}
            />
          </LocalizationProvider>
        </Stack>
      )}

      {!inGameResponseMode && (
        <>
          <Stack direction="row" alignItems="center" mt={4} mb={1}>
            <Controller
              control={controlMail}
              name="sendMailNotification"
              render={({ field: { value, ...field } }) => (
                <Checkbox sx={{ p: 0, mr: 1 }} checked={value} {...field} />
              )}
            />
            <h3 style={{ margin: 0 }}>
              {t("distributionSystem.sendMailNotification")}
            </h3>
          </Stack>
          <Stack direction="row" alignItems="center" mb={1}>
            <Controller
              control={controlIsMassMail}
              name="isMassMail"
              render={({ field: { value, ...field } }) => (
                <Switch
                  {...field}
                  checked={value}
                  sx={{ ml: -1 }}
                  disabled={!result.sendMailNotification}
                />
              )}
            />
            <h3
              style={{
                margin: 0,
                color: !result.sendMailNotification
                  ? "rgba(0, 0, 0, 0.38)"
                  : "inherit",
              }}
            >
              {t("distributionSystem.isMassMail")}
            </h3>
          </Stack>
        </>
      )}

      <Grid
        container
        spacing={2}
        maxWidth={900}
        sx={{ mt: inGameResponseMode && 0 }}
      >
        <Grid item xs={5}>
          <h3
            style={{
              margin: 0,
              color: !result.sendMailNotification
                ? "rgba(0, 0, 0, 0.38)"
                : "inherit",
            }}
          >
            {t("distributionSystem.mailHeader")}
          </h3>
          <Controller
            control={controlMail}
            name="mailHeader"
            defaultValue=""
            render={({ field }) => (
              <TextField
                size="small"
                variant="outlined"
                fullWidth
                multiline
                rows={6}
                disabled={!result.sendMailNotification}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={7}>
          <h3
            style={{
              margin: 0,
              color: !result.sendMailNotification
                ? "rgba(0, 0, 0, 0.38)"
                : "inherit",
            }}
          >
            {t("distributionSystem.mailContent")}
          </h3>
          <Controller
            control={controlMail}
            name="mailContent"
            defaultValue=""
            render={({ field }) => (
              <TextField
                size="small"
                variant="outlined"
                fullWidth
                multiline
                rows={6}
                disabled={!result.sendMailNotification}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={7}>
          <h3
            style={{
              margin: 0,
              color: !result.sendMailNotification
                ? "rgba(0, 0, 0, 0.38)"
                : "inherit",
            }}
          >
            {t("distributionSystem.webUrl") +
              " " +
              "(You can use pId value like: https://mydomain.com/${pId}. It will be auto-replacement with the player's Id if it was added.)"}
          </h3>
          <Controller
            control={controlMail}
            name="webUrl"
            defaultValue=""
            render={({ field }) => (
              <TextField
                size="small"
                variant="outlined"
                fullWidth
                multiline
                rows={6}
                disabled={!result.sendMailNotification}
                {...field}
              />
            )}
          />
        </Grid>
      </Grid>

      {!inGameResponseMode && (
        <Stack direction="row" alignItems="center" mt={4} mb={1}>
          <Controller
            control={controlPush}
            name="sendPushNotification"
            defaultValue={false}
            render={({ field: { value, ...field } }) => (
              <Checkbox sx={{ p: 0, mr: 1 }} checked={value} {...field} />
            )}
          />
          <h3 style={{ margin: 0 }}>
            {t("distributionSystem.sendPushNotification")}
          </h3>
        </Stack>
      )}

      {!inGameResponseMode && (
        <Grid container spacing={2} maxWidth={900}>
          <Grid item xs={5}>
            <h3
              style={{
                margin: 0,
                color: !result.sendPushNotification
                  ? "rgba(0, 0, 0, 0.38)"
                  : "initial",
              }}
            >
              {t("distributionSystem.pushTitle")}
            </h3>
            <Controller
              control={controlPush}
              name="pushTitle"
              defaultValue=""
              render={({ field }) => (
                <TextField
                  size="small"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={6}
                  disabled={!result.sendPushNotification}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={7}>
            <h3
              style={{
                margin: 0,
                color: !result.sendPushNotification
                  ? "rgba(0, 0, 0, 0.38)"
                  : "initial",
              }}
            >
              {t("distributionSystem.pushMessage")}
            </h3>
            <Controller
              control={controlPush}
              name="pushMessage"
              defaultValue=""
              render={({ field }) => (
                <TextField
                  size="small"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={6}
                  disabled={!result.sendPushNotification}
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
      )}

      <Stack direction="row" alignItems="flex-start" pb={3} pt={2}>
        <Stack direction="row" alignItems="center">
          <Button variant="contained" onClick={onSend}>
            {inGameResponseMode
              ? t(`buttonNames.${TimerBtn ? "sendNow" : "send"}`)
              : t("buttonNames.create")}
          </Button>
          {TimerBtn}
        </Stack>
        <Box sx={{ color: "red", pl: 2, fontSize: "0.9rem" }}>{hasError}</Box>
      </Stack>
    </Box>
  );
};
