import React, { memo } from "react";
import { SpinnerCircular, SpinnerCircularProps } from "spinners-react";

export const Circular = memo<SpinnerCircularProps>(
  ({
    size = 25,
    thickness = 175,
    speed = 100,
    color = "#36ad47",
    secondaryColor = "#e8e6e6",
    ...props
  }) => (
    <SpinnerCircular
      size={size}
      thickness={thickness}
      speed={speed}
      color={color}
      secondaryColor={secondaryColor}
      {...props}
    />
  )
);
