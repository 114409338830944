import React, { useCallback } from "react";
import { Box, Container, Grid, styled } from "@mui/material";

import { Helmet } from "react-helmet";
import { WideDrawer } from "../../components/WideDrawerForm/WideDrawer";
import { Chats } from "./Chats";
import TeamMembers from "./TeamMembers";
import { Edit } from "../../components/Edit/Edit";
import { DataTable } from "./DataTable";
import OpenEditBtn from "../../components/OpenEditBtn";

import { useTranslation } from "react-i18next";
import { useTableUrlTools } from "../../utils/useTableUrlTools";
import { useTitle } from "../../utils/useTitle";
import {
  useGetGroupManagementQuery,
  useEditGroupMutation,
} from "../../slices/groupManagement/api";
import { GroupManagementSearchFieldsBack } from "./searchScheme";
import { ColumnData } from "../../components/CustomDataGrid/types";
import { GroupDataType } from "./DataTableTypes";
import { COLLECTION_NAMES, DB_NAMES, ROUTE_PATHS } from "../../consts";

const dataTableColumns: ColumnData<GroupDataType>[] = [
  {
    width: 100,
    label: "N",
    dataKey: "id",
    renderCell: (rowData, rowIndex: number) => rowIndex + 1,
  },
  {
    width: 200,
    label: "Name",
    dataKey: "teamName",
    renderCell: ({ teamName }) => teamName,
  },
  {
    width: 120,
    label: "Level",
    dataKey: "teamLevel",
    renderCell: ({ teamLevel }) => teamLevel,
  },
  {
    width: 120,
    label: "Quests done",
    dataKey: "questsCompleted",
    renderCell: ({ questsCompleted }) => questsCompleted,
  },
  {
    width: 180,
    label: "Average player level",
    dataKey: "playerAverageLevel",
    renderCell: ({ playerAverageLevel }) => playerAverageLevel,
  },
  {
    width: 200,
    label: "Last active date",
    dataKey: "lastActiveDate",
    renderCell: ({ lastActiveDate }) => lastActiveDate,
  },
  {
    width: 150,
    label: "Members",
    dataKey: "membersCount",
    renderCell: ({ membersCount }) => membersCount,
  },
  {
    width: 120,
    label: "Action",
    dataKey: "action",
    renderCell: ({ _id }) => (
      <OpenEditBtn id={_id} routePath={ROUTE_PATHS.GROUP_MANAGEMENT} />
    ),
  },
];

const searchFieldsFront = ["teamName", "id", "teamLevel"];
const collectionName = COLLECTION_NAMES.TEAM_DATA;
const dbName = DB_NAMES.RUNTIME;

const requiredFields = [
  "teamName",
  "teamLevel",
  "questsCompleted",
  "playerAverageLevel",
  "lastActive",
  "members",
  "owner",
];

const GroupManagement = () => {
  const { t } = useTranslation();
  const title = useTitle(t("groupManagement.title"));
  const editVirtualGoodsTitle = useTitle(
    t("groupManagement.editGroupManagement")
  );

  const { id, detailsDrawerOpen, closeDrawer } = useTableUrlTools(
    ROUTE_PATHS.GROUP_MANAGEMENT
  );
  const handleCloseDrawer = useCallback(
    () => closeDrawer(ROUTE_PATHS.GROUP_MANAGEMENT),
    [closeDrawer]
  );
  const getQueryData = useGetGroupManagementQuery;

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <CustomContainer maxWidth="xl">
        <DataTable
          searchFieldsFront={searchFieldsFront}
          searchFieldsBack={GroupManagementSearchFieldsBack}
          getQueryData={getQueryData}
          collectionName={collectionName}
          dbName={dbName}
          columns={dataTableColumns}
          errorMessageEntity="groupManagement"
          requiredFields={requiredFields}
        />
      </CustomContainer>
      <WideDrawer open={detailsDrawerOpen} close={handleCloseDrawer}>
        <Box m={2.5}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Edit
                id={id}
                collectionName={collectionName}
                dbName={dbName}
                title={editVirtualGoodsTitle}
                getQueryData={getQueryData}
                getEditItemTools={useEditGroupMutation}
                errorsDescriptionPath="groupManagement.errors.edit"
              />
              <TeamMembers
                id={id}
                collectionName={COLLECTION_NAMES.TEAM_DATA}
                dbName={dbName}
                title={t("groupManagement.teamMembersTitle")}
                getQueryData={getQueryData}
              />
            </Grid>
            <Grid item xs={6}>
              <Chats
                id={id}
                collectionName={COLLECTION_NAMES.TEAM_CHAT_HISTORY}
                dbName={dbName}
                title={t("groupManagement.chatHistoryTitle")}
              />
            </Grid>
          </Grid>
        </Box>
      </WideDrawer>
    </>
  );
};

export default GroupManagement;

const CustomContainer = styled(Container)({
  height: "100%",
});
