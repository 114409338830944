import React, { memo, useCallback } from "react";
import dayjs from "dayjs";

import { Box } from "@mui/system";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  TooltipProps,
} from "recharts";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";
import { DataKPIType } from "../../slices/analytics/types";

/*
  See example: https://recharts.org/en-US/examples/SimpleLineChart
  docs for LineChart: https://recharts.org/en-US/api/LineChart
*/

const CustomTooltip = ({
  active,
  payload,
}: TooltipProps<ValueType, NameType>) => {
  const style = {
    padding: "8px",
    backgroundColor: "#fff",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "4px",
    lineHeight: "1.5",
  };

  if (active) {
    const currData = payload?.length ? payload[0].payload : null;

    return (
      <div className="area-chart-tooltip" style={style}>
        <p style={{ margin: 0 }}>
          date:{" "}
          <strong>
            {currData ? dayjs.unix(currData.date).format("YYYY/MM/DD") : " -- "}
          </strong>
        </p>
        <p style={{ margin: 0 }}>
          count:{" "}
          <strong>
            {currData?.count || currData?.count === 0
              ? currData?.count
              : " -- "}
          </strong>
        </p>
      </div>
    );
  }

  return null;
};

const CustomAxisTick = memo(({ x, y, payload }: any) => (
  <g transform={`translate(${x},${y})`}>
    <text
      x={0}
      y={0}
      dy={16}
      textAnchor="end"
      fill="#666"
      fontSize={12}
      transform="rotate(-25)"
    >
      {dayjs.unix(payload.value).format("YYYY/MM/DD")}
    </text>
  </g>
));

type LineChartProps = {
  data: DataKPIType[];
};

const CustomLineChart = ({ data }: LineChartProps) => {
  const dateFormatter = useCallback(
    (date: number) => dayjs.unix(date).format("YYYY/MM/DD"),
    []
  );

  return (
    <Box height="550px" my={3}>
      <ResponsiveContainer>
        <LineChart
          data={data}
          margin={{
            top: 20,
            right: 40,
            left: 0,
            bottom: 40,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="date"
            scale="time"
            type="number"
            tickFormatter={dateFormatter}
            tick={<CustomAxisTick />}
            domain={["dataMin", "auto"]}
          />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Line
            type="monotone"
            dataKey="count"
            stroke="#1976d2"
            strokeWidth={2}
            animationDuration={1000}
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default memo(CustomLineChart);
