import React, { useCallback, useMemo, useState } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import StarIcon from "@mui/icons-material/Star";
import { Button, IconButton, Link, Stack } from "@mui/material";
import TeamMemberAlert from "./TeamMemberAlert";

import useWebsocket from "../../utils/useWebsocket";

import { MemberType } from "../../slices/groupManagement/types";
import { WebsocketActionType } from "../../slices/Websocket/types";
import { ROUTE_PATHS } from "../../consts";

export type TeamMembersFieldsType = {
  data: MemberType[];
  teamId: any;
  refetch: any;
};

export const TeamMembersFields = ({
  data,
  teamId,
  refetch,
}: TeamMembersFieldsType) => {
  // const { t } = useTranslation();
  const { sendMessage } = useWebsocket();

  const [showTable, setShowTable] = useState(false);
  const [openDeleteUserId, setOpenDeleteUserId] = useState<null | string>(null);
  const [openNewLeaderId, setOpenNewLeaderId] = useState<null | string>(null);

  const removeTeamMember = useCallback((id: string) => {
    setOpenDeleteUserId(id);
  }, []);

  const newLeader = useCallback((id: string) => {
    setOpenNewLeaderId(id);
  }, []);

  const makeNewLeader = useCallback(() => {
    const processedMsg = {
      action: WebsocketActionType.changeTeamOwner,
      inputParams: JSON.stringify({
        teamID: teamId,
        playerID: openNewLeaderId,
      }),
    };

    sendMessage(processedMsg);
    setOpenNewLeaderId(null);
    refetch();
  }, [openNewLeaderId, refetch, sendMessage, teamId]);

  const deletePlayer = useCallback(() => {
    const processedMsg = {
      action: WebsocketActionType.kickTeamMember,
      inputParams: JSON.stringify({
        playerIDForKicking: openDeleteUserId,
        teamID: teamId,
      }),
    };

    sendMessage(processedMsg);
    setOpenDeleteUserId(null);
  }, [openDeleteUserId, sendMessage, teamId]);

  const handleNegative = useCallback(() => {
    setOpenDeleteUserId(null);
    setOpenNewLeaderId(null);
  }, []);

  const handleClickShowMembers = useCallback(() => {
    setShowTable((prev) => !prev);
  }, []);

  const rows = useMemo(
    () =>
      data.map((row, index: number) => (
        <TableRow
          key={index}
          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        >
          <TableCell component="th" scope="row">
            <Link
              target="_blank"
              href={`${ROUTE_PATHS.PLAYERS}/${row.playerID}/edit`}
              style={{ textDecoration: "none" }}
            >
              {row.name}
            </Link>
          </TableCell>
          <TableCell>{row.level}</TableCell>
          <TableCell>{row.playerID}</TableCell>
          <TableCell>
            <IconButton
              onClick={() => removeTeamMember(row.playerID)}
              color="error"
            >
              <PersonRemoveIcon />
            </IconButton>
          </TableCell>
          <TableCell>
            {index !== 0 && (
              <IconButton
                onClick={() => newLeader(row.playerID)}
                color="secondary"
              >
                <StarIcon />
              </IconButton>
            )}
            {index === 0 && null}
          </TableCell>
        </TableRow>
      )),
    [data, newLeader, removeTeamMember]
  );

  return (
    <Stack alignItems="flex-start" p={2} spacing={2}>
      <Button onClick={handleClickShowMembers} variant="outlined">
        {showTable ? "Hide Members" : "Show Members"}
      </Button>
      {showTable && (
        <>
          <TableContainer
            component={Paper}
            sx={{
              "&.MuiPaper-elevation": {
                boxShadow: "none",
                border: "1px solid",
                borderColor: "divider",
              },
            }}
          >
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Player Name </TableCell>
                  <TableCell>Player level</TableCell>
                  <TableCell>Player ID</TableCell>
                  <TableCell>Kick player</TableCell>
                  <TableCell>New leader</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{rows}</TableBody>
            </Table>
          </TableContainer>
          <br />
          <TeamMemberAlert
            message={"Are you sure you want to remove this player?"}
            isOpen={!!openDeleteUserId}
            handleClickSave={deletePlayer}
            handleClickCancel={handleNegative}
          />
          <TeamMemberAlert
            message={"Do you want to make this player a new leader?"}
            isOpen={!!openNewLeaderId}
            handleClickSave={makeNewLeader}
            handleClickCancel={handleNegative}
          />
        </>
      )}
    </Stack>
  );
};
