import { useCallback, useEffect } from "react";
import ReconnectingWebSocket from "reconnecting-websocket";
import { WebSocketEventListenerMap } from "reconnecting-websocket/dist/events";

import { useFeedError } from "./feedHooks";
import { useDispatch } from "../store";
import { useGetWebsocketTokenMutation } from "../slices/Websocket/api";
import { setWasWebsocketOpen } from "../slices/Websocket/slice";
import { WEBSOCKET_URL } from "../consts";

let currentWebSocket: ReconnectingWebSocket;

//////////////////// useCreateWebsocket ////////////////////
export const useCreateWebsocket = (): void => {
  const dispatch = useDispatch();
  const [getWsToken] = useGetWebsocketTokenMutation({
    selectFromResult: () => ({}),
  });
  const feedError = useFeedError();

  const urlProvider = useCallback(async () => {
    try {
      const token = await getWsToken().unwrap();

      return `wss://${WEBSOCKET_URL}?Authorizer=${token}`;
    } catch (error: any) {
      const message = error.data?.message;
      feedError(`Websocket doesn't connect, ${message}`);

      return "";
    }
  }, [feedError, getWsToken]);

  const handleOpenWebsocket = useCallback(() => {
    dispatch(setWasWebsocketOpen(true));
  }, [dispatch]);

  useEffect(() => {
    if (!currentWebSocket) {
      currentWebSocket = new ReconnectingWebSocket(urlProvider);

      currentWebSocket.onopen = handleOpenWebsocket;
    }

    return () => currentWebSocket?.close();
  }, [handleOpenWebsocket, urlProvider]);
};

//////////////////// useWebsocket ////////////////////
const useWebsocket = () => {
  const sendMessage = useCallback(<T>(message: T | any): void => {
    message.MessageGroupId = "73-6545224-365";
    message.eventName = message.action;
    message.action = "sqs0WithMongo";
    currentWebSocket?.send(JSON.stringify(message));
  }, []);

  const subscribeOnMessage = useCallback(
    (listener: WebSocketEventListenerMap["message"]): void => {
      currentWebSocket?.addEventListener("message", listener);
    },
    []
  );
  const unsubscribeOnMessage = useCallback(
    (listener: WebSocketEventListenerMap["message"]): void => {
      currentWebSocket?.removeEventListener("message", listener);
    },
    []
  );

  const subscribeOnError = useCallback(
    (listener: WebSocketEventListenerMap["error"]): void => {
      currentWebSocket?.addEventListener("error", listener);
    },
    []
  );
  const unsubscribeOnError = useCallback(
    (listener: WebSocketEventListenerMap["error"]): void => {
      currentWebSocket?.removeEventListener("error", listener);
    },
    []
  );

  const subscribeOnClose = useCallback(
    (listener: WebSocketEventListenerMap["close"]): void => {
      currentWebSocket?.addEventListener("close", listener);
    },
    []
  );
  const unsubscribeOnClose = useCallback(
    (listener: WebSocketEventListenerMap["close"]): void => {
      currentWebSocket?.removeEventListener("close", listener);
    },
    []
  );

  const subscribeOnOpen = useCallback(
    (listener: WebSocketEventListenerMap["open"]): void => {
      currentWebSocket?.addEventListener("open", listener);
    },
    []
  );
  const unsubscribeOnOpen = useCallback(
    (listener: WebSocketEventListenerMap["open"]): void => {
      currentWebSocket?.removeEventListener("open", listener);
    },
    []
  );

  return {
    websocketStatus: currentWebSocket?.readyState,
    sendMessage,
    subscribeOnMessage,
    unsubscribeOnMessage,
    subscribeOnError,
    unsubscribeOnError,
    subscribeOnClose,
    unsubscribeOnClose,
    subscribeOnOpen,
    unsubscribeOnOpen,
  };
};

export default useWebsocket;
