import * as yup from "yup";
import { QueryListName } from "../../../slices/lists/types";
import { DynamicSchema } from "../types";
import { InputType } from "../consts";

export const virtualGoodsSchema: DynamicSchema = {
  _root: {
    type: InputType.root,
    additionalFields: ["propertySet/rarity/rarity"],
    children: {
      tags: {
        type: InputType.arrayOfSimpleValues,
        label: "TAGS",
        placeholder: "Edit TAGS",
        additionalFields: [],
        children: {},
        listName: QueryListName.virtualGoodTags,
      },
      type: {
        type: InputType.text,
        label: "type",
        placeholder: "Edit type",
        additionalFields: [],
        children: {},
        isDisabled: true,
      },
      description: {
        type: InputType.text,
        label: "Description",
        placeholder: "Edit description",
        yupRules: yup.string().min(1),
        additionalFields: [],
        children: {},
      },
      name: {
        type: InputType.text,
        label: "Name",
        placeholder: "Edit name",
        yupRules: yup.string().min(1),
        additionalFields: [],
        children: {},
      },
      shortCode: {
        type: InputType.text,
        label: "Short Code",
        placeholder: "Edit shortCode",
        yupRules: yup.string().min(1),
        additionalFields: [],
        children: {},
      },
      disabled: {
        type: InputType.checkbox,
        label: "isDisabled",
        placeholder: "Edit disabled",
        additionalFields: [],
        children: {},
        isDisabled: false,
      },
      propertySet: {
        type: InputType.link,
        label: "Property Set",
        additionalFields: [],
        children: {
          rarity: {
            type: InputType.link,
            label: "Rarity",
            additionalFields: [],
            children: {
              rarity: {
                type: InputType.numeric,
                label: "Rarity",
                placeholder: "Edit rarity",
                yupRules: yup.number().nullable().optional().min(1).max(5),
                additionalFields: [],
                children: {},
              },
            },
          },
        },
      },
    },
  },
};
