import React from "react";
import { Controller } from "react-hook-form";
import { TextField, MenuItem } from "@mui/material";
import { rewardTopDropDown } from "./selectionData";
import { useTranslation } from "react-i18next";

import {
  CustomRewardsLeaderboardsBox,
  CustomRewardsLeaderboardsBoxWidth,
  CustomTypographyRewardsTop,
  CustomSelect,
  EventErrorBox,
  CustomVerticalHolder,
  CustomLayoutBox,
} from "./EventFormsStyles";

export const Rewards = ({ control }: any) => {
  const { t } = useTranslation();
  return (
    <>
      <h3>{t("events.rewardsGrind")}</h3>
      <div
        style={{
          display: "flex",
          maxWidth: "900px",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <CustomLayoutBox>
          <CustomTypographyRewardsTop></CustomTypographyRewardsTop>
          <Controller
            control={control}
            name="reward1"
            defaultValue={"tickets"}
            render={({ field }) => {
              return (
                <CustomVerticalHolder>
                  <EventErrorBox></EventErrorBox>
                  <CustomSelect {...field}>
                    {rewardTopDropDown.map((line) => (
                      <MenuItem key={line.value} value={line.value}>
                        {line.label}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                </CustomVerticalHolder>
              );
            }}
          />
        </CustomLayoutBox>

        <CustomLayoutBox>
          <CustomTypographyRewardsTop>
            {t("events.awardAmount")}
          </CustomTypographyRewardsTop>
          <Controller
            control={control}
            name="reward1_amount"
            rules={{
              required: "required",
              min: { value: 1, message: t("events.min") },
              max: { value: 10000, message: t("events.max10000") },
            }}
            defaultValue={"1"}
            render={({ field, fieldState: { error } }) => {
              return (
                <CustomVerticalHolder>
                  <EventErrorBox>{error?.message}</EventErrorBox>
                  <TextField
                    type="number"
                    size={"small"}
                    style={{ width: 150 }}
                    variant="outlined"
                    {...field}
                  />
                </CustomVerticalHolder>
              );
            }}
          />
        </CustomLayoutBox>

        <CustomLayoutBox>
          <CustomTypographyRewardsTop style={{ width: "150px" }}>
            Amount Required:
          </CustomTypographyRewardsTop>
          <Controller
            control={control}
            rules={{
              required: "required",
              min: { value: 1, message: t("events.min") },
              max: { value: 10000, message: t("events.max10000") },
            }}
            defaultValue={"20"}
            name="reward1_required"
            render={({ field, fieldState: { error } }) => {
              return (
                <div>
                  <EventErrorBox>{error?.message}</EventErrorBox>
                  <TextField
                    type="number"
                    size={"small"}
                    style={{ width: 150 }}
                    variant="outlined"
                    {...field}
                  />
                </div>
              );
            }}
          />
        </CustomLayoutBox>
      </div>

      <h3>Reward Leaderboards</h3>
      <CustomRewardsLeaderboardsBox>
        <CustomTypographyRewardsTop>Top 1:</CustomTypographyRewardsTop>

        <Controller
          control={control}
          name="reward_1"
          defaultValue={"tickets"}
          render={({ field }) => {
            return (
              <div>
                <EventErrorBox></EventErrorBox>
                <CustomSelect {...field}>
                  {rewardTopDropDown.map((line) => (
                    <MenuItem key={line.value} value={line.value}>
                      {line.label}
                    </MenuItem>
                  ))}
                </CustomSelect>
              </div>
            );
          }}
        />

        <CustomTypographyRewardsTop>
          {t("events.awardAmount")}
        </CustomTypographyRewardsTop>
        <Controller
          control={control}
          name="reward_1_amount"
          rules={{
            required: "required",
            min: { value: 1, message: t("events.min") },
            max: { value: 10000, message: t("events.max10000") },
          }}
          defaultValue={"100"}
          render={({ field, fieldState: { error } }) => {
            return (
              <div>
                <EventErrorBox>{error?.message}</EventErrorBox>
                <TextField
                  type="number"
                  size={"small"}
                  style={{ width: 150 }}
                  variant="outlined"
                  {...field}
                />
              </div>
            );
          }}
        />
      </CustomRewardsLeaderboardsBox>

      <CustomRewardsLeaderboardsBox>
        <CustomTypographyRewardsTop>Top 2:</CustomTypographyRewardsTop>
        <Controller
          control={control}
          name="reward_2"
          defaultValue={"tickets"}
          render={({ field }) => {
            return (
              <div>
                <EventErrorBox></EventErrorBox>
                <CustomSelect {...field}>
                  {rewardTopDropDown.map((line) => (
                    <MenuItem key={line.value} value={line.value}>
                      {line.label}
                    </MenuItem>
                  ))}
                </CustomSelect>
              </div>
            );
          }}
        />

        <CustomTypographyRewardsTop>
          {t("events.awardAmount")}
        </CustomTypographyRewardsTop>
        <Controller
          control={control}
          name="reward_2_amount"
          rules={{
            required: "required",
            min: { value: 1, message: t("events.min") },
            max: { value: 10000, message: t("events.max10000") },
          }}
          defaultValue={"50"}
          render={({ field, fieldState: { error } }) => {
            return (
              <div>
                <EventErrorBox>{error?.message}</EventErrorBox>
                <TextField
                  type="number"
                  size={"small"}
                  style={{ width: 150 }}
                  variant="outlined"
                  {...field}
                />
              </div>
            );
          }}
        />
      </CustomRewardsLeaderboardsBox>

      <CustomRewardsLeaderboardsBox>
        <CustomTypographyRewardsTop>Top 3:</CustomTypographyRewardsTop>
        <Controller
          control={control}
          name="reward_3"
          defaultValue={"tickets"}
          render={({ field }) => {
            return (
              <div>
                <EventErrorBox></EventErrorBox>
                <CustomSelect {...field}>
                  {rewardTopDropDown.map((line) => (
                    <MenuItem key={line.value} value={line.value}>
                      {line.label}
                    </MenuItem>
                  ))}
                </CustomSelect>
              </div>
            );
          }}
        />

        <CustomTypographyRewardsTop>
          {t("events.awardAmount")}
        </CustomTypographyRewardsTop>
        <Controller
          control={control}
          defaultValue={"25"}
          name="reward_3_amount"
          rules={{
            required: "required",
            min: { value: 1, message: t("events.min") },
            max: { value: 10000, message: t("events.max10000") },
          }}
          render={({ field, fieldState: { error } }) => {
            return (
              <div>
                <EventErrorBox>{error?.message}</EventErrorBox>
                <TextField
                  type="number"
                  size={"small"}
                  style={{ width: 150 }}
                  variant="outlined"
                  {...field}
                />
              </div>
            );
          }}
        />
      </CustomRewardsLeaderboardsBox>

      <CustomRewardsLeaderboardsBoxWidth>
        <CustomTypographyRewardsTop>Gold</CustomTypographyRewardsTop>
        <Controller
          control={control}
          name="reward_gold"
          defaultValue={"tickets"}
          render={({ field }) => {
            return (
              <div>
                <EventErrorBox></EventErrorBox>
                <CustomSelect {...field}>
                  {rewardTopDropDown.map((line) => (
                    <MenuItem key={line.value} value={line.value}>
                      {line.label}
                    </MenuItem>
                  ))}
                </CustomSelect>
              </div>
            );
          }}
        />

        <CustomTypographyRewardsTop>
          {t("events.awardAmount")}
        </CustomTypographyRewardsTop>
        <Controller
          control={control}
          defaultValue={"15"}
          name="reward_gold_amount"
          rules={{
            required: "required",
            min: { value: 1, message: t("events.min") },
            max: { value: 10000, message: t("events.max10000") },
          }}
          render={({ field, fieldState: { error } }) => {
            return (
              <div>
                <EventErrorBox>{error?.message}</EventErrorBox>
                <TextField
                  type="number"
                  size={"small"}
                  style={{ width: 150 }}
                  variant="outlined"
                  {...field}
                />
              </div>
            );
          }}
        />

        <CustomTypographyRewardsTop>% of Players:</CustomTypographyRewardsTop>
        <Controller
          control={control}
          defaultValue={"4"}
          rules={{
            required: "required",
            min: { value: 1, message: t("events.min") },
            max: { value: 99, message: t("events.max99")},
          }}
          name="reward_gold_percent"
          render={({ field, fieldState: { error } }) => {
            return (
              <div>
                <EventErrorBox>{error?.message}</EventErrorBox>
                <TextField
                  type="number"
                  size={"small"}
                  style={{ width: 150 }}
                  variant="outlined"
                  {...field}
                />
              </div>
            );
          }}
        />
        <CustomTypographyRewardsTop></CustomTypographyRewardsTop>
      </CustomRewardsLeaderboardsBoxWidth>

      <CustomRewardsLeaderboardsBoxWidth>
        <CustomTypographyRewardsTop>Silver</CustomTypographyRewardsTop>
        <Controller
          control={control}
          name="reward_silver"
          defaultValue={"tickets"}
          render={({ field }) => {
            return (
              <div>
                <EventErrorBox></EventErrorBox>
                <CustomSelect {...field}>
                  {rewardTopDropDown.map((line) => (
                    <MenuItem key={line.value} value={line.value}>
                      {line.label}
                    </MenuItem>
                  ))}
                </CustomSelect>
              </div>
            );
          }}
        />

        <CustomTypographyRewardsTop>
          {t("events.awardAmount")}
        </CustomTypographyRewardsTop>
        <Controller
          control={control}
          defaultValue={"10"}
          name="reward_silver_amount"
          rules={{
            required: "required",
            min: { value: 1, message: t("events.min") },
            max: { value: 10000, message: t("events.max10000") },
          }}
          render={({ field, fieldState: { error } }) => {
            return (
              <div>
                <EventErrorBox>{error?.message}</EventErrorBox>
                <TextField
                  type="number"
                  size={"small"}
                  style={{ width: 150 }}
                  variant="outlined"
                  {...field}
                />
              </div>
            );
          }}
        />

        <CustomTypographyRewardsTop>% of Players:</CustomTypographyRewardsTop>
        <Controller
          control={control}
          defaultValue={"15"}
          rules={{
            required: "required",
            min: { value: 1, message: t("events.min") },
            max: { value: 99, message: t("events.max99") },
          }}
          name="reward_silver_percent"
          render={({ field, fieldState: { error } }) => {
            return (
              <div>
                <EventErrorBox>{error?.message}</EventErrorBox>
                <TextField
                  type="number"
                  size={"small"}
                  style={{ width: 150 }}
                  variant="outlined"
                  {...field}
                />
              </div>
            );
          }}
        />
        <CustomTypographyRewardsTop></CustomTypographyRewardsTop>
      </CustomRewardsLeaderboardsBoxWidth>

      <CustomRewardsLeaderboardsBoxWidth>
        <CustomTypographyRewardsTop>Bronze</CustomTypographyRewardsTop>
        <Controller
          control={control}
          name="reward_bronze"
          defaultValue={"tickets"}
          render={({ field }) => {
            return (
              <div>
                <EventErrorBox></EventErrorBox>
                <CustomSelect {...field}>
                  {rewardTopDropDown.map((line) => (
                    <MenuItem key={line.value} value={line.value}>
                      {line.label}
                    </MenuItem>
                  ))}
                </CustomSelect>
              </div>
            );
          }}
        />

        <CustomTypographyRewardsTop>
          {t("events.awardAmount")}
        </CustomTypographyRewardsTop>
        <Controller
          control={control}
          defaultValue={"5"}
          rules={{
            required: "required",
            min: { value: 1, message: t("events.min") },
            max: { value: 10000, message: t("events.max10000") },
          }}
          name="reward_bronze_amount"
          render={({ field, fieldState: { error } }) => {
            return (
              <div>
                <EventErrorBox>{error?.message}</EventErrorBox>
                <TextField
                  type="number"
                  size={"small"}
                  style={{ width: 150 }}
                  variant="outlined"
                  {...field}
                />
              </div>
            );
          }}
        />
        <CustomTypographyRewardsTop>% of Players:</CustomTypographyRewardsTop>

        <Controller
          control={control}
          defaultValue={"60"}
          rules={{
            required: "required",
            min: { value: 1, message: t("events.min") },
            max: { value: 99, message: t("events.max99") },
          }}
          name="reward_bronze_percent"
          render={({ field, fieldState: { error } }) => {
            return (
              <div>
                <EventErrorBox>{error?.message}</EventErrorBox>
                <TextField
                  type="number"
                  size={"small"}
                  style={{ width: 150 }}
                  variant="outlined"
                  {...field}
                />
              </div>
            );
          }}
        />
        <CustomTypographyRewardsTop></CustomTypographyRewardsTop>
      </CustomRewardsLeaderboardsBoxWidth>
    </>
  );
};
