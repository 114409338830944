import * as yup from "yup";
import { DynamicSchema } from "../types";
import { InputType } from "../consts";

const numericYupRule = yup.number().integer().min(0);

export const petPlayerSchema: DynamicSchema = {
  _root: {
    type: InputType.root,
    children: {
      _id: {
        type: InputType.text,
        label: "ID",
        isDisabled: true,
        additionalFields: [],
        children: {},
      },
      currentPet: {
        type: InputType.text,
        label: "currentPet",
        additionalFields: [],
        children: {},
      },
      petLastNumber: {
        type: InputType.numeric,
        label: "petLastNumber",
        yupRules: numericYupRule,
        additionalFields: [],
        children: {},
      },
      petSlots: {
        type: InputType.link,
        label: "petSlots",
        additionalFields: [],
        children: {
          slotsUsed: {
            type: InputType.numeric,
            label: "slotsUsed",
            yupRules: numericYupRule,
            additionalFields: [],
            children: {},
          },
          slotsMax: {
            type: InputType.numeric,
            label: "slotsMax",
            yupRules: numericYupRule,
            additionalFields: [],
            children: {},
          },
          slotsBought: {
            type: InputType.numeric,
            label: "slotsBought",
            yupRules: numericYupRule,
            additionalFields: [],
            children: {},
          },
          slotsEarned: {
            type: InputType.numeric,
            label: "slotsEarned",
            yupRules: numericYupRule,
            additionalFields: [],
            children: {},
          },
        },
      },
    },
  },
};
