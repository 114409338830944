import React from "react";
import {
  Divider,
  Drawer,
  DrawerProps,
  IconButton,
  styled,
  Toolbar,
} from "@mui/material";
import { ChevronRight } from "@mui/icons-material";

type WideDrawerProps = Omit<DrawerProps, "right" | "onClose"> & {
  close: () => void;
};

export const WideDrawer: React.FC<WideDrawerProps> = ({
  children,
  close,
  ...props
}) => {
  return (
    <StyledWideDrawer anchor="right" onClose={close} {...props}>
      <StyledToolbar>
        <IconButton onClick={close}>
          <ChevronRight />
        </IconButton>
      </StyledToolbar>
      <Divider />
      {children}
    </StyledWideDrawer>
  );
};

const StyledWideDrawer = styled(Drawer)(({ theme }) => ({
  width: "50%",
  "& .MuiDrawer-paper": {
    width: "90%",
    // backgroundColor: theme.palette.background.default,
    backgroundColor: "#f7f7f7",
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));
