import React, {
  Dispatch,
  memo,
  SetStateAction,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { stateToHTML } from "draft-js-export-html";
import { v4 as uuid } from "uuid";

import { Box, Stack, Tab, Tabs, Tooltip } from "@mui/material";
import { SpinnerButton } from "../../SpinnerButton";
import { ChatController } from "../ChatController";
import ChatUIEmailDraft from "./ChatUIEmailDraft";
import InternalNotes from "./InternalNotes";

import { useTranslation } from "react-i18next";
import { EditorState } from "draft-js";
import {
  ActionResponse,
  ChatMessageTypes,
  EmailDraftType,
  InternalNoteParamsType,
  TextActionRequest,
} from "../types";
import { WebsocketActionType } from "../../../slices/Websocket/types";
import { CHAT_STATUSES } from "../../../consts";
import InGameResponse from "./InGameResponse";

enum TabsValue {
  emailDraft = "emailDraft",
  notes = "notes",
  inGameResponse = "inGameResponse",
}

const initialEmailDraft: EmailDraftType = {
  body: EditorState.createEmpty(),
  subject: "",
};

type ChatUIInputProps = {
  chatController: ChatController;
  actionRequest: TextActionRequest;
  isMsgSending: boolean;
  defaultEmailSubject: string;
  setInternalNoteParams?: Dispatch<SetStateAction<InternalNoteParamsType>>;
  internalNoteParams?: InternalNoteParamsType;
  handleChangeStatus: () => void;
  playerID: string;
};

const ChatUIInput = ({
  chatController,
  actionRequest,
  isMsgSending,
  defaultEmailSubject,
  setInternalNoteParams,
  internalNoteParams,
  handleChangeStatus,
  playerID,
}: ChatUIInputProps) => {
  const { t } = useTranslation();
  const chatCtl = chatController;

  const [tabIndex, setTabIndex] = useState(TabsValue.emailDraft);
  const [noteValue, setNoteValue] = useState("");
  const [emailDraft, setEmailDraft] = useState(initialEmailDraft);
  const [currentEditorKey, setCurrentEditorKey] = useState(1); // there is no other approach to reset value of mui-rte editor, so it's used this method: https://github.com/niuware/mui-rte/issues/17#issuecomment-531064920

  useEffect(() => {
    setEmailDraft((prevState) => ({
      ...prevState,
      subject: defaultEmailSubject,
    }));
  }, [defaultEmailSubject]);

  const handleSendClick = useCallback((): void => {
    const res: ActionResponse = {
      type: ChatMessageTypes.TEXT,
      value: noteValue,
      msgActionType: WebsocketActionType.internalNoteFeedbackAdmin,
      uuid: uuid(),
    };

    switch (tabIndex) {
      case TabsValue.emailDraft:
        {
          res.value = stateToHTML(emailDraft.body.getCurrentContent());
          res.msgActionType = WebsocketActionType.replyFeedbackEmailAdmin;
          res.subject = emailDraft.subject;

          setEmailDraft(initialEmailDraft);
          setCurrentEditorKey((prev) => prev + 1);

          chatCtl.setActionResponse(actionRequest, res);
        }
        break;

      case TabsValue.notes:
        {
          if (
            internalNoteParams.chatStatusValue !==
            internalNoteParams.initialChatStatusValue
          ) {
            handleChangeStatus();
          }
          if (noteValue.length > 0) {
            chatCtl.setActionResponse(actionRequest, res);
            setNoteValue("");
          }
        }

        break;
      case TabsValue.inGameResponse:
        {
          console.log(111);
        }
        break;
      default:
        break;
    }
  }, [
    actionRequest,
    chatCtl,
    emailDraft.body,
    emailDraft.subject,
    handleChangeStatus,
    internalNoteParams.chatStatusValue,
    internalNoteParams.initialChatStatusValue,
    noteValue,
    tabIndex,
  ]);

  const handleChangeTab = useCallback(
    (event: SyntheticEvent, newValue: TabsValue) => {
      setTabIndex(newValue);
    },
    []
  );

  const handleChangeEmailSubject = useCallback((e) => {
    setEmailDraft((prevState) => ({
      ...prevState,
      subject: e?.target?.value,
    }));
  }, []);
  const handleChangeEmailBody = useCallback((state: EditorState) => {
    setEmailDraft((prevState) => ({
      ...prevState,
      body: state,
    }));
  }, []);
  const isBtnSendDisabled =
    tabIndex === TabsValue.notes &&
    noteValue.length < 1 &&
    (internalNoteParams.chatStatusValue ===
      internalNoteParams.initialChatStatusValue ||
      internalNoteParams.chatStatusValue === CHAT_STATUSES.waitingTech);

  const tooltipMsg = useMemo(() => {
    let msg = "";

    if (tabIndex === TabsValue.notes && noteValue.length < 1) {
      if (
        internalNoteParams.chatStatusValue ===
        internalNoteParams.initialChatStatusValue
      ) {
        msg = t("supportChats.msgSameStatus");
      } else if (
        internalNoteParams.chatStatusValue === CHAT_STATUSES.waitingTech
      ) {
        msg = t("supportChats.msgStatusWaitingTech");
      }
    }

    return msg;
  }, [
    internalNoteParams.chatStatusValue,
    internalNoteParams.initialChatStatusValue,
    noteValue.length,
    t,
    tabIndex,
  ]);

  return (
    <Stack
      sx={{
        mt: 2,
        p: 2,
        mb: 10,
        bgcolor: "background.default",
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tabIndex}
          onChange={handleChangeTab}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab
            label={t("supportChats.labelEmailEditor")}
            value={TabsValue.emailDraft}
          />
          <Tab
            label={t("supportChats.labelNoteEditor")}
            value={TabsValue.notes}
          />
          <Tab
            label={t("supportChats.labelInGameResponse")}
            value={TabsValue.inGameResponse}
          />
        </Tabs>
      </Box>
      <Stack alignItems="flex-start" mt={3} spacing={2}>
        {tabIndex === TabsValue.emailDraft && (
          <ChatUIEmailDraft
            editorKey={currentEditorKey}
            subject={emailDraft.subject}
            handleChangeEmailSubject={handleChangeEmailSubject}
            handleChangeEmailBody={handleChangeEmailBody}
          />
        )}
        {tabIndex === TabsValue.notes && (
          <InternalNotes
            internalNoteParams={internalNoteParams}
            setInternalNoteParams={setInternalNoteParams}
            setNoteValue={setNoteValue}
            noteValue={noteValue}
          />
        )}
        {tabIndex === TabsValue.inGameResponse && (
          <InGameResponse playerID={playerID} />
        )}
        {tabIndex !== TabsValue.inGameResponse && (
          <Tooltip title={tooltipMsg} arrow>
            <Box>
              <SpinnerButton
                type="button"
                variant="contained"
                buttonName={t("buttonNames.send")}
                loading={
                  isMsgSending || internalNoteParams.isChatStatusChanging
                }
                disabled={isBtnSendDisabled}
                onClick={handleSendClick}
              />
            </Box>
          </Tooltip>
        )}
      </Stack>
    </Stack>
  );
};

export default memo(ChatUIInput);
