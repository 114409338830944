import React, { memo } from "react";
import { useTranslation } from "react-i18next";

import { SpinnerButton } from "./SpinnerButton";

export type SubmitSpinnerButtonProps = {
  loading?: boolean;
};

export const SubmitSpinnerButton = memo<SubmitSpinnerButtonProps>(
  ({ loading }) => {
    const { t } = useTranslation();

    return (
      <SpinnerButton loading={loading} buttonName={t("submit")} type="submit" />
    );
  }
);
