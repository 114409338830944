import React, { memo, useCallback, useEffect, useMemo, useState } from "react";

import { Box } from "@mui/system";
import { Stack, Typography } from "@mui/material";
import ControlledInputText from "../../components/inputs/ControlledInputText";
import ControlledInputSelect from "../../components/inputs/ControlledInputSelect";
import ControlledInputChips from "../../components/inputs/ControlledInputChips";
import ControlledCheckbox from "../../components/inputs/ControlledCheckbox";
import RewardItem from "./RewardItem";
import { CustomErrorBox } from "../../components/inputs/StyledComponents";
import { SpinnerButton } from "../../components/SpinnerButton";

import { useTranslation } from "react-i18next";
import { useFeedError, useFeedSuccess } from "../../utils/feedHooks";
import { useForm, useWatch } from "react-hook-form";
import { useCreateQuestMutation } from "../../slices/quests/api";
import { useGetListQuery } from "../../slices/lists/api";
import { CreateQuestFormValues } from "./types";
import { QueryListName, StaticListName } from "../../slices/lists/types";
import { rarityDropDownData } from "../../components/inputs/consts";
import { createQuestInitialValues, initialCustomDisabled } from "./helpers";
import { ArrayItemType } from "../../components/DynamicForm/consts";

const CreateQuest = () => {
  const { t } = useTranslation();
  const feedError = useFeedError();
  const feedSuccess = useFeedSuccess();

  const {
    control,
    handleSubmit,
    resetField,
    reset,
    setError,
    clearErrors,
    formState: { isSubmitSuccessful, isSubmitted },
  } = useForm<CreateQuestFormValues>({
    defaultValues: createQuestInitialValues,
  });

  const [addQuest, { isLoading: isAddQuestLoading }] = useCreateQuestMutation();
  const { data: sceneTypes, isLoading: isLoadingSceneTypes } = useGetListQuery(
    QueryListName.sceneType
  );

  const sceneTypesData = useMemo(
    () =>
      sceneTypes?.map((item) => ({
        value: item,
        label: item,
      })) || [],
    [sceneTypes]
  );

  const [hasErrors, setHasErrors] = useState(false);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  const createQuest = useCallback(
    async (data: CreateQuestFormValues) => {
      const {
        questRequirementTags_1,
        questRequirementRarity_1,
        questRequirementCustom_1,
        questRewardsTags_1,
        questRewardsRarity_1,
        questRewardsCustom_1,
      } = data;

      if (
        !questRequirementTags_1 &&
        !questRequirementRarity_1 &&
        !questRequirementCustom_1
      ) {
        setError("questRequirementTags_1", {
          type: "required",
          message: t("errors.inputRequiredMsg"),
        });
        setHasErrors(true);
      } else if (
        !questRewardsTags_1 &&
        !questRewardsRarity_1 &&
        !questRewardsCustom_1
      ) {
        setError("questRewardsTags_1", {
          type: "required",
          message: t("errors.inputRequiredMsg"),
        });
        setHasErrors(true);
      } else {
        setHasErrors(false);

        try {
          const result = await addQuest(data).unwrap();

          if (result) {
            feedSuccess(t("createQuest.successMsgAfterSubmit"));
          } else {
            feedError(t("createQuest.errorMsgAfterSubmit"));
          }
        } catch (error) {
          feedError(t("createQuest.errorMsgAfterSubmit"));
        }
      }
    },
    [addQuest, feedError, feedSuccess, setError, t]
  );

  const onSubmitError = useCallback((errors) => {
    setHasErrors(true);
  }, []);

  const [questRarity, tags, character] = useWatch({
    control,
    name: ["questRarity", "tags", "character"],
  });
  const [customDisabled, setCustomDisabled] = useState(initialCustomDisabled);

  useEffect(() => {
    const isQuestRarityEmpty = questRarity === null;
    const isTagsEmpty = tags.length < 1;
    const isCharacterEmpty = character.length < 1;

    if (isCharacterEmpty) {
      if (isTagsEmpty && isQuestRarityEmpty) {
        setCustomDisabled(initialCustomDisabled);
      }
      if (!isTagsEmpty || !isQuestRarityEmpty) {
        setCustomDisabled({
          tags: false,
          questRarity: false,
          character: true,
        });
      }
    } else {
      clearErrors(["tags", "questRarity"]);

      setCustomDisabled({
        tags: true,
        questRarity: true,
        character: false,
      });
    }

    if (isSubmitted && isCharacterEmpty) {
      if (isTagsEmpty && isQuestRarityEmpty) {
        setError("tags", {
          type: "required",
          message: t("createQuest.errorMsgTagsRarityCharactersEmpty"),
        });
        setError("character", {
          type: "required",
          message: t("createQuest.errorMsgTagsRarityCharactersEmpty"),
        });
        setError("questRarity", {
          type: "required",
          message: t("createQuest.errorMsgTagsRarityCharactersEmpty"),
        });
      } else if (isTagsEmpty && !isQuestRarityEmpty) {
        setError("tags", {
          type: "required",
          message: t("errors.inputRequiredMsg"),
        });

        clearErrors("character");
      } else if (!isTagsEmpty && isQuestRarityEmpty) {
        setError("questRarity", {
          type: "required",
          message: t("errors.inputRequiredMsg"),
        });

        clearErrors("character");
      }
    }
  }, [
    character.length,
    clearErrors,
    isSubmitted,
    questRarity,
    setError,
    t,
    tags.length,
  ]);

  return (
    <Box
      sx={{
        m: 2,
        p: 2,
        backgroundColor: "white",
      }}
    >
      <form onSubmit={handleSubmit(createQuest, onSubmitError)}>
        <h2>{t("createQuest.title")}</h2>
        <Box mb={3}>
          This part is for backend management. Quest ID is like a shortCode that
          can be referred on characters if they want to use the quest.
          <br />
          Rarity and tags can be used to make generic types of quests that will
          be used by certain characters with same parameters. <br />
          If you select a character, that character will automatically have this
          quest.
          <br /> Probability will be calculated on all the quests that character
          has. So if you want to add a rare one make the probability smaller, so
          the character will only rarely ask for it.
          <br />
          If you want the quest to be unique and not be on anyone else than the
          character. Just set the rarity to 0 and do not add tags.
        </Box>
        <ControlledInputText
          control={control}
          title={t("createQuest.questID")}
          fieldName="questID"
          isRequired
        />
        <ControlledInputSelect
          control={control}
          title={t("createQuest.rarity")}
          fieldName="questRarity"
          dropDownData={rarityDropDownData}
          isDisabled={customDisabled.questRarity}
        />
        <ControlledInputChips
          control={control}
          title={t("createQuest.tags")}
          fieldName="tags"
          listName={StaticListName.characterTags}
          isDisabled={customDisabled.tags}
        />
        <ControlledInputChips
          control={control}
          title={t("createQuest.character")}
          fieldName="character"
          listName={QueryListName.character}
          isDisabled={customDisabled.character}
        />
        <ControlledInputText
          control={control}
          title={t("createQuest.probability")}
          fieldName="probability"
          isNumeric
          isRequired
        />
        <ControlledInputSelect
          control={control}
          title={t("createQuest.sceneType")}
          fieldName="sceneType"
          dropDownData={sceneTypesData}
          isLoadingDropdownData={isLoadingSceneTypes}
        />
        <ControlledCheckbox
          control={control}
          title={t("createQuest.onlySequelTitle")}
          label={t("createQuest.onlySequel")}
          fieldName="questSequel"
        />
        <ControlledInputText
          control={control}
          title={t("createQuest.questType")}
          fieldName="type"
          isRequired
        />
        <ControlledInputText
          control={control}
          title={t("createQuest.questListDescription")}
          fieldName="description"
          isRequired
        />
        <ControlledInputText
          control={control}
          title={t("createQuest.dialogStart")}
          fieldName="start"
          isRequired
        />
        <ControlledInputText
          control={control}
          title={t("createQuest.dialogQuest")}
          fieldName="quest"
          isRequired
        />
        <ControlledInputText
          control={control}
          title={t("createQuest.dialogCompleted")}
          fieldName="completed"
          isRequired
        />
        <ControlledInputText
          control={control}
          title={t("createQuest.dialogAfter")}
          fieldName="questAfter"
        />

        <Typography variant="h6" sx={{ mt: 4, mb: 1 }}>
          {t("createQuest.requirements")}
        </Typography>
        <Box mb={3}>
          First of min and max values will be used in tag and rarity spawns.
          Selecting Tag check box means that spawning will be based on tag. If
          you select tag and rarity, it will be based on both. If you select
          custom, you can select collectibles from a list that will be used.
          Item count is for how many different items will character ask.
        </Box>

        <Stack alignItems="flex-start" spacing={2}>
          {[1, 2, 3].map((numb: 1 | 2 | 3) => (
            <RewardItem
              key={`questRequirement-${numb}`}
              control={control}
              count={numb}
              name={ArrayItemType.questRequirement}
              resetField={resetField}
              setError={setError}
              clearErrors={clearErrors}
            />
          ))}
        </Stack>

        <Typography variant="h6" sx={{ mt: 5, mb: 1 }}>
          {t("createQuest.rewards")}
        </Typography>
        <Stack alignItems="flex-start" spacing={2}>
          {[1, 2, 3].map((numb: 1 | 2 | 3) => (
            <RewardItem
              key={`questRewards-${numb}`}
              control={control}
              count={numb}
              name={ArrayItemType.questRewards}
              resetField={resetField}
              setError={setError}
              clearErrors={clearErrors}
            />
          ))}
        </Stack>

        <Stack direction="row" alignItems="center" spacing={2} sx={{ my: 4 }}>
          <SpinnerButton
            variant="contained"
            buttonName={t("createQuest.submitBtnName")}
            loading={isAddQuestLoading}
          />
          {!isAddQuestLoading && hasErrors && (
            <CustomErrorBox>
              {t("errors.hasErrorsOnSubmitMessage")}
            </CustomErrorBox>
          )}
        </Stack>
      </form>
    </Box>
  );
};

export default memo(CreateQuest);
