import { Box, Typography, styled } from "@mui/material";

export const CustomTypography = styled(Typography)({
  fontSize: "15px",
  fontWeight: "400",
  color: "#5085cc",
  marginRight: "10px",
  marginTop: "-50px",
  backgroundColor: "#f7f7f7",
  padding: "0 10px",
  float: "right",
  borderRadius: "3px",
  borderColor: "1px solid white",
});

export const CurrSchemaNameTypography = styled(Typography)({
  marginLeft: 16,
  marginBottom: 8,
  color: "#26a9f0",
  textTransform: "uppercase",
});

export const CustomBox = styled(Box)({
  padding: "0px 12px 20px 12px",
});

export const ButtonContainer = styled(Box)({
  marginLeft: 8,
  marginTop: 32,
});
