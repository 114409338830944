import React, { Dispatch, SetStateAction, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { TextField } from "@mui/material";
import { Box } from "@mui/system";

type DeletePlayerAlertBodyProps = {
  setAlertBodyValue: Dispatch<SetStateAction<string>>;
  alertBodyValue: string;
  playerName: string | null;
};

const DeletePlayerAlertBody = ({
  setAlertBodyValue,
  alertBodyValue,
  playerName,
}: DeletePlayerAlertBodyProps) => {
  const { t } = useTranslation();

  const onTextChange = useCallback(
    (event) => {
      setAlertBodyValue(event.target.value);
    },
    [setAlertBodyValue]
  );

  return (
    <>
      <Box>
        {t("players.confirmDeleteMsg")} <b>{playerName}</b>
      </Box>
      <TextField
        variant="outlined"
        size="small"
        fullWidth
        label={t("players.playerName")}
        value={alertBodyValue}
        onChange={onTextChange}
        sx={{ mt: 3 }}
      />
    </>
  );
};

export default DeletePlayerAlertBody;
