import React, {
  ChangeEvent,
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
} from "react";

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import { InternalNoteParamsType } from "../types";
import { CHAT_STATUSES } from "../../../consts";

type InternalNotesProps = {
  setInternalNoteParams: Dispatch<SetStateAction<InternalNoteParamsType>>;
  internalNoteParams: InternalNoteParamsType;
  setNoteValue: Dispatch<SetStateAction<string>>;
  noteValue: string;
};

const InternalNotes = ({
  internalNoteParams,
  setInternalNoteParams,
  setNoteValue,
  noteValue,
}: InternalNotesProps) => {
  const { t } = useTranslation();

  const handleChangeSendToSlack = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setInternalNoteParams((prev) => ({
        ...prev,
        sendNotificationToSlack: e.target.checked,
      }));
    },
    [setInternalNoteParams]
  );

  const handleChangeNoteValue = useCallback(
    (e) => {
      setNoteValue(e.target.value);
    },
    [setNoteValue]
  );

  const handleStatusValueChange = useCallback(
    (e: SelectChangeEvent<`${CHAT_STATUSES}`>) => {
      setInternalNoteParams((prev) => ({
        ...prev,
        chatStatusValue: e.target.value as `${CHAT_STATUSES}`,
      }));
    },
    [setInternalNoteParams]
  );

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={3}>
        <FormControl>
          <Select
            value={internalNoteParams.chatStatusValue}
            sx={{
              width: 175,
              height: 37,
            }}
            onChange={handleStatusValueChange}
          >
            <MenuItem value={CHAT_STATUSES.open}>
              {t("supportChats.open")}
            </MenuItem>
            <MenuItem value={CHAT_STATUSES.closed}>
              {t("supportChats.closed")}
            </MenuItem>
            <MenuItem value={CHAT_STATUSES.waitingResponse}>
              {t("supportChats.waitingResponse")}
            </MenuItem>
            <MenuItem value={CHAT_STATUSES.waitingTech}>
              {t("supportChats.waitingTech")}
            </MenuItem>
            <MenuItem value={CHAT_STATUSES.handledInAsana}>
              {t("supportChats.handledInAsana")}
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl>
          <FormControlLabel
            label={
              <Typography variant="subtitle2">
                {t("supportChats.labelSendToSlack")}
              </Typography>
            }
            control={
              <Checkbox
                checked={internalNoteParams.sendNotificationToSlack}
                onChange={handleChangeSendToSlack}
              />
            }
          />
        </FormControl>
      </Stack>
      <TextField
        placeholder={t("supportChats.notePlaceholder")}
        value={noteValue}
        onChange={handleChangeNoteValue}
        minRows={7}
        maxRows={10}
        variant="outlined"
        autoFocus
        multiline
        fullWidth
        sx={{ backgroundColor: "#f7ea90" }}
      />
    </>
  );
};

export default memo(InternalNotes);
