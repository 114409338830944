import React, { useCallback, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Helmet } from "react-helmet";
import { Box, Button, Container, Grid, Stack } from "@mui/material";
import { DataTable } from "../../components/DataTable/DataTable";
import { WideDrawer } from "../../components/WideDrawerForm/WideDrawer";
import { Edit } from "../../components/Edit/Edit";
import CreateQuest from "./CreateQuest";
import { SpinnerButton } from "../../components/SpinnerButton";
import AlertMessage from "../../components/AlertMessage";

import { useTranslation } from "react-i18next";
import { useFeedError, useFeedSuccess } from "../../utils/feedHooks";
import { useTableUrlTools } from "../../utils/useTableUrlTools";
import { useTitle } from "../../utils/useTitle";
import { State, useDispatch } from "../../store";
import {
  useDeleteQuestMutation,
  useEditQuestMutation,
  useGetQuestsQuery,
} from "../../slices/quests/api";
import { setCurrentQuestID } from "../../slices/quests/slice";
import { questsSearchFieldsBack, questTableColumns } from "./helpers";
import { COLLECTION_NAMES, DB_NAMES, ROUTE_PATHS } from "../../consts";

const searchFieldsFront = ["questID"];
const collectionName = COLLECTION_NAMES.QUESTS_DATA;
const dbName = DB_NAMES.META;
const mainRoute = ROUTE_PATHS.QUESTS;
const getQueryData = useGetQuestsQuery;

const Quests = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const title = useTitle(t("quests.title"));
  const editQuestsTitle = useTitle(t("quests.editQuest"));
  const [isAlertShown, setIsAlertShown] = useState(false);

  const [refetchFunc, setRefetchFunc] = useState<null | (() => void)>(null);
  const feedSuccess = useFeedSuccess();
  const feedError = useFeedError();
  const navigate = useNavigate();

  const { id, detailsDrawerOpen, closeDrawer, addDrawerOpen } =
    useTableUrlTools(mainRoute);

  const handleCloseDrawer = useCallback(() => {
    closeDrawer();
  }, [closeDrawer]);
  const handleOpenAlert = useCallback(() => {
    setIsAlertShown(true);
  }, []);
  const [deleteItem, { isLoading: isDeleteLoading }] = useDeleteQuestMutation();

  const handleClickDelete = useCallback(async () => {
    try {
      const result = await deleteItem({
        dbName: DB_NAMES.META,
        collectionName: COLLECTION_NAMES.QUESTS_DATA,
        id,
      }).unwrap();
      if (result) {
        feedSuccess(t("quests.deleteQuestSuccessMsg"));
        navigate("");
        refetchFunc && refetchFunc();
      } else {
        feedError(t("quests.deleteQuestErrorMsg"));
      }
    } catch (error) {
      feedError(t("quests.deleteQuestErrorMsg"));
    }
    setIsAlertShown(false);
  }, [deleteItem, feedError, feedSuccess, id, navigate, refetchFunc, t]);
  const handleOpenEditBtnClick = useCallback(
    ({ playerID: questID }) => {
      dispatch(setCurrentQuestID(questID));
    },
    [dispatch]
  );
  const handleCloseAlert = useCallback(() => {
    setIsAlertShown(false);
  }, []);
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Container maxWidth="xl" sx={{ height: "100%" }}>
        <DataTable
          searchFieldsFront={searchFieldsFront}
          searchFieldsBack={questsSearchFieldsBack}
          getQueryData={getQueryData}
          collectionName={collectionName}
          setRefetchFunc={setRefetchFunc}
          dbName={dbName}
          columns={questTableColumns(handleOpenEditBtnClick)}
          errorMessageEntity="quests"
        />
        <Button
          variant="outlined"
          sx={{ ml: 2, mb: 2 }}
          component={Link}
          to={`${mainRoute}/add`}
        >
          {t("buttonNames.createNewQuest")}
        </Button>
      </Container>
      <WideDrawer open={detailsDrawerOpen} close={handleCloseDrawer}>
        <Box m={2.5}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Stack
                direction="row"
                sx={{
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                  px: 2.5,
                  mb: 2.5,
                }}
              >
                <div />
                <SpinnerButton
                  type="button"
                  buttonName={t("buttonNames.deleteQuest")}
                  loading={isDeleteLoading}
                  variant="outlined"
                  color="error"
                  onClick={handleOpenAlert}
                />
              </Stack>
              <Edit
                id={id}
                collectionName={collectionName}
                dbName={dbName}
                title={editQuestsTitle}
                getQueryData={getQueryData}
                getEditItemTools={useEditQuestMutation}
                errorsDescriptionPath="quests.errors.edit"
              />
            </Grid>
          </Grid>
        </Box>

        <AlertMessage
          isShown={isAlertShown}
          onClickOk={handleClickDelete}
          onCancel={handleCloseAlert}
          title={t("quests.confirmDeleteTitle")}
          textOk={t("buttonNames.deleteQuest")}
          isBtnOkDisabled={false}
        ></AlertMessage>
      </WideDrawer>
      <WideDrawer open={addDrawerOpen} close={handleCloseDrawer}>
        <CreateQuest />
      </WideDrawer>
    </>
  );
};

export const getCurrentQuestID = ({ questsData }: State) =>
  questsData.currentQuestID;

export default Quests;
