import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useForm, Controller } from "react-hook-form";
import {
  Button,
  TextField,
  Box,
  Stack,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
} from "@mui/material";
import { Circular } from "../../components/spinners/Circular";

import { useTranslation } from "react-i18next";
import { useGetPlayersCountMutation } from "../../slices/players/api";
import { ErrorBox } from "./EventFormsStyles";
import {
  FormValues,
  EventFormsTypes,
  Result,
  DeletePlayersFilterType,
} from "./types";

const defaultFilterType: `${DeletePlayersFilterType}` =
  DeletePlayersFilterType.mongoQuery as const;
const initialResult: Result = {
  filter: "",
  filterType: defaultFilterType,
};

export type Item = Record<string, number>;

export const EventForms = ({ onCreate }: EventFormsTypes) => {
  const { t } = useTranslation();
  const [result, setResult] = useState(initialResult);
  const [hasError, setHasError] = useState("");

  const {
    control: controlFilter,
    handleSubmit: handleSubmitFilter,
    resetField: resetFieldFilter,
    watch: watchFilter,
  } = useForm<FormValues>();

  const onSend = useCallback(() => {
    if (result.filter.length > 0) {
      resetFieldFilter("filterData");
      onCreate(result);
      setResult(initialResult);
      setHasError("");
    } else {
      setHasError("Please set the Filter");
    }
  }, [resetFieldFilter, result, onCreate]);

  const onSubmitFilter = useCallback(
    ({ filterData, filterType }: FormValues) => {
      let filter: string | string[] = filterData;

      if (filterType === DeletePlayersFilterType.playerIds) {
        filter = filterData.split("\n").map((item) => item.trim());
      }

      setResult((prevResult) => ({
        ...prevResult,
        filterType,
        filter,
      }));
      setHasError("");
    },
    []
  );

  const [getPlayersCount, { isLoading: isLoadingGetPlayersCount }] =
    useGetPlayersCountMutation();

  const [filteredPlayersCount, setFilteredPlayersCount] = useState(0);

  useEffect(() => {
    if (result.filter.length > 0) {
      switch (result.filterType) {
        case DeletePlayersFilterType.playerIds:
          setFilteredPlayersCount(result.filter.length);

          break;

        case DeletePlayersFilterType.mongoQuery:
          {
            const filter = result.filter as string;

            getPlayersCount({
              __mongoQuery: JSON.parse(filter),
            })
              .unwrap()
              .then((data) => {
                setFilteredPlayersCount(data);
              });
          }
          break;

        default:
          break;
      }
    }
  }, [getPlayersCount, result.filter, result.filterType]);

  const filterType = watchFilter("filterType", defaultFilterType);

  const placeholderFilter = useMemo(() => {
    switch (filterType) {
      case DeletePlayersFilterType.mongoQuery:
        return t("deletePlayers.placeholderFilterMongo");
      case DeletePlayersFilterType.playerIds:
        return t("deletePlayers.placeholderFilterId");
      default:
        break;
    }
  }, [filterType, t]);

  const validateFilter = useCallback(
    (value) => {
      if (filterType === DeletePlayersFilterType.mongoQuery) {
        try {
          JSON.parse(value);
        } catch (e) {
          return t("deletePlayers.errorInvalidJSON");
        }
      } else {
        return true;
      }
    },
    [filterType, t]
  );

  const renderResultFilter = useMemo(() => {
    switch (result.filterType) {
      case DeletePlayersFilterType.playerIds: {
        const filter = result.filter as string[];

        return filter.length > 0 ? (
          <Stack
            sx={{
              maxHeight: 350,
              overflowY: "auto",
              minWidth: "21rem",
              p: 1,
              border: "1px solid",
              borderColor: "action.disabled",
              borderRadius: "4px",
            }}
          >
            {filter.map((item, idx) => (
              <h3 key={idx} style={{ margin: 0 }}>
                {item}
              </h3>
            ))}
          </Stack>
        ) : null;
      }
      case DeletePlayersFilterType.mongoQuery: {
        const filter = result.filter as string;

        return filter.length > 0 ? (
          <Stack
            sx={{
              direction: "row",
              minWidth: "21rem",
              maxWidth: "30rem",
              p: 1,
              border: "1px solid",
              borderColor: "action.disabled",
              borderRadius: "4px",
            }}
            spacing={2}
          >
            <h3 style={{ margin: 0 }}>{filter}</h3>
          </Stack>
        ) : null;
      }
      default:
        return null;
    }
  }, [result.filter, result.filterType]);

  return (
    <Box style={{ backgroundColor: "white" }}>
      <form onSubmit={handleSubmitFilter(onSubmitFilter)}>
        <Stack my={3}>
          <h3 style={{ margin: 0 }}>{t("deletePlayers.fieldFilter")}:</h3>
          <Controller
            rules={{ required: true }}
            control={controlFilter}
            defaultValue={defaultFilterType}
            name="filterType"
            render={({ field }) => (
              <RadioGroup row {...field}>
                <FormControlLabel
                  sx={{ ml: 0 }}
                  value={DeletePlayersFilterType.mongoQuery}
                  control={<Radio size="small" sx={{ pl: 0 }} />}
                  label={t("deletePlayers.radioMongoLabel")}
                />
                <FormControlLabel
                  sx={{ ml: 0 }}
                  value={DeletePlayersFilterType.playerIds}
                  control={<Radio size="small" />}
                  label={t("deletePlayers.radioPlayerIdsLabel")}
                />
              </RadioGroup>
            )}
          />
          <Controller
            control={controlFilter}
            name="filterData"
            rules={{
              required: "field cannot be empty",
              validate: validateFilter,
            }}
            defaultValue=""
            render={({ field, fieldState: { invalid, error } }) => (
              <Stack>
                <div>
                  <TextField
                    size="small"
                    multiline
                    minRows={6}
                    maxRows={15}
                    variant="outlined"
                    error={invalid}
                    placeholder={placeholderFilter}
                    sx={{
                      "& textarea": {
                        resize: "both",
                        width: 435,
                      },
                    }}
                    {...field}
                  />
                </div>
                <ErrorBox>{error?.message}</ErrorBox>
              </Stack>
            )}
          />
          <Box>
            <Button type="submit" variant="contained">
              {t("buttonNames.setFilter")}
            </Button>
            <ErrorBox></ErrorBox>
          </Box>
        </Stack>
      </form>

      <Stack direction="row" spacing={3}>
        <h3 style={{ margin: 0 }}>{t("deletePlayers.resultFilter")}</h3>
        <Stack direction="row" alignItems="flex-start" color="gray" spacing={2}>
          {renderResultFilter}
          {renderResultFilter &&
            (isLoadingGetPlayersCount ? (
              <Circular />
            ) : (
              <Typography variant="subtitle1" color="info.main">
                {`${t("deletePlayers.selected")} ${filteredPlayersCount} ${t(
                  "deletePlayers.players"
                )}`}
              </Typography>
            ))}
        </Stack>
      </Stack>

      <Stack direction="row" alignItems="center" pb={3} pt={2}>
        <Button variant="contained" onClick={onSend}>
          {t("buttonNames.deletePlayers")}
        </Button>
        <Box sx={{ color: "red", pl: 2 }}>{hasError}</Box>
      </Stack>
    </Box>
  );
};
