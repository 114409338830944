import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ROUTE_PATHS } from "../../consts";
import { getUrl, prepareHeaders } from "../../utils/api";
import { DataKPIRequestType, DataKPIType } from "./types";

const {
  ANALYTICS,
  NEW_PLAYERS_KPI,
  DAILY_ACTIVE_PLAYERS_KPI,
  STEPS_KPI,
  WALK_DISTANCE_KPI,
} = ROUTE_PATHS;

export const analyticsApi = createApi({
  reducerPath: "analyticsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: getUrl("/"),
    prepareHeaders,
  }),
  tagTypes: [
    "NewPlayersKPI",
    "DailyActivePlayersKPI",
    "StepsKPI",
    "WalkDistanceKPI",
  ],
  endpoints: (builder) => ({
    getNewPlayersKPI: builder.mutation<DataKPIType[], DataKPIRequestType>({
      query: (body) => {
        return { url: `${ANALYTICS}${NEW_PLAYERS_KPI}`, method: "POST", body };
      },
      invalidatesTags: [{ type: "NewPlayersKPI", id: "LIST" }],
    }),
    getDailyActivePlayersKPI: builder.mutation<
      DataKPIType[],
      DataKPIRequestType
    >({
      query: (body) => {
        return {
          url: `${ANALYTICS}${DAILY_ACTIVE_PLAYERS_KPI}`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: [{ type: "DailyActivePlayersKPI", id: "LIST" }],
    }),
    getStepsKPI: builder.mutation<DataKPIType[], DataKPIRequestType>({
      query: (body) => {
        return {
          url: `${ANALYTICS}${STEPS_KPI}`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: [{ type: "StepsKPI", id: "LIST" }],
    }),
    getWalkDistanceKPI: builder.mutation<DataKPIType[], DataKPIRequestType>({
      query: (body) => {
        return {
          url: `${ANALYTICS}${WALK_DISTANCE_KPI}`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: [{ type: "WalkDistanceKPI", id: "LIST" }],
    }),
  }),
});

export const {
  useGetNewPlayersKPIMutation,
  useGetDailyActivePlayersKPIMutation,
  useGetStepsKPIMutation,
  useGetWalkDistanceKPIMutation,
} = analyticsApi;
