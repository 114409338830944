import React, { useCallback } from "react";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

import { Container, styled } from "@mui/material";
import { Helmet } from "react-helmet";
import { EventForms } from "./EventForms";
import { useTranslation } from "react-i18next";
import { useTitle } from "../../utils/useTitle";
import useWebsocket from "../../utils/useWebsocket";
import { DeletePlayersFilterType, InputParamsType, Result } from "./types";
import { WebsocketActionType } from "../../slices/Websocket/types";

dayjs.extend(timezone);
dayjs.extend(utc);

const DeletePlayers = () => {
  const { t } = useTranslation();
  const title = useTitle(t("deletePlayers.title"));
  const { sendMessage } = useWebsocket();

  const onCreate = useCallback(
    (data: Result) => {
      const inputParams: InputParamsType = {
        filterType: data.filterType,
      };

      if (data.filterType === DeletePlayersFilterType.mongoQuery) {
        inputParams.query = JSON.parse(data.filter as string);
      }

      if (data.filterType === DeletePlayersFilterType.playerIds) {
        inputParams.arrayStringValues = data.filter as string[];
      }

      const processedMsg = {
        action: WebsocketActionType.deletePlayers,
        inputParams: JSON.stringify(inputParams),
      };
      sendMessage(processedMsg);
    },
    [sendMessage]
  );

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <CustomContainer maxWidth="xl">
        <h2 style={{ marginBottom: "0.75rem" }}>{t("deletePlayers.title")}</h2>
        <EventForms onCreate={onCreate} />
      </CustomContainer>
    </>
  );
};

const CustomContainer = styled(Container)({
  height: "100%",
  backgroundColor: "white",
});

export default DeletePlayers;
