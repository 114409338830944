import * as React from "react";
import clsx from "clsx";
import { Theme, styled } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import { CellMeasurer, CellMeasurerCache } from "react-virtualized";
import {
  AutoSizer,
  Column,
  Table,
  TableCellRenderer,
  TableHeaderProps,
} from "react-virtualized";
import { ColumnData } from "../../components/CustomDataGrid/types";

const classes = {
  flexContainer: "ReactVirtualizedDemo-flexContainer",
  tableRow: "ReactVirtualizedDemo-tableRow",
  tableRowHover: "ReactVirtualizedDemo-tableRowHover",
  tableCell: "ReactVirtualizedDemo-tableCell",
  noClick: "ReactVirtualizedDemo-noClick",
};

const cache = new CellMeasurerCache({
  fixedWidth: true,
  minHeight: 40,
});

const styles = ({ theme }: { theme: Theme }) =>
  ({
    // temporary right-to-left patch, waiting for
    // https://github.com/bvaughn/react-virtualized/issues/454
    "& .ReactVirtualized__Table__headerRow": {
      ...(theme.direction === "rtl" && {
        paddingLeft: "0 !important",
      }),
      ...(theme.direction !== "rtl" && {
        paddingRight: undefined,
      }),
    },
    [`& .${classes.flexContainer}`]: {
      display: "flex",
      alignItems: "center",
      boxSizing: "border-box",
    },
    [`& .${classes.tableRow}`]: {
      cursor: "pointer",
      borderBottom: "1px solid lightGray",
    },
    [`& .${classes.tableRowHover}`]: {
      "&:hover": {
        backgroundColor: theme.palette.grey[200],
      },
    },
    [`& .${classes.tableCell}`]: {
      flex: 1,
    },
    [`& .${classes.noClick}`]: {
      cursor: "initial",
    },
  } as const);

interface Row {
  index: number;
}

interface MuiVirtualizedTableProps {
  columns: readonly ColumnData<Data>[];
  headerHeight?: number;
  onRowClick?: () => void;
  rowCount: number;
  rowGetter: (row: Row) => Data;
  rowHeight?: number;
}

class MuiVirtualizedTable extends React.PureComponent<MuiVirtualizedTableProps> {
  static defaultProps = {
    headerHeight: 48,
    rowHeight: 50,
  };

  getRowClassName = ({ index }: Row) => {
    const { onRowClick } = this.props;

    return clsx(classes.tableRow, classes.flexContainer, {
      [classes.tableRowHover]: index !== -1 && onRowClick !== null,
    });
  };

  cellRenderer: TableCellRenderer = ({
    cellData,
    columnIndex,
    rowIndex,
    parent,
  }) => {
    const { columns, onRowClick } = this.props;
    return (
      <CellMeasurer
        key={columnIndex}
        cache={cache}
        columnIndex={columnIndex}
        rowIndex={rowIndex}
        parent={parent}
      >
        <TableCell
          component="div"
          className={clsx(classes.tableCell, classes.flexContainer, {
            [classes.noClick]: onRowClick === null,
          })}
          variant="body"
          align={
            columnIndex !== null && columns[columnIndex].numeric
              ? "right"
              : "left"
          }
        >
          {cellData}
        </TableCell>
      </CellMeasurer>
    );
  };

  headerRenderer = ({ label }: TableHeaderProps & { columnIndex: number }) => {
    const { headerHeight } = this.props;

    return (
      <TableCell
        component="div"
        className={clsx(
          classes.tableCell,
          classes.flexContainer,
          classes.noClick
        )}
        variant="head"
        style={{ height: headerHeight }}
        align="left"
      >
        <span>{label}</span>
      </TableCell>
    );
  };

  render() {
    const { columns, headerHeight, ...tableProps } = this.props;
    return (
      <AutoSizer>
        {({ height, width }) => (
          <Table
            height={height}
            width={width}
            rowHeight={cache.rowHeight}
            gridStyle={{
              direction: "inherit",
            }}
            headerHeight={headerHeight!}
            {...tableProps}
            rowClassName={this.getRowClassName}
          >
            {columns.map(({ dataKey, ...other }, index) => {
              return (
                <Column
                  key={dataKey}
                  headerRenderer={(headerProps) =>
                    this.headerRenderer({
                      ...headerProps,
                      columnIndex: index,
                    })
                  }
                  className={classes.flexContainer}
                  cellRenderer={this.cellRenderer}
                  dataKey={dataKey}
                  {...other}
                />
              );
            })}
          </Table>
        )}
      </AutoSizer>
    );
  }
}

const VirtualizedTable = styled(MuiVirtualizedTable)(styles);

interface Data {
  fromId: number;
  who: number;
  message: string;
  when: number;
}

export default function ReactVirtualizedTable({ rows }: any) {
  return (
    <Paper
      variant="outlined"
      elevation={0}
      sx={{ height: "85vh", width: "100%" }}
    >
      <VirtualizedTable
        rowCount={rows.length}
        rowGetter={({ index }) => rows[index]}
        columns={[
          {
            width: 300,
            label: "MESSAGE",
            dataKey: "message",
            renderCell: ({ message }: any) => message,
          },
          {
            width: 200,
            label: "FROM ID",
            dataKey: "fromId",
            renderCell: ({ fromId }: any) => fromId,
            numeric: true,
          },
          {
            width: 120,
            label: "WHO",
            dataKey: "who",
            renderCell: ({ who }: any) => who,
            numeric: true,
          },
          {
            width: 150,
            label: "WHEN",
            dataKey: "when",
            renderCell: ({ when }: any) => when,
            numeric: true,
          },
        ]}
      />
    </Paper>
  );
}
