import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Button, TextField, Box, Checkbox, MenuItem } from "@mui/material";
import { FormValues, EventFormsTypes } from "./types";
import { eventSeasonDropDown } from "./selectionData";

import {
  CustomBox,
  CustomTypography,
  CustomSelect,
  CustomSelectActive,
  CustomErrorBox,
} from "./EventFormsStyles";

import { Tags } from "./Tags";
import { Rewards } from "./Rewards";

export const EventForms = ({
  vGoodTags,
  charactersList,
  vGoodShortCodes,
  onCreate,
}: EventFormsTypes) => {
  const onSubmit = (data: FormValues) => {
    resetField("eventID");
    resetField("eventName");
    resetField("eventDesc");
    resetField("eventPic");
    resetField("eventType");
    resetField("eventSeason");
    resetField("character");
    resetField("directCollectibles");
    resetField("item1");
    resetField("item2");
    resetField("item3");
    resetField("item4");
    resetField("item5");
    resetField("col1");
    resetField("rarity1");
    resetField("rarity2");
    resetField("rarity3");
    resetField("typeAmount1");
    resetField("reward1");
    resetField("reward1_amount");
    resetField("reward1_required");
    resetField("reward_1");
    resetField("reward_1_amount");
    resetField("reward_2");
    resetField("reward_2_amount");
    resetField("reward_3");
    resetField("reward_3_amount");
    resetField("reward_gold");
    resetField("reward_gold_amount");
    resetField("reward_gold_percent");
    resetField("reward_silver");
    resetField("reward_silver_amount");
    resetField("reward_silver_percent");
    resetField("reward_bronze");
    resetField("reward_bronze_amount");
    resetField("reward_bronze_percent");
    onCreate(data);
  };
  const { control, handleSubmit, watch, resetField } = useForm<FormValues>();

  const watchActiveDirectCollectibles = watch("directCollectibles", false);

  useEffect(() => {
    resetField("col1");
  }, [resetField, watch, watchActiveDirectCollectibles]);

  return (
    <Box style={{ backgroundColor: "white" }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h3>New Event Type</h3>
        <h4>
          Please make a Applanga key for Name, which can be shown in the reward
          screen. Same as event name, but &apos;_1line&apos; added in the end!
        </h4>
        <h3>Event Details</h3>
        <CustomBox>
          <CustomTypography>Event ID (backend)</CustomTypography>
          <Controller
            control={control}
            name="eventID"
            rules={{
              required: "required",
            }}
            defaultValue=""
            render={({ field, fieldState: { invalid, error } }) => {
              return (
                <>
                  <TextField
                    size={"small"}
                    style={{ width: 400 }}
                    variant="outlined"
                    error={invalid}
                    {...field}
                  />
                  {error && <CustomErrorBox>{error.message}</CustomErrorBox>}
                </>
              );
            }}
          />
        </CustomBox>
        <CustomBox>
          <CustomTypography>Event Name (Applanga)</CustomTypography>
          <Controller
            control={control}
            name="eventName"
            rules={{
              required: "required",
            }}
            defaultValue=""
            render={({ field, fieldState: { invalid, error } }) => {
              return (
                <>
                  <TextField
                    size={"small"}
                    style={{ width: 400 }}
                    variant="outlined"
                    error={invalid}
                    {...field}
                  />
                  {error && <CustomErrorBox>{error.message}</CustomErrorBox>}
                </>
              );
            }}
          />
        </CustomBox>
        <CustomBox>
          <CustomTypography>Event description (Applanga)</CustomTypography>
          <Controller
            control={control}
            name="eventDesc"
            rules={{
              required: "required",
            }}
            defaultValue=""
            render={({ field, fieldState: { invalid, error } }) => {
              return (
                <>
                  <TextField
                    size={"small"}
                    style={{ width: 400 }}
                    variant="outlined"
                    error={invalid}
                    {...field}
                  />
                  {error && <CustomErrorBox>{error.message}</CustomErrorBox>}
                </>
              );
            }}
          />
        </CustomBox>
        <CustomBox>
          <CustomTypography>Event Picture (shortcode)</CustomTypography>
          <Controller
            control={control}
            name="eventPic"
            rules={{
              required: "required",
            }}
            defaultValue=""
            render={({ field, fieldState: { invalid, error } }) => {
              return (
                <>
                  <TextField
                    size={"small"}
                    style={{ width: 400 }}
                    variant="outlined"
                    error={invalid}
                    {...field}
                  />
                  {error && <CustomErrorBox>{error.message}</CustomErrorBox>}
                </>
              );
            }}
          />
        </CustomBox>
        <CustomBox>
          <CustomTypography>Event Type</CustomTypography>
          <Controller
            control={control}
            name="eventType"
            defaultValue={"items"}
            render={({ field }) => {
              return (
                <CustomSelect {...field}>
                  <MenuItem key={"items"} value={"items"}>
                    items
                  </MenuItem>
                  <MenuItem key={"walk"} value={"walk"}>
                    walk
                  </MenuItem>
                </CustomSelect>
              );
            }}
          />
        </CustomBox>
        <CustomBox>
          <CustomTypography>Event Season</CustomTypography>
          <Controller
            control={control}
            name="eventSeason"
            defaultValue={"all time"}
            render={({ field }) => {
              return (
                <CustomSelect {...field}>
                  {eventSeasonDropDown.map((line) => (
                    <MenuItem key={line.value} value={line.value}>
                      {line.label}
                    </MenuItem>
                  ))}
                </CustomSelect>
              );
            }}
          />
        </CustomBox>
        <CustomBox>
          <CustomTypography>Character</CustomTypography>
          <Controller
            control={control}
            name="character"
            rules={{
              required: "required",
            }}
            defaultValue=""
            render={({ field, fieldState: { error } }) => {
              return (
                <>
                  <CustomSelect {...field}>
                    {charactersList.map((character: string) => (
                      <MenuItem key={character} value={character}>
                        {character}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                  {error && <CustomErrorBox>{error.message}</CustomErrorBox>}
                </>
              );
            }}
          />
        </CustomBox>
        <div>
          <h3>Collectibles collected</h3>
        </div>
        <div>
          <h4>Select collectibles directly</h4>
        </div>
        <CustomBox>
          <h4>Active</h4>
          <Controller
            name="directCollectibles"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <Checkbox {...field} defaultChecked={false} />
            )}
          />
        </CustomBox>

        {vGoodShortCodes?.length > 0 && (
          <div style={{ display: "flex" }}>
            <Controller
              control={control}
              name="item1"
              defaultValue="-"
              render={({ field }) => {
                return (
                  <CustomSelectActive {...field}>
                    <MenuItem key="-" value="-">
                      -
                    </MenuItem>
                    {vGoodShortCodes.map((shortCode: string) => (
                      <MenuItem key={shortCode} value={shortCode}>
                        {shortCode}
                      </MenuItem>
                    ))}
                  </CustomSelectActive>
                );
              }}
            />

            <Controller
              control={control}
              name="item2"
              defaultValue="-"
              render={({ field }) => {
                return (
                  <CustomSelectActive {...field}>
                    <MenuItem key="-" value="-">
                      -
                    </MenuItem>
                    {vGoodShortCodes.map((shortCode: string) => (
                      <MenuItem key={shortCode} value={shortCode}>
                        {shortCode}
                      </MenuItem>
                    ))}
                  </CustomSelectActive>
                );
              }}
            />

            <Controller
              control={control}
              name="item3"
              defaultValue="-"
              render={({ field }) => {
                return (
                  <CustomSelectActive {...field}>
                    <MenuItem key="-" value="-">
                      -
                    </MenuItem>
                    {vGoodShortCodes.map((shortCode: string) => (
                      <MenuItem key={shortCode} value={shortCode}>
                        {shortCode}
                      </MenuItem>
                    ))}
                  </CustomSelectActive>
                );
              }}
            />

            <Controller
              control={control}
              name="item4"
              defaultValue="-"
              render={({ field }) => {
                return (
                  <CustomSelectActive {...field}>
                    <MenuItem key="-" value="-">
                      -
                    </MenuItem>
                    {vGoodShortCodes.map((shortCode: string) => (
                      <MenuItem key={shortCode} value={shortCode}>
                        {shortCode}
                      </MenuItem>
                    ))}
                  </CustomSelectActive>
                );
              }}
            />

            <Controller
              control={control}
              name="item5"
              defaultValue="-"
              render={({ field }) => {
                return (
                  <CustomSelectActive {...field}>
                    <MenuItem key="-" value="-">
                      -
                    </MenuItem>
                    {vGoodShortCodes.map((shortCode: string) => (
                      <MenuItem key={shortCode} value={shortCode}>
                        {shortCode}
                      </MenuItem>
                    ))}
                  </CustomSelectActive>
                );
              }}
            />
          </div>
        )}

        <br />
        <br />
        <CustomTypography> Tag and rarity based items</CustomTypography>
        <br />
        {vGoodTags?.length > 0 && (
          <Tags
            control={control}
            vGoodTags={vGoodTags}
            watchActiveDirectCollectibles={watchActiveDirectCollectibles}
          />
        )}

        {vGoodShortCodes?.length > 1 && <Rewards control={control} />}
        <br />
        <Button type="submit" variant="contained" style={{ marginLeft: "8px" }}>
          Create
        </Button>
      </form>
      <br />
      <br />
    </Box>
  );
};
