import { EditorState } from "draft-js";
import { CHAT_STATUSES } from "../../consts";
import {
  MessageSourceType,
  WebsocketActionType,
} from "../../slices/Websocket/types";

export interface ChatOption {
  delay?: number;
  showDateTime?: boolean;
}

export enum ChatMessageTypes {
  EMPTY = "empty",
  CUSTOM = "custom",
  TEXT = "text",
  PENDING = "pending",
  SENDING = "sending",
  SYSTEM = "system",
}

export interface CustomActionRequest extends ActionRequest {
  type: ChatMessageTypes.CUSTOM;
  Component: JSX.Element;
  response?: CustomActionResponse;
}

export interface CustomActionResponse extends ActionResponse {
  type: ChatMessageTypes.CUSTOM;
}

export interface OnActionResponded {
  (response: ActionResponse): void;
}

// export type MessageContent = string | JSX.Element;
export type MessageContent = string;

export type WebFeedbackModel = {
  email: string;
  language: string;
  model: string;
  name: string;
  OS: string;
  schedule_date: string;
  schedule_time: string;
  user_id: string;
  username: string;
};

export type Attachment = {
  fileName: string;
};

export interface Message<C extends MessageContent> {
  type: `${ChatMessageTypes}`;
  content: C;
  subject?: string;
  email?: string;
  self: boolean;
  username?: string;
  avatar?: string;
  createdAt?: Date;
  updatedAt?: Date;
  systemMsgStatus?: `${CHAT_STATUSES}`;
  msgSourceType?: `${MessageSourceType}`;
  msgActionType?: `${WebsocketActionType}`;
  uuid: string;
  removed?: boolean;
  attachments?: Attachment[];
  webFeedbackModel?: WebFeedbackModel;
  adminOriginalEmail?: string;
  onTimeOut?: () => void;
  onCancel?: (message: Message<MessageContent>) => void;
}

export interface UpdatedMessage<T extends MessageContent> extends Message<T> {
  showDate: boolean;
  showTime: boolean;
}

export interface TextMessage extends Message<MessageContent> {
  type: ChatMessageTypes.TEXT;
  content: string;
}

export interface ActionRequest {
  type: `${ChatMessageTypes}`;
  always?: boolean;
  addMessage?: boolean;
  response?: ActionResponse;
}

export interface TextActionRequest extends ActionRequest {
  type: ChatMessageTypes.TEXT;
  defaultValue?: string;
  placeholder?: string;
  sendButtonText?: string;
  response?: TextActionResponse;
}

export interface ActionResponse {
  type: string;
  value: string;
  error?: Error;
  subject?: string;
  msgActionType?: `${WebsocketActionType}`;
  uuid: string;
}

export interface TextActionResponse extends ActionResponse {
  type: ChatMessageTypes.TEXT;
}

export interface OnMessagesChanged {
  (messages: Message<MessageContent>[]): void;
}

export interface OnActionChanged {
  (request: ActionRequest, response?: ActionResponse): void;
}

export interface OnActionResponded {
  (response: ActionResponse): void;
}

export type PrevMessage = {
  _id: string;
  title: string;
  adminID: string;
  value: string;
  timeStamp: number;
  version: string;
  email: string;
  type: `${MessageSourceType}`;
  uuid: string;
  status: `${CHAT_STATUSES}`;
  displayName: string;
  subject?: string;
  removed?: boolean;
  attachments?: Attachment[];
  webFeedbackModel?: WebFeedbackModel;
  adminOriginalEmail?: string;
};

export type PrevMessagesModel = {
  _id: string;
  playerID: string;
  status: `${CHAT_STATUSES}`;
  messages: PrevMessage[];
};

export type FirstMessageType = {
  _id: string;
  playerID: string;
  feedbackDataID: string;
  email: string;
  displayName: string;
  status: `${CHAT_STATUSES}`;
  firstMessage: PrevMessage;
};

export type EmailDraftType = {
  body: EditorState;
  subject: string;
};

export type EmailDataType = {
  subject: string;
  body: string;
  playerID: string;
  emailFrom: string;
};

export type InputParamsChatMsg = {
  playerID: string | null;
  feedbackDataID: string | null;
  body?: string;
  subject?: string;
  value?: string;
  sendNotificationToSlack?: boolean;
  adminOriginalEmail: string;
};

export type InternalNoteParamsType = {
  sendNotificationToSlack: boolean;
  chatStatusValue: `${CHAT_STATUSES}` | "";
  initialChatStatusValue: `${CHAT_STATUSES}` | "";
  isChatStatusChanging: boolean;
};
