import { CHAT_STATUSES } from "../../consts";

export const searchFieldsFront = ["userName", "id", "displayName", "status"];

export const searchFieldsBack = [
  {
    key: "displayName",
    type: "text",
    label: "Display Name",
    typeofData: "string",
  },
  {
    key: "_id",
    type: "text",
    label: "ID",
    typeofData: "string",
  },
  {
    key: "status",
    data: Object.values(CHAT_STATUSES),
    type: "dropDown",
    label: "Status",
    typeofData: "string",
  },
  {
    key: "lastMessage",
    type: "dataInterval",
    label: "Last message",
    typeofData: "string",
  },
  {
    key: "email",
    type: "text",
    label: "email address",
    typeofData: "string",
  },
];
