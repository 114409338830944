import React, {
  Dispatch,
  SetStateAction,
  memo,
  useEffect,
  useMemo,
  useState,
} from "react";

import { Box } from "@mui/system";
import { Stack } from "@mui/material";
import ControlledInputChips from "../../components/inputs/ControlledInputChips";
import ControlledCheckbox from "../../components/inputs/ControlledCheckbox";
import ControlledInputText from "../../components/inputs/ControlledInputText";
import ControlledInputSelect from "../../components/inputs/ControlledInputSelect";
import { CustomErrorBox } from "../../components/inputs/StyledComponents";

import { useTranslation } from "react-i18next";
import {
  Control,
  UseFormResetField,
  useWatch,
  useFormState,
  UseFormSetError,
  UseFormClearErrors,
} from "react-hook-form";
import { useGetListQuery } from "../../slices/lists/api";
import { CreateQuestFormValues, RewardItemDataType } from "./types";
import { QueryListName, StaticListName } from "../../slices/lists/types";
import {
  DropDownDataItem,
  currencyListData,
} from "../../components/inputs/consts";
import { ArrayItemType } from "../../components/DynamicForm/consts";

type DataType = CreateQuestFormValues | RewardItemDataType | any;

type RewardItemProps = {
  control: Control<DataType>;
  count?: 1 | 2 | 3;
  name: ArrayItemType.questRequirement | ArrayItemType.questRewards;
  resetField: UseFormResetField<DataType>;
  setError: UseFormSetError<DataType>;
  setAreAllInputsEmpty?: Dispatch<SetStateAction<boolean>>;
  clearErrors: UseFormClearErrors<DataType>;
  isAddNewItem?: boolean;
};

const RewardItem = ({
  control,
  count = 1,
  name,
  resetField,
  setError,
  setAreAllInputsEmpty,
  clearErrors,
  isAddNewItem = false,
}: RewardItemProps) => {
  const { t } = useTranslation();
  const { isSubmitted } = useFormState({ control });
  const [
    isTagsActive,
    tagsValue,
    isRarityActive,
    rarityValue,
    isCustomActive,
    customValue,
  ] = useWatch({
    control,
    name: [
      `${name}Tags_${count}`,
      `${name}TagsValue_${count}`,
      `${name}Rarity_${count}`,
      `${name}RarityValue_${count}`,
      `${name}Custom_${count}`,
      `${name}CustomValue_${count}`,
    ],
  });

  const [isFirstItemEmpty, setIsFirstItemEmpty] = useState(false);

  const {
    data: virtualGoodShortCodes,
    isLoading: isLoadingVirtualGoodsShortCodes,
  } = useGetListQuery(QueryListName.virtualGoodShortCodes);

  const customSelectData: DropDownDataItem[] = useMemo(() => {
    const currVirtualGoodShortCodes = virtualGoodShortCodes
      ? [...(virtualGoodShortCodes as string[])]
      : [];

    const virtualGoodsListData = currVirtualGoodShortCodes.map((item) => ({
      value: item,
      label: item,
    }));

    return [...currencyListData, ...virtualGoodsListData];
  }, [virtualGoodShortCodes]);

  useEffect(() => {
    if (isCustomActive) {
      resetField(`${name}Tags_${count}`);
      resetField(`${name}Rarity_${count}`);
    }
  }, [count, isCustomActive, name, resetField]);

  useEffect(() => {
    if (
      isSubmitted &&
      count === 1 &&
      !isTagsActive &&
      !isRarityActive &&
      !isCustomActive
    ) {
      setAreAllInputsEmpty && setAreAllInputsEmpty(true);
      setIsFirstItemEmpty(true);
    }
  }, [
    count,
    isCustomActive,
    isRarityActive,
    isSubmitted,
    isTagsActive,
    setAreAllInputsEmpty,
  ]);

  useEffect(() => {
    if (
      !isSubmitted ||
      (count === 1 && (isTagsActive || isRarityActive || isCustomActive))
    ) {
      setAreAllInputsEmpty && setAreAllInputsEmpty(false);
      setIsFirstItemEmpty(false);
    }
  }, [
    count,
    isCustomActive,
    isRarityActive,
    isSubmitted,
    isTagsActive,
    setAreAllInputsEmpty,
  ]);

  useEffect(() => {
    if (isSubmitted && isTagsActive && tagsValue.length < 1) {
      setError(`${name}TagsValue_${count}`, {
        type: "required",
        message: t("errors.inputRequiredMsg"),
      });
    } else {
      clearErrors(`${name}TagsValue_${count}`);
    }
  }, [
    t,
    count,
    name,
    isSubmitted,
    isTagsActive,
    tagsValue.length,
    clearErrors,
    setError,
  ]);

  useEffect(() => {
    if (isSubmitted && isRarityActive && rarityValue.length < 1) {
      setError(`${name}RarityValue_${count}`, {
        type: "required",
        message: t("errors.inputRequiredMsg"),
      });
    } else {
      clearErrors(`${name}RarityValue_${count}`);
    }
  }, [
    t,
    count,
    name,
    isSubmitted,
    isRarityActive,
    rarityValue.length,
    clearErrors,
    setError,
  ]);

  useEffect(() => {
    if (isSubmitted && isCustomActive && customValue.length < 1) {
      setError(`${name}CustomValue_${count}`, {
        type: "required",
        message: t("errors.inputRequiredMsg"),
      });
    } else {
      clearErrors(`${name}CustomValue_${count}`);
    }
  }, [
    t,
    count,
    name,
    isSubmitted,
    isCustomActive,
    customValue.length,
    clearErrors,
    setError,
  ]);

  return (
    <Stack direction="row" spacing={2}>
      {!isAddNewItem && (
        <Box mt={2} width="120px">
          <h4 style={{ margin: 0 }}>{`${t(
            name === "questRewards"
              ? "createQuest.reward"
              : "createQuest.requirement"
          )} ${count}`}</h4>
          {count === 1 && isFirstItemEmpty && (
            <CustomErrorBox>{t("errors.inputRequiredMsg")}</CustomErrorBox>
          )}
        </Box>
      )}
      <Box
        sx={{
          p: 2,
          ...(!isAddNewItem && {
            border: isFirstItemEmpty
              ? "2px solid #d32f2f"
              : "1px solid rgba(0, 0, 0, 0.23)",
            borderRadius: "4px",
          }),
        }}
      >
        <ControlledInputChips
          control={control}
          title={
            <ControlledCheckbox
              control={control}
              label={t("createQuest.tags")}
              fieldName={`${name}Tags_${count}` as keyof CreateQuestFormValues}
              isDisabled={isCustomActive}
            />
          }
          fieldName={`${name}TagsValue_${count}` as keyof CreateQuestFormValues}
          listName={QueryListName.virtualGoodTags}
          titleWidth={120}
          isDisabled={!isTagsActive}
        />
        <ControlledInputChips
          control={control}
          title={
            <ControlledCheckbox
              control={control}
              label={t("createQuest.rarity")}
              fieldName={
                `${name}Rarity_${count}` as keyof CreateQuestFormValues
              }
              isDisabled={isCustomActive}
            />
          }
          fieldName={
            `${name}RarityValue_${count}` as keyof CreateQuestFormValues
          }
          listName={StaticListName.rarityValues}
          titleWidth={120}
          isDisabled={!isRarityActive}
        />
        <ControlledInputSelect
          control={control}
          title={
            <ControlledCheckbox
              control={control}
              label={t("createQuest.custom")}
              fieldName={
                `${name}Custom_${count}` as keyof CreateQuestFormValues
              }
            />
          }
          fieldName={
            `${name}CustomValue_${count}` as keyof CreateQuestFormValues
          }
          dropDownData={customSelectData}
          titleWidth={120}
          isDisabled={!isCustomActive}
          isLoadingDropdownData={isLoadingVirtualGoodsShortCodes}
        />
        <Stack direction="row" justifyContent="space-between" mt={3}>
          <ControlledInputText
            control={control}
            title={t("createQuest.min")}
            inputWidth={180}
            titleWidth={50}
            fieldName={`${name}Min_${count}` as keyof CreateQuestFormValues}
            isNumeric
          />
          <ControlledInputText
            control={control}
            title={t("createQuest.max")}
            inputWidth={180}
            titleWidth={50}
            fieldName={`${name}Max_${count}` as keyof CreateQuestFormValues}
            isNumeric
          />
        </Stack>
      </Box>
    </Stack>
  );
};

export default memo(RewardItem);
