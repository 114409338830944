import React, { memo, useCallback } from "react";

import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  FilterValuesType,
  COLUMN_WIDTH,
  INPUT_WIDTH,
  MENU_ITEM_HEIGHT,
} from "./types";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: MENU_ITEM_HEIGHT * 4.5 + 8,
    },
  },
};

type ChartFilterInputProps = {
  filterName: string;
  currentValue: FilterValuesType;
  filterValues: FilterValuesType;
  handleChangeValue: (event: SelectChangeEvent<FilterValuesType>) => void;
  shouldBeTranslated?: boolean;
};

const ChartFilterInput = ({
  filterName,
  currentValue,
  filterValues,
  handleChangeValue,
  shouldBeTranslated,
}: ChartFilterInputProps) => {
  const { t } = useTranslation();

  const renderValue = useCallback(
    (selected: string[]) =>
      shouldBeTranslated
        ? selected.reduce(
            (result, name) =>
              result.length > 0
                ? `${result}, ${t(`filter.${name}`)}`
                : `${t(`filter.${name}`)}`,
            ""
          )
        : selected.join(", "),
    [shouldBeTranslated, t]
  );

  return (
    <Stack width={COLUMN_WIDTH}>
      <h3 style={{ margin: 0, marginBottom: 8, color: "#1976d2" }}>
        {filterName}:
      </h3>
      <FormControl sx={{ width: INPUT_WIDTH }} size="small">
        <InputLabel id={filterName}>{filterName}</InputLabel>
        <Select
          labelId={filterName}
          value={currentValue}
          label={filterName}
          onChange={handleChangeValue}
          name={filterName}
          renderValue={renderValue}
          MenuProps={MenuProps}
          multiple
        >
          {filterValues.map((name) => (
            <MenuItem key={name} value={name} sx={{ height: MENU_ITEM_HEIGHT }}>
              <Checkbox checked={currentValue.indexOf(name as never) > -1} />
              <ListItemText
                primary={shouldBeTranslated ? t(`filter.${name}`) : name}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
};

export default memo(ChartFilterInput);
