import { Edit, Info, ModeEdit, Remove } from "@mui/icons-material";
import { DesktopDateTimePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Popover,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import enGB from "date-fns/locale/de";
import dayjs, { Dayjs } from "dayjs";
import React, { ReactNode, useCallback, useState } from "react";
import {
  Control,
  Controller,
  UseFormSetValue,
  UseFormWatch,
  useFieldArray,
  useFormContext,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { JsonView, allExpanded, defaultStyles } from "react-json-view-lite";
import "react-json-view-lite/dist/index.css";
import { FieldsArrayAccordion } from "../../components/WideDrawerForm/FieldsArrayAccordion";
import ControlledCheckbox from "../../components/inputs/ControlledCheckbox";
import ControlledInputText from "../../components/inputs/ControlledInputText";
import {
  DropDownDataItem,
  INPUT_COLUMN_WIDTH,
  INPUT_TITLE_WIDTH,
} from "../../components/inputs/consts";
import { Circular } from "../../components/spinners/Circular";
import {
  CreateStoreContentFormValues,
  StoreContentEntry,
  StoreItem,
} from "../../slices/storeContent/types";
import {
  CustomBox,
  CustomErrorBox,
  CustomSelect,
} from "../Events/EventFormsStyles";
import { CreateQuestFormValues } from "../Quests/types";
import {
  CreateVirtualGoodFormValues,
  SetProbabilityConfigFormValues,
} from "../VirtualGoods/types";
import { truncateMinutes } from "../../utils/getJPTime";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

type StoreItems = {
  setValue: UseFormSetValue<
    Omit<CreateStoreContentFormValues, "start" | "end"> & {
      start: Dayjs | number | null;
      end: Dayjs | number | null;
    }
  >;
  lootboxData: any;
  watch: UseFormWatch<
    Omit<CreateStoreContentFormValues, "start" | "end"> & {
      start: Dayjs | number | null;
      end: Dayjs | number | null;
    }
  >;
};

export const StoreItems = ({ setValue, lootboxData, watch }: StoreItems) => {
  const { t } = useTranslation();
  const { control } = useFormContext<StoreContentEntry>();
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: "storeItems",
    rules: {
      required: true,
    },
  });

  const storeItems = watch("storeItems");

  const start = dayjs(watch("start")).unix();
  const end = dayjs(watch("end")).unix();

  const [isTimerangeModalOpen, setIsTimerangeModalOpen] = useState(false);
  const [itemIndex, setItemIndex] = useState<number>(null);

  const handleOpenTimerangeModal = () => setIsTimerangeModalOpen(true);
  const handleCloseTimerangeModal = () => {
    if (
      !storeItems[itemIndex].availabilityStart &&
      !storeItems[itemIndex].availabilityEnd
    ) {
      setValue(`storeItems.${itemIndex}.timerangeOverridden`, false);
    } else if (
      storeItems[itemIndex].availabilityStart !== start ||
      storeItems[itemIndex].availabilityEnd !== end
    ) {
      setValue(`storeItems.${itemIndex}.timerangeOverridden`, true);
    }

    setIsTimerangeModalOpen(false);
  };

  const addClickHandler = useCallback(() => {
    append({
      lootboxID: "",
      showAsOffer: false,
      discountPercentage: "0",
      price: "0",
      buyAmountLimit: "0",
      discountPercentageOverridden: false,
      priceOverridden: false,
      buyAmountLimitOverridden: false,
      availabilityStart: null,
      availabilityEnd: null,
      timerangeOverridden: false,
      orderIndex: fields.length,
    });
  }, [append]);

  return (
    <>
      <Grid item gridColumn="span 12">
        <FieldsArrayAccordion
          id="store-content-form-store-items"
          label={t("storeContentCreate.storeItems")}
          addLabel={t("storeContentCreate.addStoreItem")}
          addClickHandler={addClickHandler}
          error={!fields.length}
        >
          <Grid container spacing={2} sx={{ marginBottom: 2, marginRight: 10 }}>
            <RelevancesFields
              fields={fields}
              control={control}
              lootboxData={lootboxData}
              remove={remove}
              setValue={setValue}
              handleOpenTimerangeModal={handleOpenTimerangeModal}
              defaultTimerange={{ start, end }}
              setItemIndex={setItemIndex}
              storeItems={storeItems}
              replace={replace}
            />
          </Grid>
        </FieldsArrayAccordion>
      </Grid>
      <TimerangeModal
        mainLabel={t("storeContentCreate.timeRange")}
        setValue={setValue}
        itemIndex={itemIndex}
        isTimerangeModalOpen={isTimerangeModalOpen}
        handleCloseTimerangeModal={handleCloseTimerangeModal}
        control={control}
        start={start}
        end={end}
        setIsTimerangeModalOpen={setIsTimerangeModalOpen}
        storeItems={storeItems}
      />
    </>
  );
};

const RelevancesFields = ({
  fields,
  control,
  lootboxData,
  remove,
  setValue,
  handleOpenTimerangeModal,
  defaultTimerange,
  setItemIndex,
  storeItems,
  replace,
}) => {
  const { t } = useTranslation();
  const [anchorElId, setAnchorElId] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isElemetOnDrag, setIsElementOnDrag] = React.useState(false);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    const elementId = event.currentTarget.getAttribute("aria-owns");
    setAnchorElId(elementId);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorElId("");
    setAnchorEl(null);
  };

  const handleOnDragEnd = async (result: any) => {
    setIsElementOnDrag(false);

    if (!result.destination) return;

    const items: StoreItem[] = Array.from(storeItems);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const itemsUpdatedIndexes = items.map((el, index) => {
      return { ...el, orderIndex: index };
    });

    replace(itemsUpdatedIndexes);
  };

  return (
    <>
      <Grid
        direction="row"
        sx={{ display: "flex", paddingInline: "10px" }}
        container
        xs={12}
        item
      >
        <Grid item xs={2} sx={{ padding: "10px" }}>
          <Typography component="div">
            {`${t("storeContentCreate.storeItemFields.lootboxId")}`}
          </Typography>
        </Grid>
        <Grid item xs={2} sx={{ padding: "10px" }}>
          <Typography component="div">
            {`${t("storeContentCreate.storeItemFields.price")} `}
          </Typography>
        </Grid>
        <Grid item xs={2} sx={{ padding: "10px" }}>
          <Typography component="div">
            {`${t("storeContentCreate.storeItemFields.buyAmountLimit")} `}
          </Typography>
        </Grid>
        <Grid item xs={2} sx={{ padding: "10px" }}>
          <Typography component="div">
            {`${t("storeContentCreate.storeItemFields.discountPercentage")} `}
          </Typography>
        </Grid>
        <Grid item xs={1} sx={{ padding: "10px" }}>
          <Typography component="div">
            {`${t("storeContentCreate.storeItemFields.showAsOffer")}`}
          </Typography>
        </Grid>
        <Grid item xs={2} sx={{ padding: "10px" }}>
          <Typography component="div">
            {`${t("storeContentCreate.storeItemFields.timerange")}`}
          </Typography>
        </Grid>
      </Grid>
      <DragDropContext
        onDragEnd={handleOnDragEnd}
        onBeforeDragStart={() => {
          setIsElementOnDrag(true);
        }}
      >
        <Droppable droppableId="input">
          {(providedDroppable) => (
            <div
              {...providedDroppable.droppableProps}
              ref={providedDroppable.innerRef}
            >
              <>
                {fields
                  ?.sort((a, b) => a.orderIndex - b.orderIndex)
                  .map((field, index) => (
                    <React.Fragment key={field.id}>
                      <Draggable
                        index={index}
                        draggableId={index.toString()}
                        key={index}
                      >
                        {(providedDraggable) => (
                          <div
                            {...providedDraggable.dragHandleProps}
                            {...providedDraggable.draggableProps}
                            ref={providedDraggable.innerRef}
                          >
                            <Grid
                              container
                              xs={12}
                              sx={{ paddingInline: "10px" }}
                              item
                            >
                              <Grid
                                item
                                xs={2}
                                sx={{
                                  padding: "10px",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <ControlledInputSelect
                                    control={control}
                                    fieldName={
                                      `storeItems.${index}.lootboxID` as const
                                    }
                                    dropDownData={lootboxData.map(
                                      (lootboxData) => ({
                                        value: lootboxData.lootboxID,
                                        label: lootboxData.lootboxID,
                                      })
                                    )}
                                    isRequired
                                    itemIndex={index}
                                    setValue={setValue}
                                    lootboxData={lootboxData}
                                    isLoadingDropdownData={!lootboxData.length}
                                    defaultTimerange={defaultTimerange}
                                  />
                                  <Typography
                                    aria-owns={
                                      open
                                        ? `mouse-over-popover-${index}`
                                        : undefined
                                    }
                                    aria-haspopup="true"
                                    onMouseEnter={handlePopoverOpen}
                                    onMouseLeave={handlePopoverClose}
                                  >
                                    <Info />
                                  </Typography>
                                  <Popover
                                    id={`mouse-over-popover-${index}`}
                                    sx={{
                                      pointerEvents: "none",
                                      overflow: "auto",
                                    }}
                                    open={
                                      !!anchorElId &&
                                      anchorElId ===
                                        `mouse-over-popover-${index}`
                                    }
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "left",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "left",
                                    }}
                                    onClose={handlePopoverClose}
                                    disableRestoreFocus
                                    disableScrollLock={false}
                                  >
                                    <JsonView
                                      data={lootboxData.find(
                                        (el) => el.lootboxID === field.lootboxID
                                      )}
                                      shouldExpandNode={allExpanded}
                                      style={defaultStyles}
                                    />
                                  </Popover>
                                </div>
                              </Grid>
                              <Grid
                                item
                                xs={2}
                                sx={{ padding: "10px", position: "relative" }}
                              >
                                <ControlledInputText
                                  control={control}
                                  fieldName={
                                    `storeItems.${index}.price` as const
                                  }
                                  defaultValue={field.price}
                                  isNumeric
                                  isInputDisabled={
                                    !storeItems[index].priceOverridden
                                  }
                                  normalize={(value) => {
                                    const normalizedValue = Math.trunc(
                                      isNaN(+value) ? 0 : +value
                                    );

                                    if (normalizedValue < 0) {
                                      return 0;
                                    }

                                    return normalizedValue;
                                  }}
                                />
                                <Tooltip title="Is default config value overridden">
                                  <div
                                    style={{
                                      position: "absolute",
                                      right: "2%",
                                      top: "10px",
                                    }}
                                  >
                                    <ControlInputCheckbox
                                      control={control}
                                      fieldName={
                                        `storeItems.${index}.priceOverridden` as const
                                      }
                                      label={""}
                                      setValue={setValue}
                                      lootboxData={lootboxData}
                                      lootboxId={field.lootboxID}
                                      lootboxField={storeItems[index]}
                                      inputName={"price"}
                                    />
                                  </div>
                                </Tooltip>
                              </Grid>
                              <Grid
                                item
                                xs={2}
                                sx={{ padding: "10px", position: "relative" }}
                              >
                                <ControlledInputText
                                  control={control}
                                  fieldName={
                                    `storeItems.${index}.buyAmountLimit` as const
                                  }
                                  defaultValue={field.buyAmountLimit}
                                  isNumeric
                                  isInputDisabled={
                                    !storeItems[index].buyAmountLimitOverridden
                                  }
                                  normalize={(value) => {
                                    const normalizedValue = Math.trunc(
                                      isNaN(+value) ? 0 : +value
                                    );

                                    if (normalizedValue < 0) {
                                      return 0;
                                    }

                                    return normalizedValue;
                                  }}
                                />
                                <Tooltip title="Is default config value overridden">
                                  <div
                                    style={{
                                      position: "absolute",
                                      right: "2%",
                                      top: "10px",
                                    }}
                                  >
                                    <ControlInputCheckbox
                                      control={control}
                                      fieldName={
                                        `storeItems.${index}.buyAmountLimitOverridden` as const
                                      }
                                      label={""}
                                      setValue={setValue}
                                      lootboxData={lootboxData}
                                      lootboxId={field.lootboxID}
                                      lootboxField={storeItems[index]}
                                      inputName={"buyAmountLimit"}
                                    />
                                  </div>
                                </Tooltip>
                              </Grid>
                              <Grid
                                item
                                xs={2}
                                sx={{ padding: "10px", position: "relative" }}
                              >
                                <ControlledInputText
                                  control={control}
                                  fieldName={
                                    `storeItems.${index}.discountPercentage` as const
                                  }
                                  defaultValue={field.discountPercentage}
                                  isNumeric
                                  isInputDisabled={
                                    !storeItems[index]
                                      .discountPercentageOverridden
                                  }
                                  normalize={(value) => {
                                    const normalizedValue = Math.trunc(
                                      isNaN(+value) ? 0 : +value
                                    );

                                    if (normalizedValue < 0) {
                                      return 0;
                                    }

                                    if (normalizedValue > 100) {
                                      return 100;
                                    }

                                    return normalizedValue;
                                  }}
                                />
                                <Tooltip title="Is default config value overridden">
                                  <div
                                    style={{
                                      position: "absolute",
                                      right: "2%",
                                      top: "10px",
                                    }}
                                  >
                                    <ControlInputCheckbox
                                      control={control}
                                      fieldName={
                                        `storeItems.${index}.discountPercentageOverridden` as const
                                      }
                                      label={""}
                                      setValue={setValue}
                                      lootboxData={lootboxData}
                                      lootboxId={field.lootboxID}
                                      lootboxField={storeItems[index]}
                                      inputName={"discountPercentage"}
                                    />
                                  </div>
                                </Tooltip>
                              </Grid>
                              <Grid item xs={1} sx={{ padding: "10px" }}>
                                <ControlledCheckbox
                                  control={control}
                                  fieldName={
                                    `storeItems.${index}.showAsOffer` as const
                                  }
                                  label={""}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={2}
                                sx={{
                                  padding: "10px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Grid item xs={10}>
                                  <Typography component="div">
                                    {storeItems[index].availabilityStart
                                      ? dayjs
                                          .unix(
                                            storeItems[index].availabilityStart
                                          )
                                          .tz("Asia/Tokyo")
                                          .format("YYYY/MM/DD, HH:mm")
                                      : "No date selected"}{" "}
                                    -{" "}
                                    {storeItems[index].availabilityEnd
                                      ? dayjs
                                          .unix(
                                            storeItems[index].availabilityEnd
                                          )
                                          .tz("Asia/Tokyo")
                                          .format("YYYY/MM/DD, HH:mm")
                                      : "No date selected"}
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xs={2}
                                  sx={{
                                    marginRight: "20px",
                                  }}
                                >
                                  <IconButton
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handleOpenTimerangeModal();
                                      setItemIndex(index);
                                    }}
                                  >
                                    <Edit />
                                  </IconButton>
                                  {/* <Tooltip title="Is default date values overridden">
                                    <Checkbox
                                      checked={
                                        storeItems[index].start !==
                                          defaultTimerange?.start ||
                                        storeItems[index].end !==
                                          defaultTimerange?.end
                                      }
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          e.preventDefault();
                                          handleOpenTimerangeModal();
                                          setItemIndex(index);
                                        } else {
                                          setValue(
                                            `storeItems.${index}.start`,
                                            defaultTimerange?.start
                                          );
                                          setValue(
                                            `storeItems.${index}.end`,
                                            defaultTimerange?.end
                                          );
                                          setValue(
                                            `storeItems.${index}.timerangeOverridden`,
                                            false
                                          );
                                        }
                                      }}
                                    />
                                  </Tooltip> */}
                                </Grid>
                              </Grid>
                              <Grid item xs={1} sx={{ padding: "10px" }}>
                                <IconButton
                                  onClick={() => {
                                    remove(index);
                                  }}
                                >
                                  <Remove />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </div>
                        )}
                      </Draggable>
                    </React.Fragment>
                  ))}
              </>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

type ControlledInputSelectProps = {
  control: Control<any>;
  title?: ReactNode;
  fieldName:
    | keyof CreateStoreContentFormValues
    | keyof CreateQuestFormValues
    | keyof CreateVirtualGoodFormValues
    | keyof SetProbabilityConfigFormValues
    | string;
  isRequired?: boolean;
  isDisabled?: boolean;
  isLoadingDropdownData?: boolean;
  defaultValue?: string;
  dropDownData: DropDownDataItem[];
  inputWidth?: string | number;
  titleWidth?: string | number;
  itemIndex: number;
  setValue: UseFormSetValue<
    Omit<CreateStoreContentFormValues, "start" | "end"> & {
      start: Dayjs | number | null;
      end: Dayjs | number | null;
    }
  >;
  lootboxData: unknown[];
  defaultTimerange: Omit<CreateStoreContentFormValues, "start" | "end"> & {
    start: Dayjs | number | null;
    end: Dayjs | number | null;
  };
};

const ControlledInputSelect = ({
  control,
  fieldName,
  isRequired = false,
  isDisabled = false,
  isLoadingDropdownData = false,
  defaultValue = "",
  dropDownData,
  inputWidth = INPUT_COLUMN_WIDTH * 0.45,
  itemIndex,
  setValue,
  lootboxData,
  defaultTimerange,
}: ControlledInputSelectProps) => {
  return (
    <CustomBox>
      <Controller
        control={control}
        name={fieldName}
        defaultValue={defaultValue}
        {...(isRequired && {
          rules: {
            required: "required",
          },
        })}
        render={({ field, fieldState: { invalid, error } }) => {
          return isLoadingDropdownData ? (
            <Circular />
          ) : (
            <>
              <CustomSelect
                {...field}
                sx={{ width: inputWidth }}
                disabled={isDisabled}
                error={invalid}
                onChange={(selectedOption) => {
                  const lootbox: any = lootboxData.find(
                    (el: any) =>
                      el.lootboxID === (selectedOption.target.value as string)
                  );

                  setValue(`storeItems.${itemIndex}`, {
                    lootboxID: selectedOption.target.value as string,
                    showAsOffer: false,
                    discountPercentage: lootbox.discountPercentage,
                    price: lootbox.price,
                    buyAmountLimit: lootbox.buyAmountLimit,
                    discountPercentageOverridden: false,
                    priceOverridden: false,
                    buyAmountLimitOverridden: false,
                    availabilityStart: null,
                    availabilityEnd: null,
                    timerangeOverridden: false,
                    orderIndex: itemIndex,
                  });
                }}
              >
                <MenuItem key="-" value=""></MenuItem>
                {dropDownData?.map(({ value, label }, id) => (
                  <MenuItem key={id} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </CustomSelect>
              {error && <CustomErrorBox>{error.message}</CustomErrorBox>}
            </>
          );
        }}
      />
    </CustomBox>
  );
};

const ControlInputCheckbox = ({
  control,
  label,
  fieldName,
  isDisabled = false,
  setValue,
  lootboxData,
  lootboxId,
  lootboxField,
  inputName,
}) => {
  return (
    <CustomBox>
      <Controller
        control={control}
        name={fieldName}
        render={({ field }) => (
          <>
            <Checkbox
              {...field}
              checked={!!field.value}
              disabled={isDisabled}
              sx={{ px: 0, py: 1, mr: 1 }}
              onChange={(e) => {
                if (e.target.checked) {
                  setValue(fieldName, e.target.checked);
                } else {
                  const pathArr = fieldName.split(".");
                  const checkboxFieldKey = pathArr.splice(-1);

                  const lootbox = lootboxData.find(
                    (el: any) => el.lootboxID === lootboxId
                  );

                  setValue(pathArr.join("."), {
                    ...lootboxField,
                    [checkboxFieldKey]: e.target.checked,
                    [inputName]: lootbox?.[inputName],
                  });
                }
              }}
            />
            <h4
              style={{
                margin: 0,
                color: isDisabled ? "rgba(0, 0, 0, 0.38)" : "initial",
              }}
            >
              {label}
            </h4>
          </>
        )}
      />
    </CustomBox>
  );
};

const TimerangeModal = ({
  setValue,
  itemIndex,
  isTimerangeModalOpen,
  handleCloseTimerangeModal,
  control,
  start,
  end,
  setIsTimerangeModalOpen,
  mainLabel,
  storeItems,
}) => {
  const handleDesktopDateTimePickerChange = (
    inputDatetimeValue: number,
    inputName: "availabilityEnd" | "availabilityStart"
  ) => {
    if (!inputDatetimeValue) return;

    const currentTime = new Date().setMinutes(0, 0, 0);
    const japanTime = new Date(
      new Date().toLocaleString("en-US", {
        timeZone: "Asia/Tokyo",
      })
    ).setMinutes(0, 0, 0);

    const difference = Math.floor((japanTime - currentTime) / 1000);

    const timestamp =
      new Date(inputDatetimeValue).setMinutes(0, 0, 0) / 1000 - difference;

    setValue(`storeItems.${itemIndex}.${inputName}`, timestamp);
  };

  const prevStoreItem = itemIndex > 0 ? storeItems[itemIndex - 1] : null;
  const nextStoreItem =
    itemIndex < storeItems.length - 1 ? storeItems[itemIndex + 1] : null;

  return (
    <Modal open={isTimerangeModalOpen} onClose={handleCloseTimerangeModal}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "60%",
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography component="div" sx={{ color: "initial" }}>
          {mainLabel}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <Controller
              name={`storeItems.${itemIndex}`}
              control={control}
              render={({ field }) => (
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={enGB}
                >
                  <DesktopDateTimePicker
                    {...field}
                    onChange={(val) =>
                      handleDesktopDateTimePickerChange(
                        val,
                        "availabilityStart"
                      )
                    }
                    value={
                      field.value?.availabilityStart
                        ? truncateMinutes(
                            dayjs
                              .unix(field.value.availabilityStart as number)
                              .tz("Asia/Tokyo")
                          )
                        : null
                    }
                    views={["year", "month", "day", "hours"]}
                    shouldDisableDate={(date) => {
                      const truncatedPickerTokyoDate = dayjs(date)
                        .tz("Asia/Tokyo", true)
                        .startOf("day")
                        .unix();
                      const truncatedStartDate = dayjs
                        .unix(start)
                        .tz("Asia/Tokyo", true)
                        .add(1, "hour")
                        .startOf("day")
                        .unix();
                      const truncatedEndDate = dayjs
                        .unix(end)
                        .tz("Asia/Tokyo", true)
                        .add(1, "hour")
                        .startOf("day")
                        .unix();

                      const truncatedPrevStoreItemEndDate = dayjs
                        .unix(prevStoreItem?.availabilityEnd)
                        .tz("Asia/Tokyo", true)
                        .add(1, "hour")
                        .startOf("day")
                        .unix();

                      return (
                        truncatedPickerTokyoDate < truncatedStartDate ||
                        truncatedPickerTokyoDate > truncatedEndDate ||
                        truncatedPickerTokyoDate < truncatedPrevStoreItemEndDate
                      );
                    }}
                    shouldDisableTime={(time) => {
                      const startHour = dayjs
                        .unix(start)
                        .tz("Asia/Tokyo")
                        .get("h");
                      const endHour = dayjs
                        .unix(end)
                        .tz("Asia/Tokyo")
                        .add(-1, "h")
                        .get("h");
                      const prevStoreItemEndHour = dayjs
                        .unix(prevStoreItem?.availabilityEnd)
                        .tz("Asia/Tokyo")
                        .get("h");

                      const prevStoreItemDateEndDate = dayjs
                        .unix(prevStoreItem?.availabilityEnd)
                        .tz("Asia/Tokyo", true)
                        .startOf("day")
                        .unix();

                      const truncatedSelectedStartDate = dayjs
                        .unix(field.value.availabilityStart as number)
                        .tz("Asia/Tokyo", true)
                        .startOf("day")
                        .unix();
                      const truncatedCurrentSectionTokyoStartDate = dayjs
                        .unix(start)
                        .tz("Asia/Tokyo", true)
                        .startOf("day")
                        .unix();
                      const truncatedCurrentSectionTokyoEndDate = dayjs
                        .unix(end)
                        .tz("Asia/Tokyo", true)
                        .startOf("day")
                        .unix();

                      return (
                        (truncatedSelectedStartDate ===
                          truncatedCurrentSectionTokyoStartDate &&
                          time < startHour) ||
                        (end &&
                          truncatedSelectedStartDate ===
                            truncatedCurrentSectionTokyoEndDate &&
                          time > endHour) ||
                        (prevStoreItem?.end &&
                          truncatedSelectedStartDate ===
                            prevStoreItemDateEndDate &&
                          time < prevStoreItemEndHour)
                      );
                    }}
                    ampm={false}
                    inputFormat="yyyy/MM/dd HH:mm"
                    renderInput={(params) => (
                      <div style={{ position: "relative" }}>
                        <TextField
                          {...params}
                          error={params.inputProps.value ? false : true}
                          margin="normal"
                          fullWidth
                          size="small"
                          // onKeyDown={(e) => {
                          //   e.preventDefault();
                          // }}
                        />
                        <Grid
                          item
                          sx={{
                            ml: 1,
                            position: "absolute",
                            top: "24px",
                            right: "36px",
                          }}
                        >
                          <Typography variant="body1">(GMT+9)</Typography>
                        </Grid>
                      </div>
                    )}
                  />
                </LocalizationProvider>
              )}
            />
          </Grid>
          <Grid item xs={5}>
            <Controller
              name={`storeItems.${itemIndex}`}
              control={control}
              render={({ field }) => (
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={enGB}
                >
                  <DesktopDateTimePicker
                    {...field}
                    onChange={(val) =>
                      handleDesktopDateTimePickerChange(val, "availabilityEnd")
                    }
                    value={
                      field.value?.availabilityEnd
                        ? truncateMinutes(
                            dayjs
                              .unix(field.value.availabilityEnd as number)
                              .tz("Asia/Tokyo")
                          )
                        : null
                    }
                    views={["year", "month", "day", "hours"]}
                    shouldDisableDate={(date) => {
                      const truncatedPickerTokyoDate = dayjs(date)
                        .tz("Asia/Tokyo", true)
                        .startOf("day")
                        .unix();
                      const truncatedEndDate = dayjs
                        .unix(end)
                        .tz("Asia/Tokyo", true)
                        .add(1, "hour")
                        .startOf("day")
                        .unix();
                      const truncatedStartDate = dayjs
                        .unix(start)
                        .tz("Asia/Tokyo", true)
                        .add(1, "hour")
                        .startOf("day")
                        .unix();

                      const truncatedNextStoreItemStartDate = dayjs
                        .unix(nextStoreItem?.availabilityStart)
                        .tz("Asia/Tokyo", true)
                        .add(1, "hour")
                        .startOf("day")
                        .unix();

                      const truncatedStoreItemStartDate = dayjs
                        .unix(field.value?.availabilityStart)
                        .tz("Asia/Tokyo", true)
                        .startOf("day")
                        .unix();

                      return (
                        truncatedPickerTokyoDate <
                          truncatedStoreItemStartDate ||
                        truncatedPickerTokyoDate > truncatedEndDate ||
                        truncatedPickerTokyoDate < truncatedStartDate ||
                        (nextStoreItem?.start &&
                          truncatedPickerTokyoDate >
                            truncatedNextStoreItemStartDate)
                      );
                    }}
                    shouldDisableTime={(time) => {
                      const startHour = dayjs
                        .unix(start)
                        .tz("Asia/Tokyo")
                        .add(1, "h")
                        .get("h");
                      const endHour = dayjs.unix(end).tz("Asia/Tokyo").get("h");
                      const nextStoreItemStartHour = dayjs
                        .unix(nextStoreItem?.availabilityStart)
                        .tz("Asia/Tokyo")
                        .add(-1, "h")
                        .get("h");
                      const storeItemEndHour = dayjs
                        .unix(field?.value.availabilityStart)
                        .tz("Asia/Tokyo")
                        .add(1, "h")
                        .get("h");

                      const nextStoreItemStartDate = dayjs
                        .unix(nextStoreItem?.availabilityStart)
                        .tz("Asia/Tokyo", true)
                        .startOf("day")
                        .unix();

                      const truncatedDefaultEndDate = dayjs
                        .unix(field.value?.availabilityEnd as number)
                        .tz("Asia/Tokyo", true)
                        .startOf("day")
                        .unix();
                      const truncatedCurrentSectionTokyoStartDate = dayjs
                        .unix(start)
                        .tz("Asia/Tokyo", true)
                        .startOf("day")
                        .unix();
                      const truncatedCurrentSectionTokyoEndDate = dayjs
                        .unix(end)
                        .tz("Asia/Tokyo", true)
                        .startOf("day")
                        .unix();
                      const truncatedStoreItemStartDate = dayjs
                        .unix(field.value?.availabilityStart)
                        .tz("Asia/Tokyo", true)
                        .startOf("day")
                        .unix();

                      return (
                        (truncatedStoreItemStartDate &&
                          truncatedStoreItemStartDate ===
                            truncatedDefaultEndDate &&
                          time < storeItemEndHour) ||
                        (truncatedDefaultEndDate ===
                          truncatedCurrentSectionTokyoStartDate &&
                          time < startHour) ||
                        (end &&
                          truncatedDefaultEndDate ===
                            truncatedCurrentSectionTokyoEndDate &&
                          time > endHour) ||
                        (nextStoreItem?.start &&
                          truncatedDefaultEndDate === nextStoreItemStartDate &&
                          time > nextStoreItemStartHour)
                      );
                    }}
                    ampm={false}
                    inputFormat="yyyy/MM/dd HH:mm"
                    renderInput={(params) => (
                      <div style={{ position: "relative" }}>
                        <TextField
                          {...params}
                          error={params.inputProps.value ? false : true}
                          margin="normal"
                          fullWidth
                          size="small"
                          // onKeyDown={(e) => {
                          //   e.preventDefault();
                          // }}
                        />
                        <Grid
                          item
                          sx={{
                            ml: 1,
                            position: "absolute",
                            top: "24px",
                            right: "36px",
                          }}
                        >
                          <Typography variant="body1">(GMT+9)</Typography>
                        </Grid>
                      </div>
                    )}
                  />
                </LocalizationProvider>
              )}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="outlined"
              onClick={() => {
                setValue(`storeItems.${itemIndex}.availabilityStart`, null);
                setValue(`storeItems.${itemIndex}.availabilityEnd`, null);
              }}
              sx={{
                marginTop: "16px",
                height: "40px",
              }}
            >
              Clear
            </Button>
          </Grid>
        </Grid>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "0 10px",
          }}
        >
          <Button
            variant="outlined"
            onClick={handleCloseTimerangeModal}
            sx={{
              marginTop: "16px",
              height: "40px",
            }}
          >
            Set
          </Button>
        </Grid>
      </Box>
    </Modal>
  );
};
