import React from "react";
import { Button, Paper, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Dotted } from "./spinners/Dotted";

export const PaperPage = styled(Paper)({
  minHeight: "calc(100% - 60px)",
  display: "flex",
  flexDirection: "column",
  padding: "16px",
});

type PaperPageErrorProps = {
  refetch: () => void;
  disabled?: boolean;
  message: string;
};

export const PaperPageError = ({
  refetch,
  disabled,
  message,
}: PaperPageErrorProps) => {
  const { t } = useTranslation();

  return (
    <ErrorPaper>
      <ErrorMessage>{message}</ErrorMessage>
      <Button
        color="error"
        variant="outlined"
        onClick={refetch}
        disabled={disabled}
      >
        {t("tryAgain")}
      </Button>
    </ErrorPaper>
  );
};

export const PaperPageSpinner = () => (
  <LoadingPaper>
    <Dotted size={70} />
  </LoadingPaper>
);

const LoadingPaper = styled(PaperPage)({
  flexGrow: 1,
  justifyContent: "center",
  alignItems: "center",
  marginTop: "0",
  minHeight: "calc(100% - 60px)",
  borderRadius: "0",
  boxShadow: "none",
});

const ErrorPaper = styled(PaperPage)({
  alignItems: "center",
});

const ErrorMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  marginBottom: "10px",
}));
