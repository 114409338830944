import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import commonEn from "./locales/en/common";
import errorsEn from "./locales/en/errors";
import validationErrorsEn from "./locales/en/validationErrors";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      common: commonEn,
      errors: errorsEn,
      validationErrors: validationErrorsEn,
      users: {
        errors: {
          refetch: "Unable to load players, try again",
          add: "Something went wrong adding a player, try again",
          edit: "Something went wrong editing the player, try again",
          delete: "Something went wrong deleting the player, try again",
        },
      },
    },
  },
  lng: "en",
  defaultNS: "common",
  interpolation: { escapeValue: false },
});

export default i18n;
