import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { getUrl, prepareHeaders } from "../../utils/api";
import { VirtualGood, VirtualGoodsType } from "./types";
import {
  SearchPaginateRequestBody,
  PaginateResponse,
  EditRequestBody,
} from "../../types";
import { CreateVirtualGoodFormValues } from "../../container/VirtualGoods/types";
import {
  COLLECTION_NAMES,
  CREATE_ITEM_TYPE,
  DB_NAMES,
  ROUTE_PATHS,
} from "../../consts";
import { ListRequestBodyDto } from "../lists/types";

const { SEARCH, UPDATE, CREATE } = ROUTE_PATHS;

export const virtualGoodsApi = createApi({
  reducerPath: "virtualGoodsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: getUrl("/"),
    prepareHeaders,
  }),
  tagTypes: ["VirtualGood"],
  endpoints: (builder) => ({
    getVirtualGoods: builder.query<any, any>({
      query: (body) => {
        return {
          url: `${ROUTE_PATHS.LISTS}`,
          method: "POST",
          body: {
            listName: body,
          },
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ _id: id }) => ({
                type: "VirtualGood" as const,
                id,
              })),
              { type: "VirtualGood", id: "LIST" },
            ]
          : [{ type: "VirtualGood", id: "LIST" }],
    }),

    editVirtualGood: builder.mutation<VirtualGood, EditRequestBody>({
      query: (body) => ({ url: `${SEARCH}${UPDATE}`, method: "POST", body }),
      invalidatesTags: [{ type: "VirtualGood", id: "LIST" }],
    }),

    createVirtualGood: builder.mutation<
      CreateVirtualGoodFormValues,
      CreateVirtualGoodFormValues
    >({
      query: (body) => ({
        url: `${SEARCH}${CREATE}`,
        method: "POST",
        body: {
          data: { ...body, __type: CREATE_ITEM_TYPE.virtualGood },
          dbName: DB_NAMES.META,
          collectionName: COLLECTION_NAMES.VIRTUAL_GOODS,
        },
      }),
    }),
  }),
});

export const {
  useGetVirtualGoodsQuery,
  useEditVirtualGoodMutation,
  useCreateVirtualGoodMutation,
} = virtualGoodsApi;
