import { QueryListName } from "../../../slices/lists/types";
import { ArrayItemType, InputType } from "../consts";
import { DynamicSchema } from "../types";

export const questsSchema: DynamicSchema = {
  _root: {
    type: InputType.root,
    children: {
      _id: {
        type: InputType.text,
        label: "ID",
        additionalFields: [],
        children: {},
        isDisabled: true,
      },
      baseProbability: {
        type: InputType.numeric,
        label: "baseProbability",
        placeholder: "Edit baseProbability",
        additionalFields: [],
        children: {},
      },
      questID: {
        type: InputType.text,
        label: "questID",
        placeholder: "Edit questID",
        additionalFields: [],
        children: {},
      },
      questRarity: {
        type: InputType.numeric,
        label: "questRarity",
        placeholder: "Edit questRarity",
        additionalFields: [],
        children: {},
      },
      tags: {
        type: InputType.arrayOfSimpleValues,
        label: "tags",
        placeholder: "Edit tags",
        additionalFields: [],
        children: {},
        listName: QueryListName.virtualGoodTags,
      },
      characters: {
        type: InputType.arrayOfSimpleValues,
        label: "Characters",
        placeholder: "Edit characters",
        additionalFields: [],
        children: {},
        listName: QueryListName.character,
      },
      sequelCharacters: {
        type: InputType.arrayOfSimpleValues,
        label: "Characters",
        placeholder: "Edit sequelCharacters",
        additionalFields: [],
        children: {},
        listName: QueryListName.character,
      },
      disabled: {
        type: InputType.checkbox,
        label: "disabled",
        placeholder: "Edit disabled",
        additionalFields: [],
        children: {},
        isShownWithoutData: true,
      },
      data: {
        type: InputType.link,
        label: "Data",
        additionalFields: [],
        children: {
          questType: {
            type: InputType.text,
            label: "questType",
            placeholder: "Edit questType",
            additionalFields: [],
            children: {},
          },
          name: {
            type: InputType.text,
            label: "name",
            placeholder: "Edit name",
            additionalFields: [],
            children: {},
            isDisabled: true,
          },
          description: {
            type: InputType.text,
            label: "description",
            placeholder: "Edit description",
            additionalFields: [],
            children: {},
          },
          completed: {
            type: InputType.checkbox,
            label: "completed",
            placeholder: "Edit completed",
            additionalFields: [],
            children: {},
            isDisabled: true,
          },
          dialog: {
            type: InputType.link,
            label: "dialog",
            additionalFields: [],
            children: {
              start: {
                type: InputType.text,
                label: "start",
                placeholder: "Edit start",
                additionalFields: [],
                children: {},
              },
              quest: {
                type: InputType.text,
                label: "quest",
                placeholder: "Edit quest",
                additionalFields: [],
                children: {},
              },
              completed: {
                type: InputType.text,
                label: "completed",
                placeholder: "Edit completed",
                additionalFields: [],
                children: {},
              },
            },
          },
          _spawnTable_requirements: {
            type: InputType.link,
            label: "_spawnTable_requirements",
            additionalFields: [],
            children: {
              items: {
                type: InputType.link,
                label: "items",
                additionalFields: [],
                children: {},
                arrayItemType: ArrayItemType.questRequirement,
              },
            },
          },
          rewards: {
            type: InputType.link,
            label: "rewards",
            additionalFields: [],
            children: {},
            arrayItemType: ArrayItemType.questRewards,
          },
        },
      },
    },
  },
};
