export default {
  required: "This field can not be empty",
  incorrectEmail: "Email format is wrong",
  incorrectName: "Should contain only letters and dots",
  incorrectPassword: "Password format is wrong",
  tooShort: "Shouldn't be shorter than {{minLength}} characters",
  tooLong: "Shouldn't be longer than {{maxLength}} characters",
  oneOrMoreRole: "User must have at least one role",
  atLeastOneNumAndChar: "At least one character and one number are required",
  passwordMustMatch: "Passwords must match",
};
