import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/dist/query";

import { logOut } from "../slices/auth/actions";
import { useGetListQuery } from "../slices/lists/api";
import { useDispatch } from "../store";
import {
  QueryListName,
  ListNameType,
  StaticListName,
} from "../slices/lists/types";
import { ERROR_STATUS_UNAUTHORIZED, ROUTE_PATHS } from "../consts";
import {
  characterTagsValues,
  currencyList,
  rarityValues,
} from "../components/inputs/consts";

type ResultType = {
  listData: (string | number)[];
  isLoadingListData: boolean;
  isFetchingListData: boolean;
  listDataError?: unknown;
  refetchListData?: () => void;
};

export const useGetLists = (listName: ListNameType) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const queryListName = useMemo(() => {
    const isStaticListName = Object.values(StaticListName).some(
      (x) => listName === x
    );

    return isStaticListName ? skipToken : (listName as `${QueryListName}`);
  }, [listName]);

  const {
    isLoading: isLoadingQueryList,
    isFetching: isFetchingQueryList,
    data: queryList,
    error: queryListError,
    refetch: queryListRefetch,
  } = useGetListQuery(queryListName);

  if (
    queryListError &&
    "status" in queryListError &&
    queryListError.status === ERROR_STATUS_UNAUTHORIZED
  ) {
    dispatch(logOut());
    navigate(ROUTE_PATHS.SIGN_IN);
  }

  const result: ResultType = {
    isLoadingListData: false,
    isFetchingListData: false,
    listData: [],
  };

  switch (listName) {
    case StaticListName.characterTags:
      result.listData = characterTagsValues;
      break;

    case StaticListName.rarityValues:
      result.listData = rarityValues;
      break;

    case StaticListName.virtualGoodCurrencies:
      result.listData = currencyList;
      break;

    case QueryListName.character:
    case QueryListName.sceneType:
    case QueryListName.virtualGoodShortCodes:
    case QueryListName.virtualGoodTags:
      result.listData = queryList;
      result.isLoadingListData = isLoadingQueryList;
      result.isFetchingListData = isFetchingQueryList;
      result.listDataError = queryListError;
      result.refetchListData = queryListRefetch;
      break;

    default:
      break;
  }

  return result;
};
