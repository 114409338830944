import React, { memo } from "react";

import MUIRichTextEditor, { TMUIRichTextEditorStyles } from "mui-rte";
import { createTheme, Stack, TextField, Theme } from "@mui/material";

import { ThemeProvider } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { EditorState } from "draft-js";

const defaultTheme: Theme = createTheme({});
const myTheme: TMUIRichTextEditorStyles = {
  overrides: {
    MUIRichTextEditor: {
      editor: {
        minHeight: 150,
        maxHeight: 350,
        overflowY: "auto",
        padding: 16,
        borderRadius: 4,
        border: "1px solid #0000003b",
      },
      editorContainer: {
        marginTop: 0,
      },
      toolbar: {
        marginBottom: 8,
        borderRadius: 4,
        backgroundColor: "#f5f5f5",
      },
      placeHolder: {
        position: "absolute",
        width: "100%",
        padding: 16,
        cursor: "text",
        color: "#00000061",
      },
    },
  },
};
const theme = { ...defaultTheme, ...myTheme };

type ChatUIEmailDraftProps = {
  handleChangeEmailSubject: (e: any) => void;
  handleChangeEmailBody: (state: EditorState) => void;
  editorKey: number;
  subject: string;
};

const ChatUIEmailDraft = ({
  handleChangeEmailSubject,
  handleChangeEmailBody,
  editorKey,
  subject,
}: ChatUIEmailDraftProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={2} width="100%">
        <h3 style={{ margin: 0 }}>{t("supportChats.emailSubject")}:</h3>
        <TextField
          variant="outlined"
          size="small"
          multiline
          sx={{ maxWidth: "30rem", flexGrow: 1 }}
          label={t("supportChats.emailSubject")}
          value={subject}
          onChange={handleChangeEmailSubject}
        />
      </Stack>
      <Stack width="100%">
        <h3 style={{ margin: 0 }}>{t("supportChats.emailBody")}:</h3>
        <ThemeProvider theme={theme}>
          <MUIRichTextEditor
            key={editorKey}
            label={t("supportChats.emailBodyPlaceholder")}
            onChange={handleChangeEmailBody}
            controls={[
              "title",
              "bold",
              "italic",
              "underline",
              "strikethrough",
              "bulletList",
              "numberList",
              "link",
              "code",
              "undo",
              "redo",
              "clear",
            ]}
          />
        </ThemeProvider>
      </Stack>
    </>
  );
};

export default memo(ChatUIEmailDraft);
