import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";

import { Box, Button, Stack } from "@mui/material";
import RewardItem from "../../../container/Quests/RewardItem";
import { CustomErrorBox } from "../../inputs/StyledComponents";
import { SpinnerButton } from "../../SpinnerButton";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useFeedError, useFeedSuccess } from "../../../utils/feedHooks";
import { useEditQuestMutation } from "../../../slices/quests/api";
import {
  RequirementItemDataType,
  RewardItemDataType,
} from "../../../container/Quests/types";
import {
  addRequirementInitialValues,
  addRewardInitialValues,
  ArrayItemType,
} from "../consts";
import { COLLECTION_NAMES, DB_NAMES } from "../../../consts";

const getNewItemParams = (
  data: RewardItemDataType | RequirementItemDataType,
  itemType: `${ArrayItemType}`,
  pathToArray: string[],
  arrayCount: number
) => {
  const path = `${pathToArray.join(".")}.${arrayCount}`;
  let value = null;

  switch (itemType) {
    case ArrayItemType.questRewards:
      {
        const currData = data as RewardItemDataType;
        value = {
          _randomIntAmount_amount: {
            min: currData.questRewardsMin_1,
            max: currData.questRewardsMax_1,
          },
          currentAmount: 1,
          ...(currData.questRewardsCustom_1
            ? {
                typeID: currData.questRewardsCustomValue_1,
              }
            : {
                _randomItemObject_typeID: {
                  ...(currData.questRewardsTags_1
                    ? {
                        tags: currData.questRewardsTagsValue_1,
                      }
                    : {}),
                  ...(currData.questRewardsRarity_1
                    ? {
                        rarities: currData.questRewardsRarityValue_1,
                      }
                    : {}),
                },
              }),
        };
      }
      break;

    case ArrayItemType.questRequirement:
      {
        const currData = data as RequirementItemDataType;
        value = {
          _randomIntAmount_amount: {
            min: currData.questRequirementMin_1,
            max: currData.questRequirementMax_1,
          },
          currentAmount: 1,
          ...(currData.questRequirementCustom_1
            ? {
                typeID: currData.questRequirementCustomValue_1,
              }
            : {
                _randomItemObject_typeID: {
                  ...(currData.questRequirementTags_1
                    ? {
                        tags: currData.questRequirementTagsValue_1,
                      }
                    : {}),
                  ...(currData.questRequirementRarity_1
                    ? {
                        rarities: currData.questRequirementRarityValue_1,
                      }
                    : {}),
                },
              }),
        };
      }
      break;

    default:
      break;
  }

  return [
    {
      value,
      path,
    },
  ];
};

type AddRewardProps = {
  arrayCount: 1 | 2 | 3;
  itemType: ArrayItemType.questRequirement | ArrayItemType.questRewards;
  setIsShown: Dispatch<SetStateAction<boolean>>;
  pathToArray: string[];
  id: string;
};

const AddReward = ({
  arrayCount,
  itemType,
  setIsShown,
  pathToArray,
  id,
}: AddRewardProps) => {
  const { t } = useTranslation();
  const feedError = useFeedError();
  const feedSuccess = useFeedSuccess();
  const [editItem, { isLoading: isEditLoading }] = useEditQuestMutation();

  const defaultValues = useMemo(() => {
    switch (itemType) {
      case ArrayItemType.questRewards:
        return addRewardInitialValues;
      case ArrayItemType.questRequirement:
        return addRequirementInitialValues;

      default:
        break;
    }
  }, [itemType]);
  const { control, handleSubmit, resetField, setError, clearErrors } = useForm<
    RewardItemDataType | RequirementItemDataType
  >({ defaultValues });

  const [areAllInputsEmpty, setAreAllInputsEmpty] = useState(true);

  const createQuest = useCallback(
    (data: RewardItemDataType | RequirementItemDataType) => {
      if (!areAllInputsEmpty) {
        const newParams = getNewItemParams(
          data,
          itemType,
          pathToArray,
          arrayCount
        );

        try {
          const result = editItem({
            dbName: DB_NAMES.META,
            collectionName: COLLECTION_NAMES.QUESTS_DATA,
            id,
            newParams,
            isIncremented: false,
          }).unwrap();

          if (result) {
            feedSuccess(t("createQuest.successMsgAfterSubmit"));
            setIsShown(false);
          } else {
            feedError(t("createQuest.errorMsgAfterSubmit"));
          }
        } catch (error) {
          feedError(t("createQuest.errorMsgAfterSubmit"));
        }
      }
    },
    [
      areAllInputsEmpty,
      arrayCount,
      editItem,
      feedError,
      feedSuccess,
      id,
      itemType,
      pathToArray,
      setIsShown,
      t,
    ]
  );

  const onCancelClick = useCallback(() => {
    setIsShown(false);
  }, [setIsShown]);

  return (
    <form onSubmit={handleSubmit(createQuest)}>
      <Stack mb={4}>
        <RewardItem
          control={control}
          name={itemType}
          resetField={resetField}
          setError={setError}
          clearErrors={clearErrors}
          setAreAllInputsEmpty={setAreAllInputsEmpty}
          isAddNewItem
        />
      </Stack>
      <Stack direction="row" justifyContent="space-between" spacing={2}>
        <Box>
          {areAllInputsEmpty && (
            <CustomErrorBox>
              {t("players.addArrayItem.addRewardRequiredInputsMsg")}
            </CustomErrorBox>
          )}
        </Box>
        <Stack direction="row" spacing={2}>
          <Button variant="outlined" onClick={onCancelClick}>
            {t("buttonNames.cancel")}
          </Button>
          <SpinnerButton
            type="submit"
            variant="outlined"
            buttonName={t("buttonNames.addItem")}
            loading={isEditLoading}
          />
        </Stack>
      </Stack>
    </form>
  );
};

export default AddReward;
