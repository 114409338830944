import React, { memo } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import TreeView from "@mui/lab/TreeView";
import TreeItem, { TreeItemProps, treeItemClasses } from "@mui/lab/TreeItem";
import Typography from "@mui/material/Typography";
import PrivacyTipOutlinedIcon from "@mui/icons-material/PrivacyTipOutlined";
import Label from "@mui/icons-material/AutoAwesomeMotionOutlined";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import MapIcon from "@mui/icons-material/Map";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import InsightsIcon from "@mui/icons-material/Insights";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import { SvgIconProps } from "@mui/material/SvgIcon";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import StorefrontIcon from "@mui/icons-material/Storefront";

declare module "react" {
  interface CSSProperties {
    "--tree-view-color"?: string;
    "--tree-view-bg-color"?: string;
  }
}

type ParentTreeItemProps = TreeItemProps & {
  bgColor?: string;
  color?: string;
  labelIcon: React.ElementType<SvgIconProps>;
  labelInfo?: string;
  labelText: string;
};

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  [`& .${treeItemClasses.content}`]: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    "&.Mui-expanded": {
      fontWeight: theme.typography.fontWeightBold,
    },
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
    "&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
      color: "var(--tree-view-color)",
    },
    [`& .${treeItemClasses.label}`]: {
      fontWeight: "inherit",
      color: "inherit",
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 0,
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: theme.spacing(2),
    },
  },
}));

const ParentTreeItem = ({
  bgColor,
  color,
  labelIcon: LabelIcon,
  labelInfo,
  labelText,
  ...other
}: ParentTreeItemProps) => (
  <StyledTreeItemRoot
    label={
      <Box sx={{ display: "flex", alignItems: "center", p: 0.5, pr: 0 }}>
        <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
        <Typography variant="body2" sx={{ fontWeight: "inherit", flexGrow: 1 }}>
          {labelText}
        </Typography>
        <Typography variant="caption" color="inherit">
          {labelInfo}
        </Typography>
      </Box>
    }
    style={{
      "--tree-view-color": color,
      "--tree-view-bg-color": bgColor,
    }}
    {...other}
  />
);

import { Divider, Drawer, IconButton, Toolbar } from "@mui/material";
import { ChevronLeft, AutoAwesome, SystemUpdateAlt } from "@mui/icons-material";
import BuildIcon from "@mui/icons-material/Build";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AssessmentIcon from "@mui/icons-material/Assessment";
import CustomTreeItem from "./CustomTreeItem";

import { useTranslation } from "react-i18next";
import { useSelector } from "../../../store";
import { getUnreadChatIds } from "../../../slices/Websocket/slice";

import { ROLE, ROUTE_PATHS } from "../../../consts";
import { getCurrentRoles } from "../../../slices/Roles/slice";
import { Roles } from "../../../types";
import { useMatch } from "react-router-dom";

const {
  SUPPORT,
  PLAYERS,
  EVENT_CREATOR,
  V_GOODS,
  QUESTS,
  STORE_CONTENT,
  DEPLOYMENT,
  MAINTENANCE,
  GROUP_MANAGEMENT,
  DISTRIBUTION_SYSTEM,
  DELETE_PLAYERS,
  MAP_CONTENT,
  SERVICE_STATUS,
  NEW_PLAYERS_KPI,
  DAILY_ACTIVE_PLAYERS_KPI,
  MONTHLY_ACTIVE_PLAYERS_KPI,
  STEPS_KPI,
  WALK_DISTANCE_KPI,
} = ROUTE_PATHS;
const { Admin, PlayerSupport, PlayerSupportAdvanced, GameContentDeveloper, ViewAnalyticsData } = ROLE;

type NavigationDrawerProps = {
  open: boolean;
  closeDrawer: () => void;
};

const NavigationDrawer = ({ open, closeDrawer }: NavigationDrawerProps) => {
  const { t } = useTranslation();
  const match = useMatch("/:path");
  const unreadChatIds = useSelector(getUnreadChatIds);
  const currentRoles = useSelector(getCurrentRoles);

  const checkPermittedRoles = (permittedRoles: Roles) => {
    return permittedRoles.some((role) => currentRoles.includes(role));
  };

  return (
    <StyledDrawer variant="persistent" open={open}>
      <CloseButtonBar>
        <IconButton onClick={closeDrawer}>
          <ChevronLeft />
        </IconButton>
      </CloseButtonBar>
      <Divider />

      <TreeView
        defaultExpanded={[
          "Game content",
          "Player support",
          "Admin",
          "Analytics",
        ]}
        defaultCollapseIcon={<ArrowDropDownIcon />}
        defaultExpandIcon={<ArrowRightIcon />}
        defaultEndIcon={<div style={{ width: 24 }} />}
        sx={{ height: 264, flexGrow: 1, maxWidth: 400, overflowY: "auto" }}
        defaultSelected={match?.pathname}
      >
        {checkPermittedRoles([Admin, GameContentDeveloper]) && (
          <ParentTreeItem
            nodeId="Game content"
            labelText={t("navigation.gameContent")}
            labelIcon={Label}
          >
            <CustomTreeItem
              to={EVENT_CREATOR}
              nodeId={EVENT_CREATOR}
              icon={CalendarMonthIcon}
              label={t("navigation.eventCreator")}
              isHidden={checkPermittedRoles([Admin, GameContentDeveloper])}
            />
            <CustomTreeItem
              to={V_GOODS}
              nodeId={V_GOODS}
              icon={AutoAwesome}
              label={t("navigation.virtualGoods")}
              isHidden={checkPermittedRoles([Admin, GameContentDeveloper])}
            />
            <CustomTreeItem
              nodeId={MAP_CONTENT}
              to={MAP_CONTENT}
              icon={MapIcon}
              label={t("navigation.mapContent")}
              isHidden={checkPermittedRoles([Admin, GameContentDeveloper])}
            />
            <CustomTreeItem
              nodeId={QUESTS}
              to={QUESTS}
              icon={DirectionsRunIcon}
              label={t("navigation.quests")}
              isHidden={checkPermittedRoles([Admin, GameContentDeveloper])}
            />
            <CustomTreeItem
              nodeId={STORE_CONTENT}
              to={STORE_CONTENT}
              icon={StorefrontIcon}
              label={t("navigation.storeContent")}
              isHidden={checkPermittedRoles([Admin, GameContentDeveloper])}
            />
          </ParentTreeItem>
        )}
        {checkPermittedRoles([Admin, PlayerSupport]) && (
          <ParentTreeItem
            nodeId="Player support"
            labelText={t("navigation.playerSupport")}
            labelIcon={Label}
          >
            <CustomTreeItem
              to={PLAYERS}
              nodeId={PLAYERS}
              icon={SupervisorAccountIcon}
              label={t("navigation.players")}
              isHidden={checkPermittedRoles([Admin])}
            />
            <CustomTreeItem
              to={SUPPORT}
              nodeId={SUPPORT}
              icon={PrivacyTipOutlinedIcon}
              label={t("navigation.support")}
              unreadChatsCount={unreadChatIds.length}
              isHidden={checkPermittedRoles([Admin, PlayerSupport])}
            />
            <CustomTreeItem
              to={GROUP_MANAGEMENT}
              nodeId={GROUP_MANAGEMENT}
              icon={ManageAccountsIcon}
              label={t("navigation.groupManagement")}
              isHidden={checkPermittedRoles([Admin, PlayerSupport])}
            />
          </ParentTreeItem>
        )}
        {checkPermittedRoles([Admin,PlayerSupportAdvanced]) && (
          <ParentTreeItem
            nodeId="Admin"
            labelText={t("navigation.admin")}
            labelIcon={Label}
          >
            {checkPermittedRoles([Admin, PlayerSupportAdvanced]) && (<CustomTreeItem
              to={DISTRIBUTION_SYSTEM}
              nodeId={DISTRIBUTION_SYSTEM}
              icon={AssessmentIcon}
              label={t("navigation.distributionSystem")}
              isHidden={checkPermittedRoles([Admin, PlayerSupportAdvanced])}
            />)}
            {checkPermittedRoles([Admin]) && (
            <CustomTreeItem
              to={MAINTENANCE}
              nodeId={MAINTENANCE}
              icon={BuildIcon}
              label={t("navigation.maintenance")}
              isHidden={checkPermittedRoles([Admin])}
            />)}
            {checkPermittedRoles([Admin]) && (
            <CustomTreeItem
              to={DELETE_PLAYERS}
              nodeId={DELETE_PLAYERS}
              icon={PersonRemoveIcon}
              label={t("navigation.deletePlayers")}
              isHidden={checkPermittedRoles([Admin])}
            />)}
            {checkPermittedRoles([Admin]) && (
            <CustomTreeItem
              to={DEPLOYMENT}
              nodeId={DEPLOYMENT}
              icon={SystemUpdateAlt}
              label={t("navigation.deployment")}
              isHidden={checkPermittedRoles([Admin])}
            />)}
            {checkPermittedRoles([Admin]) && (
            <CustomTreeItem
              to={SERVICE_STATUS}
              nodeId={SERVICE_STATUS}
              icon={SettingsSuggestIcon}
              label={t("navigation.serviceStatus")}
              isHidden={checkPermittedRoles([Admin])}
            />)}
          </ParentTreeItem>
        )}
        {checkPermittedRoles([Admin, ViewAnalyticsData]) && (
          <ParentTreeItem
            nodeId="Analytics"
            labelText={t("navigation.analytics")}
            labelIcon={Label}
          >
            {/* <CustomTreeItem
              to={NEW_PLAYERS_KPI}
              nodeId={NEW_PLAYERS_KPI}
              icon={InsightsIcon}
              label={t("navigation.newPlayersKpi")}
              isHidden={checkPermittedRoles([Admin, ViewAnalyticsData])}
            /> */}
            <CustomTreeItem
              to={DAILY_ACTIVE_PLAYERS_KPI}
              nodeId={DAILY_ACTIVE_PLAYERS_KPI}
              icon={InsightsIcon}
              label={t("navigation.dailyActivePlayersKpi")}
              isHidden={checkPermittedRoles([Admin, ViewAnalyticsData])}
            />
            <CustomTreeItem
              to={MONTHLY_ACTIVE_PLAYERS_KPI}
              nodeId={MONTHLY_ACTIVE_PLAYERS_KPI}
              icon={InsightsIcon}
              label={t("navigation.monthlyActivePlayersKpi")}
              isHidden={checkPermittedRoles([Admin, ViewAnalyticsData])}
            />
            <CustomTreeItem
              to={STEPS_KPI}
              nodeId={STEPS_KPI}
              icon={InsightsIcon}
              label={t("navigation.stepsKpi")}
              isHidden={checkPermittedRoles([Admin, ViewAnalyticsData])}
            />
            <CustomTreeItem
              to={WALK_DISTANCE_KPI}
              nodeId={WALK_DISTANCE_KPI}
              icon={InsightsIcon}
              label={t("navigation.walkDistanceKpi")}
              isHidden={checkPermittedRoles([Admin, ViewAnalyticsData])}
            />
          </ParentTreeItem>
        )}
      </TreeView>
    </StyledDrawer>
  );
};

export default memo(NavigationDrawer);

const StyledDrawer = styled(Drawer)<{ open: boolean }>(({ open }) => ({
  width: open ? "240px" : 0,
  flexShrink: 0,
  "& .MuiDrawer-paper": {
    width: "240px",
  },
}));

const CloseButtonBar = styled(Toolbar)({
  display: "flex",
  flexDirection: "row-reverse",
});
