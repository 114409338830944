export const rewardTopDropDown = [
  { value: "tickets", label: "tickets" },
  { value: "rubies", label: "rubies" },
  { value: "blueberry", label: "blueberry" },
  { value: "coins", label: "coins" },
];

export const rarityDropDown = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
];

export const typeAmountDropDown = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
];

export const eventSeasonDropDown = [
  { value: "all time", label: "all time" },
  { value: "winter", label: "winter" },
  { value: "summer", label: "summer" },
  { value: "spring", label: "spring" },
  { value: "fall", label: "fall" },
];
