import React from "react";
import { Button, ButtonProps } from "@mui/material";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { useTranslation } from "react-i18next";

type ChatCancelBtnProps = ButtonProps & {
  timeToCount: number;
  onCancel: () => void;
  onTimeOut: () => void;
};

export const ChatCancelBtn = ({
  timeToCount,
  onCancel,
  onTimeOut,
  ...buttonProps
}: ChatCancelBtnProps) => {
  const { t } = useTranslation();

  return (
    <Button
      {...buttonProps}
      variant="text"
      size="small"
      onClick={onCancel}
      startIcon={
        <CountdownCircleTimer
          isPlaying
          duration={timeToCount}
          colors="rgb(54, 173, 71)"
          size={20}
          strokeWidth={3}
          onComplete={onTimeOut}
        />
      }
    >
      {t("buttonNames.cancel")}
    </Button>
  );
};
