import * as yup from "yup";
import { DynamicSchema } from "../types";
import { InputType } from "../consts";

const numericYupRule = yup.number().integer().min(0);

export const playerPersonalValleySchema: DynamicSchema = {
  _root: {
    type: InputType.root,
    children: {
      _id: {
        type: InputType.text,
        label: "ID",
        isDisabled: true,
        additionalFields: [],
        children: {},
      },
      craftedItems: {
        type: InputType.link,
        label: "craftedItems",
        additionalFields: [],
        children: {
          flower_white: {
            type: InputType.numeric,
            label: "flower_white",
            yupRules: numericYupRule,
            additionalFields: [],
            children: {},
          },
          house_hemulen: {
            type: InputType.numeric,
            label: "house_hemulen",
            yupRules: numericYupRule,
            additionalFields: [],
            children: {},
          },
          flowerbed_yellow: {
            type: InputType.numeric,
            label: "flowerbed_yellow",
            yupRules: numericYupRule,
            additionalFields: [],
            children: {},
          },
          lantern_pole: {
            type: InputType.numeric,
            label: "lantern_pole",
            yupRules: numericYupRule,
            additionalFields: [],
            children: {},
          },
          wheelbarrow: {
            type: InputType.numeric,
            label: "wheelbarrow",
            yupRules: numericYupRule,
            additionalFields: [],
            children: {},
          },
          birch_red_lantern: {
            type: InputType.numeric,
            label: "birch_red_lantern",
            yupRules: numericYupRule,
            additionalFields: [],
            children: {},
          },
          tent_party: {
            type: InputType.numeric,
            label: "tent_party",
            yupRules: numericYupRule,
            additionalFields: [],
            children: {},
          },
        },
      },
    },
  },
};
