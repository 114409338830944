export const playerSearchFieldsBack = [
  {
    key: "displayName",
    type: "text",
    label: "Display Name",
    typeofData: "string",
  },
  {
    key: "_id",
    type: "text",
    label: "ID",
    typeofData: "string",
  },
  {
    key: "email",
    type: "text",
    label: "email address",
    typeofData: "string",
  },
  // {
  //   key: "lastSeen",
  //   type: "data",
  //   label: "lastSeen date",
  // },
  // {
  //   key: "country",
  //   type: "text",
  //   label: "country",
  // },
];
