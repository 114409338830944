import React, { useCallback, useEffect, useState } from "react";

import { Button, Stack, Typography } from "@mui/material";
import { Circular } from "../../components/spinners/Circular";
import AlertMessage from "../../components/AlertMessage";
import VirtualTable from "../../components/CustomDataGrid/VirtualTable";

import { getJPTime } from "../../utils/getJPTime";
import { useTranslation } from "react-i18next";
import { useGetDistributionsQuery } from "../../slices/distributionSystem/api";
import { DistributionSystemStatus } from "./types";
import { ColumnData } from "../../components/CustomDataGrid/types";
import { DistributionSystemResponse } from "../../slices/distributionSystem/types";

const dataTableColumns: ColumnData<DistributionSystemResponse>[] = [
  {
    width: 70,
    label: "N",
    dataKey: "id",
    renderCell: (rowData, rowIndex: number) => rowIndex + 1,
  },
  {
    width: 200,
    label: "Admin ID",
    dataKey: "adminID",
    renderCell: ({ adminID }) => adminID,
  },
  {
    width: 100,
    label: "Status",
    dataKey: "status",
    renderCell: ({ status }) => status,
  },
  {
    width: 200,
    label: "Created at",
    dataKey: "timestampCreated",
    renderCell: ({ timestampCreated }) => getJPTime(timestampCreated),
  },
  {
    width: 250,
    label: "Currencies",
    dataKey: "currencies",
    renderCell: ({ currencies }) =>
      currencies?.length > 0
        ? currencies.reduce(
            (result: string, currency, idx: number) =>
              idx === 0
                ? `${Object.keys(currency)}: ${Object.values(currency)}`
                : `${result}, ${Object.keys(currency)}: ${Object.values(
                    currency
                  )}`,
            ""
          )
        : null,
  },
  {
    width: 300,
    label: "Goods",
    dataKey: "goods",
    renderCell: ({ goods }) =>
      goods?.length > 0
        ? goods.reduce(
            (result: string, good, idx: number) =>
              idx === 0
                ? `${Object.keys(good)}: ${Object.values(good)}`
                : `${result}, ${Object.keys(good)}: ${Object.values(good)}`,
            ""
          )
        : null,
  },
];

const ScheduledDistributionsCount = () => {
  const { t } = useTranslation();
  const [scheduledCount, setScheduledCount] = useState(0);
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const {
    data: distributionsData,
    isLoading: isLoadingDistributions,
    isFetching: isFetchingDistributions,
  } = useGetDistributionsQuery({
    search: {
      status: DistributionSystemStatus.active,
    },
    page: 1,
    limit: 1,
    pagination: false,
  });

  useEffect(() => {
    if (distributionsData?.docs?.length > 0) {
      setScheduledCount(distributionsData.docs.length);
    }
  }, [distributionsData?.docs?.length]);

  const handleShowDetailsClick = useCallback(() => {
    setIsAlertOpen(true);
  }, []);

  const handleCloseAlert = useCallback(() => {
    setIsAlertOpen(false);
  }, []);

  return (
    <>
      <Stack direction="row" alignItems="center" mb={5} spacing={2}>
        {isLoadingDistributions || isFetchingDistributions ? (
          <Circular />
        ) : (
          <>
            <Typography
              variant="body1"
              color={scheduledCount > 0 ? "info.main" : "inherit"}
            >
              {t("distributionSystem.upcomingScheduledDistributions")}:{" "}
              <b>{scheduledCount}</b>
            </Typography>
            {scheduledCount > 0 && (
              <Button
                variant="outlined"
                size="small"
                onClick={handleShowDetailsClick}
              >
                {t("buttonNames.showDetails")}
              </Button>
            )}
          </>
        )}
      </Stack>
      <AlertMessage
        isShown={isAlertOpen}
        onCancel={handleCloseAlert}
        title={t("distributionSystem.upcomingScheduledDistributions")}
        textCancel={t("buttonNames.close")}
        maxWidth="lg"
      >
        <Stack height="35rem">
          <VirtualTable
            data={distributionsData?.docs}
            columns={dataTableColumns}
          />
        </Stack>
      </AlertMessage>
    </>
  );
};

export default ScheduledDistributionsCount;
