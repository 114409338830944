import React, { memo, SyntheticEvent } from "react";
import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  styled,
  TextField,
  IconButton,
  FormControl,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { DropDownSearch } from "./SearchToolbar/DropDownSearch";
import { SearchFields } from "./SearchToolbar/SearchFields";
import { searchFieldsBackType } from "../DataTable/DataTableTypes";
import { timeIntervalType } from "./SearchToolbar/SearchFields";
import { SearchParams } from "../../types";

export type SearchToolbarProps = {
  addSearchData: (
    value: string | number | Date | null | timeIntervalType
  ) => void;
  transferSearchData: () => void;
  addTypeOfSearchData: (typeOfSearchData: string) => void;
  getAllItems: () => void;
  searchData: SearchParams;
  searchValueType: string;
  searchInFront: string;
  setSearchInFront: (value: string) => void;
  onReset: () => void;
  searchFieldsBack: searchFieldsBackType[];
  filter?: React.ReactNode;
  isSupportTable: boolean;
  isClosedIssuesShown?: boolean;
  handleChangeShowClosedIssues?: (
    event: SyntheticEvent,
    checked: boolean
  ) => void;
};

const SearchToolbar = ({
  getAllItems,
  addSearchData,
  transferSearchData,
  addTypeOfSearchData,
  searchData,
  searchValueType,
  searchInFront,
  setSearchInFront,
  onReset,
  searchFieldsBack,
  filter,
  isSupportTable = false,
  isClosedIssuesShown = false,
  handleChangeShowClosedIssues,
}: SearchToolbarProps) => {
  const { t } = useTranslation();

  let searchValue;
  if (searchData) {
    searchValue = searchData[searchValueType];
  }
  if (searchData === null) {
    searchValue = "";
  }

  return (
    <Box mb={1}>
      <ContainerBox>
        <SubContainerBoxLeft>
          <DropDownSearch
            addTypeOfSearchData={addTypeOfSearchData}
            searchFieldsBack={searchFieldsBack}
          />
          <SearchFields
            searchValueType={searchValueType}
            searchFieldsBack={searchFieldsBack}
            error={false}
            searchData={searchValue}
            addSearchData={addSearchData}
          />
          <Button
            sx={{ height: 40, width: 50, ml: 1 }}
            variant="outlined"
            onClick={transferSearchData}
            disabled={searchValue === ""}
          >
            {t("filter.search")}
          </Button>
          <Button
            sx={{ height: 40, width: 50, ml: 1 }}
            variant="outlined"
            onClick={getAllItems}
            disabled={false}
          >
            All
          </Button>
        </SubContainerBoxLeft>

        <SubContainerBoxRight>
          {filter}
          <TextField
            variant="outlined"
            size="small"
            label={t("filter.search")}
            value={searchInFront}
            onChange={(event) => setSearchInFront(event.currentTarget.value)}
          />
          <IconButton onClick={onReset}>
            <Close />
          </IconButton>
        </SubContainerBoxRight>
      </ContainerBox>
      {isSupportTable && (
        <FormControl>
          <FormControlLabel
            label={
              <Typography variant="subtitle2">
                {t("support.labelShowClosedIssues")}
              </Typography>
            }
            control={<Checkbox defaultChecked={isClosedIssuesShown} />}
            onChange={handleChangeShowClosedIssues}
          />
        </FormControl>
      )}
    </Box>
  );
};

const ContainerBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: 8,
}));

const SubContainerBoxLeft = styled(Box)({
  display: "flex",
});

const SubContainerBoxRight = styled(Box)({
  display: "flex",
  alignItems: "center",
});

export default memo(SearchToolbar);
