import React, { useCallback } from "react";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

import { Container, styled } from "@mui/material";
import { Helmet } from "react-helmet";
import { PaperPageError, PaperPageSpinner } from "../../components/PaperPage";
import { EventForms } from "./EventForms";
import ScheduledDistributionsCount from "./ScheduledDistributionsCount";

import { useTranslation } from "react-i18next";
import { useTitle } from "../../utils/useTitle";
import useWebsocket from "../../utils/useWebsocket";
import { useGetListQuery } from "../../slices/lists/api";
import { DistributionSystemFilterType, InputParamsType, Result } from "./types";
import { WebsocketActionType } from "../../slices/Websocket/types";
import { QueryListName } from "../../slices/lists/types";

dayjs.extend(timezone);
dayjs.extend(utc);

const DistributionSystem = () => {
  const { t } = useTranslation();
  const title = useTitle(t("distributionSystem.title"));
  const { sendMessage } = useWebsocket();
  const {
    data: virtualGoodShortCodes,
    error: virtualGoodsError,
    refetch: virtualGoodsRefetch,
    isLoading: isLoadingVirtualGoods,
    isFetching: isFetchingVirtualGoods,
  } = useGetListQuery(QueryListName.virtualGoodShortCodes);

  const onCreate = useCallback(
    (data: Result) => {
      const inputParams: InputParamsType = {
        goods: data.goods,
        currencies: data.currencies,
        pets: data.pets,
        furniture: data.furniture,
        sendMailNotification: data.sendMailNotification,
        isMassMail: data.isMassMail,
        sendPushNotification: data.sendPushNotification,
        filterType: data.filterType,
      };

      if (data.filterType === DistributionSystemFilterType.mongoQuery) {
        inputParams.query = JSON.parse(data.filter as string);
      }

      if (
        data.filterType === DistributionSystemFilterType.playerEmails ||
        data.filterType === DistributionSystemFilterType.playerIds
      ) {
        inputParams.arrayStringValues = data.filter as string[];
      }

      if (data.executionTimeUTC) {
        inputParams.executionTimeUTC = dayjs(data.executionTimeUTC)
          .tz("Asia/Tokyo", true)
          .utc()
          .unix();
      }

      if (data.sendMailNotification) {
        inputParams.mailContent = data.mailContent;
        inputParams.mailHeader = data.mailHeader;
        if (
          data.webUrl &&
          data.webUrl != null &&
          data.webUrl != "" &&
          data.webUrl.length > 5
        ) {
          inputParams.webUrl = data.webUrl;
        }
      }

      if (data.sendPushNotification) {
        inputParams.pushTitle = data.pushTitle;
        inputParams.pushMessage = data.pushMessage;
      }

      const processedMsg = {
        action: WebsocketActionType.distributionSystemUpdateTask,
        inputParams: JSON.stringify(inputParams),
      };
      sendMessage(processedMsg);
    },
    [sendMessage]
  );

  if (isLoadingVirtualGoods) {
    return <PaperPageSpinner />;
  }

  if (virtualGoodsError) {
    return (
      <PaperPageError
        refetch={virtualGoodsRefetch}
        disabled={isFetchingVirtualGoods}
        message="refetch"
      />
    );
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <CustomContainer maxWidth="xl">
        <h2 style={{ marginBottom: "0.75rem" }}>
          {t("distributionSystem.title")}
        </h2>
        <ScheduledDistributionsCount />
        <EventForms
          vGoodShortCodes={virtualGoodShortCodes}
          onCreate={onCreate}
        />
      </CustomContainer>
    </>
  );
};

const CustomContainer = styled(Container)({
  height: "100%",
  backgroundColor: "white",
});

export default DistributionSystem;
