import React, { useCallback, useEffect, useState } from "react";
import { Container, styled } from "@mui/material";
import { Helmet } from "react-helmet";

import { SpinnerButton } from "../../components/SpinnerButton";
import { useTranslation } from "react-i18next";
import { useTitle } from "../../utils/useTitle";
import useWebsocket from "../../utils/useWebsocket";
import { useSelector } from "../../store";
import { useFeedError, useFeedSuccess } from "../../utils/feedHooks";

import { WebsocketActionType } from "../../slices/Websocket/types";
import { getWasWebsocketOpen } from "../../slices/Websocket/slice";
import { CURRENT_ENVIRONMENT, ENVIRONMENT_OPTION } from "../../consts";

const Deployment = () => {
  const { t } = useTranslation();
  const title = useTitle(t("deployment.title"));
  const deploymentTypeName = `Transfer meta collections ${process.env.REACT_APP_DEPLOYMENT_TYPE_NAME}`;

  const wasWebsocketOpen = useSelector(getWasWebsocketOpen);
  const { sendMessage, subscribeOnMessage, unsubscribeOnMessage } =
    useWebsocket();
  const feedError = useFeedError();
  const feedSuccess = useFeedSuccess();

  const [isMsgPending, setIsMsgPending] = useState(false);

  const onMessageReceived = useCallback(
    (event: MessageEvent) => {
      const msg = JSON.parse(event.data);

      if (msg?.action === WebsocketActionType.transferCollections) {
        setIsMsgPending(false);

        if (msg?.data?.error) {
          feedError(msg?.data?.error);
        }

        if (msg?.data?.scriptData?.message) {
          feedSuccess(msg?.data?.scriptData?.message);
        }
      }
    },
    [feedError, feedSuccess]
  );

  useEffect(() => {
    if (wasWebsocketOpen) {
      subscribeOnMessage(onMessageReceived);
    }

    return () => {
      unsubscribeOnMessage(onMessageReceived);
    };
  }, [
    onMessageReceived,
    subscribeOnMessage,
    unsubscribeOnMessage,
    wasWebsocketOpen,
  ]);

  const handleClick = () => {
    const processedMsg = {
      action: WebsocketActionType.transferCollections,
      inputParams: "{}",
    };

    setIsMsgPending(true);
    sendMessage(processedMsg);
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <CustomContainer maxWidth="xl">
        {CURRENT_ENVIRONMENT !== ENVIRONMENT_OPTION.dev && (
          <SpinnerButton
            variant="contained"
            size="large"
            onClick={handleClick}
            sx={{ marginBottom: "20px" }}
            buttonName={deploymentTypeName}
            type="button"
            loading={isMsgPending}
          />
        )}
      </CustomContainer>
    </>
  );
};

const CustomContainer = styled(Container)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  paddingTop: "40px",
});

export default Deployment;
