import React, { memo, useCallback, useEffect, useMemo, useState } from "react";

import { Box, Button, Stack } from "@mui/material";
import {
  CustomTypography,
  CustomBox,
  CurrSchemaNameTypography,
} from "./DynamicFormStyle";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import { InputList } from "./components/InputList";
import { AddArrayItemAlert } from "./components/AddArrayItemAlert";
import AlertMessage, {
  AlertMessageProps,
  defaultAlertMessageProps,
} from "../AlertMessage";
import ItemToDelete from "./components/ItemToDelete";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { useEditQuestMutation } from "../../slices/quests/api";
import { useFeedError, useFeedSuccess } from "../../utils/feedHooks";
import { dynamicSchemaList, numericYupRule } from "./schemas";
import {
  DynamicFormData,
  DynamicFormProps,
  DynamicFormField,
  DynamicSchema,
  submittedFormData,
} from "./types";
import { ArrayItemType, ALERT as DirectionAfterCancelSaveType } from "./consts";
import { COLLECTION_NAMES, DB_NAMES } from "../../consts";

const DynamicForm = ({
  collectionName,
  data,
  handleSubmitForm,
  fieldsErrors,
  vGoodsNames,
  isEditable,
  setIsEditable,
  id,
  isEditBtnHidden = false,
}: DynamicFormProps) => {
  const { t } = useTranslation();
  const feedError = useFeedError();
  const feedSuccess = useFeedSuccess();
  const [currCollectionPath, setCurrCollectionPath] = useState<string[]>([]);

  const rootSchemaObject = dynamicSchemaList[collectionName]?._root;

  const currSchemaObject = useMemo(
    () =>
      currCollectionPath.reduce<DynamicFormField>(
        (result, pathStep) =>
          result?.children ? result.children[pathStep] : result,
        rootSchemaObject as DynamicFormField
      ),
    [currCollectionPath, rootSchemaObject]
  );
  const currSchemaName = useMemo(
    () => currCollectionPath[currCollectionPath.length - 1],
    [currCollectionPath]
  );

  const currSchema = useMemo(() => {
    if (currSchemaObject?.label === "Goods") {
      const vGoodsNamesSchema = vGoodsNames?.reduce<DynamicSchema>(
        (result, item) => ({
          ...result,
          [item]: {
            type: "numeric",
            label: item,
            additionalFields: [],
            children: {},
          },
        }),
        {}
      );

      return {
        ...currSchemaObject?.children,
        ...vGoodsNamesSchema,
      };
    }

    return currSchemaObject?.children;
  }, [currSchemaObject?.children, currSchemaObject?.label, vGoodsNames]);

  const currData = useMemo(
    () =>
      currCollectionPath.reduce<DynamicFormData>(
        (result, pathStep) => result[pathStep],
        data as DynamicFormData
      ),
    [currCollectionPath, data]
  );

  const currAdditionalFieldsSchema = useMemo(
    () =>
      currSchemaObject?.additionalFields?.reduce<DynamicSchema>(
        (result, fieldPath) => {
          const fieldPathSteps = fieldPath.split("/");
          const schemaItem = fieldPathSteps.reduce(
            (res, step) => (res.children ? res.children[step] : res),
            rootSchemaObject
          );

          return { ...result, [fieldPath]: schemaItem };
        },
        {}
      ),
    [currSchemaObject?.additionalFields, rootSchemaObject]
  );

  const currYupRulesSchema = useMemo(() => {
    const getYupRules = (schema: DynamicSchema, isAdditional = false) =>
      Object.keys(schema).reduce((result, schemaItemName) => {
        const inputName = currCollectionPath.reduceRight(
          (result, pathStep) => (pathStep ? `${pathStep}/${result}` : result),
          schemaItemName
        );

        return schema[schemaItemName].yupRules
          ? {
              ...result,
              [isAdditional ? schemaItemName : inputName]:
                schema[schemaItemName].yupRules,
            }
          : result;
      }, {});

    const commonYupRules = currSchema && getYupRules(currSchema);
    const additionalYupRules =
      currAdditionalFieldsSchema &&
      getYupRules(currAdditionalFieldsSchema, true);

    if (currSchemaObject?.consistOfNumericFields) {
      const numericYupRules = Object.keys(currData).reduce(
        (result, dataItemName) => {
          const inputName = currCollectionPath.reduceRight(
            (result, pathStep) => (pathStep ? `${pathStep}/${result}` : result),
            dataItemName
          );

          return {
            ...result,
            [inputName]: numericYupRule,
          };
        },
        {}
      );

      return yup.object({
        ...commonYupRules,
        ...additionalYupRules,
        ...numericYupRules,
      });
    }

    return yup.object({ ...commonYupRules, ...additionalYupRules });
  }, [
    currSchema,
    currAdditionalFieldsSchema,
    currSchemaObject?.consistOfNumericFields,
    currCollectionPath,
    currData,
  ]);

  const currAdditionalFieldsData = useMemo(
    () =>
      currSchemaObject?.additionalFields?.reduce((result, fieldPath) => {
        const fieldPathSteps = fieldPath.split("/");
        const dataItem = fieldPathSteps.reduce(
          (res, step) => res && res[step],
          data
        );

        return { ...result, [fieldPath]: dataItem };
      }, {} as DynamicFormData),
    [currSchemaObject?.additionalFields, data]
  );

  const {
    watch,
    handleSubmit,
    control,
    reset,
    formState: { dirtyFields },
  } = useForm({
    resolver: yupResolver(currYupRulesSchema),
  });
  const [storedSchemaItemName, setStoredSchemaItemName] = useState<string>();
  const [isSomeInputModified, setIsSomeInputModified] = useState(false);

  const handleSubmittedData = useCallback(
    (submittedData: submittedFormData) => {
      const processedData = Object.keys(submittedData).reduce(
        (result, inputName) => {
          if (dirtyFields[inputName]) {
            // If there are numeric fields on this level:
            if (currSchemaObject?.consistOfNumericFields) {
              const isAdditionalField =
                currSchemaObject.additionalFields?.includes(inputName);

              // If there are additional fields with numeric fields on this level
              if (isAdditionalField) {
                return {
                  ...result,
                  needToUpdate: {
                    ...result.needToUpdate,
                    [inputName]: submittedData[inputName],
                  },
                };
              } else {
                const submittedFieldData = submittedData[
                  inputName
                ] as unknown as number;
                const initialFieldData = (inputName
                  .split("/")
                  .reduce(
                    (res, fieldPathStep) => res && res[fieldPathStep],
                    data
                  ) || 0) as unknown as number;

                const incrementedFieldData =
                  submittedFieldData - initialFieldData;

                return incrementedFieldData > 0 || incrementedFieldData < 0
                  ? {
                      ...result,
                      needToIncrement: {
                        ...result.needToIncrement,
                        [inputName]: incrementedFieldData,
                      },
                    }
                  : result;
              }
            }

            return {
              ...result,
              needToUpdate: {
                ...result.needToUpdate,
                [inputName]: submittedData[inputName],
              },
            };
          }

          return result;
        },
        {
          needToUpdate: {},
          needToIncrement: {},
        }
      );

      return handleSubmitForm(processedData);
    },
    [currSchemaObject, data, dirtyFields, handleSubmitForm]
  );

  useEffect(() => {
    reset({ ...data, ...currAdditionalFieldsData });
    setIsSomeInputModified(false);
  }, [currAdditionalFieldsData, data, reset]);

  useEffect(() => {
    const subscription = watch(() => setIsSomeInputModified(true));
    return () => subscription.unsubscribe();
  }, [watch]);

  const resetForm = useCallback(() => {
    reset();
    setIsSomeInputModified(false);
    setIsEditable(false);
  }, [reset, setIsEditable]);

  const [directionAfterCancelSave, setDirectionAfterCancelSave] =
    useState<DirectionAfterCancelSaveType>(null);
  const [alertMessageProps, setAlertMessageProps] = useState<AlertMessageProps>(
    defaultAlertMessageProps
  );

  const handleClickAlertSave = useCallback(() => {
    setDirectionAfterCancelSave(null);
    setAlertMessageProps(defaultAlertMessageProps);
    alert(t("messages.closeAlertAndSaveDataChanges"));
  }, [t]);

  const handleClickAlertSaveCancel = useCallback(() => {
    setDirectionAfterCancelSave(null);
    setAlertMessageProps(defaultAlertMessageProps);
    resetForm();
    setIsEditable(false);

    if (directionAfterCancelSave === DirectionAfterCancelSaveType.GO_DOWN) {
      storedSchemaItemName &&
        setCurrCollectionPath((prevState: string[]) => [
          ...prevState,
          storedSchemaItemName,
        ]);
    }

    if (directionAfterCancelSave === DirectionAfterCancelSaveType.GO_UP) {
      setCurrCollectionPath((prevState: string[]) => {
        prevState.pop();
        return [...prevState];
      });
    }
  }, [
    directionAfterCancelSave,
    resetForm,
    setIsEditable,
    storedSchemaItemName,
  ]);

  const handleClickGoBack = useCallback(() => {
    if (isSomeInputModified) {
      setDirectionAfterCancelSave(DirectionAfterCancelSaveType.GO_UP);
      setAlertMessageProps({
        isShown: true,
        title: t("messages.haveUnsavedChanges"),
        children: t("messages.saveChangesOrCancel"),
        textOk: t("buttonNames.save"),
        onCancel: handleClickAlertSaveCancel,
        onClickOk: handleClickAlertSave,
      });
    } else {
      setCurrCollectionPath((prevState: string[]) => {
        prevState.pop();
        return [...prevState];
      });
      setIsEditable(false);
    }
  }, [
    handleClickAlertSaveCancel,
    handleClickAlertSave,
    isSomeInputModified,
    setIsEditable,
    t,
  ]);

  const handleClickObjectLink = useCallback(
    (dataItemName: string) => {
      setStoredSchemaItemName(dataItemName);

      if (isSomeInputModified) {
        setDirectionAfterCancelSave(DirectionAfterCancelSaveType.GO_DOWN);
        setAlertMessageProps({
          isShown: true,
          title: t("messages.haveUnsavedChanges"),
          children: t("messages.saveChangesOrCancel"),
          textOk: t("buttonNames.save"),
          onCancel: handleClickAlertSaveCancel,
          onClickOk: handleClickAlertSave,
        });
      } else {
        resetForm();
        setIsEditable(false);
        setCurrCollectionPath((prevState: string[]) => [
          ...prevState,
          dataItemName,
        ]);
      }
    },
    [
      handleClickAlertSaveCancel,
      handleClickAlertSave,
      isSomeInputModified,
      resetForm,
      setIsEditable,
      t,
    ]
  );

  const [editQuest] = useEditQuestMutation();

  const handleDeleteArrayItem = useCallback(
    (arrayItemCount: number | string, arrayItemType: `${ArrayItemType}`) => {
      const updatedArray = currData?.filter(
        (_x: any, index: number) => index !== +arrayItemCount
      );

      setAlertMessageProps((prevState) => ({
        ...prevState,
        isOkBtnLoading: true,
      }));

      switch (arrayItemType) {
        case ArrayItemType.questRequirement:
        case ArrayItemType.questRewards:
          try {
            const result = editQuest({
              dbName: DB_NAMES.META,
              collectionName: COLLECTION_NAMES.QUESTS_DATA,
              id,
              newParams: [
                {
                  value: updatedArray,
                  path: `${currCollectionPath.join(".")}`,
                },
              ],
              isIncremented: false,
            }).unwrap();

            if (result) {
              feedSuccess(t("createQuest.successMsgAfterDeleteItem"));
              setAlertMessageProps(defaultAlertMessageProps);
            } else {
              setAlertMessageProps((prevState) => ({
                ...prevState,
                isOkBtnLoading: false,
              }));
              feedError(t("createQuest.errorMsgAfterDeleteItem"));
            }
          } catch (error) {
            setAlertMessageProps((prevState) => ({
              ...prevState,
              isOkBtnLoading: false,
            }));
            feedError(t("createQuest.errorMsgAfterDeleteItem"));
          }

          break;

        default:
          break;
      }
    },
    [currCollectionPath, currData, editQuest, feedError, feedSuccess, id, t]
  );

  const handleClickDeleteItem = useCallback(
    (arrayItemCount: number | string) => {
      setAlertMessageProps({
        isShown: true,
        title: t("messages.confirmDeleteArrayItemTitle"),
        children: (
          <ItemToDelete
            count={arrayItemCount}
            data={currData[arrayItemCount]}
            type={currSchemaObject?.arrayItemType}
          />
        ),
        textOk: t("buttonNames.deleteItem"),
        onCancel: () => setAlertMessageProps(defaultAlertMessageProps),
        onClickOk: () =>
          handleDeleteArrayItem(
            arrayItemCount,
            currSchemaObject?.arrayItemType
          ),
      });
    },
    [currData, currSchemaObject?.arrayItemType, handleDeleteArrayItem, t]
  );

  const [isAddArrayItemAlertShown, setIsAddArrayItemAlertShown] =
    useState(false);
  const openAddNewArrayItem = useCallback(() => {
    setIsAddArrayItemAlertShown(true);
  }, []);

  const hasInputs = useMemo(
    () =>
      currSchema
        ? Object.keys(currSchema).reduce(
            (result, schemaItemName) =>
              currSchema[schemaItemName].type !== "link" &&
              currSchema[schemaItemName].type !== "root"
                ? true
                : result,
            false
          )
        : true,
    [currSchema]
  );

  const isCurrDataArrayEditable = useMemo(() => {
    const hasArrayItemType = Object.values(ArrayItemType).includes(
      currSchemaObject?.arrayItemType as ArrayItemType
    );

    return Array.isArray(currData) && hasArrayItemType;
  }, [currData, currSchemaObject?.arrayItemType]);

  return (
    <>
      <CustomBox>
        <AlertMessage {...alertMessageProps} />

        {!isEditBtnHidden && hasInputs && !isSomeInputModified && (
          <CustomTypography>
            {isEditable ? "EDIT" : "READ"} MODE
          </CustomTypography>
        )}

        <form onSubmit={handleSubmit(handleSubmittedData)}>
          {currCollectionPath.length > 0 && (
            <CurrSchemaNameTypography>
              {currSchemaName}
            </CurrSchemaNameTypography>
          )}
          <Box sx={{ maxHeight: "60vh", overflowY: "auto" }}>
            <InputList
              control={control}
              schemaObject={currSchemaObject}
              currSchema={currSchema}
              additionalFieldsSchema={currAdditionalFieldsSchema}
              data={currData}
              additionalFieldsData={currAdditionalFieldsData}
              currCollectionPath={currCollectionPath}
              fieldsErrors={fieldsErrors}
              isEditable={isEditable}
              isArrayEditable={isCurrDataArrayEditable}
              handleClickObjectLink={handleClickObjectLink}
              handleClickDeleteItem={handleClickDeleteItem}
            />
          </Box>

          <Stack direction="row" justifyContent="space-between" mx={1} mt={4}>
            <Stack direction="row" spacing={2}>
              {!isEditBtnHidden && !isEditable && hasInputs && (
                <Button variant="outlined" onClick={() => setIsEditable(true)}>
                  {t("buttonNames.editMode")}
                </Button>
              )}
              {!isEditBtnHidden &&
                isEditable &&
                hasInputs &&
                !isSomeInputModified && (
                  <Button
                    variant="outlined"
                    onClick={() => setIsEditable(false)}
                  >
                    {t("buttonNames.readMode")}
                  </Button>
                )}
              {currCollectionPath.length > 0 && (
                <Button
                  startIcon={<ArrowLeftIcon />}
                  variant="outlined"
                  onClick={handleClickGoBack}
                >
                  {t("buttonNames.goBack")}
                </Button>
              )}
              {isSomeInputModified && (
                <>
                  <Button type="submit" variant="contained">
                    {t("buttonNames.save")}
                  </Button>
                  <Button onClick={resetForm} variant="outlined">
                    {t("buttonNames.reset")}
                  </Button>
                </>
              )}
            </Stack>
            {isCurrDataArrayEditable && (
              <Button onClick={openAddNewArrayItem} variant="outlined">
                {t("buttonNames.addItem")}
              </Button>
            )}
          </Stack>
        </form>
      </CustomBox>
      {isCurrDataArrayEditable && (
        <AddArrayItemAlert
          isShown={isAddArrayItemAlertShown}
          setIsShown={setIsAddArrayItemAlertShown}
          arrayItemType={currSchemaObject?.arrayItemType}
          pathToArray={currCollectionPath}
          arrayCount={currData.length}
          id={id}
        />
      )}
    </>
  );
};

export default memo(DynamicForm);
