import React, { useMemo } from "react";

import { DynamicFormInput, Input } from "../DynamicFormInput";

import { Control, FieldValues } from "react-hook-form";
import { numericFieldNames, numericYupRule } from "../schemas";
import { DynamicFormData, DynamicFormField, DynamicSchema } from "../types";
import { InputType } from "../consts";

type InputListProps = {
  control: Control<FieldValues, any>;
  schemaObject: DynamicFormField;
  currSchema: DynamicSchema;
  additionalFieldsSchema: DynamicSchema;
  data: DynamicFormData;
  additionalFieldsData: DynamicFormData;
  currCollectionPath: string[];
  fieldsErrors: Record<string, string[]>;
  isEditable: boolean;
  isArrayEditable?: boolean;
  handleClickObjectLink: (dataItemName: string) => void;
  handleClickDeleteItem?: (arrayItemCount: number | string) => void;
};

const isObject = (item: unknown): boolean =>
  typeof item === "object" && !Array.isArray(item) && item !== null;

export const InputList = ({
  control,
  schemaObject,
  currSchema,
  additionalFieldsSchema,
  data,
  additionalFieldsData,
  currCollectionPath,
  fieldsErrors,
  isEditable,
  isArrayEditable = false,
  handleClickObjectLink,
  handleClickDeleteItem,
}: InputListProps) => {
  const additionalFormInputs = useMemo(
    () =>
      additionalFieldsSchema &&
      Object.keys(additionalFieldsSchema).map((schemaItemName) => {
        const dataItem = additionalFieldsData?.[schemaItemName];
        const schemaItem = additionalFieldsSchema[schemaItemName];

        return (
          <DynamicFormInput
            key={schemaItemName}
            control={control}
            fieldsErrors={fieldsErrors}
            formIsDisabled={!isEditable}
            inputName={schemaItemName}
            schemaItem={schemaItem}
            dataItem={dataItem}
          />
        );
      }),
    [
      control,
      additionalFieldsData,
      additionalFieldsSchema,
      fieldsErrors,
      isEditable,
    ]
  );

  const commonInputs = useMemo(() => {
    if (
      schemaObject?.label === "Currencies" ||
      schemaObject?.label === "Goods"
    ) {
      return (
        currSchema &&
        Object.keys(currSchema).map((schemaItemName) => {
          const schemaItem = currSchema?.[schemaItemName];
          const dataItem = data[schemaItemName];
          const inputName = currCollectionPath.reduceRight(
            (result, pathStep) => (pathStep ? `${pathStep}/${result}` : result),
            schemaItemName
          );

          return (
            <DynamicFormInput
              key={schemaItemName}
              control={control}
              fieldsErrors={fieldsErrors}
              formIsDisabled={!isEditable}
              inputName={inputName}
              schemaItem={schemaItem}
              dataItem={dataItem === undefined ? 0 : dataItem}
            />
          );
        })
      );
    }

    const inputsFromData = Object.keys(data).map((dataItemName) => {
      const dataItem = data[dataItemName];
      const schemaItem = currSchema && currSchema[dataItemName];
      if (schemaItem?.hide) return <></>;
      const inputName = currCollectionPath.reduceRight(
        (result, pathStep) => (pathStep ? `${pathStep}/${result}` : result),
        dataItemName
      );

      if (
        isObject(dataItem) ||
        (Array.isArray(dataItem) &&
          schemaItem?.type !== InputType.arrayOfSimpleValues)
      ) {
        return (
          <Input
            key={inputName}
            defaultValue={dataItemName}
            handleClickLink={() => handleClickObjectLink(dataItemName)}
            type={InputType.link}
            label={schemaItem?.label || dataItemName}
            arrayItemType={schemaObject?.arrayItemType}
            isItemOfEditableArray={isArrayEditable}
            handleClickDeleteItem={handleClickDeleteItem}
          />
        );
      }

      if (
        schemaObject?.consistOfNumericFields ||
        numericFieldNames.includes(dataItemName) ||
        numericFieldNames.includes(currCollectionPath.at(-1))
      ) {
        const customSchemaItem: DynamicFormField = {
          type: InputType.numeric,
          label: dataItemName,
          placeholder: `Edit ${dataItemName}`,
          yupRules: numericYupRule,
        };

        return (
          <DynamicFormInput
            key={inputName}
            control={control}
            fieldsErrors={fieldsErrors}
            formIsDisabled={!isEditable}
            inputName={inputName}
            schemaItem={customSchemaItem}
            dataItem={dataItem}
          />
        );
      }

      if (schemaItem) {
        return (
          <DynamicFormInput
            key={inputName}
            control={control}
            fieldsErrors={fieldsErrors}
            formIsDisabled={!isEditable}
            inputName={inputName}
            schemaItem={schemaItem}
            dataItem={dataItem}
          />
        );
      }

      const defaultSchemaItem: DynamicFormField = {
        type: InputType.default,
        label: dataItemName,
        placeholder: `Edit ${dataItemName}`,
      };

      return (
        <DynamicFormInput
          key={inputName}
          control={control}
          fieldsErrors={fieldsErrors}
          formIsDisabled={!isEditable}
          inputName={inputName}
          schemaItem={defaultSchemaItem}
          dataItem={dataItem}
        />
      );
    });

    const inputsFromSchema = currSchema
      ? Object.keys(currSchema).reduce((result, schemaItemName) => {
          const schemaItem = currSchema[schemaItemName];
          const dataItem = data[schemaItemName];
          const inputName = currCollectionPath.reduceRight(
            (result, pathStep) => (pathStep ? `${pathStep}/${result}` : result),
            schemaItemName
          );

          return schemaItem.isShownWithoutData && dataItem === undefined
            ? [
                ...result,
                <DynamicFormInput
                  key={schemaItemName}
                  control={control}
                  fieldsErrors={fieldsErrors}
                  formIsDisabled={!isEditable}
                  inputName={inputName}
                  schemaItem={schemaItem}
                  dataItem={dataItem}
                />,
              ]
            : result;
        }, [])
      : [];

    return [...inputsFromData, ...inputsFromSchema];
  }, [
    schemaObject?.label,
    schemaObject?.consistOfNumericFields,
    schemaObject?.arrayItemType,
    data,
    currSchema,
    currCollectionPath,
    control,
    fieldsErrors,
    isEditable,
    isArrayEditable,
    handleClickDeleteItem,
    handleClickObjectLink,
  ]);

  return (
    <>
      {additionalFormInputs}
      {commonInputs}
    </>
  );
};
