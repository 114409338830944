import React, { FocusEvent, memo, ReactNode } from "react";
import { Control, Controller } from "react-hook-form";

import { TextField, Typography } from "@mui/material";
import { CustomBox, CustomErrorBox } from "./StyledComponents";

import { CreateQuestFormValues } from "../../container/Quests/types";
import {
  CreateVirtualGoodFormValues,
  SetProbabilityConfigFormValues,
} from "../../container/VirtualGoods/types";
import { INPUT_COLUMN_WIDTH, INPUT_TITLE_WIDTH } from "./consts";

// export enum Variant {
//   virtualGood = "virtualGood",
//   quest = "quest",
// }

// type ConditionalControl =
//   | {
//       variant: Variant.quest;
//       control: Control<CreateQuestFormValues>;
//       fieldName: keyof CreateQuestFormValues;
//     }
//   | {
//       variant: Variant.virtualGood;
//       control: Control<CreateVirtualGoodFormValues>;
//       fieldName: keyof CreateVirtualGoodFormValues;
//     };

type ControlledInputTextProps = {
  // control: Control<CreateQuestFormValues | CreateVirtualGoodFormValues>;
  control: Control<any>;
  title?: ReactNode;
  fieldName:
    | keyof CreateQuestFormValues
    | keyof CreateVirtualGoodFormValues
    | keyof SetProbabilityConfigFormValues
    | string;
  isRequired?: boolean;
  isNumeric?: boolean;
  isInputDisabled?: boolean;
  defaultValue?: string | number;
  inputWidth?: string | number;
  titleWidth?: string | number;
  onBlur?: (e: FocusEvent) => void;
  normalize?: (value: string | number) => string | number;
};

const ControlledInputText = ({
  control,
  title,
  fieldName,
  isRequired = false,
  isNumeric = false,
  isInputDisabled = false,
  defaultValue,
  inputWidth = INPUT_COLUMN_WIDTH,
  titleWidth = INPUT_TITLE_WIDTH,
  onBlur,
  normalize = (value) => value,
}: ControlledInputTextProps) => {
  return (
    <CustomBox>
      {title && (
        <Typography
          width={titleWidth}
          sx={{ color: isInputDisabled ? "rgba(0, 0, 0, 0.38)" : "initial" }}
        >
          {title}
        </Typography>
      )}
      <Controller
        control={control}
        name={fieldName}
        defaultValue={defaultValue}
        {...(isRequired && {
          rules: {
            required: "required",
          },
        })}
        render={({ field, fieldState: { invalid, error } }) => (
          <>
            <TextField
              size="small"
              sx={{ width: inputWidth }}
              variant="outlined"
              error={invalid}
              disabled={isInputDisabled}
              {...field}
              {...(onBlur && {
                onBlur: (e) => {
                  onBlur(e);
                  field.onBlur();
                },
              })}
              {...(isNumeric
                ? {
                    onBlur: (e) => {
                      const value = +e.target.value.trim();
                      field?.onChange(isNaN(value) ? 0 : normalize(value));

                      onBlur && onBlur(e);
                      field?.onBlur();
                    },
                  }
                : {
                    onBlur: (e) => {
                      const value = e.target.value;
                      field?.onChange(normalize(value || ""));

                      onBlur && onBlur(e);
                      field?.onBlur();
                    },
                  })}
            />
            {error && <CustomErrorBox>{error.message}</CustomErrorBox>}
          </>
        )}
      />
    </CustomBox>
  );
};

export default memo(ControlledInputText);
