import React from "react";
import styled from "@emotion/styled";
import { Outlet } from "react-router-dom";
import { Box, Toolbar } from "@mui/material";
import Header from "./Header";

const CommonLayout = () => (
  <GlobalBox>
    <Header />
    <Main>
      {/*
          Toolbar below compensates for header space. Looks wrong,
          but it's how they do it on MUI documentation.
        */}
      <Toolbar />
      <Outlet />
    </Main>
  </GlobalBox>
);

const GlobalBox = styled(Box)({
  display: "flex",
  height: "100vh",
});

const Main = styled("main")({
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
});

export default CommonLayout;
