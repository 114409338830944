export default {
  title: "Moomin Move Admin",

  errors: {
    connection: "Network connection error",
    inputRequiredMsg: "required",
    inputOneOfRequiredMsg: "one of these fields is required",
    hasErrorsOnSubmitMessage: "All required fields must be filled",
    noData: "There is no data",
  },

  tryAgain: "Try again",
  logOut: "Log out",
  proceed: "Proceed",
  cancel: "Cancel",
  openInANewTab: "Open in a new tab",
  submit: "Submit",
  edit: "Edit",
  delete: "Delete",
  search: "Search",
  open: "Open",
  typeHere: "Type here...",

  id: "ID",
  username: "Username",
  email: "Email",
  authCode: "Authorization Code",
  newPassword: "New password",
  password: "Password",
  actions: "Actions",

  messages: {
    haveUnsavedChanges: "You have unsaved changes",
    saveChangesOrCancel: "Save changes or cancel?",
    confirmDeletingMessage: "Confirm deleting message",
    deleteMessage: "Delete message",
    closeAlertAndSaveDataChanges: "close alert and save data changes",
    somethingWentWrongAndActionWithMessageWasntSuccessful:
      "Something went wrong, and action with message wasn't successful",
    confirmDeleteArrayItemTitle:
      "Are you sure you want to delete this item? You won't be able to undo this action",
  },
  buttonNames: {
    deletePlayers: "Delete players",
    editMode: "To Edit mode",
    readMode: "To Read mode",
    goBack: "Go back",
    save: "Save",
    add: "Add",
    addItem: "Add item",
    set: "Set",
    send: "Send",
    sendNow: "Send now",
    create: "Create",
    cancel: "Cancel",
    delete: "Delete",
    close: "Close",
    activate: "Activate",
    deactivate: "Deactivate",
    ok: "OK",
    reset: "Reset",
    setFilters: "Set filters",
    setFilter: "Set filter",
    confirmPlayer: "Confirm player",
    recoveryPassword: "Recovery Password",
    deletePlayer: "Delete player",
    deleteQuest: "Delete quest",
    deleteItem: "Delete item",
    createNewQuest: "Create new Quest",
    createNewVirtualGood: "Create new Virtual Good",
    setProbabilityConfig: "Set probability config",
    refreshList: "Refresh list",
    showDetails: "Show details",
  },
  navigation: {
    players: "Players",
    serviceStatus: "Service Status",
    admin: "Admin",
    gameContent: "Game content",
    virtualGoods: "Virtual Goods",
    quests: "Quests",
    storeContent: "Store Content",
    deployment: "Deployment",
    maintenance: "Maintenance",
    support: "Support",
    groupManagement: "Group management",
    distributionSystem: "Distribution system",
    deletePlayers: "Delete players",
    mapContent: "Map content",
    eventCreator: "Event Creator",
    playerSupport: "Player support",
    analytics: "Analytics",
    newPlayersKpi: "New players KPI",
    dailyActivePlayersKpi: "Daily active players KPI",
    monthlyActivePlayersKpi: "Monthly active players KPI",
    stepsKpi: "Steps KPI",
    walkDistanceKpi: "Walk distance KPI",
  },
  players: {
    title: "Players",
    editPlayer: "Edit player",
    addPlayer: "Add player",
    confirmDeleteTitle:
      "Are you sure you want to delete this player? You won't be able to undo this action",
    confirmDeleteMsg:
      "Enter the player's name to confirm the deletion. The current player's name is",
    playerName: "Player's Name",
    deletePlayerSuccessMsg: "Player was deleted successfully",
    deletePlayerErrorMsg: "Something went wrong, Player wasn't deleted",
    confirmPlayerSuccessMsg: "Player was confirmed successfully",
    confirmPlayerErrorMsg: "Something went wrong, Player wasn't confirmed",
    confirmDeleteDescription: "You won't be able to undo this action",
    errors: {
      editPlayer: "Something went wrong editing the player, try again",
      editPlayerPersonalValleys:
        "Something went wrong editing crafted items, try again",
      editPetPlayers: "Something went wrong editing players pets, try again",
      editPlayerMoneySpent:
        "Something went wrong editing player money spent, try again",
    },
    addArrayItem: {
      addRewardTitle: "Add reward",
      addRequirementTitle: "Add requirement",
      addRewardRequiredInputsMsg:
        "At least one input (Tags, Rarity, or Custom) should be filled",
    },
    labelPlayerData: "Player data",
    labelPlayerPersonalValleys: "Crafted items",
    labelPlayerPets: "Pets",
    labelPlayerMoneySpent: "Money spent",
    editPlayerPersonalValleys: "Edit crafted items",
    editPetPlayers: "Edit pets",
    editPlayerMoneySpent: "Edit money spent",
  },
  supportChats: {
    supportChat: "Support chat",
    closed: "Closed",
    open: "Open",
    waitingResponse: "Waiting response",
    waitingTech: "Waiting Tech",
    handledInAsana: "Handled in Asana",
    emailSubject: "Email subject",
    emailBody: "Email body",
    emailBodyPlaceholder: "Type email body here...",
    notePlaceholder:
      "Type note here. This note will be only visible for admins and will not sent to the player",
    titleOS: "OS",
    titleLanguage: "Language",
    titleModel: "Model",
    titleName: "Name",
    titleUserName: "User name",
    titleUserId: "User ID",
    titleScheduleDate: "Schedule date",
    titleSubject: "Subject",
    titleAttachments: "Attachments",
    titleAdminEmail: "Admin email",
    subjectTemplateRE: "RE:",
    subjectTemplateUserRegistered: "RE: Support request from",
    subjectTemplateUserUnregistered: "RE: Contact request from",
    labelEmailEditor: "Email response",
    labelNoteEditor: "Internal notes and status",
    labelInGameResponse: "in-game response",
    labelSendToSlack: "Send Slack notification",
    labelChangeStatusToWaitingTech: 'Change status to "waiting tech"',
    msgSameStatus: "Status should be changed or note should be filled",
    msgStatusWaitingTech: 'Note should be filled if status is "Waiting Tech"',
  },
  virtualGoods: {
    title: "Virtual goods",
    editVirtualGoods: "Edit Virtual Goods",
    add: "Add Virtual Good",
    edit: "Edit Virtual Good",
    confirmDeleteTitle: "Are you sure you want to delete this Virtual Good?",
    confirmDeleteDescription: "You won't be able to undo this action",
    errors: {
      edit: "Something went wrong editing the Virtual Good, try again",
    },
  },
  quests: {
    title: "Quests",
    editQuest: "Edit Quest",
    errors: {
      edit: "Something went wrong editing the Quest, try again",
    },
    deleteMsgTitle: "This item will be deleted",
    deleteQuestSuccessMsg: "Quest was deleted successfully",
    deleteQuestErrorMsg: "Something went wrong, Quest wasn't deleted",
    confirmDeleteTitle: "Are you sure you want to delete this Quest?",
  },
  support: {
    title: "Support Chat",
    labelShowClosedIssues: "Show closed issues",
  },
  storeContent: {
    title: "Store Content",
    timelineTabLabel: "{{tab}} timeline",
    fillGap: "Add Store Content",
    show: "Edit Store Content",
    gapsInRange: "There are gaps throughout the timeline!",
    thisWeekUnset:
      "The store tab configuration does not cover the entire current week. Please create or extend the start or end dates to fill any gaps.",
    emptyStoreItemsError: "Store items should not be empty",
    emptyStoreItemTimerangeError: "Store items should have a valid timerange",
  },
  groupManagement: {
    title: "Group Management",
    teamMembersTitle: "Team Members",
    chatHistoryTitle: "Chat history",
    editGroupManagement: "Edit Group Management",
    add: "Add Group",
    edit: "Edit Group",
    confirmDeleteTitle: "Are you sure you want to delete this Group?",
    confirmDeleteDescription: "You won't be able to undo this action",
    errors: {
      edit: "Something went wrong editing the Group, try again",
    },
  },
  events: {
    rewardsGrind: "Rewards Grind",
    awardAmount: "Award Amount:",
    min: "1 min",
    max99: "99 max",
    max10000: "10000 max",
  },
  deployment: {
    title: "Deployment",
  },
  maintenance: {
    title: "Maintenance",
    cancelScheduledMaintenanceStatusMessageSuccess:
      "The scheduled maintenance status was canceled successfully",
    cancelScheduledMaintenanceStatusMessageError:
      "Something went wrong and canceling the maintenance status had an error",
  },
  distributionSystem: {
    title: "Distribution system",
    resultVirtualGoods: "Result virtual goods:",
    resultCurrencies: "Result currencies:",
    resultPets: "Result pets:",
    resultFurniture: "Result furniture:",
    resultFilter: "Result filter:",
    sendMailNotification: "Send mail notification?",
    isMassMail: "Mass mail",
    mailHeader: "Mail header",
    mailContent: "Mail content",
    webUrl: "Web URL",
    sendPushNotification: "Send push notification?",
    pushTitle: "Title",
    pushMessage: "Message",
    fieldCount: "Count",
    fieldCurrency: "Currency",
    fieldPet: "Pet",
    fieldFurniture: "Furniture",
    fieldFilter: "Filter",
    fieldExecutionTime: "Execution time",
    fieldExecutionTimeLabel: "Japanese timezone",
    radioMongoLabel: "Mongo query",
    radioPlayerIdsLabel: "Player IDs",
    radioPlayerEmailsLabel: "Player emails",
    placeholderFilterMongo: "Type Mongo query here...",
    placeholderFilterEmail: "Type list of player emails here...",
    placeholderFilterId: "Type list of player IDs here...",
    errorInvalidJSON: "you entered invalid json object",
    selected: "Selected",
    players: "players",
    upcomingScheduledDistributions: "Upcoming scheduled distributions",
    errorMsgDistribution:
      "Please set the Filter and Execution time. Then add one Virtual good or Currency, or check 'Send mail notification' (and fill header and content), or check 'Send push notification'",
    errorMsgInGameResponse:
      "Please add one Virtual good or Currency, or check 'Mail header' and 'Mail content' (and fill them)",
  },
  deletePlayers: {
    title: "Delete players",
    resultFilter: "Result filter:",
    fieldCount: "Count",
    fieldCurrency: "Currency",
    fieldFilter: "Filter",
    radioMongoLabel: "Mongo query",
    radioPlayerIdsLabel: "Player IDs",
    placeholderFilterMongo: "Type Mongo query here...",
    placeholderFilterId: "Type list of player IDs here...",
    errorInvalidJSON: "you entered invalid json object",
    selected: "Selected",
    players: "players",
    upcomingScheduledDistributions: "Upcoming scheduled distributions",
  },
  filter: {
    Filter: "Filter",
    Period: "Period",
    Gender: "Gender",
    Age: "Age",
    DeepLink: "Deep link",
    search: "search",
    labelFromDate: "From date",
    labelToDate: "To date",
    no_answer: "No answer",
    noValue: "No value",
    male: "Male",
    female: "Female",
    aomori: "Aomori",
    hokkaido: "Hokkaido",
    menolabo: "Menolabo",
    landingPage: "Landing Page",
  },
  storeContentDetails: {
    title: "Store Content Details",
    id: "ID",
    tab: "Tab",
    start: "Start",
    end: "End",
    storeItems: "Store Items",
    n: "N",
    lootboxId: "Lootbox ID",
    discountPercentage: "Discount Percentage",
    showAsOffer: "Show As Offer",
    toEdit: "Edit Store Content",
    price: "Price",
    buyAmountLimit: "Buy Amount Limit",
    storeItemStart: "Start",
    storeItemEnd: "End",
  },
  storeContentCreate: {
    submitCreate: "Create Store Content",
    submitEdit: "Update Store Content",
    resetDateTimeRange: "Clear",
    title: "Add Store Content",
    editTitle: "Edit Store Content",
    tab: "Tab",
    storeItems: "Store Items",
    timeRange: "Time Range",
    addStoreItem: "Add Store Item",
    storeItemFields: {
      lootboxId: "Lootbox ID",
      discountPercentage: "Discount",
      showAsOffer: "Show as Offer",
      price: "Price",
      buyAmountLimit: "Buy Amount Limit",
      timerange: "Timerange",
    },
    successMsgAfterSubmit: "Store Content Created",
    successMsgAfterSubmitEdit: "Store Content Updated",
    successMsgAfterDelete: "Store Content Deleted",
    delete: "Delete",
  },
  createQuest: {
    title: "Quest management",
    questID: "Quest ID*",
    rarity: "Rarity",
    tags: "Tags",
    character: "Character",
    probability: "Probability*",
    sceneType: "Scene",
    onlySequel: "Only Sequel",
    onlySequelTitle:
      "Check this box if this quest is ignored unless it's sequel to another",
    questType: "Quest Type*",
    questListDescription: "Quest List Description*",
    dialogStart: "Dialog Start*",
    dialogQuest: "Dialog quest*",
    dialogCompleted: "Dialog completed*",
    dialogAfter: "Quest after completing this one",
    requirements: "Requirements",
    tag: "Tag",
    itemCount: "Item count",
    custom: "Custom",
    requirement: "Requirement",
    reward: "Reward",
    min: "Min",
    max: "Max",
    amount: "Amount",
    rewards: "Rewards",
    submitBtnName: "Create Quest",
    errorMsgAfterSubmit:
      "Something went wrong, and new Quest wasn't created successfully",
    successMsgAfterSubmit: "New Quest was created successfully",
    successMsgAfterDeleteItem: "Item was deleted successfully",
    errorMsgAfterDeleteItem:
      "Something went wrong, and item wasn't deleted successfully",
    errorMsgTagsRarityCharactersEmpty:
      "(Tags & Rarity) or Characters shouldn't be empty",
  },
  createVirtualGood: {
    title: "Create Virtual Good",
    name: "Name*",
    description: "Description*",
    rarity: "Rarity*",
    type: "Type*",
    shortCode: "Short code*",
    tags: "Tags*",
    isDisabled: "Disabled",
    errorMsgAfterSubmit:
      "Something went wrong, and new Virtual Good wasn't created successfully",
    successMsgAfterSubmit: "New Virtual Good was created successfully",
  },
  setProbabilityConfig: {
    title: "Create & Update Probability Config",
    selectVirtualGood: "Select Virtual good",
    selectPreset: "Select preset",
    saveProbabilityConfig: "Save Probability Config",
    configName: "Config name",
    waterTitle: "Water",
    airTitle: "Air",
    forestTitle: "Forest",
    groundTitle: "Ground",
    rarity: "rarity",
    errorMsgAfterSubmit:
      "Something went wrong, and Probability Config wasn't created/updated successfully",
    successMsgAfterSubmit:
      "Probability Config was created/updated successfully",
    errorMsgAfterCreateUpdateProbabilityConfigPresetSubmit:
      "Something went wrong, and Probability Config Preset wasn't created/updated successfully",
    successMsgAfterCreateUpdateProbabilityConfigPresetSubmit:
      "Probability Config Preset was created/updated successfully",
    errorMsgAfterCreateUpdateProbabilityConfigPresetValidation:
      "config name must be at least 3 symbols, and the value can't be 'default'",
    main: " - main",
  },
};
