import { useCallback, useEffect } from "react";
import { useMatch } from "react-router-dom";
import { CHAT_STATUSES, ROUTE_PATHS } from "../consts";
import {
  addChatId,
  cleanUnreadChatIds,
  getUnreadChatIds,
  getWasWebsocketOpen,
  removeChatId,
} from "../slices/Websocket/slice";
import {
  WebsocketActionType,
  WebsocketMessageStatus,
  MessageSourceType,
} from "../slices/Websocket/types";
import { useDispatch, useSelector } from "../store";
import { useFeedError, useFeedSuccess } from "../utils/feedHooks";
import useWebsocket, { useCreateWebsocket } from "../utils/useWebsocket";

const WebsocketConnector = () => {
  useCreateWebsocket();

  const dispatch = useDispatch();
  const wasWebsocketOpen = useSelector(getWasWebsocketOpen);
  const unreadChatIds = useSelector(getUnreadChatIds);
  const match = useMatch("/:path");
  const matchPlayerID = useMatch("/:path/:id/edit");

  const {
    subscribeOnMessage,
    unsubscribeOnMessage,
    subscribeOnError,
    unsubscribeOnError,
  } = useWebsocket();

  const feedError = useFeedError();
  const feedSuccess = useFeedSuccess();

  useEffect(() => {
    if (
      (match?.params.path === ROUTE_PATHS.SUPPORT.slice(1) ||
        matchPlayerID?.params.path === ROUTE_PATHS.SUPPORT.slice(1)) &&
      unreadChatIds.length > 0
    ) {
      dispatch(cleanUnreadChatIds());
    }
  }, [
    dispatch,
    match?.params.path,
    matchPlayerID?.params.path,
    unreadChatIds.length,
  ]);

  const onMessageReceived = useCallback(
    (event: MessageEvent) => {
      const msg = JSON.parse(event.data);

      // -------------------------------------------------------
      // todo
      if (
        msg.action === WebsocketActionType.deletePlayers &&
        msg.status === WebsocketMessageStatus.success
      ) {
        feedSuccess(
          "The task to delete players was added to the queue successfully."
        );
      }
      if (
        msg.action === WebsocketActionType.distributionSystemUpdateTask &&
        msg.status === WebsocketMessageStatus.success
      ) {
        feedSuccess("distributionSystemUpdateTask success");
      }
      if (
        msg.action ===
          WebsocketActionType.distributionSystemUpdateOnePlayerTask &&
        msg.status === WebsocketMessageStatus.success
      ) {
        feedSuccess("distributionSystemUpdateOnePlayerTask success");
      }
      // -------------------------------------------------------
      if (
        msg.action === WebsocketActionType.createEventType &&
        msg.status === WebsocketMessageStatus.success
      ) {
        feedSuccess("eventCreatorCreateEvent success");
      }

      if (
        msg?.action === WebsocketActionType.replyFeedbackAdmin ||
        msg?.action === WebsocketActionType.sendFeedback
      ) {
        const msgType = msg.data?.message?.type;
        const playerID = msg.data?.playerID;
        const isChatOpen = msg.data?.status === CHAT_STATUSES.open;
        const isChatInUnread = unreadChatIds.includes(playerID);

        if (msgType === MessageSourceType.system) {
          const msgValue = msg.data.message.value;

          feedSuccess(msgValue);
        }

        if (isChatOpen) {
          if (!isChatInUnread && msgType === MessageSourceType.player) {
            dispatch(addChatId(playerID));
          }
          if (isChatInUnread && msgType === MessageSourceType.admin) {
            dispatch(removeChatId(playerID));
          }
        }

        if (
          (match?.params.path === ROUTE_PATHS.SUPPORT.slice(1) ||
            matchPlayerID?.params.path === ROUTE_PATHS.SUPPORT.slice(1)) &&
          matchPlayerID?.params.id !== playerID &&
          msgType === MessageSourceType.player
        ) {
          const playerName = msg.data?.displayUserName;
          const msgValue = msg.data?.message?.value;

          feedSuccess(`Player ${playerName || ""}: ${msgValue}`);
        }
      }

      if (msg?.scriptData?.message) {
        feedSuccess(msg?.scriptData?.message);
      }

      if (msg?.scriptData?.ERROR) {
        feedError(`Websocket error: ${msg.scriptData?.ERROR}`);
      }

      if (msg?.error) {
        feedError(`Websocket error: ${msg.error}`);
      }
      if (msg?.status === WebsocketMessageStatus.error) {
        feedError(msg.errorMessage);
      }
    },
    [
      dispatch,
      feedError,
      feedSuccess,
      match?.params.path,
      matchPlayerID?.params.id,
      matchPlayerID?.params.path,
      unreadChatIds,
    ]
  );

  useEffect(() => {
    if (wasWebsocketOpen) {
      subscribeOnMessage(onMessageReceived);
    }

    return () => {
      unsubscribeOnMessage(onMessageReceived);
    };
  }, [
    onMessageReceived,
    subscribeOnMessage,
    unsubscribeOnMessage,
    wasWebsocketOpen,
  ]);

  const handleOnError = useCallback(() => {
    feedError("Websocket error");
  }, [feedError]);

  useEffect(() => {
    if (wasWebsocketOpen) {
      subscribeOnError(handleOnError);
    }

    return () => {
      unsubscribeOnError(handleOnError);
    };
  }, [handleOnError, subscribeOnError, unsubscribeOnError, wasWebsocketOpen]);

  return null;
};

export default WebsocketConnector;
