import React from "react";
import { Helmet } from "react-helmet";

import { useTranslation } from "react-i18next";
import { useTitle } from "../../utils/useTitle";

const MapContent = () => {
  const { t } = useTranslation();
  const title = useTitle(t("navigation.mapContent"));

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <iframe
        src={process.env.REACT_APP_IFRAME_URL}
        style={{
          border: "none",
          height: "100%",
        }}
      />
    </>
  );
};

export default MapContent;
