export const GroupManagementSearchFieldsBack = [
  {
    key: "teamName",
    type: "text",
    label: "Team name",
    typeofData: "string",
  },
  {
    key: "teamID",
    type: "text",
    label: "TeamID",
    typeofData: "string",
  },
  {
    key: "teamLevel",
    type: "text",
    label: "Team Level",
    typeofData: "number",
  },
];
