export enum DistributionSystemFilterType {
  mongoQuery = "mongoQuery",
  playerIds = "playerIds",
  playerEmails = "playerEmails",
}

export enum DistributionSystemStatus {
  active = "active",
  inProgress = "inProgress",
  applied = "applied",
  error = "error",
}

export type EventFormsTypes = {
  vGoodShortCodes?: string[];
  onCreate: (data: Result) => void;
  inGameResponseMode?: boolean;
  playerID?: string;
};

type BaseResultType = {
  goods: Array<Record<string, number>>;
  currencies: Array<Record<string, number>>;
  pets: Array<Record<string, number>>;
  furniture: Array<Record<string, number>>;
  sendMailNotification: boolean;
  isMassMail: boolean;
  mailContent?: string;
  mailHeader?: string;
  webUrl?: string;
  sendPushNotification: boolean;
  pushMessage?: string;
  pushTitle?: string;
  filterType: `${DistributionSystemFilterType}`;
};

export type Result = BaseResultType & {
  filter: string | string[];
  executionTimeUTC: number | null;
  inGameResponseMode: boolean;
};

export type InputParamsType = BaseResultType & {
  query?: string;
  arrayStringValues?: string[];
  executionTimeUTC?: number | null;
};

export type FormValues = {
  filterData: string;
  filterType: `${DistributionSystemFilterType}`;
  virtualGoodCount: string;
  virtualGoodKey: string;
  currencyCount: string;
  currencyKey: string;
  petCount: string;
  petKey: string;
  furnitureCount: string;
  furnitureKey: string;
  sendMailNotification: boolean;
  isMassMail: boolean;
  mailContent: string;
  mailHeader: string;
  webUrl: string;
  sendPushNotification: boolean;
  pushMessage: string;
  pushTitle: string;
  executionTimeUTC: number | null;
};
