import React, { useCallback } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Box, styled } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { searchFieldsBackType } from "../../DataTable/DataTableTypes";

export type DropDownSearch = {
  addTypeOfSearchData: (searchData: string) => void;
  searchFieldsBack: searchFieldsBackType[];
};

export const DropDownSearch = ({
  addTypeOfSearchData,
  searchFieldsBack,
}: DropDownSearch) => {
  const handleChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      if (typeof event.target.value === "string") {
        addTypeOfSearchData(event.target.value);
      }
    },
    [addTypeOfSearchData]
  );

  const searchDropDown = searchFieldsBack.map((el, i) => (
    <MenuItem key={i} value={el.key}>
      {el.label}
    </MenuItem>
  ));

  return (
    <StyledBox>
      <FormControl>
        <Select
          sx={{ width: "130px", height: "40px" }}
          defaultValue={`${searchFieldsBack[0].key}`}
          onChange={handleChange}
        >
          {searchDropDown}
        </Select>
      </FormControl>
    </StyledBox>
  );
};

export const StyledBox = styled(Box)({
  width: "135px",
});
