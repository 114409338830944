import React, { memo, ReactNode } from "react";
import {
  Breakpoint,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { SpinnerButton } from "./SpinnerButton";
import { useTranslation } from "react-i18next";

export const defaultAlertMessageProps = { isShown: false };

export type AlertMessageProps = {
  isShown: boolean;
  isOkBtnLoading?: boolean;
  onClickOk?: () => void;
  onCancel?: () => void;
  title?: ReactNode;
  textOk?: string;
  textCancel?: string;
  isBtnOkDisabled?: boolean;
  children?: ReactNode;
  maxWidth?: false | Breakpoint;
};

const AlertMessage = ({
  isShown = false,
  onClickOk,
  onCancel,
  title,
  textOk,
  textCancel,
  isBtnOkDisabled = false,
  isOkBtnLoading = false,
  children,
  maxWidth = "sm",
}: AlertMessageProps) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={isShown}
      onClose={onCancel}
      fullWidth
      scroll="paper"
      maxWidth={maxWidth}
    >
      {title && <DialogTitle>{title}</DialogTitle>}
      {children && <DialogContent>{children}</DialogContent>}
      <DialogActions>
        <Button onClick={onCancel} sx={{ mr: 2 }}>
          {textCancel || t("buttonNames.cancel")}
        </Button>
        {onClickOk && (
          <SpinnerButton
            onClick={onClickOk}
            disabled={isBtnOkDisabled}
            loading={isOkBtnLoading}
            buttonName={textOk || t("buttonNames.ok")}
            type="button"
            variant="text"
          />
        )}
      </DialogActions>
    </Dialog>
  );
};

export default memo(AlertMessage);
