import React, { memo, useCallback, useMemo } from "react";
import parse from "html-react-parser";
import { sanitize } from "dompurify";

import { Box, Typography, Button, Stack } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import DvrOutlinedIcon from "@mui/icons-material/DvrOutlined";

import { ChatCancelBtn } from "./ChatCancelBtn";
import { useTranslation } from "react-i18next";
import { ChatMessageTypes, Message, MessageContent } from "../types";
import { MSG_TIMEOUT } from "../../../consts";
import { MessageSourceType } from "../../../slices/Websocket/types";

type ChatUIMessageProps = {
  message: Message<MessageContent>;
  showDate: boolean;
  showTime: boolean;
  onDelete: (uuid: string) => void;
};

const getColorParams = (msg: Message<MessageContent>) => {
  let msgBgColor = "grey.100";
  let msgColor = "text.primary";

  if (msg.self) {
    msgColor = "primary.contrastText";

    if (msg.type === ChatMessageTypes.TEXT) {
      msgBgColor = "primary.main";
    }

    if (msg.msgSourceType === MessageSourceType.internalNotes) {
      msgBgColor = "#f7ea90";
      msgColor = "text.primary";
    }

    if (
      msg.type === ChatMessageTypes.SENDING ||
      msg.type === ChatMessageTypes.PENDING
    ) {
      msgBgColor = "grey.500";
    }

    if (msg.type === ChatMessageTypes.SYSTEM) {
      switch (msg.systemMsgStatus) {
        // it was commeted because right now we don't save status in message, it is only for chat, so if we add status state in message, then we can use this logic
        // case CHAT_STATUSES.closed:
        //   msgBgColor = "error.light";
        //   break;
        // case CHAT_STATUSES.waitingResponse:
        //   msgBgColor = "warning.light";
        //   break;
        // case CHAT_STATUSES.waitingResponse:
        //   msgBgColor = "warning.light";
        //   break;
        // case CHAT_STATUSES.waitingTech:
        //   msgBgColor = "secondary.light";
        //   break;
        // case CHAT_STATUSES.handledInAsana:
        //   msgBgColor = "secondary.light";
        //   break;
        default:
          msgBgColor = "info.light";
          break;
      }
    }
  }

  return { msgBgColor, msgColor };
};

const ChatUIMessage = ({
  message,
  showDate,
  showTime,
  onDelete,
}: ChatUIMessageProps) => {
  const { t } = useTranslation();
  const dispDate = useMemo(
    () => message.updatedAt || message.createdAt,
    [message.createdAt, message.updatedAt]
  );

  const MessageFooter = useMemo(
    () => (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        maxWidth="100%"
        mx={1}
        mt="3px"
      >
        <Box display="flex" alignItems="center" mr={2}>
          {!message.self &&
            ((message.msgSourceType === MessageSourceType.player && (
              <PhoneIphoneIcon color="action" fontSize="small" sx={{ mr: 1 }} />
            )) ||
              (message.msgSourceType === MessageSourceType.playerMail && (
                <MailOutlineIcon
                  color="action"
                  fontSize="small"
                  sx={{ mr: 1 }}
                />
              )) ||
              (message.msgSourceType ===
                MessageSourceType.playerWebFeedback && (
                <DvrOutlinedIcon
                  color="action"
                  fontSize="small"
                  sx={{ mr: 1 }}
                />
              )))}
          <Typography variant="caption" color="textSecondary">
            {dispDate?.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
              hourCycle: "h24",
            })}
          </Typography>
        </Box>
        {!message.self && (
          <Typography variant="caption" color="textSecondary">
            {message.email}
          </Typography>
        )}
      </Box>
    ),
    [message.self, message.msgSourceType, message.email, dispDate]
  );

  const MessageHeader = useMemo(() => {
    const {
      self,
      username,
      webFeedbackModel,
      subject,
      attachments,
      adminOriginalEmail,
    } = message;
    return (
      <Stack minHeight={20} pb="4px" px={1}>
        {username && (
          <Box maxWidth="100%">
            <Typography variant="body2" align={self ? "right" : "left"}>
              {username}
            </Typography>
          </Box>
        )}
        {webFeedbackModel?.OS && (
          <Typography variant="caption" color="textSecondary" lineHeight={1.4}>
            <strong>{t("supportChats.titleOS")}: </strong>
            {webFeedbackModel.OS}
          </Typography>
        )}
        {webFeedbackModel?.model && (
          <Typography variant="caption" color="textSecondary" lineHeight={1.4}>
            <strong>{t("supportChats.titleModel")}: </strong>
            {webFeedbackModel.model}
          </Typography>
        )}
        {webFeedbackModel?.name && (
          <Typography variant="caption" color="textSecondary" lineHeight={1.4}>
            <strong>{t("supportChats.titleName")}: </strong>
            {webFeedbackModel.name}
          </Typography>
        )}
        {webFeedbackModel?.username && (
          <Typography variant="caption" color="textSecondary" lineHeight={1.4}>
            <strong>{t("supportChats.titleUserName")}: </strong>
            {webFeedbackModel.username}
          </Typography>
        )}
        {webFeedbackModel?.user_id && (
          <Typography variant="caption" color="textSecondary" lineHeight={1.4}>
            <strong>{t("supportChats.titleUserId")}: </strong>
            {webFeedbackModel.user_id}
          </Typography>
        )}
        {webFeedbackModel?.language && (
          <Typography variant="caption" color="textSecondary" lineHeight={1.4}>
            <strong>{t("supportChats.titleLanguage")}: </strong>
            {webFeedbackModel.language}
          </Typography>
        )}
        {webFeedbackModel?.schedule_date && (
          <Typography variant="caption" color="textSecondary" lineHeight={1.4}>
            <strong>{t("supportChats.titleScheduleDate")}: </strong>
            {webFeedbackModel.schedule_date} {webFeedbackModel?.schedule_time}
          </Typography>
        )}
        {attachments && (
          <Typography variant="caption" color="textSecondary" lineHeight={1.4}>
            <strong>{t("supportChats.titleAttachments")}: </strong>
            &quot;{attachments.map((item) => item.fileName).join('", "')}&quot;
          </Typography>
        )}
        {adminOriginalEmail && (
          <Typography variant="caption" color="textSecondary" lineHeight={1.4}>
            <strong>{t("supportChats.titleAdminEmail")}: </strong>
            {adminOriginalEmail}
          </Typography>
        )}
        {subject && (
          <Typography variant="caption" lineHeight={1.4}>
            <strong>
              {t("supportChats.titleSubject")}: {subject}
            </strong>
          </Typography>
        )}
      </Stack>
    );
  }, [message, t]);

  const ChatDeleteBtn = useMemo(
    () =>
      message.self &&
      message.type === ChatMessageTypes.TEXT && (
        <Button
          variant="text"
          size="small"
          sx={{
            position: "absolute",
            left: "-49px",
            top: "calc(50% - 12px)",
            minWidth: "auto",
            fontSize: "10px",
          }}
          color="error"
          onClick={() => onDelete(message.uuid)}
        >
          {t("buttonNames.delete")}
        </Button>
      ),
    [message.self, message.type, message.uuid, onDelete, t]
  );

  const { msgBgColor, msgColor } = useMemo(
    () => getColorParams(message),
    [message]
  );
  const msgContent = useMemo(() => {
    const purifiedHtml = sanitize(message.content.replaceAll("\n", "<br>"), {
      ALLOWED_TAGS: ["br", "p", "span"],
    });

    return parse(purifiedHtml);
  }, [message.content]);

  const handleCancelMsgSend = useCallback(
    () =>
      message.onCancel
        ? message.onCancel(message)
        : console.log("onCancel undefined"),
    [message]
  );

  const handleTimeOutMsgSend = useCallback(
    () =>
      message.onTimeOut
        ? message.onTimeOut()
        : console.log("onTimeOut undefined"),
    [message]
  );

  if (message.removed) {
    return <div id={message.uuid} />;
  }

  return (
    <Stack maxWidth="100%">
      {showDate && (
        <Typography align="center">{dispDate?.toLocaleDateString()}</Typography>
      )}
      <Box
        id={message.uuid}
        sx={{
          display: "flex",
          justifyContent: message.self ? "flex-end" : "flex-start",
          maxWidth: "100%",
          my: 2,
          pl: message.self ? "20%" : 0,
          pr: message.self ? 0 : "20%",
          overflowWrap: "break-word",
        }}
      >
        <Stack
          minWidth={0}
          pr={message.self ? "2px" : 0}
          pl={message.self ? 0 : "2px"}
          position="relative"
        >
          {message.self && message.type === ChatMessageTypes.PENDING && (
            <ChatCancelBtn
              timeToCount={MSG_TIMEOUT}
              onCancel={handleCancelMsgSend}
              onTimeOut={handleTimeOutMsgSend}
              sx={{
                position: "absolute",
                left: "-87px",
                top: "calc(50% - 16px)",
                fontSize: "12px",
                "& div": { top: "-1px" },
              }}
            />
          )}
          {MessageHeader}
          <Box
            sx={{
              position: "relative",
              maxWidth: "100%",
              py: 1,
              px: 2,
              bgcolor: msgBgColor,
              color: msgColor,
              borderRadius: 3,
              boxShadow: 2,
              "& p": { m: 0 },
            }}
          >
            {ChatDeleteBtn}
            {msgContent}
          </Box>
          {showTime ? MessageFooter : <Box sx={{ height: "22px" }} />}
        </Stack>
      </Box>
    </Stack>
  );
};

export default memo(ChatUIMessage);
