import { Box, Select, styled } from "@mui/material";

export const CustomSelectActive = styled(Select)({
  width: "250px",
  marginLeft: "0px",
  height: "40px",
});

export const ErrorBox = styled(Box)({
  color: "red",
  margin: "5px",
  height: "16px",
  fontSize: "0.8rem",
});
