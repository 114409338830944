import React from "react";
import { useSnackbar } from "notistack";
import { useCallback } from "react";
import { Button, styled } from "@mui/material";
import { Link } from "react-router-dom";
import i18n from "../i18n";
import { FEED_TIMEOUT } from "../consts";

export const useFeedError = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useCallback(
    (message: string) =>
      enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: FEED_TIMEOUT,
      }),
    [enqueueSnackbar]
  );
};

export const useFeedSuccess = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useCallback(
    (message: string, link?: string) =>
      enqueueSnackbar(message, {
        variant: "success",
        autoHideDuration: FEED_TIMEOUT,
        action: link ? (
          <OpenButton variant="outlined" component={Link} to={link}>
            {i18n.t("open")}
          </OpenButton>
        ) : null,
      }),
    [enqueueSnackbar]
  );
};

const OpenButton = styled(Button, {
  shouldForwardProp: () => true,
})<Record<string, unknown>>({
  color: "rgba(255, 255, 255, 0.7)",
  borderColor: "rgba(255, 255, 255, 0.7)",
  ":hover": {
    color: "white",
    borderColor: "white",
  },
});
