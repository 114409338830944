import { Box, Select, styled, Typography } from "@mui/material";

export const CustomTypography = styled(Typography)({
  width: "250px",
});

export const CustomBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  margin: "1px 1px 5px 1px",
});

export const CustomDropDownBox = styled(Box)({
  marginRight: "10px",
  display: "flex",
  flexDirection: "column",
});

export const CustomRewardsLeaderboardsBox = styled(Box)({
  display: "flex",
  marginBottom: "20px",
  justifyContent: "space-around",
  width: "600px",
  alignItems: "center",
});

export const CustomRewardsLeaderboardsBoxWidth = styled(Box)({
  display: "flex",
  marginBottom: "20px",
  justifyContent: "space-around",
  width: "1050px",
  alignItems: "center",
});

export const CustomSelect = styled(Select)({
  width: "120px",
  height: "40px",
  marginRight: "10px",
});

export const CustomSelectActive = styled(Select)({
  width: "120px",
  marginLeft: "20px",
  height: "40px",
});

export const CustomErrorBox = styled(Box)({
  color: "#d32f2f",
  margin: "5px",
});

export const CustomTypographyRewardsTop = styled(Typography)({
  width: "120px",
  variant: "subtitle1",
  component: "h2",
  paddingTop: "10px",
});

export const EventErrorBox = styled(Box)({
  color: "red",
  height: "14px",
  fontSize: "14px",
  marginBottom: "3px",
  paddingLeft: "10px",
});

export const CustomVerticalHolder = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

export const CustomLayoutBox = styled(Box)({
  display: "flex",
  width: "280px",
  alignItems: "center",
  justifyContent: "space-between",
});
