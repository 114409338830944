import React, { memo, ReactNode } from "react";

import { Control, Controller } from "react-hook-form";
import { Checkbox } from "@mui/material";
import { CustomBox, CustomTypography } from "./StyledComponents";

import { CreateQuestFormValues } from "../../container/Quests/types";
import { CreateVirtualGoodFormValues } from "../../container/VirtualGoods/types";

type ControlledCheckboxProps = {
  control: Control<any>;
  title?: ReactNode;
  label: ReactNode;
  fieldName:
    | keyof CreateQuestFormValues
    | keyof CreateVirtualGoodFormValues
    | string;
  isDisabled?: boolean;
};

const ControlledCheckbox = ({
  control,
  title,
  label,
  fieldName,
  isDisabled = false,
}: ControlledCheckboxProps) => {
  return (
    <CustomBox>
      {title && (
        <CustomTypography
          sx={{ color: isDisabled ? "rgba(0, 0, 0, 0.38)" : "initial" }}
        >
          {title}
        </CustomTypography>
      )}
      <Controller
        control={control}
        name={fieldName}
        render={({ field }) => (
          <>
            <Checkbox
              {...field}
              checked={!!field.value}
              disabled={isDisabled}
              sx={{ px: 0, py: 1, mr: 1 }}
            />
            <h4
              style={{
                margin: 0,
                color: isDisabled ? "rgba(0, 0, 0, 0.38)" : "initial",
              }}
            >
              {label}
            </h4>
          </>
        )}
      />
    </CustomBox>
  );
};

export default memo(ControlledCheckbox);
